

import * as React from 'react';

import { CircularProgress, Backdrop,} from '@mui/material';

export default function AguardarRetorno(props) {

    return (
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.show}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }