// import React from "react";
import { TextField } from "@material-ui/core";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr from "../../components/LocalesPtBr";

import {
  LinearProgress,FormControl,InputLabel,InputAdornment,FilledInput,
} from '@mui/material';

import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// components
import PageTitle from "../../components/PageTitle";
import { Button } from "../../components/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import Config from '../../config/Config';
import AguardarRetorno from "../../components/BackDrop/AguardarRetorno";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  // { id: 'id', label: 'id', minWidth: '10%' },
  { id: 'editar', label: '', minWidth: '5%' },
  { id: 'descricao', label: 'Descrição', minWidth: '80%' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function OperationFinance(props) {

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [listaDados, setListaDados] = useState([]);
  const [idselecionado, setIdSelecionado] = useState(0);
  const [valorPesquisa, setValorPesquisa] = useState('');

  const [exibirProgress, setExibirProgress] = useState(true);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaDados.length) : 0;

  const [recarregar,SetRecarregar] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function listarRegistros(valor) {

    if(valor===undefined){
      setimpedirMultClick(true);

      //caso nao tenha valor informado pega do campo
      valor=valorPesquisa;
    }


    SetRecarregar(false);

    const response = await fetch(`${Config.urlRoot}/operationfinance/list`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        pesquisar:valor,
        sort:""
      })
    });

    let json = await response.json();

    setExibirProgress(false);

    if(response.status!==200 && response.status!==201){
        setListaDados([]);
    }
    else {
      setListaDados(json);
    }

    setimpedirMultClick(false);

  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {

    if(recarregar===true){
      setExibirProgress(true);
      SetRecarregar(false);

      listarRegistros("");
    }

  }, [listarRegistros, recarregar]);

  const registroSelecionado = (id,index) => {
    setIdSelecionado(id);

    const banc=listaDados[index];

    props.history.push({
      pathname: '/app/OperationFinanceRegister',
      idregistro: { text: id },
      banc: { text: banc }
    });
  }

  const novoRegistro = () => {

    const banc={
      id:'0',
      codigo:0,
      banco:'',
      descricao:'',
      agencia:'',
      digito_ag:'',
      conta:'',
      digito_conta:'',
      datacadastro:'',
      dataalteracao:'',
      responsavel:'',
      mensagem1:'',
      mensagem2:'',
      diasprotesto:0,
      nossonumero:'',
      carteira:'',
      convenio:'',
      protestar:'false',
      cnab:'',
      jurosdemora:0,
      multa:0,
      especie:'',
      codigoempresa:'',
      qtdremessa:0,
      desativar:'false',
      apiboleto:'false',
    }

    props.history.push({
      pathname: '/app/OperationFinanceRegister',
      idregistro: { text: '0' },
      banc: { text: banc },
    });
  }

  const pesquisarRegistros = (valor) => {

    setValorPesquisa(valor);
    listarRegistros(valor);
  }

  const handleChangeClickPesquisar = () => {

    pesquisarRegistros(undefined);

  };

  return (
    <>
      <PageTitle title="Operações Financeiras (Lista)" button="Novo" eventoBotao={novoRegistro} />

      {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

      <AguardarRetorno show={impedirMultClick} />

      <TableContainer component={Paper}>
        <ThemeProvider theme={LocalesPtBr()}>
          <Table  size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                    <FormControl fullWidth sx={{ m: 0.6, width: '45ch' }} variant="filled">
                      <InputLabel htmlFor="tfPesquisar">Pesquisar ...</InputLabel>
                      <FilledInput
                        id="tfPesquisar"
                        size="small"
                        // defaultValue={fildRazao||''}
                        onChange={(e) => pesquisarRegistros(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleChangeClickPesquisar}
                            >
                              <SearchIcon color="primary" />
                            </IconButton>
                          </InputAdornment>}
                      />
                    </FormControl>
{/*
                <TextField
                fullWidth
                  id="tfPesquisar"
                  margin="normal"
                  placeholder="Pesquisar ..."
                  type="text"
                  style={{ marginLeft: 15, paddingLeft: 10}}
                  onChange={(e) => pesquisarRegistros(e.target.value)}
                />
                <IconButton color="primary" aria-label="add to shopping cart" size="large"
                  onClick={() => listarRegistros()}
                >
                  <SearchIcon fontSize="inherit" />
                </IconButton> */}
              </TableRow>
            </TableHead>

            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? listaDados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : listaDados
              ).map((row,index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" >
                    <IconButton size="small"
                      onClick={() => registroSelecionado(row.id,index)}>
                      <EditIcon fontSize="inherit" color={"success"} />
                    </IconButton>
                  </TableCell>

                  <TableCell style={{ width: "80%" }} align="left">
                    {row.descricao}
                  </TableCell>

                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={listaDados.length}
                  rowsPerPage={rowsPerPage}
                  page={page}

                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Linhas por página',
                    },
                    native: true,
                  }}

                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </ThemeProvider>
      </TableContainer>
    </>
  );
}
