// import React from "react";
import { Grid, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


import {
  LinearProgress,
} from '@mui/material';

import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import * as locales from '@mui/material/locale';

import {
  FormControl,InputLabel,InputAdornment,FilledInput
} from '@mui/material';

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Button } from "../../components/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd } from "../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr  from "../../components/LocalesPtBr";

import Config from '../../config/Config';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: '', label: '', minWidth: '5%' },
  { id: 'razao', label: 'Razão Social', minWidth: '40%' },
  { id: 'fantasia', label: 'Nome Fantasia', minWidth: '40%' },
  { id: 'cnpj', label: 'CPF/CNPJ', minWidth: '15%' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ListTransport(props) {

  const [recarregar,SetRecarregar] = useState(true);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [listaDados, setListaDados] = useState([]);
  const [idselecionado, setIdSelecionado] = useState(0);
  const [valorPesquisa, setValorPesquisa] = useState('');

  const [exibirProgress, setExibirProgress] = useState(true);

  const [carregarListaAuxiliar,SetCarregarListaAuxiliar] = useState(false);

  const [listaPlanoContas, setListaPlanoContas] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaDados.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 // eslint-disable-next-line react-hooks/exhaustive-deps
 async function listarRegistros(valor) {

  if(valor===undefined){
    //caso nao tenha valor informado pega do campo
    valor=valorPesquisa;
  }

  const response = await fetch(`${Config.urlRoot}/transporter/search`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      dataBase:configuracaoBd,
      pesquisar:valor,
      sort:""
    })
  });

  let json = await response.json();

  setExibirProgress(false);

  if(response.status!==200 && response.status!==201){
  setListaDados([]);
    return;
  }

  setListaDados(json);
}

async function listasAuxiliares() {

  const response = await fetch(`${Config.urlRoot}/transporter/listAux`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      dataBase:configuracaoBd,
    })
  });

  let json = await response.json();

  setExibirProgress(false);

  if(response.status!==200 && response.status!==201){
    return;
  }

  setListaPlanoContas(json.planocontasList);

}

var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

useEffect(() => {

  if(!carregarListaAuxiliar){
    SetCarregarListaAuxiliar(false);

    listasAuxiliares();
  }
},[carregarListaAuxiliar]);

useEffect(() => {

  if(recarregar){
    setExibirProgress(true);
    SetRecarregar(false);

    listarRegistros("");

  }

}, [listarRegistros, recarregar]);

  const registroSelecionado = (id,index) => {
    setIdSelecionado(id);

    const transp = listaDados[index];

    props.history.push({
      pathname: '/app/TransportRegister',
      idregistro: { text: id },
      transp: { text: transp },
      planocontas:{ text:listaPlanoContas},
    });
  }

  const novoRegistro = () => {

    const transp = {codigo: ""
    ,razao: ""
    ,fantasia: ""
    ,endereco: ""
    ,bairro: ""
    ,cidade: ""
    ,codigocidade: ""
    ,uf: ""
    ,codigouf: ""
    ,cep: ""
    ,telefone1: ""
    ,telefone2: ""
    ,celular: ""
    ,contato: ""
    ,cpf_cnpj: ""
    ,rg_insc: ""
    ,email: ""
    ,datacad: ""
    ,datacadastro: ""
    ,dataalteracao: ""
    ,responsavel: ""
    ,status: ""
    ,plano: ""
    ,tipotransportador: ""
    ,homepage: ""
    ,observacao: ""};

    props.history.push({
      pathname: '/app/TransportRegister',
      idregistro: { text: '0' },
      transp: { text: transp },
      planocontas:{ text:listaPlanoContas},
    });
  }

  const pesquisarRegistros = (valor) => {
    setValorPesquisa(valor);
    listarRegistros(valor);

  }

  return (
    <>
      <PageTitle title="Transportadores (Lista)" button="Novo" eventoBotao={novoRegistro} />
      {/* <Grid container spacing={4}> */}

      {exibirProgress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      <ThemeProvider theme={LocalesPtBr()}>
        <TableContainer component={Paper}>
          {/* <Grid item xs={{minWidth: 500}}> */}

          <Box>
            <FormControl fullWidth sx={{ m: 0.6, width: '45ch' }} variant="filled">
              <InputLabel htmlFor="tfPesquisar">Pesquisar ...</InputLabel>
              <FilledInput
                id="tfPesquisar"
                size="small"
                // defaultValue={fildRazao||''}
                onChange={(e) => pesquisarRegistros(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => listarRegistros()}
                    >
                      <SearchIcon color="primary" />
                    </IconButton>
                  </InputAdornment>}
              />
            </FormControl>
          </Box>

          <Table  size="small" >

            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? listaDados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : listaDados
              ).map((row,index) => (
                <TableRow key={row.id}>

                 <TableCell align="center">
                    <IconButton size="small"
                        onClick={() => registroSelecionado(row.id,index)} //atualiza o item da compra
                        >
                      <EditIcon fontSize="inherit" color={"success"} />
                    </IconButton>

                  </TableCell>

                  <TableCell
                    align="left">
                    {row.razao}
                  </TableCell>
                  <TableCell align="left">
                    {row.fantasia}
                  </TableCell>
                  <TableCell align="left">
                    {row.cpf_cnpj}
                  </TableCell>

                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={listaDados.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  //

                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Linhas por página',

                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>

            {/* <Table data={mock.table} />
            <TableFooter>
            <TableRow>

            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 100]}
              count={mock.table.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            </TableRow>
            </TableFooter> */}
          </Table>
          {/* </Grid> */}
          {/* </Grid> */}
        </TableContainer>
      </ThemeProvider>
    </>
  );
}
