// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';


import {
  FormControl,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Input,InputAdornment
} from '@mui/material';

import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import { Button } from "../../../components/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd } from "../../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import DadosCfop from "../../../constant/DadosCfop";
import Config from '../../../config/Config';



export default function TransportRegister(props) {

  const [recarregarCidade,SetRecarregarCidade] = useState(false);

  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  const [exibirProgressMunicipios, setExibirProgressMunicipios] = useState(false);
  const [exibirProgressPlanoContas, setExibirProgressPlanoContas] = useState(false);

  const [lsPlanoContas, setLsPlanoContas] = useState(props.location.planocontas.text);

  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.transp.text);


  const [fildId, setFildId] = useState(props.location.idregistro.text);
  const [fildCodigo, setFildCodigo] = useState(dadosRegistro.codigo);
  const [fildRazao, setFildRazao] = useState(dadosRegistro.razao);
  const [fildFantasia, setFildFantasia] = useState(dadosRegistro.fantasia);
  const [fildEndereco, setFildEndereco] = useState(dadosRegistro.endereco);
  const [fildBairro, setFildBairro] = useState(dadosRegistro.bairro);
  const [fildCodigoCidade, setFildCodigoCidade] = useState(dadosRegistro.codigocidade);
  const [fildCodigoUf, setFildCodigoUf] = useState(dadosRegistro.codigouf);
  const [fildCep, setFildCep] = useState(dadosRegistro.cep);
  const [fildTelefone1, setFildTelefone1] = useState(dadosRegistro.telefone1);
  const [fildTelefone2, setFildTelefone2] = useState(dadosRegistro.telefone2);
  const [fildCelular, setFildCelular] = useState(dadosRegistro.celular);
  const [fildContato, setFildContato] = useState(dadosRegistro.contato);
  const [fildCnpj, setFildCnpj] = useState(dadosRegistro.cpf_cnpj);
  const [fildInscricao, setFildInscricao] = useState(dadosRegistro.rg_insc);
  const [fildEmail, setFildEmail] = useState(dadosRegistro.email);
  const [fildStatus, setFildStatus] = useState(dadosRegistro.status);
  const [fildPlano, setFildPlano] = useState(dadosRegistro.plano);
  const [fildObservacao, setFildObservacao] = useState(dadosRegistro.observacao);
  const [fildDataCadastro, setFildDataCadastro] = useState(dadosRegistro.datacadastro);
  const [fildDataAtualizacao, setFildDataAtualizacao] = useState(dadosRegistro.dataatualizacao);
  const [fildResponsavel, setFildResponsavel] = useState(dadosRegistro.responsavel);
  //Fim dos campos do formulario

  const [dadosMunicipio, setDadosMunicipios] = useState([]);
  const dadosUf = [
    {
      "cuf": "11",
      "uf": "RO"
    },
    {
      "cuf": "12",
      "uf": "AC"
    },
    {
      "cuf": "13",
      "uf": "AM"
    },
    {
      "cuf": "14",
      "uf": "RR"
    },
    {
      "cuf": "15",
      "uf": "PA"
    },
    {
      "cuf": "16",
      "uf": "AP"
    },
    {
      "cuf": "17",
      "uf": "TO"
    },
    {
      "cuf": "21",
      "uf": "MA"
    },
    {
      "cuf": "22",
      "uf": "PI"
    },
    {
      "cuf": "23",
      "uf": "CE"
    },
    {
      "cuf": "24",
      "uf": "RN"
    },
    {
      "cuf": "25",
      "uf": "PB"
    },
    {
      "cuf": "26",
      "uf": "PE"
    },
    {
      "cuf": "27",
      "uf": "AL"
    },
    {
      "cuf": "28",
      "uf": "SE"
    },
    {
      "cuf": "29",
      "uf": "BA"
    },
    {
      "cuf": "31",
      "uf": "MG"
    },
    {
      "cuf": "32",
      "uf": "ES"
    },
    {
      "cuf": "33",
      "uf": "RJ"
    },
    {
      "cuf": "35",
      "uf": "SP"
    },
    {
      "cuf": "41",
      "uf": "PR"
    },
    {
      "cuf": "42",
      "uf": "SC"
    },
    {
      "cuf": "43",
      "uf": "RS"
    },
    {
      "cuf": "50",
      "uf": "MS"
    },
    {
      "cuf": "51",
      "uf": "MT"
    },
    {
      "cuf": "52",
      "uf": "GO"
    },
    {
      "cuf": "53",
      "uf": "DF"
    },
    {
      "cuf": "00",
      "uf": "EX"
    }
  ];

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const [openModalMunicipios, setOpenModalMunicipios] = useState(false);
  const [currency, setCurrency] = useState(fildId);

  const [validaRazao, setValidaRazao] = useState(false);
  const [msgErroRazao, setMsgErroRazao] = useState('');

  const [validaFantasia, setValidaFantasia] = useState(false);
  const [msgErroFantasia, setMsgErroFantasia] = useState('');

  const [validaCgc, setValidaCgc] = useState(false);
  const [msgErroCgc, setMsgErroCgc] = useState('');

  const [validaUf, setValidaUf] = useState(false);
  const [msgErroUf, setMsgErroUf] = useState('');

  const [cepJaConsultado, setCepJaConsultado] = useState(false);
  const [cnpjJaConsultado, setCnpjJaConsultado] = useState(false);

  const valoresAtivo = [
    {
      value: 'ATIVO',
      label: 'Sim',
    },
    {
      value: 'INATIVO',
      label: 'Não',
    }
  ];


  const handleChange = (event) => {
    // setFildBanco(event.target.value);
  };


  const handleChangeAtivo = (event) => {
    setFildStatus(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleChangeUf = (event) => {
    setFildCodigoUf(event.target.value);
    SetRecarregarCidade(true);
  }

  const handleChangePlanoContas = (event) => {
    setFildPlano(event.target.value);
  }

  const handleChangeCidade = (event) => {
    setFildCodigoCidade(event.target.value);
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeCep = (e) => {
    setFildCep(e);

    if (e.length !== 8) {
      setCepJaConsultado(false);
    }

    ConsultarCep();
  }

  const handleChangeCnpj = (e) => {
    setFildCnpj(e);

    if (e.length !== 14) {
      setCnpjJaConsultado(false);
      //return;
    }

  }

  //Listas

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function listarMunicipios(_uf) {

    //Chama o progress
    setExibirProgressMunicipios(true);

    //Chama a API
    const response = await fetch(`${Config.urlRootMaster}/coutryibge/list`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        cuf:fildCodigoUf,
        cidade:"",
        order:""
      })
    });

    let json = await response.json();

    if(response.status!==200){
      setDadosMunicipios([]);
      setExibirProgressMunicipios(false);

      return;
    }

    setDadosMunicipios(json);
    setExibirProgressMunicipios(false);

  }

  async function ConsultarCep() {

    if (fildCep.length === 8 && cepJaConsultado === false) {
      //Chama a API

      const response = await fetch('https://viacep.com.br/ws/' + fildCep + '/json/');

      let json = await response.json();

      if (json.erro === 'true') {

      }
      else {
        setCepJaConsultado(true);

        const c_uf = dadosUf.find(element => element.uf === json.uf).cuf;

        setFildCodigoUf(c_uf);

        setFildEndereco(json.logradouro + " , " + json.complemento);
        setFildBairro(json.bairro);
        setFildCodigoCidade(json.ibge);
      }

    }

  }

  async function ConsultarCnpj() {
    setimpedirMultClick(true);

    if (fildCnpj.length === 14) {

      const response = await fetch('https://www.trinityst.com.br/apiAntares/apiUtilitarios.php', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          requisicao: 'consulta-cnpj',
          senha: senhaRetorno,
          login: usuarioRetorno,
          cnpj: fildCnpj
        })
      });

      let json = await response.json();

      if (json.status === "ERROR") {

        setimpedirMultClick(false);

        setTipoMensagem('error');
        setTextoMensagem(json.message);
        handleOpenMessage();

      } else {

        //const itemExists = dadosUf.find((item) => item.uf === json.uf);

        dadosUf.map((item) => {
          if (item.uf.toLowerCase() === json.uf.toLowerCase()) {

            setFildCodigoUf(item.cuf);
          }
        });

        setTimeout(() => {

          dadosMunicipio.map((item) => {

            if (item.municipio.toLowerCase() === json.municipio.toLowerCase()) {

              setFildCodigoCidade(item.cmunicipio);
            }
          });

        }, 4000);

        setFildRazao(json.nome);
        setFildFantasia(json.fantasia);
        setFildBairro(json.bairro);
        setFildEndereco(json.logradouro);
        setFildCep(json.cep);

        try { setFildTelefone1(json.telefone); } catch { }
        try { setFildEmail(json.email); } catch { }

        setimpedirMultClick(false);

      }

    }

  }

  //Fim Listas

  async function salvarDados() {

    if (fildRazao.length === 0) {
      setValidaRazao(true);
      setMsgErroRazao('Informe a Razão Social!');
    }

    if (fildFantasia.length === 0) {
      setValidaFantasia(true);
      setMsgErroFantasia('Informe o Nome Fantasia!');
    }

    let _cpf_cnpj = fildCnpj.replace('.', '').replace('-', '').replace('/', '');

    if (_cpf_cnpj.length !== 11 && _cpf_cnpj.length !== 14) {
      setValidaCgc(true);
      setMsgErroCgc('CPF/CNPJ inválido!');
    }

    if (fildRazao.length === 0) {

      setValidaRazao(true);
      setMsgErroRazao('Informe a Razão Social!');
    }
    else {
      //Chama o progress
      setExibirProgress(true);

      //Limpa a validação do campo
      setValidaRazao(false);
      setMsgErroRazao('');

      setValidaFantasia(false);
      setMsgErroFantasia('');

      //Chama a API

      const registro = [{
        id:  parseInt(fildId, 10)
        , codigo:fildCodigo
        , razao: fildRazao
        , fantasia: fildFantasia
        , endereco: fildEndereco
        , bairro: fildBairro
        , codigocidade: fildCodigoCidade
        , codigouf: fildCodigoUf
        , cep: fildCep
        , telefone1: fildTelefone1
        , telefone2: fildTelefone2
        , celular: fildCelular
        , contato: fildContato
        , cpf_cnpj: fildCnpj
        , rg_insc: fildInscricao
        , email: fildEmail
        , status: fildStatus
        , plano: fildPlano
        , observacao: fildObservacao
        , responsavel:usuarioRetorno
      }];

      const response = await fetch(`${Config.urlRoot}/transporter`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //id restornado
        setFildId(json[0].id);

        setFildCodigo(json[0].codigo);
        // setFildRazao(json.razao);
        // setFildFantasia(json.fantasia);
        // setFildEndereco(json.endereco);
        // setFildBairro(json.bairro);
        // setFildCodigoCidade(json.codigocidade);
        // setFildCodigoUf(json.codigouf);
        // setFildCep(json.cep);
        // setFildTelefone1(json.telefone1);
        // setFildTelefone2(json.telefone2);
        // setFildCelular(json.celular);
        // setFildContato(json.contato);
        // setFildCnpj(json.cpf_cnpj);
        // setFildInscricao(json.rg_insc);
        // setFildEmail(json.email);
        // setFildStatus(json.status);
        // setFildPlano(json.plano);
        // setFildObservacao(json.observao);
        setFildDataCadastro(json.created_at);
        setFildDataAtualizacao(json.updated_at);
        // setFildResponsavel(json.resposavel);
      }

      //Encerra o progress
      setExibirProgress(false);

      handleOpenMessage();
    }
  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {

    if (recarregarCidade) {
      if (fildCodigoUf.length > 0) {
        SetRecarregarCidade(false);
        listarMunicipios(fildCodigoUf);
      }
    }

  }, [fildCodigoUf, listarMunicipios, recarregarCidade]);

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModalMunicipios = () => {
    setOpenModalMunicipios(true);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function botaoVoltar() {
    props.history.push('/app/Transport');
  }

  function botaoNovo() {

    setFildId('0');
    setFildRazao('');
    setFildFantasia('');
    setFildEndereco('');
    setFildBairro('');
    setFildCodigoCidade('');
    setFildCodigoUf('');
    setFildCep('');
    setFildTelefone1('');
    setFildTelefone2('');
    setFildCelular('');
    setFildContato('');
    setFildCnpj('');
    setFildInscricao('');
    setFildEmail('');
    setFildStatus('ATIVO');
    setFildPlano('');
    setFildObservacao('');
    setFildDataCadastro('');
    setFildDataAtualizacao('');
    setFildResponsavel('');
  }


  return (
    <>


      <PageTitle title="Transportador "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      <Grid container spacing={2}>

        <Grid item xs={16} md={4} >
          <Card variant="outlined">

            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '65ch' }, }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="tfRazao"
                  label="Razão Social"
                  value={fildRazao}
                  //defaultValue={fildRazao}
                  variant="filled"
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildRazao(event.target.value)}
                  error={validaRazao}
                  fullWidth
                />
              </div>

              <div>
                <TextField
                  id="tfFantasia"
                  label="Nome Fantasia"
                  variant="filled"
                  value={fildFantasia}
                  //defaultValue={fildFantasia}
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildFantasia(event.target.value)}
                  error={validaFantasia}
                  fullWidth
                />
              </div>
              <div>
                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 1, width: '31.5ch' }, }}
                  noValidate
                  autoComplete="off"
                >

                  <FormControl fullWidth variant="filled">
                    <InputLabel htmlFor="tfCpfCnpj">CPF / CNPJ</InputLabel>
                    <Input
                      id="tfCpfCnpj"
                      value={fildCnpj}
                      //defaultValue={fildCnpj}
                      inputProps={{ maxLength: 14 }}
                      variant="filled"
                      onChange={(event) => handleChangeCnpj(event.target.value)}
                      error={validaCgc}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={(event) => ConsultarCnpj()}

                          >
                            <SearchIcon color="primary" />
                          </IconButton>
                        </InputAdornment>}
                    />
                  </FormControl>

                  <TextField
                    id="tfIe"
                    label="Inscrição estadual"
                    variant="filled"
                    value={fildInscricao}
                    //defaultValue={fildInscricao}
                    inputProps={{ maxLength: 18 }}
                    onChange={(event) => setFildInscricao(event.target.value)}
                  />

                </Box>
              </div>

              <div>
                <div>

                  <Box
                    component="form"
                    sx={{ '& .MuiTextField-root': { m: 1, width: '31.5ch' }, }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="tfFon1"
                      label="Fone 1"
                      variant="filled"
                      value={fildTelefone1}
                      //defaultValue={fildTelefone1}
                      inputProps={{ maxLength: 11 }}
                      onChange={(event) => setFildTelefone1(event.target.value)}
                      fullWidth
                    // style={{width:186}}
                    />

                    <TextField
                      id="tfFone2"
                      label="Fone 2"
                      variant="filled"
                      value={fildTelefone2}
                      //defaultValue={fildTelefone2}
                      inputProps={{ maxLength: 11 }}
                      onChange={(event) => setFildTelefone2(event.target.value)}
                    // style={{width:186}}
                    />
                  </Box>
                </div>
              </div>

              <div>
                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 1, width: '31.5ch' }, }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="tfCelular"
                    label="Celular"
                    variant="filled"
                    value={fildCelular}
                    //defaultValue={fildCelular}
                    inputProps={{ maxLength: 11 }}
                    onChange={(event) => setFildCelular(event.target.value)}
                  // style={{width:186}}
                  />

                  <TextField
                    id="tfContato"
                    label="Contato"
                    variant="filled"
                    value={fildContato}
                    //defaultValue={fildContato}
                    inputProps={{ maxLength: 25 }}
                    onChange={(event) => setFildContato(event.target.value)}
                  // style={{width:186}}
                  />
                </Box>
              </div>


              <div>
                <TextField
                  id="tfEmail"
                  label="Email"
                  variant="filled"
                  value={fildEmail}
                  //defaultValue={fildEmail}
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildEmail(event.target.value)}
                  fullWidth
                />
              </div>

              <div>

                <TextField
                  id="tfStatus"
                  select
                  label="Ativo"
                  value={fildStatus}
                  onChange={handleChangeAtivo}
                  variant="filled"
                >
                  {valoresAtivo.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} >
          <Card variant="outlined">

            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '65ch' },
              }}
              noValidate
              autoComplete="off"
            >

              <div>

                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 1, width: '51.5ch' }, }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField
                    id="tfCep"
                    label="Cep"
                    variant="filled"
                    value={fildCep}
                    //defaultValue={fildCep}
                    inputProps={{ maxLength: 8 }}
                    onChange={(event) => handleChangeCep(event.target.value)}
                    // onBlur={ConsultarCep()}
                  // style={{ width: 400 }}
                  />

                  <TextField
                    id="tfUf"
                    select
                    label="UF"
                    value={fildCodigoUf}
                    onChange={handleChangeUf}
                    variant="filled"
                    style={{ width: 90 }}
                  >
                    {dadosUf.map((option) => (
                      <MenuItem key={option.cuf} value={option.cuf}>
                        {option.uf}
                      </MenuItem>
                    ))}
                  </TextField>

                </Box>

                <TextField
                  id="tfEndereco"
                  label="Endereço"
                  variant="filled"
                  value={fildEndereco}
                  //defaultValue={fildEndereco}
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildEndereco(event.target.value)}
                  fullWidth
                />
              </div>

              <div>
                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 1, width: '65ch' }, }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="tfBairro"
                    label="Bairro"
                    variant="filled"
                    value={fildBairro}
                    //defaultValue={fildBairro}
                    inputProps={{ maxLength: 50 }}
                    onChange={(event) => setFildBairro(event.target.value)}
                    fullWidth
                  />

                </Box>
              </div>

              <div>
                {exibirProgressMunicipios && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

                <TextField
                  id="tfCidade"
                  select
                  label="Cidade"
                  value={fildCodigoCidade}
                  onChange={handleChangeCidade}
                  variant="filled"
                // style={{width:400}}

                >
                  {dadosMunicipio.map((option) => (
                    <MenuItem key={option.cmunicipio} value={option.cmunicipio}>
                      {option.municipio}
                    </MenuItem>
                  ))}
                </TextField>

              </div>

              <div>
                {exibirProgressPlanoContas && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

                <TextField
                  id="tfPlanoContas"
                  select
                  label="Plano de Contas"
                  value={fildPlano}
                  onChange={handleChangePlanoContas}
                  variant="filled"
                >
                  {lsPlanoContas.map((option) => (
                    <MenuItem key={option.codigoresumido} value={option.codigoresumido}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <TextField
                id="tfObservacao"
                label="Observação"
                multiline
                rows={4}
                variant="filled"
                value={fildObservacao}
                //defaultValue={fildObservacao}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => setFildObservacao(event.target.value)}
              />
            </Box>
          </Card>
        </Grid>


        <footer>

          <Grid container spacing={0}>
            <Grid item xs={12} md={12} style={{ marginLeft: 15, marginRight: 0 }}>
              <Card variant="outlined">
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '139ch' }}>
                  {/* <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '100ch' },
        }}
        noValidate
        autoComplete="off"
      > */}
                  <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfDataCadastro">Data do Cadastro</InputLabel>
                    <FilledInput
                      id="tfDataCadastro"
                      value={fildDataCadastro}
                      //defaultValue={fildDataCadastro}
                      onChange={(event) => setFildDataCadastro(event.target.value)}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfDataAlteracao">Data da Alteração</InputLabel>
                    <FilledInput
                      id="tfDataAlteracao"
                      value={fildDataAtualizacao}
                      //defaultValue={fildDataAtualizacao}
                      onChange={(event) => setFildDataAtualizacao(event.target.value)}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfResponsavel">Responsável</InputLabel>
                    <FilledInput
                      id="tfResponsavel"
                      readOnly={true}
                      value={fildResponsavel}
                      //defaultValue={fildResponsavel}
                      // onChange={(event) => setFildResponsavel(event.target.value)}
                    />
                  </FormControl>
                </Box>
              </Card>
            </Grid>
          </Grid>

        </footer>

      </Grid>

    </>
  );
}
