// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr from "../../../components/LocalesPtBr";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { browserName, CustomView } from 'react-device-detect';

import { Button } from "../../../components/Wrappers/Wrappers";

import { isMobile } from 'react-device-detect';

import {
  Typography,
  FormControl,
  TextField,
  Grid,
  Paper,
  InputLabel,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Modal, InputAdornment, FormControlLabel, Checkbox,
  FormHelperText, CircularProgress,
  Backdrop, Stepper, Step, StepLabel, StepContent, Input
} from '@mui/material';

//Estilizacao para Mobile
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import PageTitleMobile from "../../../components/PageTitleMobile/PageTitleMobile";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

import { blue } from '@material-ui/core/colors';

import PrintPurchaseOrder from "../report/PrintPurchaseOrder";

import PesquisarProdutos from './../../../components/PesquisarProdutos';
import PesquisarFornecedores from './../../../components/PesquisarFornecedores';
import Desconto from './../../../components/Desconto';

import FormatarValor from './../../../components/FormatarValor/FormatarValor';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function converterParaFloat(valor) {
  if (valor !== null && valor !== '') {
    return parseFloat(valor);
  } else {
    return 0;
  }
}

function converterParaInt(valor) {
  if (valor !== null && valor !== '') {
    return parseInt(valor);
  } else {
    return 0;
  }
}

const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[,][0-9]+)$');

function formatarMoeda(v) {

  var valor = v;

  valor = valor + '';
  valor = parseInt(valor.replace(/[\D]+/g, ''));
  valor = valor + '';
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }


  if (valor === 'NaN') valor = '';

  if (valor.substr(0, 1) === ',') { valor = "0" + valor; }

  return valor;
}

function formatValue(val) {
  const options = {

    // currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
  };
  val = val.replace(',', '.');
  const formatNumber = new Intl.NumberFormat("pt-BR", options);
  return formatNumber.format(val);
  // val: -70549.82999999999
  // formatNumber.format(val): -R$ 70.549,83
}

function isNumeric(str) {

  var regex = /^[0-9.,]+$/;

  return (regex.test(str));
}

function formatarValoresPtBr(valor) {

  let valorFormatado = Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(valor);//valor.toLocaleString('pt-br', {minimumFractionDigits: 2});//

  return valorFormatado;

}

function formatarValores(valor) {

  //Caso não seja apenas numeros zeramos o valor
  if (!isNumeric(valor)) { valor = 0; }

  if (valor !== null && valor !== '' && valor !== undefined) {

    //let valorFormatado = valor.toLocaleString('pt-br', {minimumFractionDigits: 2});//Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(valor);

    // valor = valor + '';
    // valor = parseInt(valor.replace(/[\D]+/g, ''));
    // valor = valor + '';
    // valor = valor.replace(/([0-9]{2})$/g, ",$1");

    // if (valor.length > 6) {
    //   valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    // }

    return Intl.NumberFormat('pt-br', { minimumFractionDigits: 2 }).format(valor);

  } else {

    valor = 0;
    // valor = valor + '';
    // valor = parseInt(valor.replace(/[\D]+/g, ''));
    // valor = valor + '';
    // valor = valor.replace(/([0-9]{2})$/g, ",$1");

    // if (valor.length > 6) {
    //   valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    // }

    return valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

}

function verificaSeIteiroOuFloat(valor) {

  //Caso não seja apenas numeros zeramos o valor
  if (!isNumeric(valor)) { valor = 0; }

  if (valor !== null && valor !== '' && valor !== undefined) {

    return valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });

    // valor = valor + '';
    // valor = parseInt(valor.replace(/[\D]+/g, ''));
    // valor = valor + '';
    // valor = valor.replace(/([0-9]{2})$/g, ",$1");

    // if (valor.length > 6) {
    //   valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    // }

  } else {

    valor = 0;
    //   valor = valor + '';
    //   valor = parseInt(valor.replace(/[\D]+/g, ''));
    //   valor = valor + '';
    //   valor = valor.replace(/([0-9]{2})$/g, ",$1");

    //   if (valor.length > 6) {
    //     valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    //   }

    valor = valor.toLocaleString('pt-br', { minimumFractionDigits: 0 });

  }

  return valor;

}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: 'produto', label: 'Código' },
  { id: 'descricao', label: 'Descrição' },
  // { id: 'st', label: 'ST/CSOSN' },
  // { id: 'cf', label: 'NCM' },
  { id: 'unidade', label: 'Und.' },
  // { id: 'cfop', label: 'CFOP' },
  { id: 'icms %', label: 'ICMS' },
  { id: 'ipi %', label: 'IPI' },
  { id: 'quantidade', label: 'Qtd.' },
  { id: 'atendido', label: 'Atendido' },
  { id: 'preco', label: 'Val. Unit.' },
  { id: 'total', label: 'TOTAL' },
  { id: '', label: '' },
  { id: '', label: '' },
];

const columnsMobile = [
  { id: 'descricao', label: 'Descrição' }
];

const columnsSearch = [
  { id: 'produto', label: 'Código' },
  { id: 'descricao', label: 'Descrição' },
  { id: '', label: '' },
];

const columnsSearchCliente = [
  { id: 'razao', label: 'Razão Social' },
  { id: 'fantasia', label: 'Nome Fantasia' },
  { id: 'cnpj', label: 'CNPJ' },
  { id: '', label: '' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const listaModeloBaseCalculo = [
  {
    codigo: '0',
    descricao: 'Margem de Valor Agregado (%)'
  },
  {
    codigo: '1',
    descricao: 'Preço de Pauta'
  },
  {
    codigo: '3',
    descricao: 'Valor da Operação'
  }

];

export default function PurchaseOrderRegister(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [listaDados, setListaDados] = useState([]);

  const [idselecionado, setIdSelecionado] = useState(0);

  //------------------- Habilita / Desabilita controles
  const [registroCancelado, setRegistroCancelado] = useState(false);

  //------------------- Pesquisa de Produtos
  const [listaProdutos, setListaProdutos] = useState([]);
  const [pageProdutos, setPageProdutos] = React.useState(0);
  const [rowsPerPageProdutos, setRowsPerPageProdutos] = React.useState(5);
  const emptyRowsProdutos = pageProdutos > 0 ? Math.max(0, (1 + pageProdutos) * rowsPerPage - listaProdutos.length) : 0;

  const handleChangePageProdutos = (event, newPage) => {
    setPageProdutos(newPage);
  };

  const handleChangeRowsPerPageProdutos = (event) => {
    setRowsPerPageProdutos(parseInt(event.target.value, 10));
    setPageProdutos(0);
  };

  const [widthCelular, setWidthCelular] = useState(600);

  //-------------------

  const [openChavesReferenciadas, setOpenChavesReferenciadas] = useState(false);
  const handleOpenModaChavesReferenciadas = () => setOpenChavesReferenciadas(true);

  const [valueTab, setValueTab] = useState(0);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaDados.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openMessage, setOpenMessage] = React.useState(false);

  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(true);
  const [exibirProgressProdutos, setExibirProgressProdutos] = useState(false);
  const [exibirProgressCfop, setExibirProgressCfop] = useState(false);
  const [exibirProgressRecebimentos, setExibirProgressRecebimentos] = useState(false);

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  //Estilizacao para Mobile


  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  //Fim Estilizacao Mobile

  //Campos do formulario
  const [pedidoCancelado, setPedidoCancelado] = useState(false);
  const [fildId, setFildId] = useState(props.location.idregistro.text);
  const [fildEmissao, setFildEmissao] = useState(new Date());
  const [fildDocumento, setFildDocumento] = useState('');
  const [fildFornecedor, setFildFornecedor] = useState('');
  const [fildRazao, setFildRazao] = useState('');
  const [fildFantasia, setFildFantasia] = useState('');
  const [fildCnpjcpf, setFildCnpjcpf] = useState('');
  const [fildEntrega, setFildEntrega] = useState('');
  const [fildDesconto, setFildDesconto] = useState('');
  const [fildFrete, setFildFrete] = useState('');
  const [fildFreteValor, setfildFreteValor] = useState('');
  const [fildAcrescimo, setFildAcrescimo] = useState('');
  const [fildRecebimento, setFildRecebimento] = useState('');
  const [fildUsuario, setFildUsuario] = useState('');
  const [fildNomeUsuario, setFildNomeUsuario] = useState('');
  const [fildCfop, setFildCfop] = useState('');
  const [fildCancelado, setFildCancelado] = useState('');
  const [fildAuditoria, setFildAuditoria] = useState('');
  const [fildCodigoTransportadora, setFildCodigoTransportadora] = useState('');
  const [fildPrazoEntrega, setFildPrazoEntrega] = useState('');
  const [fildOrdemCompra, setFildOrdemCompra] = useState('');
  const [fildBcIcms, setFildBcIcms] = useState('0,00');
  const [fildBcIpi, setFildBcIpi] = useState('0,00');
  const [fildObservacao, setFildObservacao] = useState('');
  const [fildDataCadastro, setFildDataCadastro] = useState('');
  const [fildDataAlteracao, setFildDataAlteracao] = useState('');
  const [fildResponsavel, setFildResponsavel] = useState('');
  const [fildMododesconto, setFildMododesconto] = useState('P');
  const [fildTipodesconto, setFildTipodesconto] = useState('');

  const [fildBasecalculoicms, setFildBasecalculoicms] = useState('0,00');
  const [fildValorIcms, setFildValorIcms] = useState('0,00');
  const [fildBasecalculoicmsst, setFildBasecalculoicmsst] = useState('0,00');
  const [fildValoricmsst, setFildValoricmsst] = useState('0,00');
  const [fildBasecalculoipi, setFildBasecalculoipi] = useState('0,00');
  const [fildValorIpi, setFildValorIpi] = useState('0,00');
  const [fildTotalProdutos, setfildTotalProdutos] = useState('0,00');
  const [fildTotal, setFildTotal] = useState('0,00');

  const [fildTransportador, setFildTransportador] = useState('');
  const [fildRazaoTransportador, setFildRazaoTransportador] = useState('');
  const [fildFantasiaTransportador, setFildFantasiaTransportador] = useState('');
  const [fildCnpjTransportador, setFildCnpjTransportador] = useState('');
  //Fim dos campos do formulario

  //ITEM
  const [fildIdProduto, setFildIdProduto] = useState(0);
  const [fildProduto, setFildProduto] = useState('');
  const [fildDescricao, setFildDescricao] = useState('');
  const [fildSt, setFildSt] = useState('');
  const [fildPreco, setFildPreco] = useState('');
  const [fildIcms, setFildIcms] = useState('');
  const [fildIpi, setFildIpi] = useState('');
  const [fildUnidade, setFildUnidade] = useState('');
  const [fildQuantidade, setFildQuantidade] = useState('');
  const [fildVolume, setFildVolume] = useState('');
  const [pLiquidoUnitario, setPLiquidoUnitario] = useState(0);
  const [pBrutoUnitario, setPBrutoUnitario] = useState(0);
  const [fildModBcItem, setFildModBcItem] = useState('');

  const [item, setItem] = useState([]);
  const [listaItens, setListaItens] = useState([]);
  const [dadosCabecalho, setDadosCabecalho] = useState([]);
  const [dadosFornecedor, setDadosFornecedor] = useState([]);

  //---------------
  //Campos alterados no item
  const [descricaoAlterado, setDescricaoAlterado] = useState('');
  const [cstAlterado, setCstAlterado] = useState('');
  const [cfAlterado, setCfAlterado] = useState('');
  const [unidadeAlterado, setUnidadeAlterado] = useState('');
  const [AtendidoAlterado, setAtendidoAlterado] = useState('');
  const [QuantidadeAlterado, setQuantidadeAlterado] = useState('');
  const [cfopAlterado, setCfopAlterado] = useState('');
  const [icmsAlterado, setIcmsAlterado] = useState('');
  const [ipiAlterado, setIpiAlterado] = useState('');
  //---------------

  const [exibirProgressMunicipios, setExibirProgressMunicipios] = useState(false);

  const [openPesquisar, setOpenPesquisar] = useState(false);
  const handleOpenModalPesquisar = () => setOpenPesquisar(true);
  const handleCloseModalPesquisar = () => setOpenPesquisar(false);

  const [openDesconto, setOpenDesconto] = useState(false);
  const handleOpenModalDesconto = () => setOpenDesconto(true);
  const [tipoDesconto, setTipoDesconto] = useState(''); //armazena o tipo do desconto P=total e I=Produto
  const [visualizarItemNoDesconto, setVisualizarItemNoDesconto] = useState(false);
  const [fildItemDesconto, setFildItemDesconto] = useState('');

  const handleCloseModalDesconto = () => setOpenDesconto(false);
  const [opcaoDesconto, setOpcaoDesconto] = useState([false, true]);
  const [indicadorDesconto, setIndicadorDesconto] = useState('');//serve para mostrar no campo de desconto se é pra informar $ ou %

  const handleChangeModoDesconto = (event) => {
    setFildMododesconto(event.target.value);

    if (event.target.value === "I") {

      setVisualizarItemNoDesconto(true);
    } else {
      setVisualizarItemNoDesconto(false);
    }
  }

  const [listaForencedores, setListaForencedores] = useState([]);
  const [openPesquisarFornecedor, setopenPesquisarFornecedor] = useState(false);
  const handleOpenModalPesquisarFornecedor = () => setopenPesquisarFornecedor(true);
  const handleCloseModalPesquisarForencedor = () => setopenPesquisarFornecedor(false);

  const [pageFornecedor, setPageFornecedor] = React.useState(0);
  const [rowsPerpageFornecedor, setRowsPerpageFornecedor] = React.useState(5);
  const emptyRowsFornecedor = pageFornecedor > 0 ? Math.max(0, (1 + pageFornecedor) * rowsPerpageFornecedor - listaForencedores.length) : 0;

  const handleChangepageFornecedor = (event, newPage) => {
    setPageFornecedor(newPage);
  };

  const handleChangeRowsPerpageFornecedor = (event) => {
    setPageFornecedor(parseInt(event.target.value, 10));
    setPageFornecedor(0);
  };

  const [listaTransportadores, setListaTransportadores] = useState([]);
  const [openPesquisarTransportador, setOpenPesquisarTransportador] = useState(false);
  const handleOpenModalPesquisarTransportador = () => setOpenPesquisarTransportador(true);
  const handleCloseModalPesquisarTransportador = () => setOpenPesquisarTransportador(false);

  const [pageTransportador, setPageTransportador] = React.useState(0);
  const [rowsPerPageTransportador, setRowsPerPageTransportador] = React.useState(5);
  const emptyRowsTransportador = pageTransportador > 0 ? Math.max(0, (1 + pageTransportador) * rowsPerPageTransportador - listaTransportadores.length) : 0;

  const handleChangePageTransportador = (event, newPage) => {
    setPageTransportador(newPage);
  };

  const handleChangeRowsPerPageTransportador = (event) => {
    setPageTransportador(parseInt(event.target.value, 10));
    setPageTransportador(0);
  };

  //Inverte pois o cmapo é de INATIVO entao quando estiver INATIVO=TRUE
  const modoDesconto = [
    {
      value: 'P',
      label: 'Total',
    },
    {
      value: 'I',
      label: 'Produto',
    }
  ];

  const tipoFrete = [
    { value: '0', label: 'Contratação do Frete por conta do Remetente (CIF)' },
    { value: '1', label: 'Contratação do Frete por conta do Destinatário (FOB)' },
    { value: '2', label: 'Contratação do Frete por conta de Terceiros' },
    { value: '3', label: 'Transporte Próprio por conta do Remetente' },
    { value: '4', label: 'Transporte Próprio por conta do Destinatário' },
    { value: '9', label: 'Sem Ocorrência de Transporte' },

  ];

  const [habilitarAdicaoAlteracao, setHabilitarAdicaoAlteracao] = useState(false);

  // Variaveis pra validar campos
  const [validaCliente, setValidaCliente] = useState(false);
  const [msgErroCliente, setMsgErroCliente] = useState('');

  const [validaProduto, setValidaProduto] = useState(false);
  const [msgErroProduto, setMsgErroProduto] = useState('');

  const [validaQuantidade, setValidaQuantidade] = useState(false);
  const [msgErroQuantidade, setMsgErroQuantidade] = useState('');

  const [validaPreco, setValidaPreco] = useState(false);
  const [msgErroPreco, setMsgErroPreco] = useState('');

  const [validaCfop, setValidaCfop] = useState(false);
  const [msgErroCfop, setMsgErroCfop] = useState('');

  const [validaRecebimento, setValidaRecebimento] = useState(false);
  const [msgErroRecebimento, setMsgErroRecebimento] = useState('');

  //-------------------------

  const [lsCfop, setLsCfop] = useState([]);
  const [lsRecebimento, setLsRecebimento] = useState([]);

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeEmissao = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildEmissao(newValue.toJSON());
      }
    }
  };

  const handleChangeCfop = (event) => {
    setFildCfop(event.target.value);
  }

  // Handles do registro alterado
  const handleUpdateDescricaoItem = (event) => {

    setDescricaoAlterado(event.target.value);
  }

  const handleUpdateUnidadeItem = (event) => {

    setUnidadeAlterado(event.target.value);
  }

  const handleUpdateCfopItem = (event) => {

    setCfopAlterado(event.target.value);

    // const itemExists = listaItens.find((item) => item.chave === rg.chave);

    // console.log('chamou o evento do cfop item'+rg.chave);

    // if (itemExists) {

    //   console.log('Alterou ');


    //   setListaItens((prevItems) => {
    //     return prevItems.map((item) => {
    //       if (item.chave === idRegistro) {

    //         item.cfop = rg;

    //       }
    //       return item;
    //     });
    //   });

    // }
  }

  const handleUpdateQuantidadeItem = (event) => {

    setQuantidadeAlterado(event.target.value);
  }

  const handleUpdateAtendidoItem = (event) => {

    setAtendidoAlterado(event.target.value);
  }

  const handleIcmsAtendidoItem = (event) => {

    setIcmsAlterado(event.target.value);
  }

  const handleIpiAtendidoItem = (event) => {

    setIpiAlterado(event.target.value);
  }

  const handleConfirmarDesconto = () => {

    handleCloseModalDesconto();
  };

  const handleChangeRecebimento = (event) => {
    setFildRecebimento(event.target.value);
  }

  const handleChangeTipoFrete = (event) => {
    setFildFrete(event.target.value);
  }

  const handleChangeDescontoPercentual = (event) => {
    setOpcaoDesconto([event.target.checked, false]);
    setIndicadorDesconto('%');

  }

  const handleChangeDescontoValor = (event) => {
    setOpcaoDesconto([false, event.target.checked]);
    setIndicadorDesconto('R$');

  }

  const handleChangePesquisarTransportador = (valor) => {
    pesquisarTransportador(valor);
  }

  const handleImpedirMultClick = () => {
    setimpedirMultClick(true);
  }

  const handleChangePedidoCancelado = (valor) => {
    setPedidoCancelado(valor);
  }

  const handleValorDesconto = (valor) => {

    setFildDesconto(valor);

  };

  function converter_percentual_para_valor(_percentual){

    //Desconto em %
    let _vTotal = 0;

    if (fildTotalProdutos !== "") {
      _vTotal = parseFloat(fildTotalProdutos);
    }

    let conversao =0;
    try{ conversao = (_percentual / 100) * (_vTotal);}catch{}

    return conversao;

  }

  function handleConcluirDesconto(){

    if(validarDesconto()===true){
      saveRegistro();
    }

  }

  function validarDesconto() {

    let descontoLiberado = false;

    //Desconto no TOTAL da Venda
    if (fildMododesconto === "P") {

      //TOTAL DA VENDA

      if (opcaoDesconto[1]) {

        let conversao=0;
        try{conversao = (parseFloat(fildDesconto.replace('.', '').replace(',', '.')) * 1 );}catch{}
        let v_total   = (parseFloat(fildTotalProdutos) * 1 );

        //FormatarValor(2,2)

        if (conversao <= v_total) {

          descontoLiberado = true;

        } else { setFildDesconto(0); }

      } else {

        //Desconto em %

        let conversao =0;
        try{ conversao = converter_percentual_para_valor(parseFloat(fildDesconto.replace('.', '').replace(',', '.')));}catch{}

        let v_total = parseFloat(fildTotalProdutos);

        console.log('valor convertido:',conversao);

        if (conversao <= v_total) {

          setFildDesconto(FormatarValor(2,2,conversao));

          descontoLiberado = true;

        } else { setFildDesconto(0); }

        setOpcaoDesconto([false, true]);
      }

      handleConfirmarDesconto();

      if (!descontoLiberado) {

        setTipoMensagem('error');
        setTextoMensagem('Desconto não pode ser maior que o valor total!');
        handleOpenMessage();

        setFildDesconto(0);

        // return;
      }

    } else if (fildMododesconto === "I") {
      //DESCONTO POR ITEM

      descontoNoItem(fildItemDesconto);
    }

    return descontoLiberado;

  }

  // Fim Handles do registro alterado

  function changeItemIcms(e, index) {

    let _valor = e.target.value;
    // _valor = verificaSeIteiroOuFloat(_valor);

    let it = [...listaItens];

    it[index].icms = _valor;
    // it[index].icms = converterParaInt(_valor);
    setListaItens(it);
  }

  function changeItemIpi(e, index) {
    // let _valor = e.target.value;
    // _valor = verificaSeIteiroOuFloat(_valor);

    // let it = [...listaItens];
    // it[index].ipi = converterParaInt(_valor);
    // setListaItens(it);

    let _valor = e.target.value;

    _valor = _valor.replace(',', '.');

    let it = [...listaItens];

    it[index].ipi = _valor;

    setListaItens(it);
  }

  function changeItemUnidade(e, index) {
    let it = [...listaItens];
    it[index].unidade = e.target.value;
    setListaItens(it);
  }

  function changeItemDescricao(e, index) {
    let it = [...listaItens];
    it[index].descricao = e.target.value;
    setListaItens(it);
  }

  function changeItemSt(e, index) {
    let it = [...listaItens];
    it[index].st = e.target.value;
    setListaItens(it);
  }

  function changeItemCf(e, index) {
    let it = [...listaItens];
    it[index].cf = e.target.value;
    setListaItens(it);
  }

  function changeItemQuantidade(e, index) {

    let _valor = e.target.value;
    _valor = verificaSeIteiroOuFloat(_valor);

    let it = [...listaItens];
    it[index].quantidade = _valor;
    setListaItens(it);
  }

  function changeItemAtendido(e, index) {

    let _valor = e.target.value;
    // let _valorParaCalculo = e.target.value;

    _valor = _valor.replace(',', '.');
    // _valor =converterParaFloat(_valor);
    console.log(_valor);
    //_valorParaCalculo = verificaSeIteiroOuFloat(_valorParaCalculo);

    // _valor = verificaSeIteiroOuFloat(_valor);

    let it = [...listaItens];

    let _preco = it[index].preco;
    console.log(_preco);
    // _preco =_preco.replace('.', '');
    _preco = _preco.replace(',', '.');
    //_preco =verificaSeIteiroOuFloat(_preco);
    console.log(_valor * _preco);
    it[index].atendido = _valor;
    it[index].total = _valor * _preco;

    setListaItens(it);
  }

  function preencherDadosProduto(id, produto, descricao, pliquido, pbruto, qtdvolume, modbc, precovenda, unidade, st) {

    setFildIdProduto(id);
    setFildProduto(produto);
    setFildDescricao(descricao);
    setPLiquidoUnitario(pliquido);
    setPBrutoUnitario(pbruto);
    setFildVolume(qtdvolume);
    setFildModBcItem(modbc);
    setFildPreco(precovenda);
    setFildUnidade(unidade);
    setFildSt(st);

    handleCloseModalPesquisar();

    setListaProdutos([]);

    setHabilitarAdicaoAlteracao(false);

  }

  function limparDadosProduto() {

    setFildIdProduto('');
    setFildProduto('');
    setFildDescricao('');
    setPLiquidoUnitario('');
    setPBrutoUnitario('');
    setFildVolume('');
    setFildModBcItem('');
    setFildPreco('');
    setFildUnidade('');
    setFildPreco('');
    setFildQuantidade('');
    setFildSt('');
    setFildSt('');


  }

  function preencherDadosFornecedor(codigo, razao, fantasia, cnpj, uf) {

    setFildFornecedor(codigo);
    setFildRazao(razao);
    setFildFantasia(fantasia);
    setFildCnpjcpf(cnpj);

    handleCloseModalPesquisarForencedor();

    setListaForencedores([]);
  }

  function preencherDadosTransportador(codigo, razao, fantasia, cnpj) {

    setFildTransportador(codigo);
    setFildRazaoTransportador(razao);
    setFildFantasiaTransportador(fantasia);
    setFildCnpjTransportador(cnpj);


    handleCloseModalPesquisarTransportador();

    setListaTransportadores([]);
  }

  function montarRegistro() {

    if (fildFornecedor === "") {
      setValidaCliente(true);
      setMsgErroCliente('Selecione o fornecedor.');
      return;

    } else {
      setValidaCliente(false);
      setMsgErroCliente('');
    }

    if (fildCfop === "") {
      setValidaCfop(true);
      setMsgErroCfop('Selecione o cfop.');
      return;

    } else {
      setValidaCfop(false);
      setMsgErroCfop('');
    }

    if (fildRecebimento === "") {
      setValidaRecebimento(true);
      setMsgErroRecebimento('Selecione a Forma de pagamento.');
      return;

    } else {
      setValidaRecebimento(false);
      setMsgErroRecebimento('');
    }

    if (fildProduto === "") {
      setValidaProduto(true);
      setMsgErroProduto('Selecione o produto.');
      return;

    } else {
      setValidaProduto(false);
      setMsgErroProduto('');
    }

    if (fildQuantidade === "" || fildQuantidade === 0) {
      setValidaQuantidade(true);
      setMsgErroQuantidade('Quantidade inválida.');
      return;

    } else {
      setValidaQuantidade(false);
      setMsgErroQuantidade('');
    }

    if (fildPreco === "" || fildPreco === 0) {
      setValidaPreco(true);
      setMsgErroPreco('Val. Unitário válido.');
      return;

    } else {
      setValidaPreco(false);
      setMsgErroPreco('');
    }

    setimpedirMultClick(true);

    var _total = fildQuantidade * fildPreco;

    const newItem = {
      chave: 0
      , data: fildEmissao
      , produto: fildProduto
      , descricao: fildDescricao
      , referencia: ''
      , quantidade: fildQuantidade
      , atendido: fildQuantidade
      , custo: 0
      , preco: fildPreco
      , cfop: fildCfop
      , cliente: fildFornecedor
      , documento: fildDocumento
      , unidade: fildUnidade
      , cancelado: "false"
      , icms: fildIcms
      , desconto: 0
      , ipi: fildIpi
      , valoricms: 0
      , valoripi: 0
      , reducaobase: 0
      , cf: ''
      , st: ''
      , cbarra: ''
      , movimentaestoque: ''
      , qtdvolume: fildVolume
      , pliquido: 0
      , pbruto: 0
      , registro: ''
      , ordemcompra: fildOrdemCompra
      , bcicms: 0
      , bcipi: 0
      , total: _total
      , bicms: 0
      , acrescimo: 0
      , modbcicms: ''
      , modbcicmsst: ''
    };

    //Adiciona ou altera o item na lista
    addItem(newItem);

    //Limpa  os dados do produto
    limparDadosProduto();

  }

  function addItem(data) {

    const itemExists = listaItens.find((item) => item.chave === data.chave && item.chave > 0);

    if (itemExists) {

      //Atualiza o Item selecionado pela CHAVE do registro
      setListaItens((prevItems) => {

        return prevItems.map((item) => {
          if (item.chave === data.chave) {

            var _total = data.atendido * data.preco;

            item.data = fildEmissao;
            item.descricao = data.descricao;
            item.quantidade = data.quantidade;
            item.atendido = data.atendido;
            item.preco = data.preco;
            item.cfop = data.cfop;
            item.cliente = fildFornecedor;
            item.documento = fildDocumento;
            item.unidade = fildUnidade;
            item.icms = data.icms;
            item.desconto = data.desconto;
            item.ipi = data.ipi;
            item.cf = data.cf;
            item.st = data.st;
            item.qtdvolume = data.volume;
            item.pliquido = data.pliquido;
            item.pbruto = data.pbruto;
            item.ordemcompra = fildOrdemCompra;
            item.total = _total;
          }
          return item;
        });
      });

    } else {

      //Adiciona um novo registro obs.= obrigatoriamente a chave do registro deve ser 0 para a API saber que é um registro novo
      //listaItens.push(data);
      //console.log(listaItens);

      setListaItens((prevItems) => {

        return [...prevItems, data];
      });

      setHabilitarAdicaoAlteracao(true);
    }

  }

  function updateIcmsItem(idRegistro) {

    const itemExists = listaItens.find((item) => item.chave === idRegistro);

    if (itemExists) {

      setListaItens((prevItems) => {

        return prevItems.map((item) => {
          if (item.chave === idRegistro) {

            item.icms = icmsAlterado;

          }
          return item;
        });
      });

    }
    // setHabilitarAdicaoAlteracao(true);
  }

  function updateItem(data, idRegistro) {

    const itemExists = listaItens.find((item) => item.chave === idRegistro);

    if (itemExists) {

      //Atualiza o Item selecionado pela CHAVE do registro
      setListaItens((prevItems) => {

        return prevItems.map((item) => {
          if (item.chave === data.chave) {

            var _total = data.atendido * data.preco;

            item.data = fildEmissao;
            item.descricao = data.descricao;
            item.quantidade = data.quantidade;
            item.atendido = data.atendido;
            item.preco = data.preco;
            item.cfop = cfopAlterado;
            item.cliente = fildFornecedor;
            item.documento = fildDocumento;
            item.unidade = fildUnidade;
            item.icms = data.icms;
            item.desconto = data.desconto;
            item.ipi = data.ipi;
            item.cf = data.cf;
            item.st = data.st;
            item.qtdvolume = data.volume;
            item.pliquido = data.pliquido;
            item.pbruto = data.pbruto;
            item.ordemcompra = fildOrdemCompra;
            item.total = _total;
          }
          return item;
        });
      });

    }

    // setHabilitarAdicaoAlteracao(true);
  }

  async function saveRegistro() {

    if (pedidoCancelado) { return; }

    let json = [];

    try {
      if (fildId === '0' && fildFornecedor !== '') {

        //Novo Pedido

        const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPreCompra.php', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            requisicao: 'save-pedido',
            senha: senhaRetorno,
            login: usuarioRetorno,
            id: fildId,
            fornecedor: fildFornecedor,
            observacao: fildObservacao,
            recebimento: fildRecebimento,
            cfop: fildCfop,
            acrescimo: fildAcrescimo,
            desconto: fildDesconto,
            ordemcompra: fildOrdemCompra,
            transportador: fildTransportador,
            frete: fildFrete,
            fretevalor: fildFreteValor,
            itens: listaItens
          })
        });

        json = await response.json();

        if (json.success) {

          setFildId(json.pedido[0].id);
          setFildEmissao(json.pedido[0].emissao);
          setFildDocumento(json.pedido[0].documento);
          setFildFornecedor(json.pedido[0].fornecedor);
          setFildFantasia(json.pedido[0].fantasia);
          setFildRazao(json.pedido[0].razao);
          setFildCnpjcpf(json.pedido[0].cnpj);
          setFildObservacao(json.pedido[0].observacao);
          setFildRecebimento(json.pedido[0].recebimento);
          setFildCfop(json.pedido[0].cfop);
          setFildAcrescimo(converterParaFloat(json.pedido[0].acrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildDesconto(converterParaFloat(json.pedido[0].desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildOrdemCompra(json.pedido[0].ordemcompra);
          setFildFrete(json.pedido[0].frete);
          setfildFreteValor(converterParaFloat(json.pedido[0].fretevalor).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildDataCadastro(json.pedido[0].datacadastro);
          setFildDataAlteracao(json.pedido[0].dataalteracao);
          setFildResponsavel(json.pedido[0].responsavel);
          // setFildTotalPBruto(converterParaFloat(json.pedido[0].pbruto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          // setFildTotalPLiquido(converterParaFloat(json.pedido[0].pliquido).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

          setFildBasecalculoicms(converterParaFloat(json.pedido[0].bcicms).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValorIcms(converterParaFloat(json.pedido[0].vicms).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildBasecalculoicmsst(converterParaFloat(json.pedido[0].vbcst).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValoricmsst(converterParaFloat(json.pedido[0].vicmsst).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildBasecalculoipi(converterParaFloat(json.pedido[0].bcipi).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValorIpi(converterParaFloat(json.pedido[0].vipi).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setfildTotalProdutos(converterParaFloat(json.pedido[0].totalproduto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildTotal(converterParaFloat(json.pedido[0].total).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

          setFildTransportador(json.pedido[0].codigotransportadora);
          setFildRazaoTransportador(json.pedido[0].razaotransportador);
          setFildFantasiaTransportador(json.pedido[0].fantasiatransportador);
          setFildCnpjTransportador(json.pedido[0].cnpjtransportador);

          setListaItens(json.itens);

          //Libera o click
          setimpedirMultClick(false);

          setTipoMensagem('success');
          setTextoMensagem('Registro salvo com sucesso!');
          handleOpenMessage();

        }

      } else {


        console.log(JSON.stringify({
          requisicao: 'update-pedido',
          senha: senhaRetorno,
          login: usuarioRetorno,
          id: fildId,
          emissao: fildEmissao,
          documento: fildDocumento,
          fornecedor: fildFornecedor,
          observacao: fildObservacao,
          recebimento: fildRecebimento,
          cfop: fildCfop,
          acrescimo: fildAcrescimo,
          desconto: fildDesconto,
          ordemcompra: fildOrdemCompra,
          transportador: fildTransportador,
          frete: fildFrete,
          fretevalor: fildFreteValor,
          itens: listaItens
        }));
        return;

        //Atualiza Pedido
        const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPreCompra.php', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            requisicao: 'update-pedido',
            senha: senhaRetorno,
            login: usuarioRetorno,
            id: fildId,
            emissao: fildEmissao,
            documento: fildDocumento,
            fornecedor: fildFornecedor,
            observacao: fildObservacao,
            recebimento: fildRecebimento,
            cfop: fildCfop,
            acrescimo: fildAcrescimo,
            desconto: fildDesconto,
            ordemcompra: fildOrdemCompra,
            transportador: fildTransportador,
            frete: fildFrete,
            fretevalor: fildFreteValor,
            itens: listaItens
          })
        });

        json = await response.json();

        if (json.success) {

          // setFildId(json.pedido[0].id);
          setFildEmissao(json.pedido[0].emissao);
          setFildDocumento(json.pedido[0].documento);
          setFildFornecedor(json.pedido[0].fornecedor);
          setFildFantasia(json.pedido[0].fantasia);
          setFildRazao(json.pedido[0].razao);
          setFildCnpjcpf(json.pedido[0].cnpj);
          setFildObservacao(json.pedido[0].observacao);
          setFildRecebimento(json.pedido[0].recebimento);
          setFildCfop(json.pedido[0].cfop);
          setFildAcrescimo(converterParaFloat(json.pedido[0].acrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildDesconto(converterParaFloat(json.pedido[0].desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildOrdemCompra(json.pedido[0].ordemcompra);
          setFildFrete(json.pedido[0].frete);
          setfildFreteValor(converterParaFloat(json.pedido[0].fretevalor).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildDataCadastro(json.pedido[0].datacadastro);
          setFildDataAlteracao(json.pedido[0].dataalteracao);
          setFildResponsavel(json.pedido[0].responsavel);
          // setFildTotalPBruto(converterParaFloat(json.pedido[0].pbruto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          // setFildTotalPLiquido(converterParaFloat(json.pedido[0].pliquido).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

          setFildBasecalculoicms(converterParaFloat(json.pedido[0].bcicms).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValorIcms(converterParaFloat(json.pedido[0].vicms).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildBasecalculoicmsst(converterParaFloat(json.pedido[0].vbcst).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValoricmsst(converterParaFloat(json.pedido[0].vicmsst).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildBasecalculoipi(converterParaFloat(json.pedido[0].bcipi).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValorIpi(converterParaFloat(json.pedido[0].vipi).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setfildTotalProdutos(converterParaFloat(json.pedido[0].totalproduto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildTotal(converterParaFloat(json.pedido[0].total).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

          setFildTransportador(json.pedido[0].codigotransportadora);
          setFildRazaoTransportador(json.pedido[0].razaotransportador);
          setFildFantasiaTransportador(json.pedido[0].fantasiatransportador);
          setFildCnpjTransportador(json.pedido[0].cnpjtransportador);

          setListaItens(json.itens);

          //Libera o click
          setimpedirMultClick(false);
          setTipoMensagem('success');
          setTextoMensagem('Registro salvo com sucesso!');
          handleOpenMessage();

        }

      }

      setHabilitarAdicaoAlteracao(false);

    } catch (error) {
      console.error(error);
    }
  }

  async function cancelRegistro() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPreCompra.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'cancelar-pedido',
        senha: senhaRetorno,
        login: usuarioRetorno,
        id: fildId,
        documento: fildDocumento,

      })
    });

    let json = await response.json();

    if (json.success) {

      setTipoMensagem('success');
      setTextoMensagem('Cancelamento realizado com sucesso!');
      handleOpenMessage();

      dadosCompleto();

    }

  }

  async function removeRegistro(index, idChave) {

    setHabilitarAdicaoAlteracao(true);

    let json = [];

    listaItens.splice(index, 1);

    //Atualiza Pedido
    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPreCompra.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'update-pedido',
        senha: senhaRetorno,
        login: usuarioRetorno,
        id: fildId,
        emissao: fildEmissao,
        documento: fildDocumento,
        cliente: fildFornecedor,
        observacao: fildObservacao,
        recebimento: fildRecebimento,
        cfop: fildCfop,
        acrescimo: fildAcrescimo,
        desconto: fildDesconto,
        ordemcompra: fildOrdemCompra,
        transportador: fildTransportador,
        frete: fildFrete,
        fretevalor: fildFreteValor,
        itens: listaItens,
        iditem: idChave
      })
    });

    json = await response.json();

    if (json.success) {

      // setFildId(json.pedido[0].id);
      setFildEmissao(json.pedido[0].emissao);
      setFildDocumento(json.pedido[0].documento);
      setFildFornecedor(json.pedido[0].fornecedor);
      setFildFantasia(json.pedido[0].fantasia);
      setFildRazao(json.pedido[0].razao);
      setFildCnpjcpf(json.pedido[0].cnpj);
      setFildObservacao(json.pedido[0].observacao);
      setFildRecebimento(json.pedido[0].recebimento);
      setFildCfop(json.pedido[0].cfop);
      setFildAcrescimo(converterParaFloat(json.pedido[0].acrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildDesconto(converterParaFloat(json.pedido[0].desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildOrdemCompra(json.pedido[0].ordemcompra);
      setFildFrete(json.pedido[0].frete);
      setfildFreteValor(converterParaFloat(json.pedido[0].fretevalor).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildDataCadastro(json.pedido[0].datacadastro);
      setFildDataAlteracao(json.pedido[0].dataalteracao);
      setFildResponsavel(json.pedido[0].responsavel);
      // setFildTotalPBruto(converterParaFloat(json.pedido[0].pbruto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      // setFildTotalPLiquido(converterParaFloat(json.pedido[0].pliquido).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

      setFildBasecalculoicms(converterParaFloat(json.pedido[0].bcicms).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildValorIcms(converterParaFloat(json.pedido[0].vicms).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildBasecalculoicmsst(converterParaFloat(json.pedido[0].vbcst).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildValoricmsst(converterParaFloat(json.pedido[0].vicmsst).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildBasecalculoipi(converterParaFloat(json.pedido[0].bcipi).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildValorIpi(converterParaFloat(json.pedido[0].vipi).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setfildTotalProdutos(converterParaFloat(json.pedido[0].totalproduto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildTotal(converterParaFloat(json.pedido[0].total).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

      setFildTransportador(json.pedido[0].codigotransportadora);
      setFildRazaoTransportador(json.pedido[0].razaotransportador);
      setFildFantasiaTransportador(json.pedido[0].fantasiatransportador);
      setFildCnpjTransportador(json.pedido[0].cnpjtransportador);

      if (json.itens.length > 0) {
        setListaItens(json.itens);
      }
      else {
        setListaItens([]);
      }

      //Libera o click
      setimpedirMultClick(false);

    }

  }

  //Litas

  async function pesquisarTransportador(valor) {

    // setExibirProgress(true);

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiTransportadores.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listar',
        senha: senhaRetorno,
        login: usuarioRetorno,
        condicao: valor
      })
    });

    let json = await response.json();

    // setExibirProgress(false);

    if (json.success) {
      if (json.result === null) {
        setListaTransportadores([]);

      } else {
        setListaTransportadores(json.result);

      }
    }
    else { setListaTransportadores([]); }

  }

  async function listarCfop() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiCfop.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listarNaOperacao',
        senha: senhaRetorno,
        login: usuarioRetorno,
        origem: 'E'
      })
    });

    let json = await response.json();

    if (json.success) {
      if (json.result === null) {
        setLsCfop([]);
      } else {
        setLsCfop(json.result);
      }
    }
    else {
      setLsCfop([]);
    }

    setExibirProgressCfop(false);
  }

  async function listarRecebimentos() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiRecebimentos.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listar',
        senha: senhaRetorno,
        login: usuarioRetorno
      })
    });

    let json = await response.json();

    if (json.success) {
      if (json.result === null) {
        setLsRecebimento([]);
      } else {
        setLsRecebimento(json.result);
      }
    }
    else {
      setLsRecebimento([]);
    }

    setExibirProgressRecebimentos(false);

  }

  //Fim Listas

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {

    if (isMobile) { setWidthCelular(300); }

    if (listaItens.length > 0) {

      if (habilitarAdicaoAlteracao) {

        saveRegistro();

      }

    }

  }, [habilitarAdicaoAlteracao, listaItens, saveRegistro]);

  useEffect(() => {

    //É um novo Registro
    if (props.location.idregistro.text === undefined) {
      props.history.push('/app/ListPurchaseOrder');

    }
    else if (props.location.idregistro.text === "0") {

      handleChangePedidoCancelado(false);
      setExibirProgress(true);

      setExibirProgressRecebimentos(true);
      listarRecebimentos();

      setExibirProgressCfop(true);
      listarCfop();

      setNovoRegistro(true);
      setExibirProgress(false);

    } else {

      setExibirProgress(true);

      listarRecebimentos();
      listarCfop();

      dadosCompleto();
    }

  }, [dadosCompleto, props.history, props.location.idregistro.text]);

  async function dadosCompleto() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPreCompra.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'pedido-completo',
        senha: senhaRetorno,
        login: usuarioRetorno,
        id: fildId
      })
    });

    let json = await response.json();


    if (json.success) {
      if (json.result === null) {

      } else {

        //setFildId(json.pedido[0].chave);
        setFildEmissao(json.pedido[0].emissao);
        setFildDocumento(json.pedido[0].documento);
        setFildFornecedor(json.pedido[0].fornecedor);
        setFildFantasia(json.pedido[0].fantasia);
        setFildRazao(json.pedido[0].razao);
        setFildCnpjcpf(json.pedido[0].cnpj);
        setFildObservacao(json.pedido[0].observacao);
        setFildRecebimento(json.pedido[0].recebimento);
        setFildCfop(json.pedido[0].cfop);

        setFildAcrescimo(converterParaFloat(json.pedido[0].acrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildDesconto(converterParaFloat(json.pedido[0].desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildOrdemCompra(json.pedido[0].ordemcompra);
        setFildFrete(json.pedido[0].frete);
        setfildFreteValor(converterParaFloat(json.pedido[0].fretevalor).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildDataCadastro(json.pedido[0].datacadastro);
        setFildDataAlteracao(json.pedido[0].dataalteracao);
        setFildResponsavel(json.pedido[0].responsavel);
        // setFildTotalPBruto(converterParaFloat(json.pedido[0].pbruto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        // setFildTotalPLiquido(converterParaFloat(json.pedido[0].pliquido).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

        setFildBasecalculoicms(converterParaFloat(json.pedido[0].bcicms).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildValorIcms(converterParaFloat(json.pedido[0].vicms).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildBasecalculoicmsst(converterParaFloat(json.pedido[0].vbcst).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildValoricmsst(converterParaFloat(json.pedido[0].vicmsst).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildBasecalculoipi(converterParaFloat(json.pedido[0].bcipi).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildValorIpi(converterParaFloat(json.pedido[0].vipi).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

        setfildTotalProdutos(converterParaFloat(json.pedido[0].totalprodutos).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildTotal(converterParaFloat(json.pedido[0].total).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

        setFildTransportador(json.pedido[0].codigotransportadora);
        setFildRazaoTransportador(json.pedido[0].razaotransportador);
        setFildFantasiaTransportador(json.pedido[0].fantasiatransportador);
        setFildCnpjTransportador(json.pedido[0].cnpjtransportador);

        if (json.pedido[0].cancelado === "true") { setPedidoCancelado(true); }
        else { setPedidoCancelado(false); }


        setDadosCabecalho(json.pedido[0]);

        setDadosFornecedor(json.dadosFornecedor[0]);

        setListaItens(json.itens);
      }
    }
    else {
      //setListaDados([]);
    }

    setExibirProgress(false);
  }

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const BlocoValores = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const stylePesquisaProduto = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  }

  const styleDesconto = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,

  }

  function modalPesquisaTransportador() {
    return (
      <Modal
        open={openPesquisarTransportador}
        onClose={handleCloseModalPesquisarTransportador}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylePesquisaProduto}>

          <Typography style={{ marginLeft: 5 }}>PESQUISAR TRANSPORTADOR</Typography>

          <Grid item xs={12} md={2} >
            <Card variant="outlined">
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 0 }, }}
                noValidate
                autoComplete="off"
              >
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfModalPesquisaTransportador">Pesquisar </InputLabel>
                  <FilledInput
                    id="tfModalPesquisaTransportador"
                    autoFocus={true}
                    variant="filled"
                    //value={valorPesquisa}
                    // defaultValue={valorPesquisa}
                    onChange={(e) => handleChangePesquisarTransportador(e.target.value)}
                    sx={{ m: 1 }}
                  />
                </FormControl>

                {exibirProgress && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                )}

                <Paper className="container">
                  <TableContainer>
                    <Table aria-label="custom pagination table">
                      <TableHead>
                        <TableRow>

                          {(isMobile) ? columnsSearchCliente.splice(1, 2) : null}

                          {columnsSearchCliente.map((column) => (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {(rowsPerPageTransportador > 0
                          ? listaTransportadores.slice(pageTransportador * rowsPerPageTransportador, pageTransportador * rowsPerPageTransportador + rowsPerPageTransportador)
                          : listaTransportadores
                        ).map((row) => (
                          <TableRow key={row.id}>

                            <TableCell
                              align="left">
                              {row.razao}
                            </TableCell>

                            {(!isMobile) ?
                              (<TableCell
                                align="left">
                                {row.fantasia}
                              </TableCell>) : null
                            }

                            {(!isMobile) ?
                              (<TableCell
                                align="left">
                                {row.cnpj}
                              </TableCell>) : null
                            }

                            <TableCell align="center">
                              <Button
                                size="small"
                                color={"success"}
                                onClick={() => preencherDadosTransportador(row.codigo, row.razao, row.fantasia, row.cnpj)}
                              >
                                <CheckIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}

                        {emptyRowsTransportador > 0 && (
                          <TableRow style={{ height: 53 * emptyRowsTransportador }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={listaTransportadores.length}
                            rowsPerPage={rowsPerPageTransportador}
                            page={pageTransportador}

                            onPageChange={handleChangePageTransportador}
                            onRowsPerPageChange={handleChangeRowsPerPageTransportador}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Card>
          </Grid>


          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalPesquisarForencedor}>Voltar
          </Button>

          {/* <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarContato}>Salvar
          </Button> */}

        </Box>
      </Modal>
    )
  }

  function awaiteSend() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={impedirMultClick}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const [expanded, setExpanded] = React.useState('panel1');

  function botaoVoltar() {
    props.history.push('/app/PedidoCompra');
  }

  function botaoNovo() {

    setFildId('0');
    setFildEmissao(new Date());
    setFildDocumento('');
    setFildFornecedor('');
    setFildRazao('');
    setFildFantasia('');
    setFildCnpjcpf('');
    setFildEntrega('');
    setFildDesconto('');
    setFildFrete('');
    setfildFreteValor('');
    setFildAcrescimo('');
    setFildRecebimento('');
    setFildUsuario('');
    setFildNomeUsuario('');
    setFildCfop('');
    setFildCancelado('');
    setFildAuditoria('');
    setFildCodigoTransportadora('');
    setFildPrazoEntrega('');
    setFildOrdemCompra('');
    setFildBcIcms('0,00');
    setFildBcIpi('0,00');
    setFildObservacao('');
    setFildDataCadastro('');
    setFildDataAlteracao('');
    setFildResponsavel('');

    setFildBasecalculoicms('0,00');
    setFildValorIcms('0,00');
    setFildBasecalculoicmsst('0,00');
    setFildValoricmsst('0,00');
    setFildBasecalculoipi('0,00');
    setFildValorIpi('0,00');
    setfildTotalProdutos('0,00');
    setFildTotal('0,00');

    setFildTransportador('');
    setFildRazaoTransportador('');
    setFildFantasiaTransportador('');
    setFildCnpjTransportador('');

    setListaItens([]);

    handleChangePedidoCancelado(false);
  }

  function telaCelular() {

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
      <div>
        {/* Apos enviar solicitação aguardar o retorno para liberar a manipulação */}
        {awaiteSend()}

        {modalPesquisaTransportador()}

        <PesquisarFornecedores
          openPesquisarFornecedor={openPesquisarFornecedor}
          handleCloseModalPesquisarFornecedor={handleCloseModalPesquisarForencedor}
          preencherDadosFornecedor={preencherDadosFornecedor}
        />

        <PesquisarProdutos
          openPesquisar={openPesquisar}
          handleCloseModalPesquisar={handleCloseModalPesquisar}
          preencherDadosProduto={preencherDadosProduto}
        />

        {/* <Desconto
          openDesconto={openDesconto}
          handleCloseModalDesconto={handleCloseModalDesconto}
          tipoDesconto={tipoDesconto}
          handleChangeMdoDesconto={handleChangeModoDesconto}
          opcaoDesconto={opcaoDesconto}
          visualizarItemNoDesconto={visualizarItemNoDesconto}
          fildDesconto={fildDesconto}
          handleValorDesconto={handleValorDesconto}
          // validarDesconto={validarDesconto}
          exibirItemParaDesconto={exibirItemParaDesconto}
          handleChangeDescontoValor={handleChangeDescontoValor}
          handleChangeDescontoPercentual={handleChangeDescontoPercentual}
        /> */}

        <PageTitleMobile title={"Pedido - " + fildDocumento}
          buttonReturn="Voltar" eventoRetornar={botaoVoltar}

          buttonNew="Novo" eventoNovo={botaoNovo}

          buttonCancel="Cancelar" evendoCancelar={cancelRegistro}

          button="Salvar" eventoBotao={saveRegistro}
        />

        {pedidoCancelado ? <Typography style={{ marginLeft: 5, color: 'red' }}>CANCELADO</Typography> : null}

        <Snackbar open={openMessage} autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseMessage}>
          <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
            {textoMesagem}
          </Alert>
        </Snackbar>


        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Pedido</Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Grid >
              {/* <IconButton
                size="large"
                onClick={handleOpenModalPesquisarFornecedor} >
                <SearchIcon fontSize="inherit" color="primary" />
              </IconButton> */}

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfFantasia"
                >Nome Fantasia</InputLabel>
                <Input
                  id="tfFantasia"
                  value={fildFantasia}
                  defaultValue={fildFantasia}
                  readOnly={true}
                  onChange={(event) => setFildFantasia(event.target.value)}

                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        // aria-label="toggle password visibility"
                        onClick={(!pedidoCancelado) ? handleOpenModalPesquisarFornecedor : null}

                      >
                        <SearchIcon color="primary" />
                      </IconButton>
                    </InputAdornment>}

                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfCnpj">CNPJ</InputLabel>
                <FilledInput
                  id="tfCnpj"
                  value={fildCnpjcpf}
                  defaultValue={fildCnpjcpf}
                  readOnly={true}
                  onChange={(event) => setFildCnpjcpf(event.target.value)}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    noValidate={false}
                    id="tfDataEmissao"
                    label="Emissão"
                    inputFormat="DD/MM/YYYY"
                    value={fildEmissao}
                    onChange={handleChangeEmissao}
                    readOnly={pedidoCancelado}
                    renderInput={(params) => <TextField sx={{ m: 0 }} variant="filled" {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfOrdemCompra">Ordem de Compra</InputLabel>
                <FilledInput
                  id="tfOrdemCompra"
                  value={fildOrdemCompra}
                  defaultValue={fildOrdemCompra}
                  inputProps={{ maxLength: 15 }}
                  readOnly={pedidoCancelado}
                  onChange={(event) => setFildOrdemCompra(event.target.value)}
                />
              </FormControl>

              {/* lembrar de inserir quando o cfop for de devolução chamar o modal para referenciar as chaves nfe  */}
              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                {exibirProgressCfop && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}
                <TextField
                  id="tfCfop"
                  select
                  label="CFOP"
                  value={fildCfop}
                  onChange={handleChangeCfop}
                  variant="filled"
                  readOnly={pedidoCancelado}
                  error={validaCfop}
                >
                  {lsCfop.map((option) => (
                    <MenuItem key={option.cfop} value={option.cfop}>
                      {option.cfop + " - " + option.descricao}
                    </MenuItem>
                  ))}
                </TextField>

                <FormHelperText error id="component-error-text">{msgErroCfop}</FormHelperText>
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                {exibirProgressRecebimentos && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}
                <TextField
                  id="tfRecebimento"
                  select
                  label="Forma de Pagamento"
                  value={fildRecebimento}
                  onChange={handleChangeRecebimento}
                  variant="filled"
                  readOnly={pedidoCancelado}
                  error={validaRecebimento}
                >
                  {lsRecebimento.map((option) => (
                    <MenuItem key={option.codigo} value={option.codigo}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error id="component-error-text">{msgErroRecebimento}</FormHelperText>
              </FormControl>

              {/* <IconButton size="large"
                onClick={handleOpenModalDesconto}>
                <AddCircleRoundedIcon fontSize="inherit" color="error" />
              </IconButton> */}

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfDesconto">Desconto</InputLabel>
                <FilledInput
                  // type="number"
                  id="tfDesconto"
                  value={formatarMoeda(fildDesconto)}
                  defaultValue={fildDesconto}
                  readOnly={true}
                  onChange={(event) => setFildDesconto(event.target.value)}
                  // startAdornment={<InputAdornment position="start">{indicadorDesconto}</InputAdornment>}

                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        // aria-label="toggle password visibility"
                        // onClick={(!pedidoCancelado) ? handleOpenModalDesconto : null}
                        onClick={handleOpenModalDesconto}

                      >
                        <AddCircleRoundedIcon color="error" />
                      </IconButton>
                    </InputAdornment>}
                />
              </FormControl>

              {/* TOTAIS */}

              {/* <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfBruto">Peso Bruto</InputLabel>
                <FilledInput
                  // type="number"
                  id="tfBruto"
                  value={formatarMoeda(fildTotalPBruto)}
                  defaultValue={fildTotalPBruto}
                  onChange={(event) => setFildTotalPBruto(formatarMoeda(event.target.value))}

                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfPliquido">Peso Líquido</InputLabel>
                <FilledInput
                  id="tfPliquido"
                  // type="number"
                  value={formatarMoeda(fildTotalPLiquido)}
                  defaultValue={fildTotalPLiquido}
                  onChange={(event) => setFildTotalPLiquido(formatarMoeda(event.target.value))}
                />
              </FormControl> */}

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfAcrescimo">Acréscimo</InputLabel>
                <FilledInput
                  id="tfAcrescimo"
                  name="tfAcrescimo"
                  // type="number"
                  value={formatarMoeda(fildAcrescimo)}
                  defaultValue={fildAcrescimo}
                  readOnly={pedidoCancelado}
                  // onChange={formatarValores(event.target.value)}
                  onChange={(event) => setFildAcrescimo(formatarMoeda(event.target.value))}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfTotalProduto">Total dos Produtos</InputLabel>
                <FilledInput
                  id="tfTotalProduto"
                  // type="number"
                  readOnly={true}
                  value={formatarMoeda(fildTotalProdutos)}
                  defaultValue={fildTotalProdutos}
                //onChange={(event) => setfildTotalProdutos(event.target.value)}

                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfTotal">Total</InputLabel>
                <FilledInput
                  id="tfTotal"
                  // type="number"
                  readOnly={true}
                  value={formatarMoeda(fildTotal)}
                  defaultValue={fildTotal}
                // onChange={(event) => setFildTotal(event.target.value)}
                />
              </FormControl>

              {/* Fim TOTAIS */}

            </Grid>
            <Typography style={{ marginLeft: 5 }}>Transportador e Frete</Typography>
            {/* <Card variant="outlined" > */}

            {/* TRANSPORTADOR */}
            <Grid item xs={12} >

              {/* <IconButton size="large" sx={{ m: 1 }}>
                <SearchIcon fontSize="inherit" color="primary"
                  onClick={handleOpenModalPesquisarTransportador} />
              </IconButton> */}

              <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                <InputLabel htmlFor="tfFantasia">Nome Fantasia</InputLabel>
                <FilledInput
                  id="tfFantasia"
                  value={fildFantasiaTransportador}
                  defaultValue={fildFantasiaTransportador}
                  readOnly={true}
                  onChange={(event) => setFildFantasiaTransportador(event.target.value)}

                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        // aria-label="toggle password visibility"
                        onClick={(!pedidoCancelado) ? handleOpenModalPesquisarTransportador : null}

                      >
                        <SearchIcon color="primary" />
                      </IconButton>
                    </InputAdornment>}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                <InputLabel htmlFor="tfCnpj">CNPJ</InputLabel>
                <FilledInput
                  id="tfCnpj"
                  value={fildCnpjTransportador}
                  defaultValue={fildCnpjTransportador}
                  readOnly={true}
                  onChange={(event) => setFildCnpjTransportador(event.target.value)}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                <TextField
                  id="tfTipoFrete"
                  select
                  label="Frete"

                  value={fildFrete}
                  onChange={handleChangeTipoFrete}
                  variant="filled"
                  readOnly={pedidoCancelado}
                >
                  {tipoFrete.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                <InputLabel htmlFor="tfValorFrete">Frete Valor</InputLabel>

                <FilledInput
                  id="tfValorFrete"
                  // type="number"
                  value={fildFreteValor}
                  defaultValue={fildFreteValor}
                  readOnly={pedidoCancelado}
                  onChange={(event) => setfildFreteValor(formatarValores(event.target.value))}
                  startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                />
              </FormControl>

              {/* TOTAIS */}

            </Grid>
            {/* </Card> */}

            <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfObservacao">Observação</InputLabel>
              <FilledInput
                id="tfObservacao"
                value={fildObservacao}
                defaultValue={fildObservacao}
                inputProps={{ maxLength: 255 }}
                readOnly={pedidoCancelado}
                onChange={(event) => setFildObservacao(event.target.value)}
              />
            </FormControl>

          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Itens</Typography>
          </AccordionSummary>
          <AccordionDetails>

            {/* PRODUTO */}
            <Grid item xs={12} md={12} >

              <Card item variant="outlined" sx={{ flexGrow: 1 }}>
                <Typography sx={{ m: 1 }} >Produto(s)</Typography>

                <Grid item xs={12} md={12} sx={{ m: 1 }}>

                  {/* <IconButton size="large" sx={{ m: 1 }}
                    onClick={handleOpenModalPesquisar}
                  >
                    <SearchIcon fontSize="inherit" color="primary" />

                  </IconButton> */}

                  <FormControl sx={{ m: 0.6, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfDescricao">Descrição</InputLabel>
                    <FilledInput
                      id="tfDescricao"
                      value={fildDescricao}
                      defaultValue={fildDescricao}
                      readOnly={true}
                      onChange={(event) => setFildDescricao(event.target.value)}
                      error={validaProduto}

                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={(!pedidoCancelado) ? handleOpenModalPesquisar : null}
                          >
                            <SearchIcon color="primary" />
                          </IconButton>
                        </InputAdornment>}
                    />
                    <FormHelperText error id="component-error-text">{msgErroProduto}</FormHelperText>
                  </FormControl>

                  <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                    <InputLabel htmlFor="tfQuantidade">Qtde.</InputLabel>
                    <FilledInput
                      id="tfQuantidade"
                      // type="number"
                      value={fildQuantidade}
                      defaultValue={fildQuantidade}
                      readOnly={pedidoCancelado}
                      onChange={(event) => setFildQuantidade(formatarMoeda(event.target.value))}
                      error={validaQuantidade}
                    />
                    <FormHelperText error id="component-error-text">{msgErroQuantidade}</FormHelperText>
                  </FormControl>

                  <FormControl sx={{ m: 0.6, width: '16ch' }} variant="filled">
                    <InputLabel htmlFor="tfPreco">Valor Unitário</InputLabel>
                    <FilledInput
                      id="tfPreco"
                      // type="number"
                      value={fildPreco}
                      defaultValue={fildPreco}
                      readOnly={pedidoCancelado}
                      startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      onChange={(event) => setFildPreco(formatarMoeda(event.target.value))}
                      error={validaPreco}
                    />
                    <FormHelperText error id="component-error-text">{msgErroPreco}</FormHelperText>
                  </FormControl>

                  <Button
                    color={"success"}
                    size="small"
                    className="px-2"
                    variant="contained"
                    onClick={(!pedidoCancelado) ? montarRegistro() : null}
                  >
                    <CheckIcon />Incluir
                  </Button>

                </Grid>

              </Card>

            </Grid>

            <TableContainer>
              <Table>
                {/* <Table sx={{ minWidth: 500 }} aria-label="custom pagination table"> */}
                <TableHead>
                  <TableRow>
                    {columnsMobile.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(rowsPerPage > 0
                    ? listaItens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : listaItens
                  ).map((row, index) => (
                    <TableRow key={index} >
                      <TableCell
                        align="center">
                        <TextField
                          fullWidth
                          id="tf_DescricaoProduto"
                          multiline
                          variant="standard"
                          // style={{ width: '100%' }}
                          value={row.descricao}
                          defaultValue={row.descricao}
                          onChange={(!pedidoCancelado) ? e => changeItemDescricao(e, index) : null}
                        />

                        <FormControl sx={{ m: 0.1, width: '17ch' }} variant="standard">
                          <InputLabel htmlFor={"tf_QuantidadeProduto" + index}>Qtd.</InputLabel>
                          <FilledInput
                            id={"tf_QuantidadeProduto" + index}
                            value={formatarMoeda(row.quantidade)}
                            defaultValue={row.quantidade}
                            onChange={(!pedidoCancelado) ? e => changeItemQuantidade(e, index) : null}
                          />
                        </FormControl>

                        <FormControl sx={{ m: 0.1, width: '17ch' }} variant="standard">
                          <InputLabel htmlFor={"tf_AtendidoProduto" + index}>Atend.</InputLabel>
                          <FilledInput
                            id={"tf_AtendidoProduto" + index}
                            value={row.atendido}
                            defaultValue={row.atendido}
                            onChange={(!pedidoCancelado) ? e => changeItemAtendido(e, index) : null}

                          />
                        </FormControl>

                        <FormControl sx={{ m: 0.1, width: '17ch' }} variant="standard">
                          <InputLabel htmlFor={"tfPreco" + index}>Preço</InputLabel>
                          <FilledInput
                            id={"tfPreco" + index}
                            readOnly={true}
                            value={formatarMoeda(row.preco)}
                            defaultValue={row.preco}
                          />
                        </FormControl>

                        <FormControl sx={{ m: 0.1, width: '17ch' }} variant="standard">
                          <InputLabel htmlFor={"tfTotal" + index}>Total</InputLabel>
                          <FilledInput
                            id={"tfTotal" + index}
                            readOnly={true}
                            value={formatarValoresPtBr(row.total)}
                            defaultValue={row.total}
                          />
                        </FormControl>

                        <IconButton size="small" sx={{ marginRight: 2 }}
                          onClick={(!pedidoCancelado) ? saveRegistro() : null}
                        >
                          <CheckIcon fontSize="inherit" color={"success"} />
                        </IconButton>

                        <IconButton size="small" sx={{ m: 0.1 }}
                          onClick={(!pedidoCancelado) ? removeRegistro({ index }, row.chave) : null}
                        >
                          <DeleteRoundedIcon fontSize="inherit" color={"error"} />
                        </IconButton>

                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>

                {/* <TableFooter> */}
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={listaItens.length}
                    rowsPerPage={rowsPerPage}
                    page={page}

                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Linhas por página',

                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
                {/* </TableFooter> */}

              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

      </div>
    );

  }

  function ImprimirPedido() {

    PrintPurchaseOrder(dadosCabecalho, dadosFornecedor, listaItens);
  }

  function exibirItemParaDesconto() {
    return (
      <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
        <InputLabel htmlFor="tfItemDesconto">Item</InputLabel>
        <FilledInput
          type="number"
          id="tfItemDesconto"
          value={fildItemDesconto}
          defaultValue={fildItemDesconto}
          onChange={(event) => setFildItemDesconto(event.target.value)}
        />
      </FormControl>
    )
  }

  // function validarDesconto() {

  //   let liberado = false;

  //   //Desconto no TOTAL da Venda
  //   if (tipoDesconto === "P") {
  //     //TOTAL DA VENDA

  //     if (opcaoDesconto[1]) {

  //       let conversao = (parseFloat(fildDesconto) * 1);
  //       let v_total = (parseFloat(fildTotal) * 1);

  //       if (conversao <= v_total) {

  //         liberado = true;

  //       } else { setFildDesconto(0); }

  //     } else {

  //       //Desconto em %
  //       let _vTotal = 0;

  //       if (fildTotal !== "") {
  //         _vTotal = parseFloat(fildTotal);
  //       }

  //       let conversao = (parseFloat(fildDesconto) / 100) * (_vTotal);
  //       let v_total = parseFloat(fildTotal);

  //       if (conversao <= v_total) {

  //         liberado = true;

  //       } else { setFildDesconto(0); }

  //       setFildDesconto(conversao);
  //       setOpcaoDesconto([false, true]);
  //     }

  //     handleConfirmarDesconto();

  //     if (!liberado) {

  //       setTipoMensagem('error');
  //       setTextoMensagem('Desconto não pode ser maior que o valor total!');
  //       handleOpenMessage();

  //       return;
  //     }

  //     saveRegistro();

  //   } else if (tipoDesconto === "I") {
  //     //DESCONTO POR ITEM

  //     descontoNoItem(fildItemDesconto);

  //   }

  // }

  function descontoNoItem(nomeroitem) {

    handleConfirmarDesconto();

    const itemExists = listaItens.find((item) => item.numero_item === nomeroitem);

    if (!itemExists) {

      setFildDesconto(0);
      setTipoMensagem('error');
      setTextoMensagem('Item não encontrado!');
      handleOpenMessage();
      //escreve amensagem informando que o item nao foi encontrado

      return;
    }

    let liberado = false;
    setListaItens((prevItems) => {

      return prevItems.map((item) => {
        if (item.numero_item === nomeroitem) {

          let vDesconto = parseFloat(fildDesconto);

          if (vDesconto <= item.valor_subtotal) {

            liberado = true;

          } else { setFildDesconto(0); }

          item.valor_desconto = fildDesconto;
        }

        return item;
      });
    });

    if (!liberado) {

      setTipoMensagem('error');
      setTextoMensagem('Desconto não pode ser maior que o valor total do produto!');
      handleOpenMessage();

      return;
    }

    setHabilitarAdicaoAlteracao(true);

  }

  // function modalDesconto() {
  //   return (
  //     <Modal
  //       open={openDesconto}
  //       onClose={handleCloseModalDesconto}
  //       aria-labelledby="modal-modal-title"
  //       aria-describedby="modal-modal-description"
  //     >
  //       <Box sx={styleDesconto}>

  //         <Typography style={{ marginLeft: 5 }}>DESCONTO</Typography>

  //         <Grid item xs={12} >
  //           <Card variant="outlined">
  //             <Box
  //               component="form"
  //               sx={{ '& .MuiTextField-root': { m: 1 }, }}
  //               noValidate
  //               autoComplete="off"
  //             >
  //               <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
  //                 <TextField
  //                   id="tfModoDesconto"
  //                   select
  //                   label="Modo Desconto"
  //                   // sx={{ width: '25ch' }}
  //                   value={fildMododesconto}
  //                   onChange={handleChangeModoDesconto}
  //                   variant="filled"
  //                 >
  //                   {modoDesconto.map((option) => (
  //                     <MenuItem key={option.value} value={option.value}>
  //                       {option.label}
  //                     </MenuItem>
  //                   ))}
  //                 </TextField>
  //               </FormControl>

  //               <div>
  //                 <FormControlLabel
  //                   sx={{ m: 0.4 }}
  //                   style={{ marginTop: 12 }}
  //                   label="%"
  //                   control={<Checkbox checked={opcaoDesconto[0]} onChange={handleChangeDescontoPercentual} />}
  //                 />

  //                 <FormControlLabel
  //                   style={{ marginTop: 12 }}
  //                   label="R$"
  //                   control={<Checkbox checked={opcaoDesconto[1]} onChange={handleChangeDescontoValor} />}
  //                 />
  //               </div>

  //               {(visualizarItemNoDesconto) ? exibirItemParaDesconto() : null}

  //               <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
  //                 <FilledInput
  //                   // type="number"
  //                   id="tfDescontoModal"
  //                   autoFocus={true}
  //                   value={fildDesconto}
  //                   defaultValue={fildDesconto}

  //                   onChange={(event) => handleValorDesconto(formatarMoeda(event.target.value))}
  //                 />
  //               </FormControl>

  //             </Box>
  //           </Card>
  //         </Grid>

  //         <Button variant="contained" color="error" style={{ margin: 5 }}
  //           onClick={handleCloseModalDesconto}>Voltar
  //         </Button>
  //         <Button variant="contained" color="success" style={{ margin: 5 }}
  //           onClick={validarDesconto}>Confirmar
  //         </Button>

  //       </Box>
  //     </Modal>
  //   )
  // }

  function telaPadrao() {
    return (
      <>
        {/* Apos enviar solicitação aguardar o retorno para liberar a manipulação */}
        {awaiteSend()}

        {modalPesquisaTransportador()}

        <PesquisarFornecedores
          openPesquisarFornecedor={openPesquisarFornecedor}
          handleCloseModalPesquisarFornecedor={handleCloseModalPesquisarForencedor}
          preencherDadosFornecedor={preencherDadosFornecedor}
        />

        <PesquisarProdutos
          openPesquisar={openPesquisar}
          handleCloseModalPesquisar={handleCloseModalPesquisar}
          preencherDadosProduto={preencherDadosProduto}
        />

        {/* {modalDesconto()} */}

        <Desconto
          openDesconto={openDesconto}
          handleCloseModalDesconto={handleCloseModalDesconto}
          tipoDesconto={tipoDesconto}
          handleChangeMdoDesconto={handleChangeModoDesconto}
          opcaoDesconto={opcaoDesconto}
          visualizarItemNoDesconto={visualizarItemNoDesconto}
          fildDesconto={fildDesconto}
          handleValorDesconto={handleValorDesconto}
          exibirItemParaDesconto={exibirItemParaDesconto}
          handleChangeDescontoValor={handleChangeDescontoValor}
          handleChangeDescontoPercentual={handleChangeDescontoPercentual}
          validarDesconto={handleConcluirDesconto}
        />

        <PageTitle title={"Pedido de Compra " + fildDocumento}
          EnableDesableButton={registroCancelado}
          buttonReturn="Voltar" eventoRetornar={botaoVoltar}
          buttonNew="Novo" eventoNovo={botaoNovo}
          buttonCancel="Cancelar" evendoCancelar={cancelRegistro}
          button="Salvar" eventoBotao={saveRegistro}
          buttonPrint="Imprimir" eventoImprimir={ImprimirPedido}
        />

        {pedidoCancelado ? <Typography style={{ marginLeft: 5, color: 'red' }}>CANCELADO</Typography> : null}

        <Snackbar open={openMessage} autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseMessage}>
          <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
            {textoMesagem}
          </Alert>
        </Snackbar>

        <Grid container spacing={0}>
          {exibirProgress && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}

          {/* Dados da Compra */}
          <Grid item xs={16} md={16} sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}>
            {/* Compra */}
            <Card variant="outlined" sx={{ flexGrow: 1 }} >
              <Grid >
                <FormControl fullWidth sx={{ m: 0.6, width: '45ch' }} variant="filled">
                  <InputLabel htmlFor="tfFantasia">Nome Fantasia</InputLabel>
                  <FilledInput
                    id="tfFantasia"
                    value={fildFantasia}
                    defaultValue={fildFantasia}
                    readOnly={true}
                    onChange={(event) => setFildFantasia(event.target.value)}

                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(!pedidoCancelado) ? handleOpenModalPesquisarFornecedor : null}
                        >
                          <SearchIcon color="primary" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 0.6, width: '45ch' }} variant="filled">
                  <InputLabel htmlFor="tfRazao">Razão Social</InputLabel>
                  <FilledInput
                    id="tfRazao"
                    value={fildRazao}
                    defaultValue={fildRazao}
                    onChange={(event) => setFildRazao(event.target.value)}
                    readOnly={true}
                    error={validaCliente}
                  />
                  <FormHelperText error id="component-error-text">{msgErroCliente}</FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={{ m: 0.6, width: '28ch' }} variant="filled">
                  <InputLabel htmlFor="tfCnpj">CNPJ</InputLabel>
                  <FilledInput
                    id="tfCnpj"
                    value={fildCnpjcpf}
                    defaultValue={fildCnpjcpf}
                    readOnly={true}
                    onChange={(event) => setFildCnpjcpf(event.target.value)}
                  />
                </FormControl>

                <div></div>

                <FormControl sx={{ m: 0.6 }} variant="filled">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      noValidate={false}
                      id="tfDataEmissao"
                      label="Emissão"
                      inputFormat="DD/MM/YYYY"
                      value={fildEmissao}
                      onChange={handleChangeEmissao}
                      readOnly={pedidoCancelado}
                      renderInput={(params) => <TextField sx={{ m: 0, width: '20ch' }} variant="filled" {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl fullWidth sx={{ m: 0.6, width: '18ch' }} variant="filled">
                  <InputLabel htmlFor="tfOrdemCompra">Ordem de Compra</InputLabel>
                  <FilledInput
                    id="tfOrdemCompra"
                    value={fildOrdemCompra}
                    defaultValue={fildOrdemCompra}
                    inputProps={{ maxLength: 15 }}
                    readOnly={pedidoCancelado}
                    onChange={(event) => setFildOrdemCompra(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 0.6, width: '35ch' }} variant="filled">
                  {exibirProgressCfop && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}
                  <TextField
                    id="tfCfop"
                    select
                    label="CFOP"
                    value={fildCfop}
                    onChange={handleChangeCfop}
                    variant="filled"
                    error={validaCfop}
                    readOnly={pedidoCancelado}
                  >
                    {lsCfop.map((option) => (
                      <MenuItem key={option.cfop} value={option.cfop}>
                        {option.cfop + " - " + option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>

                  <FormHelperText error id="component-error-text">{msgErroCfop}</FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={{ m: 0.6, width: '31.5ch' }} variant="filled">
                  {exibirProgressRecebimentos && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}
                  <TextField
                    id="tfRecebimento"
                    select
                    label="Forma de Pagamento"
                    value={fildRecebimento}
                    onChange={handleChangeRecebimento}
                    variant="filled"
                    error={validaRecebimento}
                    readOnly={pedidoCancelado}
                  >
                    {lsRecebimento.map((option) => (
                      <MenuItem key={option.codigo} value={option.codigo}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText error id="component-error-text">{msgErroRecebimento}</FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={{ m: 0.6, width: '18ch' }} variant="filled">
                  <InputLabel htmlFor="tfDesconto">Desconto</InputLabel>
                  <FilledInput
                    id="tfDesconto"
                    value={formatarMoeda(fildDesconto)}
                    defaultValue={fildDesconto}
                    readOnly={true}
                    onChange={(event) => setFildDesconto(event.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(!pedidoCancelado) ? handleOpenModalDesconto : null}
                        >
                          <AddCircleRoundedIcon color="error" />
                        </IconButton>
                      </InputAdornment>}
                  />
                </FormControl>

                {/* TOTAIS */}

                <FormControl sx={{ m: 0.6, width: '17ch' }} variant="filled">
                  <InputLabel htmlFor="tfAcrescimo">Acréscimo</InputLabel>
                  <FilledInput
                    id="tfAcrescimo"
                    name="tfAcrescimo"
                    // type="number"
                    value={formatarMoeda(fildAcrescimo)}
                    defaultValue={fildAcrescimo}
                    readOnly={pedidoCancelado}
                    onChange={(event) => setFildAcrescimo(formatarMoeda(event.target.value))}
                  />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '17ch' }} variant="filled">
                  <InputLabel htmlFor="tfTotalProduto">Total dos Produtos</InputLabel>
                  <FilledInput
                    id="tfTotalProduto"
                    readOnly={true}
                    value={formatarMoeda(fildTotalProdutos)}
                    defaultValue={fildTotalProdutos}
                  />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '17ch' }} variant="filled">
                  <InputLabel htmlFor="tfTotal">Total</InputLabel>
                  <FilledInput
                    id="tfTotal"
                    readOnly={true}
                    value={formatarMoeda(fildTotal)}
                    defaultValue={fildTotal}
                  />
                </FormControl>

                {/* Fim TOTAIS */}

              </Grid>
              <Typography style={{ marginLeft: 5 }}>Transportador e Frete</Typography>

              {/* TRANSPORTADOR */}

              <FormControl fullWidth sx={{ m: 0.6, width: '45ch' }} variant="filled">
                <InputLabel htmlFor="tfFantasia">Nome Fantasia</InputLabel>
                <FilledInput
                  id="tfFantasia"
                  value={fildFantasiaTransportador}
                  defaultValue={fildFantasiaTransportador}
                  readOnly={true}
                  onChange={(event) => setFildFantasiaTransportador(event.target.value)}

                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(!pedidoCancelado) ? handleOpenModalPesquisarTransportador : null}
                      >
                        <SearchIcon color="primary" />
                      </IconButton>
                    </InputAdornment>}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.6, width: '28ch' }} variant="filled">
                <InputLabel htmlFor="tfCnpj">CNPJ</InputLabel>
                <FilledInput
                  id="tfCnpj"
                  value={fildCnpjTransportador}
                  defaultValue={fildCnpjTransportador}
                  readOnly={true}
                  onChange={(event) => setFildCnpjTransportador(event.target.value)}
                />
              </FormControl>

              <FormControl sx={{ m: 0.6 }} variant="filled">
                <TextField
                  id="tfTipoFrete"
                  select
                  label="Frete"
                  sx={{ width: '35ch' }}
                  value={fildFrete}
                  onChange={handleChangeTipoFrete}
                  variant="filled"
                  readOnly={pedidoCancelado}
                >
                  {tipoFrete.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.6, width: '17ch' }} variant="filled">
                <InputLabel htmlFor="tfValorFrete">Frete Valor</InputLabel>

                <FilledInput
                  id="tfValorFrete"
                  // type="number"
                  value={fildFreteValor}
                  defaultValue={fildFreteValor}
                  onChange={(event) => setfildFreteValor(formatarValores(event.target.value))}
                  readOnly={pedidoCancelado}
                  startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                />
              </FormControl>

              {/* </Card> */}
              <FormControl sx={{ m: 0.6, width: '45ch' }} variant="filled">
                <InputLabel htmlFor="tfObservacao">Observação</InputLabel>
                <FilledInput
                  multiline
                  id="tfObservacao"
                  value={fildObservacao}
                  defaultValue={fildObservacao}
                  inputProps={{ maxLength: 255 }}
                  readOnly={pedidoCancelado}
                  onChange={(event) => setFildObservacao(event.target.value)}
                />
              </FormControl>

            </Card>

          </Grid>

          {/* ITENS */}

          <Grid container spacing={0} >
            <Grid item xs={12} md={12} >

              <Card item variant="outlined" sx={{ flexGrow: 1 }}>
                <Typography sx={{ m: 1 }} >Produto(s)</Typography>

                <Grid item xs={12} md={12} sx={{ m: 1 }}>

                  <FormControl sx={{ m: 0.6, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfDescricao">Descrição</InputLabel>
                    <FilledInput
                      id="tfDescricao"
                      value={fildDescricao}
                      defaultValue={fildDescricao}
                      readOnly={true}
                      onChange={(event) => setFildDescricao(event.target.value)}
                      error={validaProduto}

                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={(!pedidoCancelado) ? handleOpenModalPesquisar : null}
                          >
                            <SearchIcon color="primary" />
                          </IconButton>
                        </InputAdornment>}
                    />
                    <FormHelperText error id="component-error-text">{msgErroProduto}</FormHelperText>
                  </FormControl>

                  <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                    <InputLabel htmlFor="tfUnidade">Unidade</InputLabel>
                    <FilledInput
                      id="tfUnidade"
                      value={fildUnidade}
                      defaultValue={fildUnidade}
                      inputProps={{ maxLength: 4 }}
                      readOnly={pedidoCancelado}
                      onChange={(event) => setFildUnidade(event.target.value)}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                    <InputLabel htmlFor="tfVolume">Volume</InputLabel>
                    <FilledInput
                      id="tfVolume"
                      // type="number"
                      value={fildVolume}
                      defaultValue={fildVolume}
                      readOnly={pedidoCancelado}
                      onChange={(event) => setFildVolume(formatarMoeda(event.target.value))}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                    <InputLabel htmlFor="tfQuantidade">Qtde.</InputLabel>
                    <FilledInput
                      id="tfQuantidade"
                      // type="number"
                      value={fildQuantidade}
                      defaultValue={fildQuantidade}
                      readOnly={pedidoCancelado}
                      onChange={(event) => setFildQuantidade(formatarMoeda(event.target.value))}
                      error={validaQuantidade}
                    />
                    <FormHelperText error id="component-error-text">{msgErroQuantidade}</FormHelperText>
                  </FormControl>

                  <FormControl sx={{ m: 0.6, width: '16ch' }} variant="filled">
                    <InputLabel htmlFor="tfPreco">Valor Unitário</InputLabel>
                    <FilledInput
                      id="tfPreco"
                      // type="number"
                      value={fildPreco}
                      defaultValue={fildPreco}
                      startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      onChange={(event) => setFildPreco(formatarMoeda(event.target.value))}
                      readOnly={pedidoCancelado}
                      error={validaPreco}
                    />
                    <FormHelperText error id="component-error-text">{msgErroPreco}</FormHelperText>
                  </FormControl>

                  <Button
                    color={"success"}
                    size="small"
                    className="px-2"
                    variant="contained"
                    onClick={(!pedidoCancelado) ? () => montarRegistro() : null}
                  >
                    <CheckIcon />Incluir
                  </Button>

                </Grid>

              </Card>

              <Card variant="outlined" sx={{ flexGrow: 1 }}>
                {/* <ThemeProvider theme={LocalesPtBr()}> */}
                <Paper className="container">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column, index) => (
                            <StyledTableCell
                              key={index}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {(rowsPerPage > 0
                          ? listaItens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : listaItens
                        ).map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="left">
                              {row.produto}
                            </TableCell>

                            <TableCell
                              // style={{ width: "40%" }}
                              align="left">
                              {/* {row.descricao} */}
                              <TextField
                                id="tf_DescricaoProduto"
                                multiline
                                variant="standard"
                                style={{ width: '100%' }}
                                value={row.descricao}
                                defaultValue={row.descricao}
                                onChange={(!pedidoCancelado) ? e => changeItemDescricao(e, index) : null}
                              />
                            </TableCell>

                            <TableCell
                              align="left">

                              <TextField
                                id="tf_UnidadeProduto"
                                variant="standard"
                                style={{ width: '7ch' }}
                                value={row.unidade}
                                defaultValue={row.unidade}
                                onChange={(!pedidoCancelado) ? e => changeItemUnidade(e, index) : null}
                              />

                            </TableCell>

                            <TableCell
                              align="left">

                              <TextField
                                id="tf_IcmsProduto"
                                // type="number"
                                variant="standard"
                                style={{ width: '8ch' }}
                                value={row.icms}
                                defaultValue={row.icms}
                                onChange={(!pedidoCancelado) ? e => changeItemIcms(e, index) : null}
                              />

                            </TableCell>

                            <TableCell
                              align="left">
                              <TextField
                                style={{ width: '8ch' }}
                                id="tf_IpiProduto"
                                //  type="number"
                                variant="standard"
                                value={row.ipi}
                                defaultValue={row.ipi}
                                onChange={(!pedidoCancelado) ? e => changeItemIpi(e, index) : null}

                              />

                            </TableCell>

                            <TableCell
                              align="left">
                              <TextField
                                style={{ width: '13ch' }}
                                id="tf_QuantidadeProduto"
                                // type="number"
                                variant="standard"
                                value={formatarMoeda(row.quantidade)}
                                defaultValue={row.quantidade}
                                onChange={(!pedidoCancelado) ? e => changeItemQuantidade(e, index) : null}
                              />
                            </TableCell>
                            <TableCell
                              align="left">
                              <TextField
                                style={{ width: '13ch' }}
                                id="tf_AtendidoProduto"
                                // type="number"
                                variant="standard"
                                value={row.atendido}
                                defaultValue={row.atendido}
                                readOnly={true}
                                onChange={(!pedidoCancelado) ? e => changeItemAtendido(e, index) : null}

                              />
                            </TableCell>

                            <TableCell
                              align="right">

                              <TextField
                                style={{ width: '13ch', align: 'right' }}
                                variant="standard"
                                readOnly={true}
                                value={formatarMoeda(row.preco)}
                                defaultValue={row.preco}
                                inputProps={{ align: 'right' }}
                              />
                            </TableCell>

                            <TableCell
                              align="right">
                              <TextField
                                style={{ width: '13ch', align: 'right' }}
                                variant="standard"
                                readOnly={true}
                                value={formatarValoresPtBr(row.total)}
                                defaultValue={row.total}
                                inputProps={{ align: 'right' }}
                              />

                            </TableCell>

                            <TableCell align="center">
                              <IconButton size="small" sx={{ m: 1 }}
                                onClick={(!pedidoCancelado) ? () => saveRegistro() : null}
                              >
                                <CheckIcon fontSize="inherit" color={"success"} />
                              </IconButton>

                              <IconButton size="small" sx={{ m: 1 }}
                                onClick={(!pedidoCancelado) ? () => removeRegistro({ index }, row.chave) : null}
                              >
                                <DeleteRoundedIcon fontSize="inherit" color={"error"} />
                              </IconButton>

                            </TableCell>
                          </TableRow>
                        ))}

                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={4} />
                          </TableRow>
                        )}
                      </TableBody>

                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={listaItens.length}
                          rowsPerPage={rowsPerPage}
                          page={page}

                          SelectProps={{
                            inputProps: {
                              'aria-label': 'Linhas por página',

                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>

                    </Table>
                  </TableContainer>
                </Paper>
                {/* </ThemeProvider> */}
              </Card>

            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (

    (isMobile) ?
      (telaCelular()) : telaPadrao()

  );
}
