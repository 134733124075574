import React from "react";
import { Button } from "@material-ui/core";


import { ButtonGroup, Menu, MenuItem, Divider } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

// styles
import useStyles from "./styles";
import { green } from '@material-ui/core/colors';

// components
import { Typography } from "../Wrappers";

//Icones
import CheckIcon from '@mui/icons-material/Check';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export default function PageTitleMobile(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  var classes = useStyles();




  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h2" size="sm">
        {props.title}
      </Typography>

      <div>
        <Button
          variant="contained"
          size="small"
          color="primary"
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Menu
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {/* VOLTAR */}
          {
            props.buttonReturn && (
              <MenuItem onClick={() => {
                props.eventoRetornar();
                handleClose();
              }} disableRipple>
                <KeyboardBackspaceIcon /> {props.buttonReturn}
              </MenuItem>)
          }

          {/* NOVO */}
          {
            props.buttonNew && (<MenuItem onClick={
              () => {
                props.eventoNovo();
                handleClose();
              }} disableRipple>
              <AddIcon /> {props.buttonNew}
            </MenuItem>)
          }

          {/* CANCELAR */}
          {
            props.buttonCancel && (<MenuItem onClick={
              () => {
                props.evendoCancelar();
                handleClose();
              }} disableRipple>
              <CloseIcon /> {props.buttonCancel}
            </MenuItem>)
          }

          {/* SALVAR */}
          {
            props.button && (<MenuItem onClick={
              () => {
                props.eventoBotao();
                handleClose();
              }} disableRipple>
              <CheckIcon /> {props.button}
            </MenuItem>)
          }

          {/* IMPRIMIR */}
          {
            props.buttonPrint && (<MenuItem onClick={
              () => {
                // props.eventoBotao();
                handleClose();
              }} disableRipple>
              <PrintIcon /> {props.buttonPrint}
            </MenuItem>)
          }

        </StyledMenu>
      </div>

    </div>
  );
}
