import React from "react";
import { Button } from "@material-ui/core";


import { ButtonGroup } from '@mui/material';

// styles
import useStyles from "./styles";
import { green } from '@material-ui/core/colors';

// components
import { Typography } from "../Wrappers";

//Icones
import CheckIcon from '@mui/icons-material/Check';
import PrintIcon from '@mui/icons-material/Print';



export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h2" size="sm">
        {props.title}
      </Typography>

      <div>
        {
          <ButtonGroup variant="contained"
            aria-label="outlined primary button group">
            {/* 
            <Button variant="contained" sx={{ m: 1 }}  size="large" color="primary" >Novo</Button>
            <Button variant="contained" sx={{ m: 1 }}  size="large" color="primary"  >NF-e</Button>
            <Button variant="contained" sx={{ m: 1 }}  size="large" color="primary">Salvar</Button> */}

            {
              props.buttonReturn && (<Button variant="contained" sx={{ m: 1 }} size="small"
                color="primary"
                onClick={() => props.eventoRetornar()}>{props.buttonReturn}</Button>
              )}

            {
              props.buttonNew && (<Button variant="contained" sx={{ m: 1 }} size="small"
                color="primary"
                onClick={() => props.eventoNovo()}>{props.buttonNew}</Button>
              )}

            {
              props.buttonCancel && (<Button variant="contained" sx={{ m: 1 }} size="small"
                color="secondary"
                disabled={props.EnableDesableButton}
                onClick={() => props.evendoCancelar()}>{props.buttonCancel}</Button>
              )}

            {props.button && (
              <Button
                variant="contained"
                size="small"
                onClick={() => props.eventoBotao()}
                startIcon={<CheckIcon />}
                color='primary'
                style={{ marginRight: 5 }}
                disabled={props.EnableDesableButton}
              >
                {props.button}
              </Button>
            )}


            {props.buttonPrint && (
              <Button
                variant="contained"
                size="small"
                color='secondary'
                style={{ marginRight: 5 }}
                onClick={(e) => props.eventoImprimir()} 
                startIcon={<PrintIcon />}
              >
                {props.buttonPrint}
              </Button>
            )}
            {/* <Button variant="contained" sx={{ m: 1 }}  size="large" color="primary">Espelhar</Button>
            <Button variant="contained" sx={{ m: 1 }}  size="large" color="primary">Imprimir</Button>
            <Button variant="contained" sx={{ m: 1 }}  size="large" color="primary">Utilitário</Button> */}
          </ButtonGroup>

        }

      </div>

    </div>
  );
}
