// import React from "react";
import { Grid, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr from "../../components/LocalesPtBr";

import { LinearProgress, InputAdornment, Input } from '@mui/material';

import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Button } from "../../components/Wrappers/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno } from "../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { isMobile } from 'react-device-detect';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: '', label: '' },
  { id: 'documento', label: 'Documento' },
  { id: 'emissao', label: 'Emissão' },
  { id: 'razao', label: 'Razão Social' },
  { id: 'fantasia', label: 'Nome Fantasia' },
  { id: 'cnpj', label: 'CPF/CNPJ' },
  { id: 'total', label: 'Total' },

];

const columnsMobile = [
  { id: 'fantasia', label: 'Nome Fantasia' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ListSalesOrder(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [listaDados, setListaDados] = useState([]);
  const [idselecionado, setIdSelecionado] = useState(0);
  const [valorPesquisa, setValorPesquisa] = useState('');

  const [exibirProgress, setExibirProgress] = useState(true);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaDados.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function listarRegistros() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPreVenda.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listar',
        senha: senhaRetorno,
        login: usuarioRetorno,
        pesquisar: valorPesquisa
      })
    });

    let json = await response.json();

    setExibirProgress(false);

    if (json.success) {
      if (json.result === null) {
        setListaDados([]);

      } else {
        setListaDados(json.result);

      }
    }
    else { setListaDados([]); }
  }

  useEffect(() => {
    setExibirProgress(true);
    listarRegistros();

  }, [listarRegistros, valorPesquisa]);

  const registroSelecionado = (id) => {
    setIdSelecionado(id);

    props.history.push({
      pathname: '/app/PedidoVendaRegister',
      idregistro: { text: id }
    });
  }

  const novoRegistro = () => {

    props.history.push({
      pathname: '/app/PedidoVendaRegister',
      idregistro: { text: '0' }
    });
  }

  const pesquisarRegistros = (valor) => {

    setValorPesquisa(valor);
    listarRegistros();

  }

  function telaMobile() {
    return (
      <TableContainer component={Paper}>
        <Box>
          <TextField
            id="tfPesquisar"
            margin="normal"
            placeholder="Pesquisar ..."
            type="text"
            style={{ marginLeft: 15, paddingLeft: 10, width: "40%" }}
            onChange={(e) => pesquisarRegistros(e.target.value)}
            focused={true}
          // setValorPesquisa(e.target.value)
          />
          <IconButton color="primary" aria-label="add to shopping cart" size="large"
            onClick={() => listarRegistros()}
          >
            <SearchIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <ThemeProvider theme={LocalesPtBr()}>
          <Table  aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columnsMobile.map((column, index) => (
                  <StyledTableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))
                }
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? listaDados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : listaDados
              ).map((row, index) => (
                <TableRow key={index}>

                  <TableCell align="left">

                    <Box fullWidth >

                      <Input
                      sx={{ m: 0.4 }}
                        fullWidth
                        id="tf_fantasia"
                        multiline
                        variant="standard"
                        value={row.fantasia}
                        defaultValue={row.fantasia}
                        readOnly={true}

                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => registroSelecionado(row.chave)}
                            >
                              <EditIcon color="success" />
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                      <TableRow key={index}>

                        <TableCell
                          align="left">
                          {row.documento}
                        </TableCell>

                        <TableCell
                          align="left">
                          {row.emissao}
                        </TableCell>

                        <TableCell
                          // style={{ width: "40%" }}
                          align="left">

                          {row.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace(".", ",")}
                        </TableCell>

                      </TableRow>

                    </Box>

                  </TableCell>

                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={listaDados.length}
                  rowsPerPage={rowsPerPage}
                  page={page}

                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Linhas por página',

                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </ThemeProvider>

      </TableContainer>
    )
  }

  function telaPadrao() {
    return (
      <TableContainer component={Paper}>
        {/* <Grid item xs={{minWidth: 500}}> */}

        <Box>
          <TextField
            id="tfPesquisar"
            margin="normal"
            placeholder="Pesquisar ..."
            type="text"
            style={{ marginLeft: 15, paddingLeft: 10, width: "40%" }}
            onChange={(e) => pesquisarRegistros(e.target.value)}
            focused={true}
          // setValorPesquisa(e.target.value)
          />
          <IconButton color="primary" aria-label="add to shopping cart" size="large"
            onClick={() => listarRegistros()}
          >
            <SearchIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <ThemeProvider theme={LocalesPtBr()}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>

                {(!isMobile) ?
                  (columns.map((column, index) => (
                    <StyledTableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))) :
                  columnsMobile.map((column, index) => (
                    <StyledTableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))
                }

              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? listaDados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : listaDados
              ).map((row, index) => (
                <TableRow key={index}>

                  <TableCell align="center">

                    <IconButton size="small" sx={{ m: 1 }}
                      onClick={() => registroSelecionado(row.chave)}
                    >
                      <EditIcon fontSize="inherit" color={"success"} />
                    </IconButton>

                  </TableCell>

                  <TableCell
                    align="left">
                    {row.documento}
                  </TableCell>

                  {(!isMobile) ?
                    (<TableCell
                      align="left">
                      {row.emissao}
                    </TableCell>) : null
                  }

                  {(!isMobile) ?
                    (<TableCell
                      align="left">
                      {row.razao}
                    </TableCell>) : null
                  }

                  <TableCell
                    // style={{ width: "40%" }}
                    align="left">
                    {row.fantasia}
                  </TableCell>

                  {(!isMobile) ?
                    (<TableCell
                      align="left">
                      {row.cnpj}
                    </TableCell>) : null
                  }

                  <TableCell
                    // style={{ width: "40%" }}
                    align="left">
                    {row.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace(".", ",")}
                  </TableCell>


                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={listaDados.length}
                  rowsPerPage={rowsPerPage}
                  page={page}

                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Linhas por página',

                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </ThemeProvider>

      </TableContainer>

    )
  }

  return (
    <>
      <PageTitle title="Pedido de Venda (Lista)" button="Novo" eventoBotao={novoRegistro} />
      {/* <Grid container spacing={4}> */}

      {exibirProgress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      {(isMobile) ?
        (telaMobile()) : telaPadrao()
      }


    </>
  );
}
