import * as React from 'react';
import { useEffect, useState, useRef, useCallback } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Button, Modal, Box, Grid, FormControl, TextField
  , FormControlLabel, FilledInput, InputLabel, Table, TableHead, TableRow,
  LinearProgress, TableBody, TableFooter, TablePagination,
  Paper,TableContainer
} from '@mui/material';
import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

import LocalesPtBr from "../../components/LocalesPtBr";
import { blue } from '@material-ui/core/colors';

import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno } from "../../context/UserContext";
import { isMobile } from 'react-device-detect';

const stylePesquisaProduto = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const columns = [
  { id: 'razao', label: 'Razão Social' },
  { id: 'fantasia', label: 'Nome Fantasia' },
  { id: 'cnpj', label: 'CNPJ' },
  { id: '', label: '' },
]; 

export default function PesquisarClientes(props) {

  const [listaClientes, setListaClientes] = useState([]);
  const [exibirProgress, setExibirProgress] = useState(false);

  const [pageCliente, setPageCliente] = React.useState(0);
  const [rowsPerPageCliente, setRowsPerPageCliente] = React.useState(5);
  const emptyRowsCliente = pageCliente > 0 ? Math.max(0, (1 + pageCliente) * rowsPerPageCliente - listaClientes.length) : 0;

  const handleChangePageCliente = (event, newPage) => {
    setPageCliente(newPage);
  };

  const handleChangeRowsPerPageCliente = (event) => {
    setPageCliente(parseInt(event.target.value, 10));
    setPageCliente(0);
  };

  async function pesquisarCliente(valor) {

    // setExibirProgress(true);

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiClientes.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listar',
        senha: senhaRetorno,
        login: usuarioRetorno,
        condicao: valor
      })
    });

    let json = await response.json();

    // setExibirProgress(false);

    if (json.success) {
      if (json.result === null) {
        setListaClientes([]);

      } else {
        setListaClientes(json.result);

      }
    }
    else { setListaClientes([]); }

  }

  return (
    <Modal
    open={props.openPesquisarCliente}
    onClose={props.handleCloseModalPesquisarCliente}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={stylePesquisaProduto}>

      <Typography style={{ marginLeft: 5 }}>PESQUISAR CLIENTE</Typography>

      <Grid item xs={12} md={2} >
        <Card variant="outlined">
          <Box
            component="form"
            sx={{ '& .MuiTextField-root': { m: 0 }, }}
            noValidate
            autoComplete="off"
          >
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="tfModalPesquisaCliente">Pesquisar </InputLabel>
              <FilledInput
                id="tfModalPesquisaCliente"
                autoFocus={true}
                variant="filled"
                //value={valorPesquisa}
                // defaultValue={valorPesquisa}
                onChange={(e) => pesquisarCliente(e.target.value)}
                sx={{ m: 1 }}
              />
            </FormControl>

            {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

            <Paper className="container">
              <TableContainer>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>

                      {(isMobile) ? columns.splice(1, 2) : null}

                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}

                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(rowsPerPageCliente > 0
                      ? listaClientes.slice(pageCliente * rowsPerPageCliente, pageCliente * rowsPerPageCliente + rowsPerPageCliente)
                      : listaClientes
                    ).map((row) => (
                      <TableRow key={row.id}>

                        <TableCell
                          // style={{ width: "25%" }}
                          align="left">
                          {row.fantasia}
                        </TableCell>


                        {(!isMobile) ?
                          (<TableCell
                            align="left">
                            {row.cnpj}
                          </TableCell>) : null
                        }

                        <TableCell align="center">
                          <Button
                            size="small"
                            color={"success"}
                            onClick={() => props.preencherDadosCliente(row.codigo, row.razao, row.fantasia, row.cnpj, row.uf)}
                          >
                            <CheckIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}

                    {emptyRowsCliente > 0 && (
                      <TableRow style={{ height: 53 * emptyRowsCliente }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={listaClientes.length}
                        rowsPerPage={rowsPerPageCliente}
                        page={pageCliente}

                        onPageChange={handleChangePageCliente}
                        onRowsPerPageChange={handleChangeRowsPerPageCliente}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>

          </Box>
        </Card>
      </Grid>


      <Button variant="contained" color="error" style={{ margin: 5 }}
        onClick={props.handleCloseModalPesquisarCliente}>Voltar
      </Button>

    </Box>
  </Modal>
  );
}