// import React from "react";
import { Grid, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr from "../../components/LocalesPtBr";

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import {Box,TableBody,TableContainer,TableFooter,TablePagination,TableRow,
  Paper,TableHead,Table,
  LinearProgress,FormControl,InputLabel,InputAdornment,FilledInput,
  Card,
} from '@mui/material';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Button } from "../../components/Wrappers/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd } from "../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import moment from 'moment';

import Progressbar from '../../components/ProgressBar/Progressbar';
import Config from '../../config/Config';
import AguardarRetorno from "../../components/BackDrop/AguardarRetorno";

moment.locale('pt-br');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: '', label: '' },
  { id: 'documento', label: 'Documento' },
  { id: 'lancamento', label: 'Lançamento' },
  { id: 'razao', label: 'Razão Social' },
  { id: 'fantasia', label: 'Nome Fantasia' },
  { id: 'cnpjcpf', label: 'CPF/CNPJ' },
  { id: 'totalnota', label: 'Total' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function converterParaFloat(valor) {
  if (valor !== null && valor !== '') {

    const v = parseFloat(valor);
    const valorFormatado =formatter.format(v);
    return valorFormatado;

  } else {
    return 0;
  }

}

var formatter = new Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  minimumFractionDigits: 2,
});

export default function ListPurchases(props) {

  var userDispatch = useUserDispatch();

  const [recarregar,SetRecarregar] = useState(true);
  const [carregadoListaAuxiliar,SetCarregadoListaAuxiliar] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [listaDados, setListaDados] = useState([]);
  const [idselecionado, setIdSelecionado] = useState(0);
  const [valorPesquisa, setValorPesquisa] = useState('');

  const [exibirProgress, setExibirProgress] = useState(true);

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const [listaRecebimento, setListaRecebimento] = useState([]);
  const [listaCfop, setListaCfop] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaDados.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function listarRegistros(valor) {

    if(valor===undefined){
      setimpedirMultClick(true);

      //caso nao tenha valor informado pega do campo
      valor=valorPesquisa;
    }

    SetRecarregar(false);

    const response = await fetch(`${Config.urlRoot}/purchase/search`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        pesquisar:valor,
        sort:""
      })
    });

    let json = await response.json();

    setExibirProgress(false);

    if(response.status!==200 && response.status!==201){
      setListaDados([]);
      setimpedirMultClick(false);

      return;
    }

      setListaDados(json.compra);

      setimpedirMultClick(false);

  }

  async function listasAuxiliares(){

    const response = await fetch(`${Config.urlRoot}/purchase/listAux`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        sort:""
      })
    });

    let json = await response.json();

    if(response.status!==200 && response.status!==201){
      setListaCfop([]);
      setListaRecebimento([]);
      return;
    }

    setListaCfop(json.cfopList);
    setListaRecebimento(json.recebimentoList);

  }

  useEffect(() => {

    if(!carregadoListaAuxiliar){
      SetCarregadoListaAuxiliar(true);

      listasAuxiliares();
    }
  },[carregadoListaAuxiliar]);

  useEffect(() => {

    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }

    if(recarregar){
      setExibirProgress(true);
      listarRegistros('');
    }

  }, [listarRegistros, props.history, recarregar, userDispatch]);

  const registroSelecionado = (id,index) => {

    if(listaRecebimento===[] || listaCfop===[] || listaCfop.length===0){
      return;
    }

    setIdSelecionado(id);

    const comp =listaDados[index];

    props.history.push({
      pathname: '/app/CompraRegister',
      idregistro: { text: id },
      comp: { text: comp },
      lsCfop: { text: listaCfop },
      lsRecebimento: { text: listaRecebimento },
      lsTransportador: { text: comp.transportadora },
      lsItens: { text: comp.estoque },
      lsFornecedor: { text: comp.fornecedores },
      lsFinanceiro: { text: comp.financeiro },
    });
  }

  const novoRegistro = () => {

    if(listaRecebimento===[] || listaCfop===[]  || listaCfop.length===0){
      return;
    }

    const comp={
      chave:"0",
      documento:"",
      pedido:"",
      fornecedor:"",
      razao:"",
      fantasia:"",
      cnpjcpf:"",
      emissao:"",
      devolucao:"false",
      desconto:0,
      frete:"",
      fretevalor:0,
      acrescimo:0,
      totalnota:0,
      totalprodutos:0,
      observacao:"",
      recebimento:"",
      usuario:"",
      nomeusuario:"",
      planocontas:"",
      cfop:"",
      qtdparcelas:0,
      cancelado:null,
      modelonf:"",
      serienf:"",
      almoxarifado:"false",
      lancamento:"",
      codigotransportador:"",
      cfopfrete:"",
      vencimentofrete:"",
      conhecimento:"",
      seriefrete:"",
      bcicmsfrete:0,
      emissaofrete:"",
      icmsfrete:"",
      faturamento:"",
      tipodesconto:"",
      au:"",
      tipo:"",
      despesa:0,
      especie:"",
      cfoptrasnportador:"",
      placa:"",
      protocolo:"",
      tipofrete:"",
      recibonfe:"",
      chavenfe:"",
      faturado:"",
      numerovolume:"",
      quantidadevol:0,
      basecalculoicms:0,
      valoricms:0,
      basecalculoicmsst:0,
      valoricmsst:0,
      valoripi:0,
      emitido:"",
      tipoemissao:"",
      fat:"",
      saida:"",
      motivocancelamento:"",
      seqcce:0,
      st:"",
      documento2:"",
      nferef:"",
      marca:"",
      conferencia:"",
      ordemcompra:"",
      justificativa:"",
      pispasep:"0",
      cofins:"0",
      despesasaduaneiras:"0",
      vii:"0",
      chavecte:"",
      modelofrete:"",
      modbcicms:"",
      centrocusto:"",
      nfref:"",
      codigocusto:"",
      ufplaca:"",
      numeroparcelas:0,
      empresa:"",
      documentomdfe:"",
      mododesconto:"",
      basecalculoipi:0,
      denegada:"",
      pliquido:0,
      pbruto:0,
      cnfe:"",
      xml_proc:"",
    }

    props.history.push({
      pathname: '/app/CompraRegister',
      idregistro: { text: '0' },
      comp: { text: comp },
      lsCfop: { text: listaCfop },
      lsRecebimento: { text: listaRecebimento },
      lsTransportador: { text: [] },
      lsItens: { text: [] },
      lsFornecedor: { text: [] },
      lsFinanceiro: { text: [] },

    });
  }

  const pesquisarRegistros = (valor) => {

    setValorPesquisa(valor);
    listarRegistros(valor);

  }

  return (
    <>
      <PageTitle title="Compras (Lista)" button="Novo" eventoBotao={novoRegistro} />

      {exibirProgress && ( <Progressbar />)}

      <AguardarRetorno show={impedirMultClick} />

      <TableContainer component={Paper}>
        <Box>
          <FormControl fullWidth size="small" sx={{ m: 0.6, width: '45ch' }} variant="filled">
            <InputLabel htmlFor="tfPesquisar">Pesquisar ...</InputLabel>
            <FilledInput
              id="tfPesquisar"
              size="small"
              onChange={(e) => pesquisarRegistros(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => listarRegistros()}
                  >
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>}
            />
          </FormControl>
        </Box>

        <ThemeProvider theme={LocalesPtBr()}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? listaDados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : listaDados
              ).map((row,index) => (
                <TableRow key={row.chave}>

                  <TableCell align="center">
                    <IconButton size="small"
                       onClick={() => registroSelecionado(row.chave,index)} >
                      <EditIcon fontSize="inherit" color={"success"} />
                    </IconButton>
                  </TableCell>

                  <TableCell
                    align="left">
                    {row.documento}
                  </TableCell>

                  <TableCell
                    align="left">
                    { moment(row.lancamento).format("DD/MM/YYYY") }
                  </TableCell>

                  <TableCell
                    align="left">
                    {row.razao}
                  </TableCell>

                  <TableCell
                    align="left">
                    {row.fantasia}
                  </TableCell>

                  <TableCell
                    align="left">
                    {row.cnpjcpf}
                  </TableCell>

                  <TableCell
                    align="left">
                    {converterParaFloat(row.totalnota)}
                  </TableCell>

                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={listaDados.length}
                  rowsPerPage={rowsPerPage}
                  page={page}

                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Linhas por página',

                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </ThemeProvider>

      </TableContainer>
    </>
  );
}
