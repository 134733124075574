// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';


import {
  Typography,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Tab, Tabs, Modal, InputAdornment
} from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd } from "../../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { blue } from '@material-ui/core/colors';

import Config from '../../../config/Config';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function converterParaFloat(valor) {
  if (valor !== null && valor !== '') {
    return parseFloat(valor);
  } else {
    return 0;
  }
}

export default function ServiceRegister(props) {

  const [valueTab, setValueTab] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  const [exibirProgressPlanoContas, setExibirProgressPlanoContas] = useState(false);
  const [exibirProgressGrupos, setExibirProgressGrupos] = useState(false);
  const [exibirProgressSituacaoTributaria, setExibirProgressSituacaoTributaria] = useState(false);
  const [exibirProgressFornecedores, setExibirProgressFornecedores] = useState(false);
  const [exibirProgressUnidades, setExibirProgressUnidades] = useState(false);

  const [cepJaConsultado, setCepJaConsultado] = useState(false);
  const [cnpjJaConsultado, setCnpjJaConsultado] = useState(false);

  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.serv.text);

  const [fildId, setFildId] = useState(props.location.idregistro.text);
  const [fildProduto, setFildProduto] = useState(dadosRegistro.produto);
  const [fildDescricao, setFildDescricao] = useState(dadosRegistro.descricao);
  const [fildInativo, setFildInativo] = useState(dadosRegistro.inativo);
  const [fildCusto, setFildCusto] = useState(dadosRegistro.custo);
  const [fildValorSugerido, setFildValorSugerido] = useState(dadosRegistro.valorsugerido);
  const [fildMargem, setFildMargem] = useState(dadosRegistro.margem);
  const [fildPrecoVenda, setFildPrecoVenda] = useState(dadosRegistro.precovenda);
  const [fildDescontoMaximo, setFildDescontoMaximo] = useState(dadosRegistro.descontomaximo);
  const [fildComissao, setFildComissao] = useState(dadosRegistro.comissao);
  const [fildAplicacao, setFildAplicacao] = useState(dadosRegistro.aplicacao);
  const [fildPlanoContas, setPlanoContas] = useState(dadosRegistro.planocontas);
  const [fildCodigoGrupo, setFildCodigoGrupo] = useState(dadosRegistro.codigogrupo);
  const [fildDataCadastro, setFildDataCadastro] = useState(dadosRegistro.datacadastro);
  const [fildDataAtualizacao, setFildDataAtualizacao] = useState(dadosRegistro.dataatualizacao);
  const [fildResponsavel, setFildResponsavel] = useState(dadosRegistro.responsavel);
  const [fildIss, setFildIss] = useState(dadosRegistro.iss);

  //Fim dos campos do formulario

  const [lsPlanoContas, setLsPlanoContas] = useState([]);
  const [lsGrupo, setLsGrupo] = useState([]);

  const [validaDescricao, setValidaDescricao] = useState(false);
  const [msgErroDescricao, setMsgErroDescricao] = useState('');

  const valoresSim_Nao = [
    {
      value: 'true',
      label: 'Sim',
    },
    {
      value: 'false',
      label: 'Não',
    }
  ];

  //o campo Inativo no banco de dados verifica se é true ou false
  const valoresAtivo_Inativo = [
    {
      value: 'true',
      label: 'Não',
    },
    {
      value: 'false',
      label: 'Sim',
    }
  ];

  const handleChangeAtivo = (event) => {
    setFildInativo(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangePlanoContas = (event) => {
    setPlanoContas(event.target.value);
  }

  const handleChangeGrupo = (event) => {
    setFildCodigoGrupo(event.target.value);
  }

  const handleChangeInativo = (event) => {
    setFildInativo(event.target.value);
  }

  const handleChangeCusto = (event) => {
    setFildCusto(event.target.value);

  }

  const handleChangeMargem = (event) => {
    setFildMargem(event.target.value);

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calcularValorSugerido = () => {

    if (fildCusto > 0 && fildMargem > 0) {

      let vCusto = parseFloat(fildCusto);
      let vMargem = parseFloat(fildMargem);
      let novoPreco = 0;

      let valor = (vCusto * vMargem) / 100;
      novoPreco = valor + vCusto;

      setFildValorSugerido(novoPreco);

    }

  }

   //Fim Listas

  async function salvarDados() {

    if (fildDescricao.length === 0) {

      setValidaDescricao(true);
      setMsgErroDescricao('Informe a Descrição do Serviço!');
    }
    else {
      //Chama o progress
      setExibirProgress(false);

      //Limpa a validação do campo
      setValidaDescricao(false);
      setMsgErroDescricao('');

      //Chama a API

      const registro = [{
        id: parseInt(fildId, 10),
        produto: fildProduto,
        descricao: fildDescricao,
        unidade: 'UN',
        movimentaestoque: 'false',
        inativo: fildInativo,
        custo: converterParaFloat(fildCusto),
        valorsugerido: converterParaFloat(fildValorSugerido),
        margem: converterParaFloat(fildMargem),
        precovenda: converterParaFloat(fildPrecoVenda),
        descontomaximo: converterParaFloat(fildDescontoMaximo),
        qtdminima: 0,
        comissao: fildComissao,
        aplicacao: fildAplicacao,
        planocontas: fildPlanoContas,
        codigogrupo: fildCodigoGrupo,
        iss: fildIss,
        servico:'true'
      }];

      let response = await fetch(`${Config.urlRoot}/product`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');
        return;
      }
      else{
        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //id restornado
        setFildId(json[0].id);
        setFildProduto(json[0].produto);
        // setFildDescricao(json.descricao);
        // setFildInativo(json.inativo);
        // setFildCusto(json.custo);
        // setFildValorSugerido(json.valorsugerido);
        // setFildMargem(json.margem);
        // setFildPrecoVenda(json.precovenda);
        // setFildDescontoMaximo(json.descontomaximo);
        // setFildComissao(json.comissao);
        // setFildAplicacao(json.aplicacao);
        // setPlanoContas(json.planocontas);
        // setFildCodigoGrupo(json.codigogrupo);
        setFildDataCadastro(json[0].created_at);
        setFildDataAtualizacao(json[0].updated_at);
        // setFildResponsavel(json.responsavel);
        // setFildIss(json.iss);
      }

      //Encerra o progress
      setExibirProgress(false);

      handleOpenMessage();
    }
  }

  useEffect(() => {
    calcularValorSugerido();
  }, [calcularValorSugerido, fildCusto, fildMargem]);

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);


  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function botaoVoltar() {
    props.history.push('/app/Service');
  }

  function botaoNovo() {

    setFildId('0');
    setFildProduto('');
    setFildDescricao('');
    setFildInativo('false');
    setFildCusto(0.00);
    setFildValorSugerido(0.00);
    setFildMargem(0.00);
    setFildPrecoVenda(0.00);
    setFildDescontoMaximo(0.00);
    setFildComissao(0.00);
    setFildAplicacao('');
    setPlanoContas('');
    setFildCodigoGrupo('');
    setFildDataCadastro('');
    setFildDataAtualizacao('');
    setFildResponsavel('');
    setFildIss(0.00);
  }

  return (
    <>
      <PageTitle title="Serviço "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}

      <Grid container spacing={2}>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Dados" {...a11yProps(0)} />
              <Tab label="Ficha Técnica" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Card variant="outlined">
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '65ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      <InputLabel htmlFor="tfDescricao">Descrição</InputLabel>
                      <FilledInput
                        id="tfDescricao"
                        value={fildDescricao}
                        //defaultValue={fildDescricao}
                        variant="filled"
                        inputProps={{ maxLength: 120 }}
                        onChange={(event) => setFildDescricao(event.target.value)}
                        error={validaDescricao}
                      />
                    </FormControl>
                  </Box>

                  <div>
                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfCusto">Custo</InputLabel>
                      <FilledInput
                        id="tfCusto"
                        type={'number'}
                        value={fildCusto}
                        //defaultValue={fildCusto}
                        onChange={handleChangeCusto}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfMargem">Margem</InputLabel>
                      <FilledInput
                        id="tfMargem"
                        type={'number'}
                        value={fildMargem}
                        //defaultValue={fildMargem}
                        onChange={handleChangeMargem}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>
                  </div>

                  <div>
                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfValorSugerido">Preço Sugerido</InputLabel>
                      <FilledInput
                        id="tfValorSugerido"
                        readOnly
                        value={fildValorSugerido}
                        //defaultValue={fildValorSugerido}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tPrecoVenda">Preço Venda</InputLabel>
                      <FilledInput
                        id="tPrecoVenda"
                        type={'number'}
                        value={fildPrecoVenda}
                        //defaultValue={fildPrecoVenda}
                        onChange={(event) => setFildPrecoVenda(event.target.value)}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      />
                    </FormControl>
                  </div>

                  <div>
                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfDescontoMaximo">Desconto Máximo</InputLabel>
                      <FilledInput
                        id="tfDescontoMaximo"
                        type={"number"}
                        value={fildDescontoMaximo}
                        //defaultValue={fildDescontoMaximo}
                        onChange={(event) => setFildDescontoMaximo(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfComissao">Comissão</InputLabel>
                      <FilledInput
                        id="tfComissao"
                        type={'number'}
                        value={fildComissao}
                        //defaultValue={fildComissao}
                        onChange={(event) => setFildComissao(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                  </div>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '41.5ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfIss">Iss</InputLabel>
                      <FilledInput
                        id="tfIss"
                        type={'number'}
                        value={fildIss}
                        //defaultValue={fildIss}
                        onChange={(event) => setFildIss(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                    <TextField
                      id="tfAtivo"
                      select
                      size="small"
                      label="Ativo"
                      variant="filled"
                      value={fildInativo}
                      //defaultValue={fildInativo}
                      onChange={handleChangeInativo}
                    >

                      {valoresAtivo_Inativo.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}

                    </TextField>
                  </Box>

                </Card>
              </Grid>

              <Grid item xs={12} md={6} >
                <Card variant="outlined">
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '85ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      {exibirProgressPlanoContas && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

                      <TextField
                        id="tfPlanoContas"
                        select
                        size="small"
                        label="Plano de Contas"
                        variant="filled"
                        fullWidth
                        value={fildPlanoContas}
                        onChange={handleChangePlanoContas}
                      >
                        {lsPlanoContas.map((option) => (
                          <MenuItem key={option.codigoresumido} value={option.codigoresumido}>
                            {option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      {exibirProgressGrupos && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

                      <TextField
                        id="tfGrupo"
                        select
                        size="small"
                        label="Grupo"
                        variant="filled"
                        fullWidth
                        value={fildCodigoGrupo}
                        onChange={handleChangeGrupo}
                      >
                        {lsGrupo.map((option) => (
                          <MenuItem key={option.grupo} value={option.grupo}>
                            {option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      <TextField
                        id="tfAplicacao"
                        label="Aplicação"
                        multiline
                        rows={8}
                        variant="filled"
                        value={fildAplicacao}
                        //defaultValue={fildAplicacao}
                        inputProps={{ maxLength: 255 }}
                        onChange={(event) => setFildAplicacao(event.target.value)}
                      />
                    </FormControl>

                  </Box>
                </Card>
              </Grid>

            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Card variant="outlined">
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '85ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                  </Box>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>

          <footer>

            <Grid container spacing={0}>
              <Grid item xs={12} md={12} style={{ marginLeft: 25, marginRight: 25 }}>
                <Card variant="outlined">
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {/* <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '100ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  > */}
                    <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
                      <InputLabel htmlFor="tfDataCadastro">Data do Cadastro</InputLabel>
                      <FilledInput
                        id="tfDataCadastro"
                        value={fildDataCadastro}
                        //defaultValue={fildDataCadastro}
                        onChange={(event) => setFildDataCadastro(event.target.value)}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
                      <InputLabel htmlFor="tfDataAlteracao">Data da Alteração</InputLabel>
                      <FilledInput
                        id="tfDataAlteracao"
                        value={fildDataAtualizacao}
                        //defaultValue={fildDataAtualizacao}
                        onChange={(event) => setFildDataAtualizacao(event.target.value)}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
                      <InputLabel htmlFor="tfResponsavel">Responsável</InputLabel>
                      <FilledInput
                        id="tfResponsavel"
                        value={fildResponsavel}
                        //defaultValue={fildResponsavel}
                        onChange={(event) => setFildResponsavel(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                </Card>
              </Grid>
            </Grid>

          </footer>

        </Box>
      </Grid>

    </>
  );
}
