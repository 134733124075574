import React from "react";
import { Grid,Box,IconButton } from "@material-ui/core";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

import {Home as Home,} from "@material-ui/icons";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import CardRecords from "../../components/CardRecords"
// import { Typography } from "../../components/Wrappers";



export default function TypographyPage() {
  var classes = useStyles();

  return (
    <>
      <PageTitle title="Opções" />
      <Grid container spacing={2}>

        <Grid item xs={6} md={3}>
        <CardRecords titulo={"Operações Financeiras"} opcao={1} descricao={"Cadastro de contas bancárias ou contas de movimento."} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Centro de Custos"} opcao={2} descricao={"Descricao do centro de custo"} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"CFOP"} opcao={3} descricao={"Descricao do cfop"} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Clientes"} opcao={4} descricao={"Descricao "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Fornecedores"} opcao={5} descricao={"Descricao "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Funcionários"} opcao={6} descricao={"Descricao "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Produtos"} opcao={7} descricao={"Descricao "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Plano de Contas"} opcao={8}  descricao={"Descricao "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Formas de Pagamento"} opcao={9} descricao={"Descricao "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Serviços"} opcao={10} descricao={"Descricao "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Status"} opcao={11} descricao={"Descricao "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Transportes"} opcao={12} descricao={"Transportador para operações Entrada e Saída. "} />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Vendedores"} opcao={13} descricao={"Pessoas direcionadas nas operaçoes de saída."} />
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <Widget title="Basic Text Settings" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Typography className={classes.text}>Basic text</Typography>
              <Typography className={classes.text} weight="light">
                Basic light text
              </Typography>
              <Typography className={classes.text} weight="medium">
                Basic medium text
              </Typography>
              <Typography className={classes.text} weight="bold">
                Basic bold text
              </Typography>
              <Typography className={classes.text}>
                BASIC UPPERCASE TEXT
              </Typography>
              <Typography className={classes.text}>
                basic lowercase text
              </Typography>
              <Typography className={classes.text}>
                Basic Capitalized Text
              </Typography>
              <Typography>
                <i>Basic Cursive Text</i>
              </Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget title="Text Size" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Typography className={classes.text} size="sm">
                Heading Typography SM Font Size
              </Typography>
              <Typography className={classes.text}>
                Heading Typography Regular Font Size
              </Typography>
              <Typography className={classes.text} size="md">
                Heading Typography MD Font Size
              </Typography>
              <Typography className={classes.text} size="xl">
                Heading Typography XL Font Size
              </Typography>
              <Typography className={classes.text} size="xxl">
                Heading Typography XXL Font Size
              </Typography>
            </div>
          </Widget>
        </Grid> */}
      </Grid>
    </>
  );
}
