import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import LayersIcon from '@mui/icons-material/Layers';
// import ListIcon from '@mui/icons-material/List';
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// import ApartmentIcon from '@mui/icons-material/Apartment';
// import BadgeIcon from '@mui/icons-material/Badge';
// import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
// import InventoryIcon from '@mui/icons-material/Inventory';
// import PaymentIcon from '@mui/icons-material/Payment';
// import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
// import InfoIcon from '@mui/icons-material/Info';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import {
  AccountBalance as AccountBalance,
  Layers as LayersIcon,
  List as ListIcon,
  PeopleAlt as PeopleAltIcon,
  Apartment as ApartmentIcon,
  Badge as BadgeIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  Inventory as InventoryIcon,
  Payment as PaymentIcon,
  MiscellaneousServices as MiscellaneousServicesIcon,
  Info as InfoIcon,
  LocalShipping as LocalShippingIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";


import { blue } from '@material-ui/core/colors';

export default function CardRecords({titulo,descricao,opcao,optionClick}) {

  

  return (
    <Card sx={{ maxWidth: 345 }} onClick={() => optionClick()}>
      <CardActionArea style={{alignItems:'center'}}>
       
        {opcao===1?(
            <AccountBalance  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===2?(
            <LayersIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null} 

        {opcao===3?(
            <ListIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null} 

        {opcao===4?(
            <PeopleAltIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null} 

        {opcao===5?(
            <ApartmentIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===6?(
            <BadgeIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===7?(
            <BrandingWatermarkIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===8?(
            <InventoryIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===9?(
            <PaymentIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===10?(
            <MiscellaneousServicesIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===11?(
            <InfoIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===12?(
            <LocalShippingIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        {opcao===13?(
            <AccountCircleIcon  
            sx={{ color: blue[500],fontSize:50 }}
            style={{marginLeft:13,marginTop:5}}/>
        ):null}

        <CardContent >
          <Typography gutterBottom variant="h6" component="div">
            {titulo}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {descricao}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

  );
}