// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr from "../../../components/LocalesPtBr";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import moment from 'moment';

import { Button } from "../../../components/Wrappers/Wrappers";

import Desconto from './../../../components/Desconto';

import {
  Typography,
  FormControl,
  TextField,
  Grid,
  Paper,
  InputLabel,
  MenuItem,
  Card,
  Snackbar,
  InputAdornment,
  Accordion, AccordionSummary, AccordionDetails,Input
} from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

import PesquisarProdutos from './../../../components/PesquisarProdutos';
import PesquisarFornecedores from './../../../components/PesquisarFornecedores';
import PesquisarTransportadores from './../../../components/PesquisarTransportadores';

import Progressbar from '../../../components/ProgressBar/Progressbar';
import Config from '../../../config/Config';

import CurrencyFormat from 'react-currency-format';
moment.locale('pt-br');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function converterParaFloat(valor) {
  if (valor !== null && valor !== '') {

    const v = parseFloat(valor);
    const valorFormatado =formatter.format(v);
    return valorFormatado;

  } else {
    return 0;
  }

}

function currencyFormat(currency){
  return new Intl.NumberFormat('pt-BR',{
    // style:'currency',
    currency:'BRL',
  }).format(currency)

}

function currencyFormatParaReal(currency){
  return new Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 2 }).format(currency)

}

function currencyFormatParaUSD(currency){
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(currency)
}

//Instanciando o objeto
var formatter = new Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  minimumFractionDigits: 2,
});

function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: 'produto', label: 'Código',width:'10ch' },
  { id: 'descricao', label: 'Descrição',width:'50ch' },
  { id: 'st', label: 'ST/CSOSN',width:'5ch' },
  { id: 'unidade', label: 'Und.' ,width:'8ch'},
  { id: 'cfop', label: 'CFOP',width:'12ch' },
  { id: 'icms', label: 'ICMS',width:'5ch' },
  { id: 'ipi', label: 'IPI',width:'5ch' },
  { id: 'quantidade', label: 'Qtd.',width:'7ch' },
  { id: 'preco', label: 'Val. Unit.',width:'9ch'},
  { id: 'total', label: 'TOTAL',width:'12ch' },
  { id: '', label: '' },
  { id: '', label: '' },
];

const columnsFinanciero = [
  { id: 'documento', label: 'Documento',width:'15ch' },
  { id: 'vencimento', label: 'Vencimento',width:'15ch' },
  { id: 'valor', label: 'Valor',width:'25ch' },
  { id: '', label: '' },
  { id: '', label: '' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const listaModeloBaseCalculo = [
  {
    codigo: '0',
    descricao: 'Margem de Valor Agregado (%)'
  },
  {
    codigo: '1',
    descricao: 'Preço de Pauta'
  },
  {
    codigo: '3',
    descricao: 'Valor da Operação'
  }

];

export default function PurchaseRegister(props) {

  const [recarregar,SetRecarregar] = useState(true);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [idselecionado, setIdSelecionado] = useState(0);

  //------------------- Financeiro
  const [pageFinanceiro, setPageFinanceiro] = React.useState(0);
  const [rowsPerPageFinanceiro, setRowsPerPageFinanceiro] = React.useState(5);

  const emptyRowsFinanceiro = pageFinanceiro > 0 ? Math.max(0, (1 + pageFinanceiro) * rowsPerPageFinanceiro - listaDadosFinanceiro.length) : 0;

  const handleChangePageFinanceiro = (event, newPage) => {
    setPageFinanceiro(newPage);
  };

  const handleChangeRowsPerPageFinanceiro = (event) => {
    setRowsPerPageFinanceiro(parseInt(event.target.value, 10));
    setPageFinanceiro(0);
  };

  //------------------- Pesquisa de Produtos
  const [listaProdutos, setListaProdutos] = useState([]);
  const [pageProdutos, setPageProdutos] = React.useState(0);
  const [rowsPerPageProdutos, setRowsPerPageProdutos] = React.useState(5);
  const emptyRowsProdutos = pageProdutos > 0 ? Math.max(0, (1 + pageProdutos) * rowsPerPage - listaProdutos.length) : 0;

  const handleChangePageProdutos = (event, newPage) => {
    setPageProdutos(newPage);
  };

  const handleChangeRowsPerPageProdutos = (event) => {
    setRowsPerPageProdutos(parseInt(event.target.value, 10));
    setPageProdutos(0);
  };

  //-------------------

  const [openDesconto, setOpenDesconto] = useState(false);
  const handleOpenModalDesconto = () => setOpenDesconto(true);
  const [tipoDesconto, setTipoDesconto] = useState(''); //armazena o tipo do desconto P=total e I=Produto
  const [visualizarItemNoDesconto, setVisualizarItemNoDesconto] = useState(false);
  const [fildItemDesconto, setFildItemDesconto] = useState('');

  const handleCloseModalDesconto = () => setOpenDesconto(false);
  const [opcaoDesconto, setOpcaoDesconto] = useState([true, true]);
  const [indicadorDesconto, setIndicadorDesconto] = useState('');//serve para mostrar no campo de desconto se é pra informar $ ou %

  const [openChavesReferenciadas, setOpenChavesReferenciadas] = useState(false);
  const handleOpenModaChavesReferenciadas = () => setOpenChavesReferenciadas(true);

  const [valueTab, setValueTab] = useState(0);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaDados.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  //Campos do formulario

  const [dadosTransportador,setDadosTransportador]=useState([
    {
      id:0,
      codigo:"",
      razao:'',
      fantasia:'',
      cpf_cnpj:'',
    }
  ]);

  const [openPesquisarTransportador, setOpenPesquisarTransportador] = useState(false);
  const handleOpenModalPesquisarTransportador = () => setOpenPesquisarTransportador(true);
  const handleCloseModalPesquisarTransportador= () => setOpenPesquisarTransportador(false);

  const [dadosRegistro,setDadosRegistro]= useState(props.location.comp.text);

  const [statusCancelado, setStatusCancelado] = useState(false);

  const [lsCfop, setLsCfop] = useState(props.location.lsCfop.text);
  const [lsRecebimento, setLsRecebimento] = useState(props.location.lsRecebimento.text);
  const [lsTransportador, setLsTransportador] = useState(props.location.lsTransportador.text);
  const [lsFornecedor, setLsFornecedor] = useState(props.location.lsFornecedor.text);
  const [listaDadosFinanceiro, setListaDadosFinanceiro] = useState(props.location.lsFinanceiro.text);
  const [listaDados, setListaDados] = useState(props.location.lsItens.text);

  const [fildId, setFildId] = useState(props.location.idregistro.text);

  const [fildFornecedor, setFildFornecedor] = useState(dadosRegistro.fornecedor);
  const [fildFantasia, setFildFantasia] = useState(dadosRegistro.fantasia );
  const [fildRazao, setFildRazao] = useState(dadosRegistro.razao );
  const [fildCnpj, setFildCnpj] = useState(dadosRegistro.cnpjcpf );
  const [fildEmissao, setFildEmissao] = useState(dadosRegistro.emissao!=="" ? dadosRegistro.emissao: new Date());
  const [fildLancamento, setFildLancamento] = useState(dadosRegistro.lancamento!=="" ? dadosRegistro.lancamento: new Date());
  const [fildAu, setFildAu] = useState(dadosRegistro.au );
  const [fildDocumento, setFildDocumento] = useState(dadosRegistro.documento );
  const [fildPedido, setFildPedido] = useState(dadosRegistro.pedido );
  const [fildOrdemCompra, setFildOrdemCompra] = useState(dadosRegistro.ordemcompra );
  const [fildModbcicms, setFildModbcicms] = useState(dadosRegistro.modbcicms );
  const [fildSerienf, setFildSerienf] = useState(dadosRegistro.serienf );
  const [fildChavenfe, setFildChavenfe] = useState(dadosRegistro.chavenfe );
  const [fildCfop, setFildCfop] = useState(dadosRegistro.cfop );
  const [fildRecebimento, setFildRecebimento] = useState(dadosRegistro.recebimento );
  const [fildMododesconto, setFildMododesconto] = useState(dadosRegistro.mododesconto );
  const [fildTipodesconto, setFildTipodesconto] = useState(dadosRegistro.tipodesconto );
  const [fildDesconto, setFildDesconto] = useState(formatMoney(parseFloat(dadosRegistro.desconto),2,',','.'));
  const [fildPliquido, setFildPliquido] = useState(formatMoney(parseFloat(dadosRegistro.pliquido),2,',','.'));
  const [fildPbruto, setFildPbruto] =  useState(formatMoney(parseFloat(dadosRegistro.pbruto),2,',','.'));
  const [fildBasecalculoicms, setFildBasecalculoicms] = useState(formatMoney(parseFloat(dadosRegistro.basecalculoicms),2,',','.'));
  const [fildValoricms, setFildValoricms] = useState(formatMoney(parseFloat(dadosRegistro.valoricms),2,',','.'));
  const [fildBasecalculoicmsst, setFildBasecalculoicmsst] = useState(formatMoney(parseFloat(dadosRegistro.basecalculoicmsst),2,',','.'));
  const [fildValoricmsst, setFildValoricmsst] = useState(formatMoney(parseFloat(dadosRegistro.valoricmsst),2,',','.'));
  const [fildBasecalculoipi, setFildBasecalculoipi] = useState(formatMoney(parseFloat(dadosRegistro.basecalculoipi),2,',','.'));
  const [fildValoripi, setFildValoripi] = useState(formatMoney(parseFloat(dadosRegistro.valoripi),2,',','.'));
  const [fildAcrescimo, setFildAcrescimo] = useState(formatMoney(parseFloat(dadosRegistro.acrescimo),2,',','.'));
  const [fildTotalprodutos, setFildTotalprodutos] = useState(formatMoney(parseFloat(dadosRegistro.totalprodutos),2,',','.'));
  const [fildTotal, setFildTotal] = useState(formatMoney(parseFloat(dadosRegistro.totalnota),2,',','.'));
  const [fildEmissaofrete, setFildEmissaofrete] = useState(dadosRegistro.emissaofrete);
  const [fildVencimentofrete, setFildVencimentofrete] = useState(dadosRegistro.vencimentofrete);
  const [fildConhecimento, setFildConhecimento] = useState(dadosRegistro.conhecimento );
  const [fildCfopfrete, setFildCfopfrete] = useState(dadosRegistro.cfopfrete );
  const [fildBcicmsfrete, setFildBcicmsfrete] =  useState(formatMoney(parseFloat(dadosRegistro.bcicmsfrete),2,',','.'));
  const [fildIcmsfrete, setFildIcmsfrete] =  useState(formatMoney(parseFloat(dadosRegistro.icmsfrete),2,',','.'));
  const [fildFretevalor, setFildFretevalor] = useState(formatMoney(parseFloat(dadosRegistro.fretevalor),2,',','.'));
  const [fildFrete, setFildFrete] = useState(dadosRegistro.frete );
  const [fildSeriefrete, setFildSeriefrete] = useState(dadosRegistro.seriefrete );
  const [fildChavecte, setFildChavecte] = useState(dadosRegistro.chavecte );
  const [fildCancelado, setFildCancelado] = useState(dadosRegistro.cancelado );
  const [fildEmitido, setFildEmitido] = useState(dadosRegistro.emitido );
  const [fildCnfe, setFildCnfe] = useState(dadosRegistro.cnfe );
  const [fildDataCadastro, setFildDataCadastro] = useState(dadosRegistro.created_at );
  const [fildDataAlteracao, setFildDataAlteracao] = useState(dadosRegistro.updated_at );
  const [fildResponsavel, setFildResponsavel] = useState(dadosRegistro.responsavel );
  const [fildObservacao, setFildObservacao] = useState(dadosRegistro.observacao );

  const [fildCodigoTransportador, setFildCodigoTransportador] = useState(dadosRegistro.codigotransportador );
  const [fildRazaoTransportador, setFildRazaoTransportador] = useState(
    (lsTransportador !== [] && lsTransportador!==null) ? lsTransportador.razao:"");
  const [fildFantasiaTransportador, setFildFantasiaTransportador] = useState(
    (lsTransportador !== [] && lsTransportador!==null) ? lsTransportador.fantasia:"");
  const [fildCnpjTransportador, setFildCnpjTransportador] = useState(
    (lsTransportador !== [] && lsTransportador!==null) ? lsTransportador.cpf_cnpj:"");
  // Fim dos campos do formulario

  //ITEM
  const [fildIdProduto, setFildIdProduto] = useState(0);
  const [fildProduto, setFildProduto] = useState('');
  const [fildDescricao, setFildDescricao] = useState('');
  const [fildSt, setFildSt] = useState('');
  const [fildPreco, setFildPreco] = useState(0);
  const [fildIcms, setFildIcms] = useState(0);
  const [fildIpi, setFildIpi] = useState(0);
  const [fildUnidade, setFildUnidade] = useState('');
  const [fildQuantidade, setFildQuantidade] = useState('');
  const [fildVolume, setFildVolume] = useState('');
  const [pLiquidoUnitario, setPLiquidoUnitario] = useState(0);
  const [pBrutoUnitario, setPBrutoUnitario] = useState(0);
  const [fildModBcItem, setFildModBcItem] = useState('');

  const [enviarItens,setEnviarItens]= useState(false);

  const [item, setItem] = useState([]);
  const [listaItens, setListaItens] = useState([]);
  const [dadosCabecalho, setDadosCabecalho] = useState([]);
  const [dadosFornecedor, setDadosFornecedor] = useState([]);

  //---------------
  const [fildAlteraDescricao, setFildAlteraDescricao] = useState('');

  const [openPesquisar, setOpenPesquisar] = useState(false);
  const handleOpenModalPesquisar = () => setOpenPesquisar(true);
  const handleCloseModalPesquisar = () => setOpenPesquisar(false);

  const [listaFornecedores, setListaFornecedores] = useState([]);
  const [openPesquisarFornecedor, setOpenPesquisarFornecedor] = useState(false);
  const handleOpenModalPesquisarForencedor = () => setOpenPesquisarFornecedor(true);
  const handleCloseModalPesquisarFornecedor = () => setOpenPesquisarFornecedor(false);

  const [pageFornecedor, setPageFornecedor] = React.useState(0);
  const [rowsPerPageFornecedor, setRowsPerPageFornecedor] = React.useState(5);
  const emptyRowsFornecedor = pageFornecedor > 0 ? Math.max(0, (1 + pageFornecedor) * rowsPerPageFornecedor - listaFornecedores.length) : 0;

  // Variaveis pra validar campos
  const [validaFornecedor, setValidaFornecedor] = useState(false);
  const [msgErroFornecedor, setMsgErroFornecedor] = useState('');

  const [validaProduto, setValidaProduto] = useState(false);
  const [msgErroProduto, setMsgErroProduto] = useState('');

  const [validaQuantidade, setValidaQuantidade] = useState(false);
  const [msgErroQuantidade, setMsgErroQuantidade] = useState('');

  const [validaPreco, setValidaPreco] = useState(false);
  const [msgErroPreco, setMsgErroPreco] = useState('');

  const [validaCfop, setValidaCfop] = useState(false);
  const [msgErroCfop, setMsgErroCfop] = useState('');

  const [validaRecebimento, setValidaRecebimento] = useState(false);
  const [msgErroRecebimento, setErroRecebimento] = useState('');

  const [validaLancamento, setValidaLancamento] = useState(false);
  const [msgErroLancamento, setMsgErroLancamento] = useState('');

  const [validaEmissao, setValidaEmissao] = useState(false);
  const [msgErroEmissao, setMsgErroEmissao] = useState('');

  const [validaSerie, setValidaSerie] = useState(false);
  const [msgErroSerie, setMsgErroSerie] = useState('');

  const [validaModoLancamento, setValidaModoLancamento] = useState(false);
  const [msgErroModoLancamento,setMsgErroModoLancamento] = useState('');

  //---------------------------
  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const handleChangePageFornecedor = (event, newPage) => {
    setPageFornecedor(newPage);
  };

  const handleChangeRowsPerPageFornecedor = (event) => {
    setPageFornecedor(parseInt(event.target.value, 10));
    setPageFornecedor(0);
  };

  const [exibirProgressCfop, setExibirProgressCfop] = useState(false);
  const [exibirProgressRecebimentos, setExibirProgressRecebimentos] = useState(false);

   const modoLancamento = [
    {
      value: 'false',
      label: 'Lançamento',
    },
    {
      value: 'true',
      label: 'Emissão',
    }
  ];

  const modoDesconto = [
    {
      value: 'P',
      label: 'Total',
    },
    {
      value: 'I',
      label: 'Produto',
    }
  ];

  const [habilitarAdicaoAlteracao, setHabilitarAdicaoAlteracao] = useState(false);

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeEmissao = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildEmissao(newValue.toJSON());
      }
    }
  };

  const handleChangeLancamento = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildLancamento(newValue.toJSON());
      }
    }
  };

  const handleChangeEmissaoFrete = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildEmissaofrete(newValue.toJSON());
      }
    }
  };

  const handleChangeVencimentoFrete = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildVencimentofrete(newValue.toJSON());
      }
    }
  };

  const handleChangeModoNfe = (event) => {
    setFildAu(event.target.value);
  }

  const handleChangeModoBcIcms = (event) => {
    setFildModbcicms(event.target.value);
  }

  const handleChangeCfop = (event) => {
    setFildCfop(event.target.value);
  }

  const handleChangeCfopFrete = (event) => {
    setFildCfopfrete(event.target.value);
  }

  const handleChangeRecebimento = (event) => {
    setFildRecebimento(event.target.value);
  }

  const handleChangeMdoDesconto = (event) => {
    setFildMododesconto(event.target.value);
  }

  const handleChangeDescontoPercentual = (event) => {
    setOpcaoDesconto([event.target.checked, opcaoDesconto[1]]);
    setIndicadorDesconto('%');

  }

  const handleChangeDescontoValor = (event) => {
    setOpcaoDesconto([opcaoDesconto[0], event.target.checked]);
    setIndicadorDesconto('R$');

  }

  function preencherDadosProduto(id, produto, descricao, pliquido, pbruto, qtdvolume, modbc) {

    setFildIdProduto(id);
    setFildProduto(produto);
    setFildDescricao(descricao);
    setPBrutoUnitario(pbruto);
    setPLiquidoUnitario(pliquido);
    setFildVolume(qtdvolume);
    setFildModBcItem(modbc);

    handleCloseModalPesquisar();

    setListaProdutos([]);
  }

  function preencherDadosFornecedor(codigo, razao, fantasia, cnpj) {

    setFildFornecedor(codigo);
    setFildRazao(razao);
    setFildFantasia(fantasia);
    setFildCnpj(cnpj);

    handleCloseModalPesquisarFornecedor();

    setListaFornecedores([]);
  }

  function preencherDadosTransportador(codigo, razao, fantasia, cpf_cnpj) {

    setFildCodigoTransportador(codigo);

    dadosTransportador.codigo = codigo;
    dadosTransportador.razao = razao;
    dadosTransportador.fantasia = fantasia;
    dadosTransportador.cpf_cnpj=cpf_cnpj;

    handleCloseModalPesquisarTransportador();

    // setListaFornecedores([]);
  }

  function limparDadosProduto() {

    setFildIdProduto('');
    setFildProduto('');
    setFildDescricao('');
    setPLiquidoUnitario('');
    setPBrutoUnitario('');
    setFildVolume('');
    setFildModBcItem('');
    setFildPreco('');
    setFildUnidade('');
    setFildPreco('');
    setFildQuantidade('');
    setFildSt('');
    setFildSt('');

  }

  //Litas

  //Preencher campos
  function preencherCampos(compra,item,financeiro,fornecedor,transportador){

    if(compra.chave>0 &&compra.chave!=="0" ){

      setExibirProgress(false);
      SetRecarregar(false);

      setFildId(compra.chave);
      setFildFornecedor(compra.fornecedor);
      setFildFantasia(compra.fantasia);
      setFildRazao(compra.razao);
      setFildCnpj(compra.cnpjcpf);

      setFildEmissao(compra.emissao);
      setFildLancamento(compra.lancamento);

      setFildAu(compra.au);
      setFildDocumento(compra.documento);
      setFildPedido(compra.pedido);
      setFildOrdemCompra(compra.ordemcompra);
      setFildModbcicms(compra.modbcicms);
      setFildSerienf(compra.serienf);
      setFildChavenfe(compra.chavenfe);
      setFildCfop(compra.cfop);
      setFildRecebimento(compra.recebimento);
      setFildMododesconto(compra.mododesconto);
      setFildTipodesconto(compra.tipodesconto);
      setFildDesconto(converterParaFloat(compra.desconto));
      setFildPliquido(converterParaFloat(compra.pliquido));
      setFildPbruto(converterParaFloat(compra.pbruto));
      setFildBasecalculoicms(converterParaFloat(compra.basecalculoicms));
      setFildValoricms(converterParaFloat(compra.valoricms));
      setFildBasecalculoicmsst(converterParaFloat(compra.basecalculoicmsst));
      setFildValoricmsst(converterParaFloat(compra.valoricmsst));
      setFildBasecalculoipi(converterParaFloat(compra.basecalculoipi));
      setFildValoripi(converterParaFloat(compra.valoripi));
      setFildAcrescimo(converterParaFloat(compra.acrescimo));
      setFildTotalprodutos(converterParaFloat(compra.totalprodutos));
      setFildTotal(converterParaFloat(compra.totalnota));
      setFildEmissaofrete(compra.emissaofrete);
      setFildVencimentofrete(compra.vencimentofrete);
      setFildConhecimento(compra.conhecimento);
      setFildCfopfrete(compra.cfopfrete);
      setFildBcicmsfrete(converterParaFloat(compra.bcicmsfrete));
      setFildIcmsfrete(converterParaFloat(compra.icmsfrete));
      setFildFretevalor(converterParaFloat(compra.fretevalor));
      setFildSeriefrete(compra.seriefrete);
      setFildChavecte(compra.chavecte);
      setFildCancelado(compra.cancelado);
      setFildEmitido(compra.emitido);
      setFildCnfe(compra.cnfe);
      setFildDataCadastro(compra.datacadastro);
      setFildDataAlteracao(compra.dataalteracao);
      setFildResponsavel(compra.responsavel);
      setFildCodigoTransportador(compra.codigotransportador);

    }else{
      setFildFornecedor('');
      setFildFantasia('');
      setFildRazao('');
      setFildCnpj('');
      setFildEmissao(null);
      setFildLancamento(null);
      setFildAu('false');
      setFildDocumento('');
      setFildPedido('');
      setFildOrdemCompra('');
      setFildModbcicms('');
      setFildSerienf('');
      setFildChavenfe('');
      setFildCfop('');
      setFildRecebimento('');
      setFildMododesconto('');
      setFildTipodesconto('');
      setFildDesconto(0.00);
      setFildPliquido(0.00);
      setFildPbruto(0.00);
      setFildBasecalculoicms(0.00);
      setFildValoricms(0.00);
      setFildBasecalculoicmsst(0.00);
      setFildValoricmsst(0.00);
      setFildBasecalculoipi(0.00);
      setFildValoripi(0.00);
      setFildAcrescimo(0.00);
      setFildTotalprodutos(0.00);
      setFildTotal(0.00);
      setFildEmissaofrete(null);
      setFildVencimentofrete(null);
      setFildConhecimento('');
      setFildCfopfrete('');
      setFildBcicmsfrete(0.00);
      setFildIcmsfrete(0.00);
      setFildFretevalor(0.00);
      setFildFrete('');
      setFildSeriefrete('');
      setFildChavecte('');
      setFildCancelado('');
      setFildEmitido('');
      setFildCnfe(0);
      setFildDataCadastro('');
      setFildDataAlteracao('');
      setFildResponsavel('');
      setFildObservacao('');
      setFildCodigoTransportador('');
      setFildRazaoTransportador('');
      setFildFantasiaTransportador('');
      setFildCnpjTransportador('');
    }

    if(item!==null && item!==undefined){
      setListaDados(item);

    }else{setListaDados([]);}

    if(financeiro!==null && financeiro!==undefined){

      setListaDadosFinanceiro(financeiro);

    }else{setListaDadosFinanceiro([]);}

    if(transportador!==null && transportador!==undefined){

      setDadosTransportador(transportador);

      setFildRazaoTransportador(transportador.razao);
      setFildFantasiaTransportador(transportador.fantasia);
      setFildCnpjTransportador(transportador.cpf_cnpj);

    }else{
      setDadosTransportador([{
        id:0,
        codigo:"",
        razao:'',
        fantasia:'',
        cpf_cnpj:'',
      }]);
    }

  }

  //Lista os dados para a tela de compra
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function popularFormulario(id) {

    setExibirProgress(true);
    setExibirProgressRecebimentos(true);
    setExibirProgressCfop(true);

    const response = await fetch(`${Config.urlRoot}/purchase/list/${id}`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
      })
    });

    SetRecarregar(false);

    let json = await response.json();

    if(response.status!==200 && response.status!==201){
      SetRecarregar(false);

      setExibirProgress(false);
      setExibirProgressRecebimentos(false);
      setExibirProgressCfop(false);

      return;
    }

    if(json.cfoplista!==null){
      setLsCfop(json.cfoplista[0]);
      setExibirProgressCfop(false);

    }

    if(json.recebimentolista!==null){
      setLsRecebimento(json.recebimentolista);
      setExibirProgressRecebimentos(false);

    }

    if(parseInt(id,10)===0){
      json.compra = {chave:0};
    }

    preencherCampos(json.compra,json.compra.estoque,json.compra.financeiro,json.compra.fornecedores,json.compra.transportadora);

  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {

      if (habilitarAdicaoAlteracao) {

        // saveRegistro();

      }

  }, [habilitarAdicaoAlteracao]);

  useEffect(() => {

    if(enviarItens){
      salvarItens();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[enviarItens]);

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleValorDesconto = (valor) => {
    setFildDesconto(valor);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const BlocoValores = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const stylePesquisaProduto = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  }

  function botaoVoltar() {
    props.history.push('/app/Compra');
  }

  function botaoNovo() {
    //implementar a limpeza dos campos
    setFildId("0");
    setFildFornecedor("");
    setFildFantasia("");
    setFildRazao("");
    setFildCnpj("");
    setFildEmissao(new Date());
    setFildLancamento(new Date());
    setFildAu("false");
    setFildDocumento("");
    setFildPedido("");
    setFildOrdemCompra("");
    setFildModbcicms("");
    setFildSerienf("");
    setFildChavenfe("");
    setFildCfop("");
    setFildRecebimento("");
    setFildMododesconto("");
    setFildTipodesconto("");
    setFildDesconto(0);
    setFildPliquido(0);
    setFildPbruto(0);
    setFildBasecalculoicms(0);
    setFildValoricms(0);
    setFildBasecalculoicmsst(0);
    setFildValoricmsst(0);
    setFildBasecalculoipi(0);
    setFildValoripi(0);
    setFildAcrescimo(0);
    setFildTotalprodutos(0);
    setFildTotal(0);
    setFildEmissaofrete("");
    setFildVencimentofrete("");
    setFildConhecimento("");
    setFildCfopfrete("");
    setFildBcicmsfrete(0);
    setFildIcmsfrete(0);
    setFildFretevalor(0);
    setFildFrete("");
    setFildSeriefrete("");
    setFildChavecte("");
    setFildCancelado("");
    setFildEmitido("false");
    setFildCnfe("");
    setFildDataCadastro("");
    setFildDataAlteracao("");
    setFildResponsavel("");
    setFildObservacao("");
  }

  function exibirItemParaDesconto() {
    return (
      <FormControl fullWidth size="small" sx={{ m: 0.4 }} variant="filled">
        <InputLabel htmlFor="tfItemDesconto">Item</InputLabel>
        <FilledInput
          type="number"
          id="tfItemDesconto"
          value={fildItemDesconto || 0}
          //defaultValue={fildItemDesconto}
          onChange={(event) => setFildItemDesconto(event.target.value)}
        />
      </FormControl>
    )
  }

  function validarFornecedor(){
    let valido=false;

    if (fildFornecedor.length === 0) {
      setValidaFornecedor(true);
      setMsgErroFornecedor('Selecione o fornecedor.');

    } else {
      setValidaFornecedor(false);
      setMsgErroFornecedor('');
      valido=true;
    }

    return valido;
  }

  function validarRecebimento(){
    let valido=false;
    if (fildRecebimento === "") {
        setValidaRecebimento(true);
        setErroRecebimento('Selecione a Forma de pagamento.');

      } else {
        setValidaRecebimento(false);
        setErroRecebimento('');
        valido=true;

      }

    return valido;
  }

  function validarCfop(){

    let valido=false;

    if (fildCfop === "") {
      setValidaCfop(true);
      setMsgErroCfop('Selecione o cfop.');

    } else {
      setValidaCfop(false);
      setMsgErroCfop('');
      valido=true;

    }

    return valido;
  }

  function validarSerie(){

    let valido=false;

    if (fildSerienf === "") {
      setValidaSerie(true);
      setMsgErroSerie('Informe a série da nota.');

    } else {
      setValidaSerie(false);
      setMsgErroSerie('');
      valido=true;

    }

    return valido;
  }

  function validarModoLancamento(){

    let valido=false;

    if (fildAu === "") {
      setValidaModoLancamento(true);
      setMsgErroModoLancamento('Informe modo de lançamento.');

    } else {
      setValidaModoLancamento(false);
      setMsgErroModoLancamento('');
      valido=true;

    }

    return valido;
  }
  //-------------------

  function addItem(){
    const lista=[...listaDados];

    let precounitario_=fildPreco;
    precounitario_ = precounitario_.toString().replace(".","").replace(",",".");

    let quantidade_=fildQuantidade;
    quantidade_ = quantidade_.toString().replace(".","").replace(",",".");

    let quantidadeVolume_=fildVolume;
    quantidadeVolume_ = quantidadeVolume_.toString().replace(".","").replace(",",".");


    const total=parseFloat((parseFloat(quantidade_) * parseFloat(precounitario_)).toFixed(2));

    let baseIcms=0;
    let icms=0;
    if(fildIcms>0){
      baseIcms=parseFloat((parseFloat(quantidade_) * parseFloat(precounitario_)).toFixed(2));

      let icms_=fildIcms;
      icms_ = icms_.toString().replace(".","").replace(",",".");
      icms = parseFloat(parseFloat(icms_).toFixed(2));
    }

    const vIcms=parseFloat((baseIcms*(icms/100)).toFixed(2));

    let baseIpi=0;
    let ipi=0;
    if(fildIpi>0){
      baseIpi=parseFloat((parseFloat(quantidade_) * parseFloat(precounitario_)).toFixed(2));

      let ipi_=fildIpi;
      ipi_ = ipi_.toString().replace(".","").replace(",",".");
      ipi = parseFloat(parseFloat(ipi_).toFixed(2));
    }

    const vIpi=parseFloat((baseIpi * (ipi/100)).toFixed(2));

    const newItem = {

      chave2: 0
      ,chave3:'0'
      ,data: fildLancamento
      ,produto:fildProduto
      ,descricao:fildDescricao
      ,referencia:''
      ,quantidade:parseFloat(quantidade_)
      ,custo:0
      ,preco:parseFloat(precounitario_)
      ,fator:'0'
      ,comissvendedor:0
      ,fretevalor:0
      ,comissvendedor2:0
      ,tipo:'Entrada'
      ,cfop:fildCfop
      ,cliente:fildFornecedor
      ,nome:fildFantasia
      ,documento:fildDocumento
      ,pedido:fildPedido
      ,unidade:fildUnidade
      ,cancelado:'false'
      ,icms:parseFloat(icms)
      ,aliquota:0
      ,st:fildSt
      ,cf:''
      ,responsavel:usuarioRetorno
      ,ipi:parseFloat(ipi)
      ,valoricms:vIcms
      ,valoripi:vIpi
      ,reducaobase:0
      ,nomeusuario:usuarioRetorno
      ,au:fildAu
      ,precon:0
      ,comissao:0
      ,predbc:0
      ,vbc:baseIcms
      ,picms:0
      ,vicms:vIcms
      ,modbcst:''
      ,pmvast:0
      ,predbcst:0
      ,vbcst:0
      ,picmsst:0
      ,vicmsst:0
      ,cbarra:''
      ,fat:'true'
      ,movimentaestoque:''
      ,atendido:parseFloat(quantidade_)
      ,qtdvolume:parseFloat(quantidadeVolume_)
      ,frete:''
      ,localizacao:''
      ,pliquido:0
      ,pbruto:0
      ,registro:''
      ,precopauta:0
      ,almoxarifado:'false'
      ,ordemcompra:fildOrdemCompra
      ,emissao:fildLancamento
      ,preco2:0
      ,chaveent:fildId
      ,baseipi:baseIpi
      ,despesas:0
      ,desconto:0
      ,desconto2:0
      ,total:total
      ,modbc:''
      ,vpis:0
      ,vcofins:0
      ,virpj:0
      ,vcsll:0
      ,total2:0
      ,pp:0
    };

    lista.push(newItem);
    setListaDados(lista);

    setEnviarItens(true);
  }

  //ALTERAÇÃO DO ITEM - Concluído em 02/03/2023
  const mudarItem = (indice, chave2,valor,coluna) => listaDados.map(item => {
    if (item.chave2 === chave2){

      switch (coluna) {
        case "descricao":
          item.descricao=valor;
          break;
        case "icms":
          let i=valor;
          i = i.toString().replace(".","").replace(",",".");
          item.icms=parseFloat(i);

          break;
        case "ipi":
          let ip=valor;
          ip = ip.toString().replace(".","").replace(",",".");
          item.ipi=parseFloat(ip);

          break;
        case "preco":
          const p = parseFloat(valor.replace(".","").replace(",","."));
          const q = parseFloat(item.quantidade);
          const t = q * p;

          item.preco=p;
          item.total=t;

          break;
        default:
          break;
      }
    }
    return item;
  });

  function alterarItem(indice,chave2,descricao,coluna){

    let it = [];

    it = mudarItem(indice, chave2,descricao,coluna);

    setListaDados(it);

  }

  //Implementado 01/03/2023 - Salva a compra criando/atualizando cabecalho e itens
  async function salvarItens(){

    let desconto_=0;
    if(fildDesconto!==""){

      let d=fildDesconto;
      d= d.toString().replace(".","").replace(",",".");
      desconto_=parseFloat(d);
    }

    let fretevalor_=0;
    if(fildFretevalor!==""){
      let f=fildFretevalor;
      f= f.toString().replace(".","").replace(",",".");
      fretevalor_=parseFloat(f);

    }

    let acrescimo_=0;
    if(fildAcrescimo!==""){
      let a=fildAcrescimo;
      a= a.toString().replace(".","").replace(",",".");
      fretevalor_=parseFloat(a);
    }

    let total_=0;
    if(fildTotal!==""){
      let t=fildTotal;
      t= t.toString().replace(".","").replace(",",".");
      total_=parseFloat(t);

    }

    let totalproduto_=0;
    if(fildTotalprodutos!==""){
      let t=fildTotalprodutos;
      t= t.toString().replace(".","").replace(",",".");
      totalproduto_=parseFloat(t);

    }

    let bcicmsfrete_=0;
    if(fildBcicmsfrete!==""){
      let b=fildBcicmsfrete;
      b= b.toString().replace(".","").replace(",",".");
      bcicmsfrete_=parseFloat(b);

    }

    let icmsfrete_=0;
    if(fildIcmsfrete!==""){

      let i=fildIcmsfrete;
      i= i.toString().replace(".","").replace(",",".");
      bcicmsfrete_=parseFloat(i);

    }

    let basecalculoicms_=0;
    if(fildBasecalculoicms!==""){
      basecalculoicms_=parseFloat(fildBasecalculoicms);

      // basecalculoicms_=parseFloat(fildBasecalculoicms.replace(".","").replace(",","."));
    }

    let valoricms_=0;
    if(fildValoricms!==""){
      valoricms_=parseFloat(fildValoricms);

      // valoricms_=parseFloat(fildValoricms.replace(".","").replace(",","."));
    }

    let basecalculoicmsst_=0;
    if(fildBasecalculoicmsst!==""){
      let b=fildBasecalculoicmsst;
      b= b.toString().replace(".","").replace(",",".");
      basecalculoicmsst_=parseFloat(b);
    }

    let valoricmsst_=0;
    if(fildValoricmsst!==""){
      let v=fildValoricmsst;
      v= v.toString().replace(".","").replace(",",".");
      valoricmsst_=parseFloat(v);

    }

    let basecalculoipi_=0;
    if(fildBasecalculoipi!==""){
      let b=fildBasecalculoipi;
      b= b.toString().replace(".","").replace(",",".");
      basecalculoipi_=parseFloat(b);

    }

    let valoripi_=0;
    if(fildValoripi!==""){
      let v=fildValoripi;
      v= v.toString().replace(".","").replace(",",".");
      valoripi_=parseFloat(v);

    }

    let pliquido_=0;
    if(fildPliquido!==""){
      let p=fildPliquido;
      p= p.toString().replace(".","").replace(",",".");
      pliquido_=parseFloat(p);

    }

    let pbruto_=0;
    if(fildPbruto!==""){
      let p=fildPbruto;
      p= p.toString().replace(".","").replace(",",".");
      pbruto_=parseFloat(p);
    }

    const comp={
        chave:fildId,
        documento:fildDocumento,
        pedido:fildPedido,
        fornecedor:fildFornecedor,
        razao:fildRazao,
        fantasia:fildFantasia,
        cnpjcpf:fildCnpj,
        emissao:fildEmissao,
        emitido:fildEmitido,
        desconto:desconto_,
        frete:fildFrete,
        fretevalor:fretevalor_,
        acrescimo:acrescimo_,
        totalnota:total_,
        totalprodutos:totalproduto_,
        observacao:fildObservacao,
        recebimento:fildRecebimento,
        cfop:fildCfop,
        serienf:fildSerienf,
        lancamento:fildLancamento,
        codigotransportador:fildCodigoTransportador,
        cfopfrete:fildCfopfrete,
        vencimentofrete:fildVencimentofrete,
        conhecimento:fildConhecimento,
        seriefrete:fildSeriefrete,
        bcicmsfrete:bcicmsfrete_,
        emissaofrete:fildEmissaofrete,
        icmsfrete:icmsfrete_,
        tipodesconto:fildTipodesconto,
        au:fildAu,
        despesa:acrescimo_,
        chavenfe:fildChavenfe,
        quantidadevol:fildVolume,
        basecalculoicms:basecalculoicms_,
        valoricms:valoricms_,
        basecalculoicmsst:basecalculoicmsst_,
        valoricmsst:valoricmsst_,
        valoripi:valoripi_,
        ordemcompra:fildOrdemCompra,
        chavecte:fildChavecte,
        modbcicms:fildModbcicms,
        basecalculoipi:basecalculoipi_,
        pliquido:pliquido_,
        pbruto:pbruto_,
      };


    const response = await fetch(`${Config.urlRoot}/stock/incluir_alterar/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dataBase:configuracaoBd,
        notapropria:fildAu,
        compra:comp,
        item:listaDados
      })
    });

    let json = await response.json();
    setListaDadosFinanceiro([]);
    preencherCampos(json.compra,json.compra.estoque,json.compra.financeiro,json.compra.fornecedores,json.compra.transportadora);
    setEnviarItens(false);

  }
  //------------------------------------------

  async function removeRegistro(id) {

    // setListaItens((prevPosts) =>
    //   prevPosts.filter((_, index) => index !== id));

    let desconto_=0;
    if(fildDesconto!==""){
      desconto_=parseFloat(fildDesconto);
    }

    let fretevalor_=0;
    if(fildFretevalor!==""){
      fretevalor_=parseFloat(fildFretevalor);
    }

    let acrescimo_=0;
    if(fildAcrescimo!==""){
      acrescimo_=parseFloat(fildAcrescimo.replace(".","").replace(",","."));
    }

    let total_=0;
    if(fildTotal!==""){
      total_=parseFloat(fildTotal.replace(".","").replace(",","."));
    }

    let totalproduto_=0;
    if(fildTotalprodutos!==""){
      totalproduto_=parseFloat(fildTotalprodutos.replace(".","").replace(",","."));
    }

    let bcicmsfrete_=0;
    if(fildBcicmsfrete!==""){
      bcicmsfrete_=parseFloat(fildBcicmsfrete.replace(".","").replace(",","."));
    }

    let icmsfrete_=0;
    if(fildIcmsfrete!==""){
      icmsfrete_=parseFloat(fildIcmsfrete.replace(".","").replace(",","."));
    }

    let basecalculoicms_=0;
    if(fildBasecalculoicms!==""){
      basecalculoicms_=parseFloat(fildBasecalculoicms.replace(".","").replace(",","."));
    }

    let valoricms_=0;
    if(fildValoricms!==""){
      valoricms_=parseFloat(fildValoricms.replace(".","").replace(",","."));
    }

    let basecalculoicmsst_=0;
    if(fildBasecalculoicmsst!==""){
      basecalculoicmsst_=parseFloat(fildBasecalculoicmsst.replace(".","").replace(",","."));
    }

    let valoricmsst_=0;
    if(fildValoricmsst!==""){
      valoricmsst_=parseFloat(fildValoricmsst.replace(".","").replace(",","."));
    }

    let basecalculoipi_=0;
    if(fildBasecalculoipi!==""){
      basecalculoipi_=parseFloat(fildBasecalculoipi.replace(".","").replace(",","."));
    }

    let valoripi_=0;
    if(fildValoripi!==""){
      valoripi_=parseFloat(fildValoripi.replace(".","").replace(",","."));
    }

    let pliquido_=0;
    if(fildPliquido!==""){
      pliquido_=parseFloat(fildPliquido.replace(".","").replace(",","."));
    }

    let pbruto_=0;
    if(fildPbruto!==""){
      pbruto_=parseFloat(fildPbruto.replace(".","").replace(",","."));
    }

    const comp={
        chave:fildId,
        documento:fildDocumento,
        pedido:fildPedido,
        fornecedor:fildFornecedor,
        razao:fildRazao,
        fantasia:fildFantasia,
        cnpjcpf:fildCnpj,
        emissao:fildEmissao,
        emitido:fildEmitido,
        desconto:desconto_,
        frete:fildFrete,
        fretevalor:fretevalor_,
        acrescimo:acrescimo_,
        totalnota:total_,
        totalprodutos:totalproduto_,
        observacao:fildObservacao,
        recebimento:fildRecebimento,
        cfop:fildCfop,
        serienf:fildSerienf,
        lancamento:fildLancamento,
        codigotransportador:fildCodigoTransportador,
        cfopfrete:fildCfopfrete,
        vencimentofrete:fildVencimentofrete,
        conhecimento:fildConhecimento,
        seriefrete:fildSeriefrete,
        bcicmsfrete:bcicmsfrete_,
        emissaofrete:fildEmissaofrete,
        icmsfrete:icmsfrete_,
        tipodesconto:fildTipodesconto,
        au:fildAu,
        despesa:acrescimo_,
        chavenfe:fildChavenfe,
        quantidadevol:fildVolume,
        basecalculoicms:basecalculoicms_,
        valoricms:valoricms_,
        basecalculoicmsst:basecalculoicmsst_,
        valoricmsst:valoricmsst_,
        valoripi:valoripi_,
        ordemcompra:fildOrdemCompra,
        chavecte:fildChavecte,
        modbcicms:fildModbcicms,
        basecalculoipi:basecalculoipi_,
        pliquido:pliquido_,
        pbruto:pbruto_,
      };

    const response = await fetch(`${Config.urlRoot}/stock/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dataBase:configuracaoBd,
        compra:comp,
      })
    });

    let json = await response.json();
    setListaDadosFinanceiro([]);
    setListaDados([]);

    preencherCampos(json.compra,json.compra.estoque,json.compra.financeiro,json.compra.fornecedores,json.compra.transportadora);

  }
  //------------------------------------------

  //Implementado 01/03/2023 - salva a compra atualizando cabecalho
  async function salvarCompra(){

    let desconto_=0;
    if(fildDesconto!==""){
      desconto_=parseFloat(fildDesconto);
    }

    let fretevalor_=0;
    if(fildFretevalor!==""){
      fretevalor_=parseFloat(fildFretevalor);
    }

    let acrescimo_=0;
    if(fildAcrescimo!==""){
      acrescimo_=parseFloat(fildAcrescimo.replace(".","").replace(",","."));
    }

    let total_=0;
    if(fildTotal!==""){
      total_=parseFloat(fildTotal.replace(".","").replace(",","."));
    }

    let totalproduto_=0;
    if(fildTotalprodutos!==""){
      totalproduto_=parseFloat(fildTotalprodutos.replace(".","").replace(",","."));
    }

    let bcicmsfrete_=0;
    if(fildBcicmsfrete!==""){
      bcicmsfrete_=parseFloat(fildBcicmsfrete.replace(".","").replace(",","."));
    }

    let icmsfrete_=0;
    if(fildIcmsfrete!==""){
      icmsfrete_=parseFloat(fildIcmsfrete.replace(".","").replace(",","."));
    }

    let basecalculoicms_=0;
    if(fildBasecalculoicms!==""){
      basecalculoicms_=parseFloat(fildBasecalculoicms.replace(".","").replace(",","."));
    }

    let valoricms_=0;
    if(fildValoricms!==""){
      valoricms_=parseFloat(fildValoricms.replace(".","").replace(",","."));
    }

    let basecalculoicmsst_=0;
    if(fildBasecalculoicmsst!==""){
      basecalculoicmsst_=parseFloat(fildBasecalculoicmsst.replace(".","").replace(",","."));
    }

    let valoricmsst_=0;
    if(fildValoricmsst!==""){
      valoricmsst_=parseFloat(fildValoricmsst.replace(".","").replace(",","."));
    }

    let basecalculoipi_=0;
    if(fildBasecalculoipi!==""){
      basecalculoipi_=parseFloat(fildBasecalculoipi.replace(".","").replace(",","."));
    }

    let valoripi_=0;
    if(fildValoripi!==""){
      valoripi_=parseFloat(fildValoripi.replace(".","").replace(",","."));
    }

    let pliquido_=0;
    if(fildPliquido!==""){
      pliquido_=parseFloat(fildPliquido.replace(".","").replace(",","."));
    }

    let pbruto_=0;
    if(fildPbruto!==""){
      pbruto_=parseFloat(fildPbruto.replace(".","").replace(",","."));
    }

      const comp={
          chave:fildId,
          documento:fildDocumento,
          pedido:fildPedido,
          fornecedor:fildFornecedor,
          razao:fildRazao,
          fantasia:fildFantasia,
          cnpjcpf:fildCnpj,
          emissao:fildEmissao,
          emitido:fildEmitido,
          desconto:desconto_,
          frete:fildFrete,
          fretevalor:fretevalor_,
          acrescimo:acrescimo_,
          totalnota:total_,
          totalprodutos:totalproduto_,
          observacao:fildObservacao,
          recebimento:fildRecebimento,
          cfop:fildCfop,
          serienf:fildSerienf,
          lancamento:fildLancamento,
          codigotransportador:fildCodigoTransportador,
          cfopfrete:fildCfopfrete,
          vencimentofrete:fildVencimentofrete,
          conhecimento:fildConhecimento,
          seriefrete:fildSeriefrete,
          bcicmsfrete:bcicmsfrete_,
          emissaofrete:fildEmissaofrete,
          icmsfrete:icmsfrete_,
          tipodesconto:fildTipodesconto,
          au:fildAu,
          despesa:acrescimo_,
          chavenfe:fildChavenfe,
          quantidadevol:fildVolume,
          basecalculoicms:basecalculoicms_,
          valoricms:valoricms_,
          basecalculoicmsst:basecalculoicmsst_,
          valoricmsst:valoricmsst_,
          valoripi:valoripi_,
          ordemcompra:fildOrdemCompra,
          chavecte:fildChavecte,
          modbcicms:fildModbcicms,
          basecalculoipi:basecalculoipi_,
          pliquido:pliquido_,
          pbruto:pbruto_,
        };

    if(listaDados.length===0){
      //Exige que o usuario lançe um Item (no lancamento ele cria o cabecalho)
      setTipoMensagem('error');
      setTextoMensagem('Informe um Item/Produto!');
      handleOpenMessage();
      return;
    }

    const response = await fetch(`${Config.urlRoot}/purchase/${fildId}`, {
      method: 'PUT',
      headers: { Accept: 'application/json','Content-Type': 'application/json' },
      body: JSON.stringify({
        dataBase:configuracaoBd,
        notapropria:fildAu,
        compra:comp
      })
    });

    let json = await response.json();

    preencherCampos(json.compra,json.compra.estoque,json.compra.financeiro,json.compra.fornecedores,json.compra.transportadora);
  }

  //==========================
  //ALTERAÇÃO DO TÍTULO - Concluído em 02/03/2023
  const mudarTitulo= (id, index,valor,coluna) => listaDadosFinanceiro.map(item => {
    if (item.chave1 === id){

      switch (coluna) {
        case "valor":
          item.valor=parseFloat(valor.replace(".","").replace(",","."));
          break;
        case "vencimento":

          item.vencimento=moment(valor, 'DD/MM/YYYY').format('YYYY-MM-DD');
          // const dt=moment(valor);
          // console.log('Nova Data:',dt);
          break;
        default:
            return null;
      }

    }
    return item;
  });

  function alterarTitulo(id,index,valor,coluna){
    let tit = [];

    tit = mudarTitulo(id,index,valor,coluna);

    setListaDadosFinanceiro(tit);
  }

  //SALVA FINANCEIRO NO BANCO
  async function saveFinanceiro(id,index){

    const registro={...listaDadosFinanceiro[index]};

    const response = await fetch(`${Config.urlRoot}/financepayable/purchase/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dataBase:configuracaoBd,
        chave2:fildId,
        financeiro:registro
      })
    });

    let json = await response.json();

    if(response.status!==200 && response.status!==201){
      setListaDadosFinanceiro([]);

      setTipoMensagem('error');
      setTextoMensagem('Falha ao salvar o registro!');
      handleOpenMessage();

      return;
    }

    setListaDadosFinanceiro(json.financeiro);

  }

  return (
    <>
      <PesquisarFornecedores
        openPesquisarFornecedor={openPesquisarFornecedor}
        handleCloseModalPesquisarFornecedor={handleCloseModalPesquisarFornecedor}
        preencherDadosFornecedor={preencherDadosFornecedor}
      />

      <PesquisarTransportadores
        openPesquisarTransportador={openPesquisarTransportador}
        handleCloseModalPesquisarTransportador={handleCloseModalPesquisarTransportador}
        preencherDadosTransportador={preencherDadosTransportador}
      />

      <PesquisarProdutos
        openPesquisar={openPesquisar}
        handleCloseModalPesquisar={handleCloseModalPesquisar}
        preencherDadosProduto={preencherDadosProduto}
      />

      <Desconto
        openDesconto={openDesconto}
        handleCloseModalDesconto={handleCloseModalDesconto}
        tipoDesconto={tipoDesconto}
        handleChangeMdoDesconto={handleChangeMdoDesconto}
        opcaoDesconto={opcaoDesconto}
        visualizarItemNoDesconto={visualizarItemNoDesconto}
        fildDesconto={fildDesconto}
        handleValorDesconto={handleValorDesconto}
        // validarDesconto={validarDesconto}
        exibirItemParaDesconto={exibirItemParaDesconto}
        handleChangeDescontoValor={handleChangeDescontoValor}
        handleChangeDescontoPercentual={handleChangeDescontoPercentual}
      />

      <PageTitle
        title="Compra "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}
        buttonNew="Novo" eventoNovo={botaoNovo}
        button="Salvar" eventoBotao={salvarCompra}
      />

      <Snackbar
        open={openMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      <Grid container spacing={1}>

      {exibirProgress && (<Progressbar />)}

        {/* Dados da Compra */}

        <Card sx={{ flexGrow: 1 }} variant="outlined">
          <Grid container spacing={1}>
            <Grid item xs>
                <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                  <InputLabel htmlFor="tfRazao">Razão Social</InputLabel>
                  <FilledInput
                    id="tfRazao"
                    value={fildRazao||''}
                    size="small"
                    error={validaFornecedor}
                    onChange={(event) => setFildRazao(event.target.value)}
                    readOnly={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleOpenModalPesquisarForencedor}>
                          <SearchIcon color="primary" />
                        </IconButton>
                      </InputAdornment>}
                  />
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.6}} variant="filled">
                  <InputLabel htmlFor="tfFantasia">Nome Fantasia</InputLabel>
                  <FilledInput
                    id="tfFantasia"
                    value={fildFantasia ||''}
                    size="small"
                    readOnly={true}
                    onChange={(event) => setFildFantasia(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                  <InputLabel htmlFor="tfCnpj">CNPJ</InputLabel>
                  <CurrencyFormat
                    value={fildCnpj||''}
                    customInput={FilledInput}
                    size="small"
                    format={(fildCnpj.length>11) ? "##.###.###/####-##":"###.###.###-##"}//"##.###.###/####-##"
                    readOnly={true}
                    onChange={(event) => setFildCnpj(event.target.value)}
                    />
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                  <TextField
                    id="tfModoCompra"
                    select
                    size="small"
                    label="Modo de Lançamento"
                    value={fildAu ||''}
                    error={validaModoLancamento}
                    // helperText={msgErroModoLancamento}
                    onChange={handleChangeModoNfe}
                    variant="filled"
                  >
                     <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>

                    {modoLancamento.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
            </Grid>

            <Grid item xs>
                <FormControl size="small" sx={{ m: 0.6 , width: '23ch'}} variant="filled">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      noValidate={false}
                      id="tfDataEmissao"
                      label="Emissão"
                      size="small"

                      inputFormat="DD/MM/YYYY"
                      value={fildEmissao  ||''}
                      onChange={handleChangeEmissao}
                      renderInput={(params) => <TextField sx={{ m: 0, width: '20ch' }} variant="filled" size="small" {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl size="small" sx={{ m: 0.6, width: '23ch' }} variant="filled">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      noValidate={false}
                      id="tfDataLancamento"
                      label="Lançamento"
                      size="small"

                      inputFormat="DD/MM/YYYY"
                      value={fildLancamento ||''}
                      onChange={handleChangeLancamento}
                      renderInput={(params) => <TextField sx={{ m: 0, width: '20ch' }} variant="filled" size="small" {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl  size="small" sx={{ m: 0.6, width: '23ch' }} variant="filled">
                  <InputLabel htmlFor="tfDocumento">N° Documento</InputLabel>
                  <FilledInput
                    id="tfDocumento"
                    value={fildDocumento ||''}
                    size="small"

                    //defaultValue={fildDocumento}
                    inputProps={{ maxLength: 8 }}
                    onChange={(event) => setFildDocumento(event.target.value)}
                  />
                </FormControl>

                <FormControl  size="small" sx={{ m: 0.6, width: '23ch' }} variant="filled">
                  <InputLabel htmlFor="tfPedido">N° Pedido</InputLabel>
                  <FilledInput
                    id="tfPedido"
                    value={fildPedido ||''}
                    size="small"

                    // defaultValue={fildPedido}
                    readOnly={true}
                    onChange={(event) => setFildPedido(event.target.value)}
                  />
                </FormControl>

                <FormControl size="small" sx={{ m: 0.6, width: '23ch' }} variant="filled">
                  <InputLabel htmlFor="tfOrdemCompra">Ordem de Compra</InputLabel>
                  <FilledInput
                    id="tfOrdemCompra"
                    value={fildOrdemCompra || ''}
                    size="small"

                    //defaultValue={fildOrdemCompra}
                    inputProps={{ maxLength: 15 }}
                    onChange={(event) => setFildOrdemCompra(event.target.value)}
                  />
                </FormControl>

                <FormControl size="small" sx={{ m: 0.6, width: '23ch' }} variant="filled">
                  <InputLabel htmlFor="tfSerie">Série</InputLabel>
                  <FilledInput
                    id="tfSerie"
                    value={fildSerienf ||''}
                    size="small"

                    error={validaSerie}
                    // helperText={msgErroSerie}
                    inputProps={{ maxLength: 3 }}
                    onChange={(event) => setFildSerienf(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                  <InputLabel htmlFor="tfChaveNfe">Chave NF-e</InputLabel>
                  <FilledInput
                    id="tfChaveNfe"
                    value={fildChavenfe ||''}
                    size="small"

                    //defaultValue={fildChavenfe}
                    inputProps={{ maxLength: 44 }}
                    onChange={(event) => setFildChavenfe(event.target.value)}
                  />
                </FormControl>


            </Grid>

            <Grid item xs>
                <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                  <TextField
                    id="tfModeloBaseCalculo"
                    select
                    size="small"
                    label="Mod. Base Cálculo"
                    value={fildModbcicms ||''}
                    onChange={handleChangeModoBcIcms}
                    variant="filled"
                  >
                     <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>
                    {listaModeloBaseCalculo.map((option, index) => (
                      <MenuItem key={index} value={option.codigo}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              {/* lembrar de inserir quando o cfop for de devolução chamar o modal para referenciar as chaves nfe  */}
                <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                  <TextField
                    id="tfCfop"
                    select
                    size="small"
                    label="CFOP"
                    value={fildCfop ||''}
                    error={validaCfop}
                    // helperText={msgErroCfop}
                    onChange={handleChangeCfop}
                    variant="filled"
                  >
                      <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>
                    {lsCfop.map((option, index) => (
                      <MenuItem key={index} value={option.cfop}>
                        {option.cfop + " - " + option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <FormControl  fullWidth size="small" sx={{ m: 0.6}} variant="filled">
                  <TextField
                    id="tfRecebimento"
                    select
                    size="small"
                    label="Forma de Pagamento"
                    value={fildRecebimento ||''}
                    error={validaRecebimento}
                    // helperText={msgErroRecebimento}
                    onChange={handleChangeRecebimento}
                    variant="filled"
                  >
                     <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>
                    {lsRecebimento.map((option, index) => (
                      <MenuItem key={index} value={option.codigo}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <FormControl  fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                  <InputLabel htmlFor="tfDesconto">Desconto</InputLabel>
                  <FilledInput
                  // type="number"
                  id="tfDesconto"
                  autoFocus={true}
                  value={fildDesconto}
                  size="small"
                  // defaultValue={props.fildDesconto}
                  startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        // aria-label="toggle password visibility"
                        onClick={handleOpenModalDesconto}
                      >
                        <AddCircleRoundedIcon color="primary" />
                      </IconButton>
                    </InputAdornment>}

                />
                </FormControl>

            </Grid>

            <Grid item xs>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfBruto">Peso Bruto</InputLabel>

                 <CurrencyFormat
                    id="tfBruto"
                    value={fildPbruto}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    // showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildPbruto(event.target.value)}
                    />
              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfPliquido">Peso Líquido</InputLabel>
                <CurrencyFormat
                    id="tfPliquido"
                    value={fildPliquido}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    // showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildPliquido(event.target.value)}
                    />

              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfBcIcms">BC. ICMS</InputLabel>
                <CurrencyFormat
                    id="tfBcIcms"
                    value={fildBasecalculoicms||0}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    // showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildBasecalculoicms(event.target.value)}
                    />

              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfVIcms">Valor ICMS</InputLabel>
                  <CurrencyFormat
                    id="tfVIcms"
                    value={fildValoricms}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    // showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildValoricms(event.target.value)}
                    />

              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfBcIcmsSub">BC. ICMS Sub.</InputLabel>
                <CurrencyFormat
                    id="tfBcIcmsSub"
                    value={fildBasecalculoicmsst}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    // showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildBasecalculoicmsst(event.target.value)}
                    />
              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfVIcmsSub">Valor ICMS Sub.</InputLabel>
                  <CurrencyFormat
                    id="tfVIcmsSub"
                    value={fildValoricmsst}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    // showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildValoricmsst(event.target.value)}
                    />
              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfBcIipi">BC. IPI</InputLabel>

                {/* <Input
                  variant="filled"
                  type="number"
                  defaultValue={converterParaFloat(fildBasecalculoipi)}
                /> */}

                  <CurrencyFormat
                    id="tfBcIipi"
                    value={fildBasecalculoipi}
                    customInput={FilledInput}
                    // thousandSeparator={true}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    onChange={(event) => setFildBasecalculoipi(event.target.value)}
                    />

              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfVIpi">Valor IPI</InputLabel>
                <CurrencyFormat
                    id="tfVIpi"
                    value={fildValoripi}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    // showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildValoripi(event.target.value)}
                    />

              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                <InputLabel htmlFor="tfAcrescimo">Outros Valores</InputLabel>
                 <CurrencyFormat
                    id="tfAcrescimo"
                    value={fildAcrescimo}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    //showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildAcrescimo(event.target.value)}
                    />

              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '17ch' }} variant="filled">
                <InputLabel htmlFor="tfTotalProduto">Total dos Produtos</InputLabel>
                <CurrencyFormat
                    id="tfTotalProduto"
                    value={fildTotalprodutos}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    //showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildTotalprodutos(event.target.value)}
                    />
              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '17ch' }} variant="filled">
                <InputLabel htmlFor="tfTotal">Total</InputLabel>
                  <CurrencyFormat
                    id="tfTotal"
                    value={fildTotal}
                    customInput={FilledInput}
                    size="small"
                    thousandSeparator= '.'
                    decimalSeparator= ','
                    //showsymbolspace= {false}
                    //significantdigits= {2}
                    //showtrailingzeros= {true}
                    onChange={(event) => setFildTotal(event.target.value)}
                    />
              </FormControl>

            </Grid>

          </Grid>

          {/* Tansportador */}
          <Accordion >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
              <Typography>Transportador e Frete</Typography>
            </AccordionSummary>

            <AccordionDetails>

            <Card variant="outlined" sx={{ flexGrow: 1 }}>
              <Grid container spacing={0.5}>
                <Grid item xs>
                    <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                      <InputLabel htmlFor="tfRazao">Razão Social</InputLabel>
                      <FilledInput
                        id="tfRazao"
                        value={fildRazaoTransportador||''}
                        size="small"
                        readOnly={true}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleOpenModalPesquisarTransportador}
                            >
                              <SearchIcon color="primary" />
                            </IconButton>
                          </InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                      <InputLabel htmlFor="tfFantasia">Nome Fantasia</InputLabel>
                      <FilledInput
                        id="tfFantasia"
                        value={fildFantasiaTransportador || ''}
                        size="small"
                        readOnly={true}
                      />
                    </FormControl>



                </Grid>
                <Grid item xs>

                    <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                      <InputLabel htmlFor="tfCnpj">CNPJ</InputLabel>
                      <FilledInput
                        id="tfCnpj"
                        value={fildCnpjTransportador ||''}
                        size="small"
                        readOnly={true}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 0.6 }} variant="filled">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          noValidate={false}
                          id="tfDataEmissaoFrete"
                          label="Emissão"
                          inputFormat="DD/MM/YYYY"
                          value={fildEmissaofrete||''}
                          onChange={handleChangeEmissaoFrete}
                          renderInput={(params) => <TextField size="small" sx={{ m: 0, width: '20ch' }} variant="filled" {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ m: 0.6 }} variant="filled">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          noValidate={false}
                          id="tfDataLancamentofrete"
                          label="Lançamento"
                          inputFormat="DD/MM/YYYY"
                          value={fildVencimentofrete ||''}
                          onChange={handleChangeVencimentoFrete}
                          renderInput={(params) => <TextField size="small" sx={{ m: 0, width: '20ch' }} variant="filled" {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>

                </Grid>
                <Grid item xs>

                    <FormControl fullWidth size="small" sx={{ m: 0.6, width: '18ch' }} variant="filled">
                      <InputLabel htmlFor="tfConhecimento">N° Conhecimento</InputLabel>
                      <FilledInput
                        id="tfConhecimento"
                        value={fildConhecimento ||''}
                        size="small"
                        inputProps={{ maxLength: 10 }}
                        onChange={(event) => setFildConhecimento(event.target.value)}
                      />
                    </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 0.6, width: '15ch' }} variant="filled">
                    <InputLabel htmlFor="tfCfopFrete">CFOP</InputLabel>
                    <FilledInput
                      id="tfCfopFrete"
                      value={fildCfopfrete ||''}
                      size="small"
                      onChange={handleChangeCfopFrete}
                    >
                       <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>
                      {lsCfop.map((option, index) => (
                        <MenuItem key={index} value={option.cfop}>
                          {option.cfop + " - " + option.descricao}
                        </MenuItem>
                      ))}
                    </FilledInput>
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 0.6, width: '12ch' }} variant="filled">
                    <InputLabel htmlFor="tfSerieFrete">Série CT-e</InputLabel>
                    <FilledInput
                      id="tfSerieFrete"
                      value={fildSeriefrete ||''}
                      size="small"
                      inputProps={{ maxLength: 3 }}
                      onChange={(event) => setFildSeriefrete(event.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
                    <InputLabel htmlFor="tfChaveCte">Chave CT-e</InputLabel>
                    <FilledInput
                      id="tfChaveCte"
                      value={fildChavecte ||''}
                      size="small"
                      inputProps={{ maxLength: 44 }}
                      onChange={(event) => setFildChavecte(event.target.value)}
                    />
                  </FormControl>

                </Grid>

                <Grid item xs>
                  <FormControl fullWidth size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                    <InputLabel htmlFor="tfBcIcmsFrete">Base Cálculo</InputLabel>
                      <CurrencyFormat
                        id="tfBcIcmsFrete"
                        value={fildBcicmsfrete}
                        customInput={FilledInput}
                        size="small"
                        thousandSeparator= '.'
                        decimalSeparator= ','
                        onChange={(event) =>setFildBcicmsfrete(event.target.value)}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                    <InputLabel htmlFor="tfIcmsFrete">ICMS</InputLabel>
                      <CurrencyFormat
                          id="tfIcmsFrete"
                          value={fildIcmsfrete}
                          customInput={FilledInput}
                          size="small"
                          thousandSeparator= '.'
                          decimalSeparator= ','
                          onChange={(event) => setFildIcmsfrete(event.target.value)}
                          startAdornment={<InputAdornment position="start">%</InputAdornment>}
                        />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 0.6, width: '14ch' }} variant="filled">
                    <InputLabel htmlFor="tfValorFrete">Frete Valor</InputLabel>
                      <CurrencyFormat
                            id="tfValorFrete"
                            value={fildFretevalor}
                            customInput={FilledInput}
                            size="small"
                            thousandSeparator= '.'
                            decimalSeparator= ','
                            onChange={(event) => setFildFretevalor(event.target.value)}
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                          />
                  </FormControl>
                </Grid>

              </Grid>
            </Card>

            </AccordionDetails>
          </Accordion>

          {/* Financeiro */}
          <Accordion >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Financeiro</Typography>

            </AccordionSummary>

            <AccordionDetails>
              <Card variant="outlined" sx={{ flexGrow: 1 }}>
                <ThemeProvider theme={LocalesPtBr()}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow style={{ height: 33 * emptyRows, }}>
                        {columnsFinanciero.map((column, index) => (
                              <StyledTableCell
                                key={index}
                                align={column.align}
                                style={{ width:column.width }}
                              >
                                {column.label}
                              </StyledTableCell>
                        ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {(rowsPerPage > 0
                                      ? listaDadosFinanceiro.slice(pageFinanceiro * rowsPerPageFinanceiro, pageFinanceiro * rowsPerPageFinanceiro + rowsPerPageFinanceiro)
                                      : listaDadosFinanceiro
                                    ).map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <Input
                                defaultValue={row.documento ||''}
                                style={{width:'15ch'}}
                                readOnly={true}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">

                              <Input
                              defaultValue={moment(row.vencimento,"YYYY-MM-DD").format('DD/MM/YYYY') ||''}
                              style={{width:'15ch'}}
                              onChange={(event) => alterarTitulo(row.chave1,index,event.target.value,"vencimento")}

                              />
                            </TableCell>
                            <TableCell align="left">
                              <Input
                              defaultValue={converterParaFloat(row.valor) ||"0,00"}
                              style={{width:'25ch'}}
                              readOnly={true}
                              />
                            </TableCell>

                            <TableCell align="center" >
                              <IconButton size="small"
                               onClick={() => saveFinanceiro(row.chave1,index)} //Atualiza o titulo
                              >
                                <CheckIcon fontSize="inherit" color={"success"} />
                              </IconButton>

                            </TableCell>

                          </TableRow>
                        ))}

                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}

                      </TableBody>

                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={listaDados.length}
                            rowsPerPage={rowsPerPage}
                            page={page}

                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',

                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>

                    </Table>
                  </TableContainer>
                </ThemeProvider>
              </Card>
            </AccordionDetails>
          </Accordion>
        </Card>

        {/* ITENS */}
        <Grid container spacing={2} >
          <Grid item xs={12} md={12} >

            <Card variant="outlined" sx={{ flexGrow: 1 }}>
              <Typography sx={{ m: 1 }} >Produto(s)</Typography>

              <Grid item xs={12} md={12} sx={{ m: 1 }}>

                <FormControl fullWidth size="small" sx={{ m: 0.6, width: '18ch' }} variant="filled">
                  <InputLabel htmlFor="tfCodigo">Código</InputLabel>
                  <FilledInput
                    id="tfCodigo"
                    value={fildProduto||''}
                    size="small"
                    onChange={(event) => setFildProduto(event.target.value)}
                    readOnly={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleOpenModalPesquisar}
                        >
                          <SearchIcon color="primary" />
                        </IconButton>
                      </InputAdornment>}
                  />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '35ch' }} variant="filled">
                  <InputLabel htmlFor="tfDescricao">Descrição</InputLabel>
                  <FilledInput
                    id="tfDescricao"
                    value={fildDescricao ||''}
                    size="small"
                    readOnly={true}
                    onChange={(event) => setFildDescricao(event.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '15ch' }} variant="filled">
                  <InputLabel htmlFor="tfSt">ST/CSOSN</InputLabel>
                  <FilledInput
                    id="tfSt"
                    value={fildSt ||''}
                    size="small"
                    inputProps={{ maxLength: 4 }}
                    onChange={(event) => setFildSt(event.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                  <InputLabel htmlFor="tfIpi">IPI</InputLabel>
                    <CurrencyFormat
                        id="tfIpi"
                        value={fildIpi}
                        customInput={FilledInput}
                        size="small"
                        thousandSeparator= '.'
                        decimalSeparator= ','
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                        onChange={(event) => setFildIpi(event.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                  <InputLabel htmlFor="tfIcms">ICMS</InputLabel>
                    <CurrencyFormat
                        id="tfIcms"
                        value={fildIcms}
                        customInput={FilledInput}
                        size="small"
                        thousandSeparator= '.'
                        decimalSeparator= ','
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                        onChange={(event) => setFildIcms(event.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                  <InputLabel htmlFor="tfUnidade">Unidade</InputLabel>
                  <FilledInput
                    id="tfUnidade"
                    value={fildUnidade ||''}
                    size="small"
                    inputProps={{ maxLength: 4 }}
                    onChange={(event) => setFildUnidade(event.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                  <InputLabel htmlFor="tfVolume">Volume</InputLabel>
                  <CurrencyFormat
                        id="tfVolume"
                        value={fildVolume}
                        customInput={FilledInput}
                        size="small"
                        thousandSeparator= '.'
                        decimalSeparator= ','
                        onChange={(event) => setFildVolume(event.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                  <InputLabel htmlFor="tfQuantidade">Qtde.</InputLabel>
                  <CurrencyFormat
                        id="tfQuantidade"
                        value={fildQuantidade}
                        customInput={FilledInput}
                        size="small"
                        thousandSeparator= '.'
                        decimalSeparator= ','
                        onChange={(event) => setFildQuantidade(event.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ m: 0.6, width: '16ch' }} variant="filled">
                  <InputLabel htmlFor="tfPreco">Valor Unitário</InputLabel>
                  <CurrencyFormat
                        id="tfPreco"
                        value={fildPreco}
                        customInput={FilledInput}
                        size="small"
                        thousandSeparator= '.'
                        decimalSeparator= ','
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        onChange={(event) => setFildPreco(event.target.value)}
                    />
                </FormControl>

                <Button
                  color={"success"}
                  size="small"
                  className="px-2"
                  variant="contained"
                  onClick={addItem} //Insere o item na compra
                >
                  <CheckIcon />Incluir
                </Button>
              </Grid>
            </Card>

            <Card variant="outlined" sx={{ flexGrow: 1 }}>
              <ThemeProvider theme={LocalesPtBr()}>
                <TableContainer component={Paper}>
                  <Table  size="small" >
                    <TableHead>
                      <TableRow style={{ height: 33 * emptyRows, }}>
                        {columns.map((column, index) => (
                          <StyledTableCell
                            key={index}
                            align={column.align}
                            style={{ width:column.width }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {(rowsPerPage > 0
                                    ? listaDados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : listaDados
                                  ).map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Input
                              defaultValue={row.produto ||''}
                              style={{width:'10ch'}}
                              readOnly={true}

                              />
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Input
                            defaultValue={row.descricao ||''}
                            onChange={(event) => alterarItem(index,row.chave2,event.target.value,"descricao")}
                            style={{width:'50ch'}}
                            readOnly={true}

                            />
                          </TableCell>

                          <TableCell align="left">
                            <Input
                            defaultValue={row.st ||''}
                            style={{width:'5ch'}}
                            readOnly={true}

                            />
                          </TableCell>

                          <TableCell align="center">
                            <Input
                            defaultValue={row.unidade ||''}
                            onChange={(event) => alterarItem(index,row.chave2,event.target.value,"unidade")}
                            style={{width:'8ch'}}
                          />
                          </TableCell>

                          <TableCell align="left">{row.cfop}</TableCell>

                          <TableCell align="center">
                            <CurrencyFormat
                                value={row.icms}
                                customInput={Input}
                                thousandSeparator= '.'
                                decimalSeparator= ','
                                //showsymbolspace= {false}
                                //significantdigits= {2}
                                //showtrailingzeros= {true}
                                onChange={(event) => alterarItem(index,row.chave2,event.target.value,"icms")}
                                style={{width:'5ch'}}
                            />
                          </TableCell>

                          <TableCell align="center">
                              <CurrencyFormat
                                // alignItems="center"
                                value={row.ipi}
                                customInput={Input}
                                thousandSeparator= '.'
                                decimalSeparator= ','
                                //showsymbolspace= {false}
                                //significantdigits= {2}
                                //showtrailingzeros= {true}
                                onChange={(event) => alterarItem(index,row.chave2,event.target.value,"ipi")}
                                style={{width:'5ch'}}
                              />
                          </TableCell>

                          <TableCell align="right">
                          {/* <Input
                              defaultValue={converterParaFloat(row.quantidade)}
                              onChange={(event) => alterarItem(index,row.chave2,event.target.value,"quantidade")}
                              size="small"
                              style={{width:'7ch'}}
                            /> */}
                            <CurrencyFormat
                                // alignItems="center"
                                value={row.quantidade}
                                customInput={Input}
                                thousandSeparator= '.'
                                decimalSeparator= ','
                                //showsymbolspace= {false}
                                //significantdigits= {2}
                                //showtrailingzeros= {true}
                                onChange={(event) => alterarItem(index,row.chave2,event.target.value,"quantidade")}
                                style={{width:'7ch'}}
                              />
                          </TableCell>

                          <TableCell align="right">
                            {/* <Input
                              defaultValue={converterParaFloat(row.preco) ||"0,00"}
                              onChange={(event) => alterarItem(index,row.chave2,event.target.value,"preco")}
                              style={{width:'9ch',alignItems:'right'}}
                              /> */}
                              <CurrencyFormat
                                // alignItems="center"
                                value={row.preco}
                                customInput={Input}
                                thousandSeparator= '.'
                                decimalSeparator= ','
                                //showsymbolspace= {false}
                                //significantdigits= {2}
                                //showtrailingzeros= {true}
                                onBlur={(event) => alterarItem(index,row.chave2,event.target.value,"preco")}
                                style={{width:'9ch'}}
                              />
                          </TableCell>

                          <TableCell align="right">
                            <Input
                              defaultValue={converterParaFloat(row.total) ||"0,00"}
                              readOnly={true}
                              style={{width:'12ch'}}
                            />
                          </TableCell>

                          <TableCell align="center" >
                            <IconButton size="small"
                            onClick={() => salvarItens()} //atualiza o item da compra
                            >
                              <CheckIcon fontSize="inherit" color={"success"} />
                            </IconButton>

                            <IconButton size="small"
                            onClick={() => removeRegistro(row.chave2)} //Deleta o item da compra
                            >
                              <DeleteRoundedIcon fontSize="inherit" color={"error"} />
                            </IconButton>
                          </TableCell>

                        </TableRow>
                      ))}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}

                    </TableBody>

                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={listaDados.length}
                          rowsPerPage={rowsPerPage}
                          page={page}

                          SelectProps={{
                            inputProps: {
                              'aria-label': 'Linhas por página',
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>

                  </Table>
                </TableContainer>
              </ThemeProvider>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
