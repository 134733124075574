import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  ModeComment as ModeComment,
  AttachMoney as AttachMoney,
  ShoppingCart as ShoppingCart,
  ShoppingBasket as ShoppingBasket,
  Print as Print,
  Build as Build,
  Widgets as Widgets
} from "@material-ui/icons";

import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
//import WidgetsIcon from '@mui/icons-material/Widgets';

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },

  { id: 1, label: "Cadastros", link: "/app/records", icon: <UIElementsIcon /> },

  // {
  //   id: 1,
  //   label: "Cadastros",
  //   link: "/app",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Produtos", link: "/app/ui/icons" },
  //     { label: "Clientes", link: "/app/clientList" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },

  {
    id: 2,
    label: "Follow Up",
    link: "/app/typography",
    icon: <ModeComment />,
  },

  // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // { id: 2, label: "Cadastros", link: "/app/clientList", icon: <TableIcon /> },
  
  {
    id: 3,
    label: "Estoque",
    // link: "/app/ui",
    icon: <Widgets />,
    children: [
      { label: "Ajuste Manual", link: "/app/AdjustmentRegister" },
      // { label: "Inventário", link: "/app/ui/charts" },
      // { label: "Produção", link: "/app/ui/charts" },      
    ],
  },

  {
    id: 4,
    label: "Entrada",
    // link: "/app/ui",
    icon: <ShoppingBasket />,
    children: [
      // { label: "Pré-Compra", link: "/app/ui/icons" },
      { label: "Pedido de Compra", link: "/app/PedidoCompra" },
      { label: "Compra", link: "/app/Compra" },
    ],
  },

  {
    id: 5,
    label: "Saída",
    // link: "/app/ui",
    icon: <ShoppingCart />,
    children: [
      { label: "Pedido de Venda", link: "/app/PedidoVenda" },
      // { label: "Venda", link: "/app/ui/charts" },
      // { label: "MDF-e", link: "/app/ui/maps" },
      // { label: "O.S.", link: "/app/ui/maps" },
    ],
  },

  {
    id: 6,
    label: "PDV",
    link: "/app/VendPDV",
    icon: <PointOfSaleIcon />,
    // children: [
    //   { label: "Pagar", link: "/app/ui/icons" },
    //   { label: "Receber", link: "/app/ui/charts" },
    //   { label: "Caixa", link: "/app/ui/maps" },
    // ],
  },

  // { id: 7, type: "divider" },

  // { id: 8, label: "Relatórios", link: "", icon: <Print /> },

  // { id: 9, type: "divider" },

  // // { id: 6, type: "title", label: "HELP" },
  // { id: 10, label: "Utilitários", link: "", icon: <Build /> },

  // { id: 11, label: "Support", link: "", icon: <SupportIcon /> },

  // { id: 12, label: "FAQ", link: "", icon: <FAQIcon /> },

  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="large" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="large" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="large" color="secondary" />,
  // },
  // {
  //   id: 16,
  //   label: "Cadastros",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Produtos", link: "/app/ui/icons" },
  //     { label: "Clientes", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
