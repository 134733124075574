import * as React from 'react';
import { useEffect, useState, useRef, useCallback } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Button, Modal, Box, Grid, FormControl, TextField
  , FormControlLabel, FilledInput, InputLabel, Table, TableHead, TableRow,
  LinearProgress, TableBody, TableFooter, TablePagination
} from '@mui/material';
import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

import LocalesPtBr from "../../components/LocalesPtBr";
import { blue } from '@material-ui/core/colors';

import Config from '../../config/Config';

import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd } from "../../context/UserContext";

const stylePesquisaProduto = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
}

const columns = [
  { id: 'produto', label: 'Código' },
  { id: 'descricao', label: 'Descrição' },
  { id: '', label: '' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function PesquisarProdutos(props) {

  const [exibirProgress, setExibirProgress] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [pageProdutos, setPageProdutos] = React.useState(0);
  const [rowsPerPageProdutos, setRowsPerPageProdutos] = React.useState(5);
  const emptyRowsProdutos = pageProdutos > 0 ? Math.max(0, (1 + pageProdutos) * rowsPerPage - listaProdutos.length) : 0;

  const handleChangePageProdutos = (event, newPage) => {
    setPageProdutos(newPage);
  };

  const handleChangeRowsPerPageProdutos = (event) => {
    setRowsPerPageProdutos(parseInt(event.target.value, 10));
    setPageProdutos(0);
  };

  async function pesquisarProdutos(valor) {

    setExibirProgress(true);

    const response = await fetch(`${Config.urlRoot}/product/search`, {
      method: 'POST',
      headers: { Accept: 'application/json','Content-Type': 'application/json' },
      body: JSON.stringify({
        dataBase:configuracaoBd,
        pesquisar: valor,
        servico:"false",
        sort:""
      })
    });

    let json = await response.json();

    setExibirProgress(false);

    if(response.status!==200 && response.status!==201){
      setListaProdutos([]);
      return;
    }

    setListaProdutos(json);

  }

  return (
    <Modal
      open={props.openPesquisar}
      onClose={props.handleCloseModalPesquisar}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description" >

      <Box sx={stylePesquisaProduto}>

        <Grid item xs={12} md={2} >
          <Card variant="outlined">
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 0 }, }}
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth size="small" variant="filled">
                <InputLabel htmlFor="tfModalPesquisadescricao">Pesquisar </InputLabel>
                <FilledInput
                  id="tfModalPesquisadescricao"
                  variant="filled"
                  defaultValue={''}
                  onChange={(e) => pesquisarProdutos(e.target.value)}
                  sx={{ m: 1 }}
                />
              </FormControl>

              {exibirProgress && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}

              <ThemeProvider theme={LocalesPtBr()}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(rowsPerPage > 0
                      ? listaProdutos.slice(pageProdutos * rowsPerPageProdutos, pageProdutos * rowsPerPageProdutos + rowsPerPageProdutos)
                      : listaProdutos
                    ).map((row) => (
                      <TableRow key={row.id}>

                        <TableCell
                          align="left">
                          {row.produto}
                        </TableCell>

                        <TableCell
                          align="left">
                          {row.descricao}
                        </TableCell>

                        <TableCell align="center">
                          <Button
                            size="small"
                            color={"success"}
                            onClick={() => props.preencherDadosProduto(
                              row.id,
                              row.produto,
                              row.descricao,
                              row.pliquido,
                              row.pbruto,
                              row.qtdvolume,
                              row.modbc)}
                          >
                            <CheckIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}

                    {emptyRowsProdutos > 0 && (
                      <TableRow style={{ height: 53 * emptyRowsProdutos }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={listaProdutos.length}
                        rowsPerPage={rowsPerPageProdutos}
                        page={pageProdutos}

                        onPageChange={handleChangePageProdutos}
                        onRowsPerPageChange={handleChangeRowsPerPageProdutos}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </ThemeProvider>
            </Box>
          </Card>
        </Grid>

        <Button variant="contained" color="error" style={{ margin: 5 }}
          onClick={props.handleCloseModalPesquisar}>Voltar
        </Button>

      </Box>
    </Modal>
  );
}