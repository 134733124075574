// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';

import AguardarRetorno from "../../../components/BackDrop/AguardarRetorno";


import {
  Typography,
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  Input,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Tab, Tabs, Modal,InputAdornment
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import FilledInput from '@mui/material/FilledInput';

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";
import { Button } from "../../../components/Wrappers/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno ,configuracaoBd } from "../../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import DadosCfop from "../../../constant/DadosCfop";

import Config from '../../../config/Config';
import { BrandingWatermarkTwoTone } from "@mui/icons-material";

export default function EmployeeRegister(props) {

  const [recarregar,SetRecarregar] = useState(true);
  const [recarregarCidade,SetRecarregarCidade] = useState(false);

  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  const [exibirProgressMunicipios, setExibirProgressMunicipios] = useState(false);
  const [exibirProgressPlanoContas, setExibirProgressPlanoContas] = useState(false);

  const [cepJaConsultado, setCepJaConsultado] = useState(false);
  const [cnpjJaConsultado, setCnpjJaConsultado] = useState(false);

  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.func.text);

  const [fildCodigo, setFildCodigo] = useState(props.location.idregistro.text);
  const [fildNome, setFildNome] = useState(dadosRegistro.nome);
  const [fildDepartamento, setFildDepartamento] = useState(dadosRegistro.departamento);
  const [fildFuncao, setFildFuncao] = useState(dadosRegistro.funcao);
  const [fildSalario, setFildSalario] = useState(dadosRegistro.salario);
  const [fildCtps, setFildCtps] = useState(dadosRegistro.ctps);
  const [fildAdmissao, setFildAdmissao] = useState(dadosRegistro.admissao);
  const [valorDataAdmissao, setValorDataAdmissao] = useState(null);
  const [fildNascimento, setFildNascimento] = useState(dadosRegistro.nascimento);
  const [fildRg, setFildRg] = useState(dadosRegistro.rg);
  const [fildEndereco, setFildEndereco] = useState(dadosRegistro.endereco);
  const [fildBairro, setFildBairro] = useState(dadosRegistro.bairro);
  const [fildCidade, setFildCidade] = useState(dadosRegistro.cidade);
  const [fildCep, setFildCep] = useState(dadosRegistro.cep);
  const [fildFone, setFildFone] = useState(dadosRegistro.fone);
  const [fildPis, setFildPis] = useState(dadosRegistro.pis);
  const [fildCpf, setFildCpf] = useState(dadosRegistro.cpf);
  const [fildObservacao, setFildObservacao] = useState(dadosRegistro.observacao);
  const [fildUf, setFildUf] = useState(dadosRegistro.uf);
  const [codigoUf,setCodigoUf]=useState("");
  const [fildSeriectps, setFildSeriectps] = useState(dadosRegistro.seriectps);
  const [fildFone2, setFildFone2] = useState(dadosRegistro.fone2);
  const [fildEmail, setFildEmail] = useState(dadosRegistro.email);
  const [fildBanco, setFildBanco] = useState(dadosRegistro.banco);
  const [fildAgencia, setFildAgencia] = useState(dadosRegistro.agencia);
  const [fildConta, setFildConta] = useState(dadosRegistro.conta);
  const [fildDadoscomplementares, setFildDadoscomplementares] = useState(dadosRegistro.dadoscomplementares);
  const [fildComissao, setFildComissao] = useState(dadosRegistro.comissao);
  const [fildInativo, setFildInativo] = useState(dadosRegistro.inativo);
  const [fildMeta, setFildMeta] = useState(dadosRegistro.meta);
  //Fim dos campos do formulario

  const [dadosMunicipio, setDadosMunicipios] = useState([]);
  const dadosUf = [
    {
      "cuf": "11",
      "uf": "RO"
    },
    {
      "cuf": "12",
      "uf": "AC"
    },
    {
      "cuf": "13",
      "uf": "AM"
    },
    {
      "cuf": "14",
      "uf": "RR"
    },
    {
      "cuf": "15",
      "uf": "PA"
    },
    {
      "cuf": "16",
      "uf": "AP"
    },
    {
      "cuf": "17",
      "uf": "TO"
    },
    {
      "cuf": "21",
      "uf": "MA"
    },
    {
      "cuf": "22",
      "uf": "PI"
    },
    {
      "cuf": "23",
      "uf": "CE"
    },
    {
      "cuf": "24",
      "uf": "RN"
    },
    {
      "cuf": "25",
      "uf": "PB"
    },
    {
      "cuf": "26",
      "uf": "PE"
    },
    {
      "cuf": "27",
      "uf": "AL"
    },
    {
      "cuf": "28",
      "uf": "SE"
    },
    {
      "cuf": "29",
      "uf": "BA"
    },
    {
      "cuf": "31",
      "uf": "MG"
    },
    {
      "cuf": "32",
      "uf": "ES"
    },
    {
      "cuf": "33",
      "uf": "RJ"
    },
    {
      "cuf": "35",
      "uf": "SP"
    },
    {
      "cuf": "41",
      "uf": "PR"
    },
    {
      "cuf": "42",
      "uf": "SC"
    },
    {
      "cuf": "43",
      "uf": "RS"
    },
    {
      "cuf": "50",
      "uf": "MS"
    },
    {
      "cuf": "51",
      "uf": "MT"
    },
    {
      "cuf": "52",
      "uf": "GO"
    },
    {
      "cuf": "53",
      "uf": "DF"
    },
    {
      "cuf": "00",
      "uf": "EX"
    }
  ];

  const [lsPlanoContas, setLsPlanoContas] = useState([]);

  const [openModalMunicipios, setOpenModalMunicipios] = useState(false);

  const [validaNome, setValidaNome] = useState(false);
  const [msgErroNome, setMsgErroNome] = useState('');

  const [validaUf, setValidaUf] = useState(false);
  const [msgErroUf, setMsgErroUf] = useState('');

  const [impedirMultClick, setimpedirMultClick] = useState(false);


  const valoresSim_Nao = [
    {
      value: 'true',
      label: 'Inativo',
    },
    {
      value: 'false',
      label: 'Ativo',
    }
  ];


  const handleChangeAtivo = (event) => {
    setFildInativo(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleChangeUf = (event) => {
    setFildUf(event.target.value);

  }

  const handleChangeCidade = (event) => {
    setFildCidade(event.target.value);
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeCep = (e) => {
    setFildCep(e);

    ConsultarCep();
  }

  const handleChangeAdmissao = (newValue) => {

    setFildAdmissao(newValue);

    if (newValue !== '') {
      if (newValue !== null) {
        setValorDataAdmissao(newValue.toJSON());
        // console.log(newValue.toJSON());
      }
    }
  };

  //Listas

  // eslint-disable-next-line no-unused-vars
  // async function popularFormulario(id) {

  //   setExibirProgress(true);
  //   setExibirProgressPlanoContas(true);
  //   const response = await fetch(`${Config.urlRoot}/employee/list/${id}`, {
  //     method: 'POST',
  //     headers: {Accept: 'application/json','Content-Type': 'application/json'},
  //     body: JSON.stringify({
  //       dataBase:configuracaoBd,
  //     })
  //   });

  //   SetRecarregar(false);

  //   let json = await response.json();

  //   if(response.status!==200 && response.status!==201){
  //     SetRecarregar(false);

  //     setExibirProgress(false);
  //     setExibirProgressPlanoContas(false);

  //     return;
  //   }

  //   if(json.funcionarioList!==null){

  //     setFildCodigo(json.funcionarioList.codigo);
  //     setFildNome(json.funcionarioList.nome);
  //     setFildDepartamento(json.funcionarioList.departamento);
  //     setFildFuncao(json.funcionarioList.funcao);
  //     setFildSalario(json.funcionarioList.salario);
  //     setFildCtps(json.funcionarioList.ctps);

  //     if (json.funcionarioList.admissao !== '') {
  //       setFildAdmissao(dayjs(json.funcionarioList.admissao));
  //     }

  //     setFildNascimento(json.funcionarioList.nascimento);
  //     setFildRg(json.funcionarioList.rg);
  //     setFildEndereco(json.funcionarioList.endereco);
  //     setFildBairro(json.funcionarioList.bairro);
  //     setFildCidade(json.funcionarioList.cidade);
  //     setFildCep(json.funcionarioList.cep);
  //     setFildFone(json.funcionarioList.fone);
  //     setFildPis(json.funcionarioList.pis);
  //     setFildCpf(json.funcionarioList.cpf);
  //     setFildObservacao(json.funcionarioList.observacao);
  //     setFildUf(json.funcionarioList.uf);
  //     setFildSeriectps(json.funcionarioList.seriectps);
  //     setFildFone2(json.funcionarioList.fone2);
  //     setFildEmail(json.funcionarioList.email);
  //     setFildBanco(json.funcionarioList.banco);
  //     setFildAgencia(json.funcionarioList.agencia);
  //     setFildConta(json.funcionarioList.conta);
  //     setFildDadoscomplementares(json.funcionarioList.dadoscomplementares);
  //     setFildComissao(json.funcionarioList.comissao);
  //     setFildInativo(json.funcionarioList.inativo);
  //     setFildMeta(json.funcionarioList.meta);

  //     setExibirProgress(false);
  //     SetRecarregar(false);
  //   }

  //   if(json.planocontasList!==null){
  //     setLsPlanoContas(json.planocontasList);
  //     setExibirProgressPlanoContas(false);

  //   }

  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function listarMunicipios(_uf) {

    //Chama o progress
    setExibirProgressMunicipios(true);

    //Chama a API

    const response = await fetch(`${Config.urlRootMaster}/coutryibge/list`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        cuf:_uf,
        cidade:"",
        order:""
      })
    });

    let json = await response.json();

    if(response.status!==200){
      setDadosMunicipios([]);
      setExibirProgressMunicipios(false);

      return;
    }

    setDadosMunicipios(json);
    setExibirProgressMunicipios(false);

  }

  async function ConsultarCep() {

    if (fildCep.length === 8 && cepJaConsultado === false) {

      //Chama a API

      const response = await fetch('https://viacep.com.br/ws/' + fildCep + '/json/');

      let json = await response.json();

      if (json.erro === 'true') {

      }
      else {
        setCepJaConsultado(true);

        const c_uf = dadosUf.find(element => element.uf === json.uf).cuf;

        setFildUf(c_uf);

        setFildEndereco(json.logradouro);
        setFildBairro(json.bairro);
        setFildCidade(json.ibge);
      }

    }

  }


  //Fim Listas

  async function salvarDados() {

    if (fildNome.length === 0) {

      setValidaNome(true);
      setMsgErroNome('Informe o Nome!');
    }
    else {

      //Limpa a validação do campo
      setValidaNome(false);
      setMsgErroNome('');

      //Chama a API

      const registro = [{
        codigo: parseInt(fildCodigo, 10),
        nome: fildNome,
        departamento: fildDepartamento,
        funcao: fildFuncao,
        salario: fildSalario,
        ctps: fildCtps,
        admissao: valorDataAdmissao,
        rg: fildRg,
        endereco: fildEndereco,
        bairro: fildBairro,
        cidade: fildCidade,
        cep: fildCep,
        fone: fildFone,
        pis: fildPis,
        cpf: fildCpf,
        observacao: fildObservacao,
        uf: fildUf,
        seriectps: fildSeriectps,
        fone2: fildFone2,
        email: fildEmail,
        banco: fildBanco,
        agencia: fildAgencia,
        conta: fildConta,
        dadoscomplementares: fildDadoscomplementares,
        comissao: fildComissao,
        inativo: fildInativo,
        meta: fildMeta,
      }];

      setimpedirMultClick(true);

      const response = await fetch(`${Config.urlRoot}/employee`, {
        method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201 ){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');
        return;
      }

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        setFildCodigo(json[0].codigo);
        // setFildNome(json.nome);
        // setFildDepartamento(json.departamento);
        // setFildFuncao(json.funcao);
        // setFildSalario(json.salario);
        // setFildCtps(json.ctps);

        // if (json.admissao !== '') {
        //   setFildAdmissao(dayjs(json.admissao));
        // }

        // setFildNascimento(json.nascimento);
        // setFildRg(json.rg);
        // setFildEndereco(json.endereco);
        // setFildBairro(json.bairro);
        // setFildCidade(json.cidade);
        // setFildCep(json.cep);
        // setFildFone(json.fone);
        // setFildPis(json.pis);
        // setFildCpf(json.cpf);
        // setFildObservacao(json.observacao);
        // setFildUf(json.uf);
        // setFildSeriectps(json.seriectps);
        // setFildFone2(json.fone2);
        // setFildEmail(json.email);
        // setFildBanco(json.banco);
        // setFildAgencia(json.agencia);
        // setFildConta(json.conta);
        // setFildDadoscomplementares(json.dadoscomplementares);
        // setFildComissao(json.comissao);
        // setFildInativo(json.inativo);
        // setFildMeta(json.meta);


        SetRecarregar(false);

      setimpedirMultClick(false);

        handleOpenMessage();

    }
  }

  const getCodigoUF= (valor) => dadosUf.map(item => {
    if (item.uf === valor){

     setCodigoUf(item.cuf);

    }
  });

  useEffect(() => {

    if (fildUf !== null) {
      if (fildUf.length > 0) {
        listarMunicipios(codigoUf);
      }
    }

  }, [codigoUf, fildUf, listarMunicipios]);

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function dadosCompleto() {

    const response = await fetch(`${Config.urlRoot}/employee/${fildCodigo}`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
      })
    });

    let json = await response.json();

    if(response.status!==200 && response.status!==201 ){
      setExibirProgress(false);
        return;
      }

        setFildCodigo(json.codigo);
        setFildNome(json.nome);
        setFildDepartamento(json.departamento);
        setFildFuncao(json.funcao);
        setFildSalario(json.salario);
        setFildCtps(json.ctps);

        if (json.admissao !== '') {
          setFildAdmissao(dayjs(json.admissao));
        }

        setFildNascimento(json.nascimento);
        setFildRg(json.rg);
        setFildEndereco(json.endereco);
        setFildBairro(json.bairro);
        setFildCidade(json.cidade);
        setFildCep(json.cep);
        setFildFone(json.fone);
        setFildPis(json.pis);
        setFildCpf(json.cpf);
        setFildObservacao(json.observacao);
        setFildUf(json.uf);
        setFildSeriectps(json.seriectps);
        setFildFone2(json.fone2);
        setFildEmail(json.email);
        setFildBanco(json.banco);
        setFildAgencia(json.agencia);
        setFildConta(json.conta);
        setFildDadoscomplementares(json.dadoscomplementares);
        setFildComissao(json.comissao);
        setFildInativo(json.inativo);
        setFildMeta(json.meta);

        setExibirProgress(false);
        SetRecarregar(false);
  }

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModalMunicipios = () => {
    setOpenModalMunicipios(true);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function botaoVoltar() {
    props.history.push('/app/Employee');
  }

  function botaoNovo() {

    setFildCodigo('0');
    setFildNome('');
    setFildDepartamento('');
    setFildFuncao('');
    setFildSalario('');
    setFildCtps('');
    setFildAdmissao('');
    setValorDataAdmissao(null);
    setFildNascimento('');
    setFildRg('');
    setFildEndereco('');
    setFildBairro('');
    setFildCidade('');
    setFildCep('');
    setFildFone('');
    setFildPis('');
    setFildCpf('');
    setFildObservacao('');
    setFildUf('');
    setFildSeriectps('');
    setFildFone2('');
    setFildEmail('');
    setFildBanco('');
    setFildAgencia('');
    setFildConta('');
    setFildDadoscomplementares('');
    setFildComissao('');
    setFildInativo('');
    setFildMeta('');
  }

  return (
    <>

      <PageTitle title="Funcionário "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

      <AguardarRetorno show={impedirMultClick} />

      <Grid container spacing={2}>

        <Grid item xs={16} md={4} >
          <Card variant="outlined">

            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '65ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="tfNome"
                  label="Nome"
                  value={fildNome}
                  size="small"
                  //defaultValue={fildNome}
                  variant="filled"
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildNome(event.target.value)}
                  error={validaNome}
                  fullWidth
                />
              </div>

              <div>
                <TextField
                  id="tfDepartamento"
                  label="Departamento"
                  variant="filled"
                  size="small"
                  value={fildDepartamento}
                  //defaultValue={fildDepartamento}
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildDepartamento(event.target.value)}
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  id="tfFuncao"
                  label="Função"
                  variant="filled"
                  size="small"
                  value={fildFuncao}
                  //defaultValue={fildFuncao}
                  inputProps={{ maxLength: 14 }}
                  onChange={(event) => setFildFuncao(event.target.value)}
                  fullWidth
                />
              </div>

              <div>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '31.5ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="tfSalario"
                    label="Salário"
                    size="small"
                    variant="filled"
                    type={'number'}
                    value={fildSalario}
                    //defaultValue={fildSalario}
                    inputProps={{ maxLength: 18 }}
                    onChange={(event) => setFildSalario(event.target.value)}
                  />

                  <TextField
                    id="tfCtps"
                    label="Carteira Profissional"
                    variant="filled"
                    size="small"
                    value={fildCtps}
                    //defaultValue={fildCtps}
                    inputProps={{ maxLength: 18 }}
                    onChange={(event) => setFildCtps(event.target.value)}
                  />
                </Box>
              </div>

              <div>
                <div>

                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '31.5ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        id="tfAdmissao"
                        label="Admissão"
                        inputFormat="DD/MM/YYYY"
                        value={fildAdmissao}
                        onChange={handleChangeAdmissao}
                        renderInput={(params) => <TextField fullWidth size="small" variant="filled" {...params} />}
                      />
                    </LocalizationProvider>

                    {/* <TextField
                      id="tfAdmissao"
                      label="Dt Admissão"
                      variant="filled"
                      value={fildAdmissao}
                      //defaultValue={fildAdmissao}
                      inputProps={{ maxLength: 11 }}
                      onChange={(event) => setFildAdmissao(event.target.value)}
                      fullWidth
                    /> */}

                    <TextField
                      id="tfRg"
                      label="RG"
                      variant="filled"
                      size="small"
                      value={fildRg}
                      //defaultValue={fildRg}
                      inputProps={{ maxLength: 11 }}
                      onChange={(event) => setFildRg(event.target.value)}
                    // style={{width:186}}
                    />
                  </Box>
                </div>
              </div>

              <div>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '31.5ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="tfFone"
                    label="Fone"
                    variant="filled"
                    size="small"
                    value={fildFone}
                    //defaultValue={fildFone}
                    inputProps={{ maxLength: 11 }}
                    onChange={(event) => setFildFone(event.target.value)}
                  // style={{width:186}}
                  />

                  <TextField
                    id="tfFone2"
                    label="Fone 2"
                    variant="filled"
                    size="small"
                    value={fildFone2}
                    //defaultValue={fildFone2}
                    inputProps={{ maxLength: 25 }}
                    onChange={(event) => setFildFone2(event.target.value)}
                  // style={{width:186}}
                  />
                </Box>
              </div>


              <div>
                <TextField
                  id="tfEmail"
                  label="Email"
                  variant="filled"
                  type={'email'}
                  size="small"
                  value={fildEmail}
                  //defaultValue={fildEmail}
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildEmail(event.target.value)}
                  fullWidth
                />
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} >
          <Card variant="outlined">

            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '65ch' },
              }}
              noValidate
              autoComplete="off"
            >

              <div>

                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '65ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <div>
                  <FormControl fullWidth  size="small"  sx={{ m: 0.6 }} variant="filled">
                    <InputLabel htmlFor="tfCep">CEP</InputLabel>
                    <FilledInput
                      id="tfCep"
                      value={fildCep}
                      //defaultValue={fildCep}
                      inputProps={{ maxLength: 8 }}
                      variant="filled"
                      onChange={(event) => handleChangeCep(event.target.value)}

                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(event) => ConsultarCep()}
                          >
                            <SearchIcon color="primary" />
                          </IconButton>
                        </InputAdornment>}
                    />
                  </FormControl>
                  </div>


                  <div>
                    <TextField
                      id="tfEndereco"
                      label="Endereço"
                      size="small"
                      variant="filled"
                      value={fildEndereco}
                      //defaultValue={fildEndereco}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => setFildEndereco(event.target.value)}
                      fullWidth
                    />
                  </div>
                </Box>
              </div>

              <div>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '65ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="tfBairro"
                    label="Bairro"
                    variant="filled"
                    value={fildBairro}
                    size="small"
                    //defaultValue={fildBairro}
                    inputProps={{ maxLength: 50 }}
                    onChange={(event) => setFildBairro(event.target.value)}
                    style={{ width: 400 }}
                  />

                  <TextField
                    id="tfUf"
                    select
                    label="UF"
                    value={fildUf}
                    size="small"
                    onChange={handleChangeUf}
                    variant="filled"
                    style={{ width: 90 }}
                  >
                    {dadosUf.map((option) => (
                      <MenuItem key={option.cuf} value={option.cuf}>
                        {option.uf}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </div>

              <div>
                {exibirProgressMunicipios && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

                <TextField
                  id="tfCidade"
                  select
                  label="Cidade"
                  value={fildCidade}
                  size="small"
                  onChange={handleChangeCidade}
                  variant="filled"
                // style={{width:400}}
                // fullWidth
                >
                  {dadosMunicipio.map((option) => (
                    <MenuItem key={option.cmunicipio} value={option.cmunicipio}>
                      {option.municipio}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div>
                <TextField
                  id="tfComplemento"
                  label="Dados Complementares"
                  variant="filled"
                  value={fildDadoscomplementares}
                  size="small"
                  //defaultValue={fildDadoscomplementares}
                  inputProps={{ maxLength: 255 }}
                  onChange={(event) => setFildDadoscomplementares(event.target.value)}
                  fullWidth
                />

              </div>

              <div>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '31.5ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="tfComissao"
                    label="Comissão"
                    type={'number'}
                    variant="filled"
                    size="small"
                    value={fildComissao}
                    //defaultValue={fildComissao}
                    inputProps={{ maxLength: 11 }}
                    onChange={(event) => setFildComissao(event.target.value)}
                  // style={{width:186}}
                  />

                  <TextField
                    id="tfMeta"
                    label="Meta"
                    type={'number'}
                    variant="filled"
                    value={fildMeta}
                    size="small"
                    //defaultValue={fildMeta}
                    inputProps={{ maxLength: 25 }}
                    onChange={(event) => setFildMeta(event.target.value)}
                  // style={{width:186}}
                  />
                </Box>
              </div>

              <div>
                <TextField
                  id="tfAtivo"
                  select
                  label="Ativo"
                  value={fildInativo}
                  size="small"
                  onChange={handleChangeAtivo}
                  variant="filled"
                >
                  {valoresSim_Nao.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </Card>
        </Grid>

      </Grid>
    </>
  );
}
