// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr from "../../../components/LocalesPtBr";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { browserName, CustomView } from 'react-device-detect';

import { Button } from "../../../components/Wrappers/Wrappers";

import { isMobile } from 'react-device-detect';

import {
  Typography,
  FormControl,
  TextField,
  Grid,
  Paper,
  InputLabel,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Modal, InputAdornment, FormControlLabel, Checkbox,
  FormHelperText, CircularProgress,
  Backdrop, Stepper, Step, StepLabel, StepContent, Input, ListItemIcon
  , ContentCopy, ListItemText, ContentPaste, MenuList, Divider
} from '@mui/material';

import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

//Estilizacao para Mobile
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno } from "../../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentsIcon from '@mui/icons-material/Payments';
import SettingsIcon from '@mui/icons-material/Settings';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

import { blue } from '@material-ui/core/colors';

import PrintSalesOrder from '../report/PrintSalesOrder';
import { ConstructionOutlined } from "@mui/icons-material";

import Desconto from './../../../components/Desconto';
import PesquisasClientes from './../../../components/PesquisarClientes';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function converterParaFloat(valor) {
  if (valor !== null && valor !== '') {
    return parseFloat(valor);
  } else {
    return 0;
  }
}

function converterParaInt(valor) {
  if (valor !== null && valor !== '') {
    return parseInt(valor);
  } else {
    return 0;
  }
}

const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[,][0-9]+)$');

function formatarMoeda(v) {

  var valor = v;

  valor = valor + '';
  valor = parseInt(valor.replace(/[\D]+/g, ''));
  valor = valor + '';
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }


  if (valor === 'NaN') valor = '';

  if (valor.substr(0, 1) === ',') { valor = "0" + valor; }

  return valor;
}

function formatValue(val) {
  const options = {

    // currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
  };
  val = val.replace(',', '.');
  const formatNumber = new Intl.NumberFormat("pt-BR", options);
  return formatNumber.format(val);
  // val: -70549.82999999999
  // formatNumber.format(val): -R$ 70.549,83
}

function isNumeric(str) {

  var regex = /^[0-9.,]+$/;

  return (regex.test(str));
}

function formatarValoresPtBr(valor) {

  let valorFormatado = Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(valor);//valor.toLocaleString('pt-br', {minimumFractionDigits: 2});//

  return valorFormatado;

}

function formatarValores(valor) {

  //Caso não seja apenas numeros zeramos o valor
  if (!isNumeric(valor)) { valor = 0; }

  if (valor !== null && valor !== '' && valor !== undefined) {

    //let valorFormatado = valor.toLocaleString('pt-br', {minimumFractionDigits: 2});//Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(valor);

    // valor = valor + '';
    // valor = parseInt(valor.replace(/[\D]+/g, ''));
    // valor = valor + '';
    // valor = valor.replace(/([0-9]{2})$/g, ",$1");

    // if (valor.length > 6) {
    //   valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    // }

    return Intl.NumberFormat('pt-br', { minimumFractionDigits: 2 }).format(valor);

  } else {

    valor = 0;
    // valor = valor + '';
    // valor = parseInt(valor.replace(/[\D]+/g, ''));
    // valor = valor + '';
    // valor = valor.replace(/([0-9]{2})$/g, ",$1");

    // if (valor.length > 6) {
    //   valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    // }

    return valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

}

function verificaSeIteiroOuFloat(valor) {

  //Caso não seja apenas numeros zeramos o valor
  if (!isNumeric(valor)) { valor = 0; }

  if (valor !== null && valor !== '' && valor !== undefined) {

    return valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });

    // valor = valor + '';
    // valor = parseInt(valor.replace(/[\D]+/g, ''));
    // valor = valor + '';
    // valor = valor.replace(/([0-9]{2})$/g, ",$1");

    // if (valor.length > 6) {
    //   valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    // }

  } else {

    valor = 0;
    //   valor = valor + '';
    //   valor = parseInt(valor.replace(/[\D]+/g, ''));
    //   valor = valor + '';
    //   valor = valor.replace(/([0-9]{2})$/g, ",$1");

    //   if (valor.length > 6) {
    //     valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    //   }

    valor = valor.toLocaleString('pt-br', { minimumFractionDigits: 0 });

  }

  return valor;

}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: 'numero_item', label: 'It.' },
  { id: 'gtin', label: 'Código' },
  { id: 'descricao', label: 'Descrição' },
  { id: 'unidade', label: 'Und.' },
  { id: 'quantidade', label: 'Qtd.' },
  { id: 'preco', label: 'Val. Unit.' },
  { id: 'total', label: 'TOTAL' },
  { id: '', label: '' },
];

const columnsMobile = [
  { id: 'descricao', label: 'Descrição' }
];

const columnsSearch = [
  { id: 'produto', label: 'Código' },
  { id: 'descricao', label: 'Descrição' },
  { id: '', label: '' },
];

const columnsSearchCliente = [
  { id: 'razao', label: 'Razão Social' },
  { id: 'fantasia', label: 'Nome Fantasia' },
  { id: 'cnpj', label: 'CNPJ' },
  { id: '', label: '' },
];

const columnsSearchVenda = [
  { id: 'data_emissao', label: 'Data' },
  { id: 'documento', label: 'N° Venda' },
  { id: 'nome', label: 'Nome' },
  { id: 'valor_total', label: 'Total' },
  { id: '', label: '' },
];

const columnsFormaPagamento = [
  { id: 'descricao', label: 'Descrição' },
  { id: 'valor', label: 'Valor' },
  { id: '', label: '' },
]

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const listaModeloBaseCalculo = [
  {
    codigo: '0',
    descricao: 'Margem de Valor Agregado (%)'
  },
  {
    codigo: '1',
    descricao: 'Preço de Pauta'
  },
  {
    codigo: '3',
    descricao: 'Valor da Operação'
  }

];

export default function SalesPDV(props) {

  //REFs - utilizado para fazer a mudanca de foco do componente

  const fildCbarraRef = useRef(null);
  const fildQuantidadeRef = useRef < HTMLInputElement > (null);

  const handleFocusQuantidade = useCallback(() => {
    document.getElementById('tfQuantidade').focus();
  }, []);


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);

  const [listaDados, setListaDados] = useState([]);

  const [idselecionado, setIdSelecionado] = useState(0);

  //------------------- Habilita / Desabilita controles
  const [registroCancelado, setRegistroCancelado] = useState(false);

  //------------------- Pesquisa de Produtos
  const [listaProdutos, setListaProdutos] = useState([]);
  const [pageProdutos, setPageProdutos] = React.useState(0);
  const [rowsPerPageProdutos, setRowsPerPageProdutos] = React.useState(5);
  const emptyRowsProdutos = pageProdutos > 0 ? Math.max(0, (1 + pageProdutos) * rowsPerPageProdutos - listaProdutos.length) : 0;

  const handleChangePageProdutos = (event, newPage) => {
    setPageProdutos(newPage);
  };

  const handleChangeRowsPerPageProdutos = (event) => {
    setRowsPerPageProdutos(parseInt(event.target.value, 10));
    setPageProdutos(0);
  };


  const [widthCelular, setWidthCelular] = useState(600);

  //-------------------

  const [opcaoDesconto, setOpcaoDesconto] = useState([false, true]);
  const [indicadorDesconto, setIndicadorDesconto] = useState('');//serve para mostrar no campo de desconto se é pra informar $ ou %

  const [openChavesReferenciadas, setOpenChavesReferenciadas] = useState(false);
  const handleOpenModaChavesReferenciadas = () => setOpenChavesReferenciadas(true);

  const [valueTab, setValueTab] = useState(0);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaDados.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);
  const [exibirProgressProdutos, setExibirProgressProdutos] = useState(false);
  const [exibirProgressVendedores, setExibirProgressVendedores] = useState(false);
  const [exibirProgressCfop, setExibirProgressCfop] = useState(false);
  const [exibirProgressRecebimentos, setExibirProgressRecebimentos] = useState(false);

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  //Estilizacao para Mobile


  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


  //Fim Estilizacao Mobile

  //Campos do formulario
  const [caixaLivre, setCaixaLivre] = useState(true);
  const [pedidoCancelado, setPedidoCancelado] = useState(false);

  const [fildId, setFildId] = useState(0);
  const [fildEmissao, setFildEmissao] = useState(new Date());
  const [fildId_nfce_movimento, setFildId_nfce_movimento] = useState('');
  const [fildVendedor, setFildVendedor] = useState('');
  const [fildDocumento, setFildDocumento] = useState('');
  const [fildData_Emissao, setFildData_Emissao] = useState(new Date());
  const [fildHora_Emissao, setFildHora_Emissao] = useState('');
  const [fildValor_total_produtos, setFildValor_total_produtos] = useState(0);
  const [fildValor_desconto, setFildValor_desconto] = useState(0);
  const [fildValor_total, setFildValor_total] = useState(0);
  const [fildStatus_nota, setFildStatus_nota] = useState('');
  const [fildTroco, setFildTroco] = useState(0);
  const [fildCliente, setFildCliente] = useState('');
  const [fildNome, setFildNome] = useState('');
  const [fildCpfcnpj, setFildCpfcnpj] = useState('');
  const [fildId_operador, setFildId_operador] = useState('');
  const [fildDesconto, setFildDesconto] = useState(0);
  const [fildTaxadesconto, setFildTaxadesconto] = useState(0);
  const [fildValoracrescimo, setFildValoracrescimo] = useState(0);
  const [fildTaxaacrescimo, setFildTaxaacrescimo] = useState(0);

  const [recebimentoSelecionado, setRecebimentoSelecionado] = useState('');
  const [descricaoRecebimentoSelecionado, setDescricaoRecebimentoSelecionado] = useState('');
  const [valorPagamento, setValorPagamento] = useState(0);
  const [valorrecebido, setValorrecebido] = useState(0);
  const [valorTroco, setValorTroco] = useState(0);
  //Fim dos campos do formulario

  //ITEM
  const [fildIdProduto, setFildIdProduto] = useState(0);
  const [fildCBarra, setFildCBarra] = useState('');
  const [fildProduto, setFildProduto] = useState('');
  const [fildDescricao, setFildDescricao] = useState('');
  const [fildUnidade, setFildUnidade] = useState('');
  const [fildPreco, setFildPreco] = useState('');
  const [fildQuantidade, setFildQuantidade] = useState(1);
  const [fildSubtotal, setFildSubtotal] = useState('');

  const [tipoDesconto, setTipoDesconto] = useState(''); //armazena o tipo do desconto P=total e I=Produto
  const [visualizarItemNoDesconto, setVisualizarItemNoDesconto] = useState(false);
  const [fildItemDesconto, setFildItemDesconto] = useState('');

  const [item, setItem] = useState([]);
  const [listaItens, setListaItens] = useState([]);
  const [dadosCabecalho, setDadosCabecalho] = useState([]);
  const [dadosCliente, setDadosCliente] = useState([]);

  //---------------

  const [openPesquisar, setOpenPesquisar] = useState(false);
  const handleOpenModalPesquisar = () => setOpenPesquisar(true);
  const handleCloseModalPesquisar = () => {

    setOpenPesquisar(false);
    document.getElementById('tfQuantidade').focus();
  }

  const [openDesconto, setOpenDesconto] = useState(false);
  const handleOpenModalDesconto = () => setOpenDesconto(true);
  const handleCloseModalDesconto = () => setOpenDesconto(false);

  const [openQuantidade, setOpenQuantidade] = useState(false);
  const handleOpenModalQuantidade = () => setOpenQuantidade(true);
  const handleCloseModalQuantidade = () => setOpenQuantidade(false);

  const [openMovimento, setOpenMovimento] = useState(false);
  const handleOpenModalMovimento = () => setOpenMovimento(true);
  const handleCloseModalMovimento = () => setOpenMovimento(false);

  const [openPesquisarCliente, setOpenPesquisarCliente] = useState(false);
  const handleOpenModalPesquisarCliente = () => setOpenPesquisarCliente(true);
  const handleCloseModalPesquisarCliente = () => setOpenPesquisarCliente(false);

  const [openPagamento, setOpenPagamento] = useState(false);
  const handleOpenModalPagamento = () => setOpenPagamento(true);
  const handleCloseModalPagamento = () => setOpenPagamento(false);
  const [listaPagamentos, setListaPagamentos] = useState([]);

  const [pagePagamento, setPagePagamento] = React.useState(0);
  const [rowsPerPagePagamento, setRowsPerPagePagamento] = React.useState(-1);
  const emptyRowsPagamento = pagePagamento > 0 ? Math.max(0, (1 + pagePagamento) * rowsPerPagePagamento - listaPagamentos.length) : 0;

  const [listaVendas, setListaVendas] = useState([]);
  const [openPesquisarVendas, setOpenPesquisarVendas] = useState(false);
  const handleOpenModalPesquisarVendas = () => setOpenPesquisarVendas(true);
  const handleCloseModalPesquisarVendas = () => setOpenPesquisarVendas(false);

  const [pageVendas, setPageVendas] = React.useState(0);
  const [rowsPerPageVendas, setRowsPerPageVendas] = React.useState(5);
  const emptyRowsVendas = pageVendas > 0 ? Math.max(0, (1 + pageVendas) * rowsPerPageVendas - listaVendas.length) : 0;



  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  // const handleChangePageCliente = (event, newPage) => {
  //   setPageCliente(newPage);
  // };

  // const handleChangeRowsPerPageCliente = (event) => {
  //   setPageCliente(parseInt(event.target.value, 10));
  //   setPageCliente(0);
  // };

  const handleChangePageVendas = (event, newPage) => {
    setPageVendas(newPage);
  };

  const handleChangeRowsPerPageVendas = (event) => {
    setPageVendas(parseInt(event.target.value, 10));
    setPageVendas(0);
  };

  //Inverte pois o cmapo é de INATIVO entao quando estiver INATIVO=TRUE
  const modoDesconto = [
    {
      value: 'P',
      label: 'Total',
    },
    {
      value: 'I',
      label: 'Produto',
    }
  ];

  const [habilitarAdicaoAlteracao, setHabilitarAdicaoAlteracao] = useState(false);

  // Variaveis pra validar campos
  const [validaCliente, setValidaCliente] = useState(false);
  const [msgErroCliente, setMsgErroCliente] = useState('');

  const [validaProduto, setValidaProduto] = useState(false);
  const [msgErroProduto, setMsgErroProduto] = useState('');

  const [validaQuantidade, setValidaQuantidade] = useState(false);
  const [msgErroQuantidade, setMsgErroQuantidade] = useState('');

  const [validaPreco, setValidaPreco] = useState(false);
  const [msgErroPreco, setMsgErroPreco] = useState('');

  const [validaRecebimento, setValidaRecebimento] = useState(false);
  const [msgErroRecebimento, setMsgErroRecebimento] = useState('');

  //-------------------------

  const [lsCfop, setLsCfop] = useState([]);
  const [lsRecebimento, setLsRecebimento] = useState([]);
  const [lsVendedor, setLsVendedor] = useState([]);

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeEmissao = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildEmissao(newValue.toJSON());
      }
    }
  };

  const handleChangeModoBcIcms = (event) => {
    // setFildModBc(event.target.value);
  }

  const handleChangeVendedor = (event) => {
    // setFildVendedor1(event.target.value);
  }


  // Handles do registro alterado

  const handleConfirmarDesconto = () => {

    if (opcaoDesconto[1]) {

    } else {

      //Desconto em %
      let conversao = (fildDesconto / 100) * (fildValor_total_produtos);
      setFildDesconto(conversao);
      setOpcaoDesconto([false, true]);
    }

    handleCloseModalDesconto();
  };

  const handleConfirmarQuantidade = () => {

    handleCloseModalQuantidade();
  };

  const handleChangeRecebimento = (event) => {

    setRecebimentoSelecionado(event.target.value);

  }

  const handleChangeMdoDesconto = (event) => {
    // setFildMododesconto(event.target.value);

    setTipoDesconto(event.target.value);

    if (event.target.value === "I") {

      setVisualizarItemNoDesconto(true);
    } else {
      setVisualizarItemNoDesconto(false);
    }
  }

  const handleChangeDescontoPercentual = (event) => {
    setOpcaoDesconto([event.target.checked, false]);
    setIndicadorDesconto('%');

  }

  const handleChangeDescontoValor = (event) => {
    setOpcaoDesconto([false, event.target.checked]);
    setIndicadorDesconto('R$');

  }

  const handleChangePesquisarProdutos = (valor) => {
    pesquisarProdutos(valor);
  }

  // const handleChangePesquisarCliente = (valor) => {
  //   pesquisarCliente(valor);
  // }

  const handleChangePesquisarVenda = (valor) => {
    pesquisarVenda(valor);
  }

  const handleImpedirMultClick = () => {
    setimpedirMultClick(true);
  }

  const handleChangePedidoCancelado = (valor) => {
    setPedidoCancelado(valor);
  }

  const handleDefinirPagamento = () => {

    const newRegistro = {
      chave1: 0
      , cliente: fildCliente
      , razao: fildNome
      , fantasia: fildNome
      , emissao: ''
      , vencimento: ''
      , documento: fildId
      , valor: formatarValoresPtBr(valorPagamento)
      , responsavel: ''
      , cancelado: 'false'
      , documento2: fildId
      , origem: 'nfc'
      , unificado: 'false'
      , valorparcial: 0
      , valordesconto: 0
      , codigorecebimento: recebimentoSelecionado
      , recebimento: descricaoRecebimentoSelecionado
      , observacao: ''
      , empresa: ''
      , forma: ''
      , cnpj: ''
      , valoracrescimo: 0
    }

    setListaPagamentos((prevItems) => {

      return [...prevItems, newRegistro];

    });

    //calculaTotalPagamento();

  }

  function calculaTotalPagamento() {

    let somaPagamentos = 0;
    let valor_total = 0;

    if (fildValor_total !== "") {
      valor_total = parseFloat(fildValor_total);
    }

    listaPagamentos.map((item) => {
      somaPagamentos = somaPagamentos + parseFloat(item.valor.replace('.', '').replace(',', '.'));
      setValorrecebido(formatarValoresPtBr(somaPagamentos));

      if (somaPagamentos > valor_total) {
        let valor_troco = formatarValoresPtBr(somaPagamentos - valor_total);
        setValorTroco(valor_troco);
      } else { setValorTroco(0); }

    }
    );

  }

  // Fim Handles do registro alterado

  function changeItemQuantidade(e, index) {

    let _valor = e.target.value;
    _valor = verificaSeIteiroOuFloat(_valor);

    let it = [...listaItens];
    it[index].quantidade = _valor;
    setListaItens(it);
  }

  function changeItemAtendido(e, index) {

    let _valor = e.target.value;

    _valor = _valor.replace(',', '.');

    let it = [...listaItens];

    let _preco = it[index].preco;
    // console.log(_preco);
    // _preco =_preco.replace('.', '');
    _preco = _preco.replace(',', '.');
    //_preco =verificaSeIteiroOuFloat(_preco);
    // console.log(_valor * _preco);
    it[index].atendido = _valor;
    it[index].total = _valor * _preco;

    setListaItens(it);
  }

  function preencherDadosProduto(id, produto, descricao, precovenda, unidade, cbarra) {

    setFildIdProduto(id);
    setFildProduto(produto);
    setFildDescricao(descricao);
    setFildPreco(formatarMoeda(precovenda));
    setFildUnidade(unidade);
    setFildCBarra(cbarra);

    if (fildQuantidade !== '') {

      let _quantidade = 0;

      try { _quantidade = fildQuantidade.replace(',', '.'); }
      catch { _quantidade = fildQuantidade; }
      finally { }

      let _preco = precovenda;

      let subTotal = _preco * _quantidade;

      setFildSubtotal(formatarValoresPtBr(subTotal));
    }

    handleCloseModalPesquisar();

    setListaProdutos([]);

    // setHabilitarAdicaoAlteracao(false);
  }

  function limparDadosProduto() {

    setFildIdProduto(0);
    setFildProduto('');
    setFildDescricao('');
    setFildCBarra('');
    setFildUnidade('');
    setFildPreco('');
    setFildSubtotal(formatarValoresPtBr(0.00));
    setFildQuantidade(1);
  }

  function limparVenda() {
    setFildId(0);
    setFildEmissao(new Date());
    setFildId_nfce_movimento('');
    setFildVendedor('');
    setFildDocumento('');
    setFildData_Emissao(new Date());
    setFildHora_Emissao('');
    setFildValor_total_produtos(0);
    setFildValor_desconto(0);
    setFildValor_total(0);
    setFildStatus_nota('');
    setFildTroco(0);
    setFildCliente('');
    setFildNome('');
    setFildCpfcnpj('');
    setFildId_operador('');
    setFildDesconto(0);
    setFildTaxadesconto(0);
    setFildValoracrescimo(0);
    setFildTaxaacrescimo(0);

    setFildIdProduto(0);
    setFildCBarra('');
    setFildProduto('');
    setFildDescricao('');
    setFildUnidade('');
    setFildPreco('');
    setFildQuantidade(1);
    setFildSubtotal('');

    setItem([]);
    setListaItens([]);
    setListaPagamentos([]);
    setDadosCabecalho([]);
    setDadosCliente([]);

  }

  function preencherDadosCliente(codigo, razao, fantasia, cnpj, uf) {

    setFildCliente(codigo);
    setFildNome(razao);
    setFildCpfcnpj(cnpj);

    handleCloseModalPesquisarCliente();

    // setListaClientes([]);
  }

  function prencheDadosVendaPdv(registro) {

    console.log(registro.id);
    setFildId(registro.id);

    dadosCompleto(registro.id);

    setExibirProgress(false);
    setCaixaLivre(false);

    handleCloseModalPesquisarVendas();

  }

  function mantarRegistro() {

    if (fildIdProduto === "") {
      setValidaProduto(true);
      setMsgErroProduto('Selecione o produto.');
      return;

    } else {
      setValidaProduto(false);
      setMsgErroProduto('');
    }

    if (fildQuantidade === "" || fildQuantidade === 0) {
      setValidaQuantidade(true);
      setMsgErroQuantidade('Quantidade inválida.');
      return;

    } else {
      setValidaQuantidade(false);
      setMsgErroQuantidade('');
    }

    if (fildPreco === "" || fildPreco === 0) {
      setValidaPreco(true);
      setMsgErroPreco('Val. Unitário inválido.');
      return;

    } else {
      setValidaPreco(false);
      setMsgErroPreco('');
    }

    // setimpedirMultClick(true);

    var _total = 0;//fildQuantidade * fildPreco;

    let _quantidade = 0;
    let _preco = 0;

    try { _quantidade = fildQuantidade.replace(',', '.'); }
    catch { _quantidade = fildQuantidade; }
    finally { }

    try { _preco = fildPreco.replace(',', '.'); }
    catch { _preco = fildPreco; }
    finally { }

    let subTotal = formatarValoresPtBr(_preco * _quantidade);

    const newItem = {
      id: 0
      , codigo_produto: ''
      , id_nfe_cabecalho: 0
      , numero_item: 0
      , gtin: fildCBarra
      , nome_produto: fildDescricao
      , ncm: ''
      , cfop: ''
      , unidade: fildUnidade
      , quantidade: fildQuantidade
      , valor_unitario: fildPreco
      , valor_desconto: ''
      , valor_subtotal: subTotal
      , valor_total: subTotal
      , data: ''
      , documento: fildDocumento
      , cancelado: 'N'
      , descricao: fildDescricao
      , icms: 0
      , icms_reducao: 0
      , valor_icms: 0
      , base_icms: 0
      , mva: 0
      , mva_reducao: 0
      , mva_icms: 0
      , valor_icms_st: 0
      , base_icms_st: 0
      , fichatecnica: ''
      , movimentaestoque: 'true'
      , produtoficha: ''
      , emitido: ''
      , codigovendedor: fildVendedor
      , nomevendedor: ''
      , comissao: 0
      , valor_acrescimo: ''
      , custo: 0
    }
    //Adiciona ou altera o item na lista
    addItem(newItem);

    //Limpa  os dados do produto
    limparDadosProduto();

  }

  function addItem(data) {

    // const itemExists = listaItens.find((item) => item.id === data.id && item.id > 0);

    // if (itemExists) {

    //Atualiza o Item selecionado pela CHAVE do registro
    //   setListaItens((prevItems) => {

    //     return prevItems.map((item) => {
    //       if (item.id === data.id) {

    //         var _total = data.quantidade * data.valor_unitario;

    //         item.data = fildData_Emissao;
    //         item.valor_desconto = 0;
    //         item.valor_subtotal = _total;
    //         item.valor_total = _total;
    //         item.valor_acrescimo = 0;
    //         item.codigovendedor = fildVendedor;
    //       }
    //       return item;
    //     });
    //   });

    // } else {

    //Adiciona um novo registro obs.= obrigatoriamente a chave do registro deve ser 0 para a API saber que é um registro novo
    //listaItens.push(data);
    // console.log(listaItens);

    setListaItens((prevItems) => {

      return [...prevItems, data];
    });

    setHabilitarAdicaoAlteracao(true);

    //}

  }

  function descontoNoItem(nomeroitem) {

    handleConfirmarDesconto();

    const itemExists = listaItens.find((item) => item.numero_item === nomeroitem);

    if (!itemExists) {

      setFildDesconto(0);
      setTipoMensagem('error');
      setTextoMensagem('Item não encontrado!');
      handleOpenMessage();
      //escreve amensagem informando que o item nao foi encontrado

      return;
    }

    let liberado = false;
    setListaItens((prevItems) => {

      return prevItems.map((item) => {
        if (item.numero_item === nomeroitem) {

          let vDesconto = parseFloat(fildDesconto);

          if (vDesconto <= item.valor_subtotal) {

            liberado = true;

          } else { setFildDesconto(0); }

          item.valor_desconto = fildDesconto;
        }

        return item;
      });
    });

    if (!liberado) {

      setTipoMensagem('error');
      setTextoMensagem('Desconto não pode ser maior que o valor total do produto!');
      handleOpenMessage();

      return;
    }

    setHabilitarAdicaoAlteracao(true);

  }

  async function saveRegistro() {

    // const date = new Date();
    // const showTime = date.getHours()
    //   + ':' + date.getMinutes()
    //   + ":" + date.getSeconds()

    // console.log(showTime);
    // if (pedidoCancelado) { return; }


    let json = [];

    try {
      if (fildId === 0) {

        //Novo Pedido

        const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPdv.php', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            requisicao: 'save-pdv',
            senha: senhaRetorno,
            login: usuarioRetorno,
            id: fildId,
            documento: fildDocumento,
            data_emissao: '',
            hora_emissao: '',
            base_calculo_icms: 0,
            valor_icms: 0,
            base_calculo_icms_st: 0,
            valor_icms_st: 0,
            valor_total_produtos: 0,
            valor_desconto: 0,
            valor_ipi: 0,
            valor_pis: 0,
            valor_cofins: 0,
            valor_total: 0,
            status_nota: '0',
            nome: fildNome,
            cpfcnpj: fildCpfcnpj,
            id_operador: '0',
            desconto: fildDesconto,
            taxadesconto: 0,
            valoracrescimo: 0,
            taxaacrescimo: 0,
            tipodesconto: tipoDesconto,
            troco: 0,
            itens: listaItens,
            financeiro: listaPagamentos
          })
        });

        json = await response.json();

        if (json.success) {

          setFildId(json.venda[0].id);
          setFildEmissao(json.venda[0].data_emissao);
          setFildId_nfce_movimento(json.venda[0].id_nfce_movimento);
          setFildVendedor(json.venda[0].vendedor);
          setFildDocumento(json.venda[0].documento);
          setFildData_Emissao(json.venda[0].data_emissao);
          setFildHora_Emissao(json.venda[0].hora_emissao);
          //setFildValor_total_produtos(converterParaFloat(json.venda[0].valor_total_produtos).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValor_desconto(converterParaFloat(json.venda[0].valor_desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValor_total(converterParaFloat(json.venda[0].valor_total).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildStatus_nota(json.venda[0].status_nota);
          setFildTroco(converterParaFloat(json.venda[0].troco).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildCliente(json.venda[0].cliente);
          setFildNome(json.venda[0].nome);
          setFildCpfcnpj(json.venda[0].cpfcnpj);
          setFildId_operador(json.venda[0].id_operador);
          setFildDesconto(converterParaFloat(json.venda[0].desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildTaxadesconto(converterParaFloat(json.venda[0].taxadesconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValoracrescimo(converterParaFloat(json.venda[0].valoracrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildTaxaacrescimo(converterParaFloat(json.venda[0].taxaacrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setTipoDesconto(json.venda[0].tipodesconto);

          setListaItens(json.itens);

          //   //Libera o click
          setimpedirMultClick(false);

          // setFildId(venda.id);

          //   setTipoMensagem('success');
          //   setTextoMensagem('Registro salvo com sucesso!');
          //   handleOpenMessage();

          setHabilitarAdicaoAlteracao(false);
        }

      } else {

        //Atualiza Pedido
        const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPdv.php', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            requisicao: 'update-pdv',
            senha: senhaRetorno,
            login: usuarioRetorno,
            id: fildId,
            documento: fildDocumento,
            data_emissao: '',
            hora_emissao: '',
            base_calculo_icms: 0,
            valor_icms: 0,
            base_calculo_icms_st: 0,
            valor_icms_st: 0,
            valor_total_produtos: 0,
            valor_desconto: 0,
            valor_ipi: 0,
            valor_pis: 0,
            valor_cofins: 0,
            valor_total: 0,
            status_nota: '0',
            troco: 0,
            nome: fildNome,
            cpfcnpj: fildCpfcnpj,
            id_operador: '0',
            desconto: fildDesconto,
            taxadesconto: 0,
            valoracrescimo: 0,
            taxaacrescimo: 0,
            tipodesconto: tipoDesconto,
            troco: valorTroco,
            itens: listaItens,
            financeiro: listaPagamentos
          })
        });

        json = await response.json();

        if (json.success) {

          setFildId(json.venda[0].id);
          setFildEmissao(json.venda[0].data_emissao);
          setFildId_nfce_movimento(json.venda[0].id_nfce_movimento);
          setFildVendedor(json.venda[0].vendedor);
          setFildDocumento(json.venda[0].documento);
          setFildData_Emissao(json.venda[0].data_emissao);
          setFildHora_Emissao(json.venda[0].hora_emissao);
          //setFildValor_total_produtos(converterParaFloat(json.venda[0].valor_total_produtos).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValor_desconto(converterParaFloat(json.venda[0].valor_desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValor_total(converterParaFloat(json.venda[0].valor_total).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildStatus_nota(json.venda[0].status_nota);
          setFildTroco(converterParaFloat(json.venda[0].troco).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildCliente(json.venda[0].cliente);
          setFildNome(json.venda[0].nome);
          setFildCpfcnpj(json.venda[0].cpfcnpj);
          setFildId_operador(json.venda[0].id_operador);
          setFildDesconto(converterParaFloat(json.venda[0].desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildTaxadesconto(converterParaFloat(json.venda[0].taxadesconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildValoracrescimo(converterParaFloat(json.venda[0].valoracrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setFildTaxaacrescimo(converterParaFloat(json.venda[0].taxaacrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
          setTipoDesconto(json.venda[0].tipodesconto);

          setListaItens(json.itens);
          setListaPagamentos(json.financeiro);

          //Verificar para fechar a venda
          if (json.financeiro.length > 0) {
            setCaixaLivre(true);
            limparVenda();

            document.getElementById('menu_novo').focus();

            setTipoMensagem('success');
            setTextoMensagem('Registro salvo com sucesso!');
            handleOpenMessage();
          }

          //   //Libera o click
          setimpedirMultClick(false);

          setHabilitarAdicaoAlteracao(false);
        }

      }

    } catch (error) {
      console.error(error);
    }
  }

  async function cancelRegistro() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPdv.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'cancelar-pdv',
        senha: senhaRetorno,
        login: usuarioRetorno,
        id: fildId,
        documento: fildDocumento,

      })
    });

    let json = await response.json();

    if (json.success) {

      setTipoMensagem('success');
      setTextoMensagem('Cancelamento realizado com sucesso!');
      handleOpenMessage();

      RegistroCancelar();
    }
  }

  async function removeRegistro(index, idChave) {

    setHabilitarAdicaoAlteracao(true);

    let json = [];

    listaItens.splice(index, 1);

    //Atualiza Pedido
    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPdv.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'update-pdv',
        senha: senhaRetorno,
        login: usuarioRetorno,
        id: fildId,
        documento: fildDocumento,
        data_emissao: '',
        hora_emissao: '',
        base_calculo_icms: 0,
        valor_icms: 0,
        base_calculo_icms_st: 0,
        valor_icms_st: 0,
        valor_total_produtos: 0,
        valor_desconto: 0,
        valor_ipi: 0,
        valor_pis: 0,
        valor_cofins: 0,
        valor_total: 0,
        status_nota: '0',
        troco: 0,
        nome: fildNome,
        cpfcnpj: fildCpfcnpj,
        id_operador: '0',
        desconto: fildDesconto,
        taxadesconto: 0,
        valoracrescimo: 0,
        taxaacrescimo: 0,
        tipodesconto: tipoDesconto,
        itens: listaItens,
        iditem: idChave
      })
    });

    json = await response.json();

    if (json.success) {

      setFildId(json.venda[0].id);
      setFildEmissao(json.venda[0].data_emissao);
      setFildId_nfce_movimento(json.venda[0].id_nfce_movimento);
      setFildVendedor(json.venda[0].vendedor);
      setFildDocumento(json.venda[0].documento);
      setFildData_Emissao(json.venda[0].data_emissao);
      setFildHora_Emissao(json.venda[0].hora_emissao);
      //setFildValor_total_produtos(converterParaFloat(json.venda[0].valor_total_produtos).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildValor_desconto(converterParaFloat(json.venda[0].valor_desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildValor_total(converterParaFloat(json.venda[0].valor_total).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildStatus_nota(json.venda[0].status_nota);
      setFildTroco(converterParaFloat(json.venda[0].troco).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildCliente(json.venda[0].cliente);
      setFildNome(json.venda[0].nome);
      setFildCpfcnpj(json.venda[0].cpfcnpj);
      setFildId_operador(json.venda[0].id_operador);
      setFildDesconto(converterParaFloat(json.venda[0].desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildTaxadesconto(converterParaFloat(json.venda[0].taxadesconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildValoracrescimo(converterParaFloat(json.venda[0].valoracrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setFildTaxaacrescimo(converterParaFloat(json.venda[0].taxaacrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      setTipoDesconto(json.venda[0].tipodesconto);

      setListaItens(json.itens);

      //   //Libera o click
      setimpedirMultClick(false);
      //   setTipoMensagem('success');
      //   setTextoMensagem('Registro salvo com sucesso!');
      //   handleOpenMessage();

      setHabilitarAdicaoAlteracao(false);

    }

  }

  function finalizarVenda() {

    let valor_total = 0;
    let valor_recebido = 0;

    try {
      valor_total = parseFloat(fildValor_total);
    }
    catch { }
    finally { }

    try {
      valor_recebido = parseFloat(valorrecebido);
    }
    catch { }
    finally { }

    if (valor_recebido >= valor_total) {

      saveRegistro();

      handleCloseModalPagamento();
    }

  }

  //Litas

  async function pesquisarProdutos_Codigo(valor) {

    setExibirProgressProdutos(true);

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiProdutos.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'pesquisar-codigo',
        senha: senhaRetorno,
        login: usuarioRetorno,
        condicao: valor,
        uf: ''
      })
    });

    let json = await response.json();

    // setExibirProgressProdutos(false);

    if (json.success) {
      if (json.result === null) {
        setListaProdutos([]);

      } else {
        // setListaProdutos(json.result);

        preencherDadosProduto(
          json.result[0].id,
          json.result[0].produto,
          json.result[0].descricao,
          json.result[0].precovenda,
          json.result[0].unidade
        );

        // document.getElementById('tfQuantidade').focus();

        // fildQuantidadeRef.current.focus();

      }
    }
    else { setListaProdutos([]); }

  }

  async function pesquisarProdutos(valor) {

    setExibirProgressProdutos(true);

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiProdutos.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'pesquisar-produto-pdv',
        senha: senhaRetorno,
        login: usuarioRetorno,
        condicao: valor,
        uf: ''
      })
    });

    let json = await response.json();

    setExibirProgressProdutos(false);

    if (json.success) {
      if (json.result === null) {
        setListaProdutos([]);

      } else {
        setListaProdutos(json.result);

      }
    }
    else { setListaProdutos([]); }

  }

  // async function pesquisarCliente(valor) {

  //   // setExibirProgress(true);

  //   const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiClientes.php', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       requisicao: 'listar',
  //       senha: senhaRetorno,
  //       login: usuarioRetorno,
  //       condicao: valor
  //     })
  //   });

  //   let json = await response.json();

  //   // setExibirProgress(false);

  //   if (json.success) {
  //     if (json.result === null) {
  //       setListaClientes([]);

  //     } else {
  //       setListaClientes(json.result);

  //     }
  //   }
  //   else { setListaClientes([]); }

  // }

  async function pesquisarVenda(valor) {

    // setExibirProgress(true);

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPdv.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listar',
        senha: senhaRetorno,
        login: usuarioRetorno,
        pesquisar: valor,
        tipo: '0'
      })
    });

    let json = await response.json();

    // setExibirProgress(false);

    if (json.success) {
      if (json.result === null) {
        setListaVendas([]);

      } else {
        setListaVendas(json.result);

      }
    }
    else { setListaVendas([]); }

  }

  async function listarRecebimentos() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiRecebimentos.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listar',
        senha: senhaRetorno,
        login: usuarioRetorno
      })
    });

    let json = await response.json();

    if (json.success) {
      if (json.result === null) {
        setLsRecebimento([]);
      } else {
        setLsRecebimento(json.result);
      }
    }
    else {
      setLsRecebimento([]);
    }

    setExibirProgressRecebimentos(false);

  }

  async function listarVendedores() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiVendedores.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listar',
        senha: senhaRetorno,
        login: usuarioRetorno
      })
    });

    let json = await response.json();

    if (json.success) {
      if (json.result === null) {
        setLsVendedor([]);
      } else {
        setLsVendedor(json.result);
      }
    }
    else {
      setLsVendedor([]);
    }

    setExibirProgressVendedores(false);
  }

  //Fim Listas

  function verificaMovimento() {

  }

  // EVENTOS MENU
  function RegistroNovo() {
    console.log('novo');
    console.log('F2 - nova venda', caixaLivre);

    if (caixaLivre) {

      limparVenda();
      setExibirProgress(false);
      setCaixaLivre(false);
    }

  }

  function RegistroCancelar() {
    console.log('cancelar');
    if (!caixaLivre) {
      setCaixaLivre(true);
      limparVenda();
      document.getElementById('menu_novo').focus();
    }

  }

  function RegistroSalvar() {
    console.log('salvar');
    if (!caixaLivre) {
      setCaixaLivre(true);
      limparVenda();

      document.getElementById('menu_novo').focus();
    }

  }

  function ConsultarNfce() {

  }

  function EfetuarPagamento() {
    console.log('pagamento');
    console.log('F9 - Pagamento', caixaLivre);
    if (!caixaLivre) {
      setOpenPagamento(true);
    }

  }

  // Fim EVENTOS MENU
  useEffect(() => {

    calculaTotalPagamento();

    setRecebimentoSelecionado('');
    setValorPagamento(0);


  }, [calculaTotalPagamento, listaPagamentos]);

  useEffect(() => {

    // if (openPagamento) {
    // const itemExists = lsRecebimento.find((item) => item.codigo === recebimentoSelecionado);

    // if (itemExists) {

    lsRecebimento.map((item) => {

      if (item.codigo === recebimentoSelecionado) {

        setDescricaoRecebimentoSelecionado(item.descricao);

      }
    });


  }, [lsRecebimento, recebimentoSelecionado]);

  useEffect(() => {

    // if (isMobile) { setWidthCelular(300); }


    if (habilitarAdicaoAlteracao) {

      saveRegistro();

    }

  }, [habilitarAdicaoAlteracao, listaItens, saveRegistro]);


  useEffect(() => {

    if (lsRecebimento.length === 0) {
      listarRecebimentos();
    }

    // //É um novo Registro
    // if (props.location.idregistro.text === undefined) {
    //   props.history.push('/app/ListSalesOrder');

    // }
    // else if (props.location.idregistro.text === "0") {

    //   setExibirProgress(true);

    //   setExibirProgressRecebimentos(true);
    //   listarRecebimentos();

    //   setExibirProgressCfop(true);
    //   listarCfop();

    //   setExibirProgressVendedores(true);
    //   listarVendedores();

    //   setNovoRegistro(true);
    //   setExibirProgress(false);

    // } else {

    //   setExibirProgress(true);

    //   listarRecebimentos();
    //   listarCfop();
    //   listarVendedores();

    //   dadosCompleto();

    // }

  }, [lsRecebimento.length]);

  useEffect(() => {

    if (fildQuantidade !== '' && fildPreco !== '') {

      let _quantidade = fildQuantidade.replace(',', '.');
      let _preco = fildPreco.replace(',', '.');

      let subTotal = _preco * _quantidade;

      setFildSubtotal(formatarValoresPtBr(subTotal));
    }


  }, [fildPreco, fildQuantidade]);


  //Fica escutando se as teclas de atalhos foram precionadas
  useEffect(() => {

    document.addEventListener('keydown', detectedKeyDown, true);

  }, [detectedKeyDown]);


  const detectedKeyDown = (e) => {
    // console.log('Tecla: ', e.key);

    //e.preventDefault();
    let charCode = String.fromCharCode(e.which).toLowerCase();

    if (e.key === "F2") {

      RegistroNovo();

    }
    else if (e.key === "F3") {
      console.log('F3 - cancelar venda');

      if (fildId > 0) {
        limparVenda();
        cancelRegistro();

      }
      //lembrar de limpar os campos
      setCaixaLivre(true);

    }
    else if (e.key === "F4") {

      console.log('F4 - salvar venda');

      if (fildId > 0) {
        saveRegistro();

      }
      //lembrar de limpar os campos
      limparVenda();
      setCaixaLivre(true);

    }
    else if (e.key === "F9") {

      EfetuarPagamento();
    }
    else if ((e.ctrlKey) && charCode === 'q') {

      handleOpenModalQuantidade();

    }

  }

  function Produto_keyPress(e) {

    if (e.keyCode === 13) {

      if (e.target.value !== "") {
        pesquisarProdutos_Codigo(e.target.value);

      }
      //console.log('value', e.target.value);
      // put the login here
    }
  }

  function Quantidade_keyPress(e) {

    if (e.keyCode === 13) {

      if (e.target.value !== "") {
        setFildQuantidade(e.target.value);

        handleConfirmarQuantidade();

      }
    }
  }

  async function dadosCompleto(id) {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPdv.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'pdv-completo',
        senha: senhaRetorno,
        login: usuarioRetorno,
        id: id
      })
    });

    let json = await response.json();


    if (json.success) {
      if (json.result === null) {

      } else {

        setFildEmissao(json.venda[0].data_emissao);
        setFildId_nfce_movimento(json.venda[0].id_nfce_movimento);
        setFildVendedor(json.venda[0].vendedor);
        setFildDocumento(json.venda[0].documento);
        setFildData_Emissao(json.venda[0].data_emissao);
        setFildHora_Emissao(json.venda[0].hora_emissao);
        //setFildValor_total_produtos(converterParaFloat(json.venda[0].valor_total_produtos).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildValor_desconto(converterParaFloat(json.venda[0].valor_desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildValor_total(converterParaFloat(json.venda[0].valor_total).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildStatus_nota(json.venda[0].status_nota);
        setFildTroco(converterParaFloat(json.venda[0].troco).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildCliente(json.venda[0].cliente);
        setFildNome(json.venda[0].nome);
        setFildCpfcnpj(json.venda[0].cpfcnpj);
        setFildId_operador(json.venda[0].id_operador);
        setFildDesconto(converterParaFloat(json.venda[0].desconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildTaxadesconto(converterParaFloat(json.venda[0].taxadesconto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildValoracrescimo(converterParaFloat(json.venda[0].valoracrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setFildTaxaacrescimo(converterParaFloat(json.venda[0].taxaacrescimo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
        setTipoDesconto(json.venda[0].tipodesconto);

        setListaItens(json.itens);
        setListaPagamentos(json.financeiro);

        setExibirProgress(false);
        setCaixaLivre(false);

      }
    }
    else {
      //setListaDados([]);
    }

    setExibirProgress(false);
  }

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const BlocoValores = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleValorDesconto = (valor) => {
    setFildDesconto(valor);
  };


  const stylePesquisaProduto = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  }

  const styleDesconto = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,

  }

  function validarDesconto() {

    let liberado = false;

    //Desconto no TOTAL da Venda
    if (tipoDesconto === "P") {
      //TOTAL DA VENDA

      if (opcaoDesconto[1]) {

        let conversao = (parseFloat(fildDesconto) * 1);
        let v_total = (parseFloat(fildValor_total) * 1);

        if (conversao <= v_total) {

          liberado = true;

        } else { setFildDesconto(0); }

      } else {

        //Desconto em %
        let _vTotal = 0;

        if (fildValor_total !== "") {
          _vTotal = parseFloat(fildValor_total);
        }

        let conversao = (parseFloat(fildDesconto) / 100) * (_vTotal);
        let v_total = parseFloat(fildValor_total);

        if (conversao <= v_total) {

          liberado = true;

        } else { setFildDesconto(0); }

        setFildDesconto(conversao);
        setOpcaoDesconto([false, true]);
      }

      handleConfirmarDesconto();

      if (!liberado) {

        setTipoMensagem('error');
        setTextoMensagem('Desconto não pode ser maior que o valor total!');
        handleOpenMessage();

        return;
      }

      saveRegistro();

    } else if (tipoDesconto === "I") {
      //DESCONTO POR ITEM

      descontoNoItem(fildItemDesconto);

    }

  }

  // MODAL

  function modalPesquisaVendaPdv() {
    return (
      <Modal
        open={openPesquisarVendas}
        onClose={handleCloseModalPesquisarVendas}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylePesquisaProduto}>

          <Typography style={{ marginLeft: 5 }}>PESQUISAR VENDA</Typography>

          <Grid item xs={12} md={2} >
            <Card variant="outlined">
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 0 }, }}
                noValidate
                autoComplete="off"
              >
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfModalPesquisaVenda">Pesquisar </InputLabel>
                  <FilledInput
                    id="tfModalPesquisaVenda"
                    autoFocus={true}
                    variant="filled"
                    //value={valorPesquisa}
                    // defaultValue={''}
                    onChange={(e) => handleChangePesquisarVenda(e.target.value)}
                    sx={{ m: 1 }}
                  />
                </FormControl>

                {exibirProgress && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                )}

                <Paper className="container">
                  <TableContainer>
                    <Table aria-label="custom pagination table">
                      <TableHead>
                        <TableRow>

                          {/* {(isMobile) ? columnsSearchCliente.splice(1, 2) : null} */}

                          {columnsSearchVenda.map((column) => (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}

                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {(rowsPerPageVendas > 0
                          ? listaVendas.slice(pageVendas * rowsPerPageVendas, pageVendas * rowsPerPageVendas + rowsPerPageVendas)
                          : listaVendas
                        ).map((row) => (
                          <TableRow key={row.id}>

                            <TableCell
                              // style={{ width: "25%" }}
                              align="left">
                              {row.data_emissao}
                            </TableCell>

                            <TableCell
                              // style={{ width: "25%" }}
                              align="left">
                              {row.documento}
                            </TableCell>

                            <TableCell
                              // style={{ width: "25%" }}
                              align="left">
                              {row.nome}
                            </TableCell>

                            <TableCell
                              // style={{ width: "25%" }}
                              align="left">
                              {row.valor_total}
                            </TableCell>

                            <TableCell align="center">
                              <Button
                                size="small"
                                color={"success"}
                                onClick={() => prencheDadosVendaPdv(row)}
                              >
                                <CheckIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}

                        {emptyRowsVendas > 0 && (
                          <TableRow style={{ height: 33 * emptyRowsVendas }}>
                            <TableCell colSpan={3} />
                          </TableRow>
                        )}
                      </TableBody>

                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={listaVendas.length}
                            rowsPerPage={rowsPerPageVendas}
                            page={pageVendas}

                            onPageChange={handleChangePageVendas}
                            onRowsPerPageChange={handleChangeRowsPerPageVendas}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Card>
          </Grid>


          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalPesquisarVendas}>Voltar
          </Button>

        </Box>
      </Modal>
    )
  }

  // function modalPesquisaCliente() {
  //   return (
  //     <Modal
  //       open={openPesquisarCliente}
  //       onClose={handleCloseModalPesquisarCliente}
  //       aria-labelledby="modal-modal-title"
  //       aria-describedby="modal-modal-description"
  //     >
  //       <Box sx={stylePesquisaProduto}>

  //         <Typography style={{ marginLeft: 5 }}>PESQUISAR CLIENTE</Typography>

  //         <Grid item xs={12} md={2} >
  //           <Card variant="outlined">
  //             <Box
  //               component="form"
  //               sx={{ '& .MuiTextField-root': { m: 0 }, }}
  //               noValidate
  //               autoComplete="off"
  //             >
  //               <FormControl fullWidth variant="filled">
  //                 <InputLabel htmlFor="tfModalPesquisaCliente">Pesquisar </InputLabel>
  //                 <FilledInput
  //                   id="tfModalPesquisaCliente"
  //                   autoFocus={true}
  //                   variant="filled"
  //                   //value={valorPesquisa}
  //                   // defaultValue={valorPesquisa}
  //                   onChange={(e) => handleChangePesquisarCliente(e.target.value)}
  //                   sx={{ m: 1 }}
  //                 />
  //               </FormControl>

  //               {exibirProgress && (
  //                 <Box sx={{ width: '100%' }}>
  //                   <LinearProgress />
  //                 </Box>
  //               )}

  //               <Paper className="container">
  //                 <TableContainer>
  //                   <Table aria-label="custom pagination table">
  //                     <TableHead>
  //                       <TableRow>

  //                         {(isMobile) ? columnsSearchCliente.splice(1, 2) : null}

  //                         {columnsSearchCliente.map((column) => (
  //                           <StyledTableCell
  //                             key={column.id}
  //                             align={column.align}
  //                             style={{ minWidth: column.minWidth }}
  //                           >
  //                             {column.label}

  //                           </StyledTableCell>
  //                         ))}
  //                       </TableRow>
  //                     </TableHead>

  //                     <TableBody>
  //                       {(rowsPerPageCliente > 0
  //                         ? listaClientes.slice(pageCliente * rowsPerPageCliente, pageCliente * rowsPerPageCliente + rowsPerPageCliente)
  //                         : listaClientes
  //                       ).map((row) => (
  //                         <TableRow key={row.id}>

  //                           <TableCell
  //                             // style={{ width: "25%" }}
  //                             align="left">
  //                             {row.fantasia}
  //                           </TableCell>


  //                           {(!isMobile) ?
  //                             (<TableCell
  //                               align="left">
  //                               {row.cnpj}
  //                             </TableCell>) : null
  //                           }

  //                           <TableCell align="center">
  //                             <Button
  //                               size="small"
  //                               color={"success"}
  //                               onClick={() => preencherDadosCliente(row.codigo, row.razao, row.fantasia, row.cnpj, row.uf)}
  //                             >
  //                               <CheckIcon />
  //                             </Button>
  //                           </TableCell>
  //                         </TableRow>
  //                       ))}

  //                       {emptyRowsCliente > 0 && (
  //                         <TableRow style={{ height: 53 * emptyRowsCliente }}>
  //                           <TableCell colSpan={6} />
  //                         </TableRow>
  //                       )}
  //                     </TableBody>

  //                     <TableFooter>
  //                       <TableRow>
  //                         <TablePagination
  //                           rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
  //                           colSpan={3}
  //                           count={listaClientes.length}
  //                           rowsPerPage={rowsPerPageCliente}
  //                           page={pageCliente}

  //                           onPageChange={handleChangePageCliente}
  //                           onRowsPerPageChange={handleChangeRowsPerPageCliente}
  //                           ActionsComponent={TablePaginationActions}
  //                         />
  //                       </TableRow>
  //                     </TableFooter>
  //                   </Table>
  //                 </TableContainer>
  //               </Paper>

  //             </Box>
  //           </Card>
  //         </Grid>


  //         <Button variant="contained" color="error" style={{ margin: 5 }}
  //           onClick={handleCloseModalPesquisarCliente}>Voltar
  //         </Button>

  //         {/* <Button variant="contained" color="success" style={{ margin: 5 }}
  //           onClick={salvarContato}>Salvar
  //         </Button> */}

  //       </Box>
  //     </Modal>
  //   )
  // }

  function modalPesquisaProdutos() {
    return (
      <Modal
        open={openPesquisar}
        onClose={handleCloseModalPesquisar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylePesquisaProduto}>

          <Typography style={{ marginLeft: 5 }}>PESQUISAR PRODUTO</Typography>

          <Grid item xs={12} md={2} >
            <Card variant="outlined">
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 0 }, }}
                noValidate
                autoComplete="off"
              >
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfModalPesquisadescricao">Pesquisar </InputLabel>
                  <FilledInput
                    id="tfModalPesquisadescricao"
                    autoFocus={true}
                    variant="filled"
                    //value={valorPesquisa}
                    // defaultValue={valorPesquisa}
                    onChange={(e) => handleChangePesquisarProdutos(e.target.value)}
                    sx={{ m: 1 }}
                  />
                </FormControl>

                {exibirProgressProdutos && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                )}

                <Paper className="container">
                  <TableContainer>
                    <Table aria-label="custom pagination table">
                      <TableHead>
                        <TableRow>

                          {(isMobile) ? columnsSearch.splice(0, 1) : null}

                          {columnsSearch.map((column) => (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {(rowsPerPageProdutos > 0
                          ? listaProdutos.slice(pageProdutos * rowsPerPageProdutos, pageProdutos * rowsPerPageProdutos + rowsPerPageProdutos)
                          : listaProdutos
                        ).map((row) => (
                          <TableRow key={row.id}>

                            {(!isMobile) ?
                              (<TableCell
                                align="left">
                                {row.produto}
                              </TableCell>) : null
                            }

                            <TableCell
                              // style={{ width: "25%" }}
                              align="left">
                              {row.descricao}
                            </TableCell>

                            <TableCell align="center">
                              <Button
                                size="small"
                                color={"success"}
                                onClick={
                                  () => preencherDadosProduto(
                                    row.id,
                                    row.produto,
                                    row.descricao,
                                    row.precovenda,
                                    row.unidade,
                                    row.cbarra)}
                              >
                                <CheckIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}

                        {emptyRowsProdutos > 0 && (
                          <TableRow style={{ height: 53 * emptyRowsProdutos }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={listaProdutos.length}
                            rowsPerPage={rowsPerPageProdutos}
                            page={pageProdutos}

                            onPageChange={handleChangePageProdutos}
                            onRowsPerPageChange={handleChangeRowsPerPageProdutos}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Card>
          </Grid>


          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalPesquisar}>Voltar
          </Button>

          {/* <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarContato}>Salvar
          </Button> */}

        </Box>
      </Modal>
    )
  }

  function modalQuantidade() {
    return (
      <Modal
        open={openQuantidade}
        onClose={handleCloseModalQuantidade}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={styleDesconto}>

          <Typography style={{ marginLeft: 5 }}>Quantidade</Typography>

          <Grid item xs={12} >
            <Card variant="outlined">
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1 }, }}
                noValidate
                autoComplete="off"
              >

                <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                  <InputLabel htmlFor="tfQuantidadeModal">Quantidade</InputLabel>
                  <FilledInput
                    // type="number"
                    id="tfQuantidadeModal"
                    autoFocus={true}
                    value={fildQuantidade}
                    defaultValue={fildQuantidade}
                    onKeyDown={Quantidade_keyPress}
                    onChange={(event) => setFildQuantidade(formatarMoeda(event.target.value))}
                  />
                </FormControl>

              </Box>
            </Card>
          </Grid>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalQuantidade}>Voltar
          </Button>
          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={handleConfirmarQuantidade}>Confirmar
          </Button>

        </Box>
      </Modal>
    )
  }

  function exibirItemParaDesconto() {
    return (
      <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
        <InputLabel htmlFor="tfItemDesconto">Item</InputLabel>
        <FilledInput
          type="number"
          id="tfItemDesconto"
          value={fildItemDesconto}
          defaultValue={fildItemDesconto}
          onChange={(event) => setFildItemDesconto(event.target.value)}
        />
      </FormControl>
    )
  }

  function modalDesconto() {
    return (
      <Modal
        open={openDesconto}
        onClose={handleCloseModalDesconto}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDesconto}>

          <Typography style={{ marginLeft: 5 }}>DESCONTO</Typography>

          <Grid item xs={12} >
            <Card variant="outlined">
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1 }, }}
                noValidate
                autoComplete="off"
              >
                <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                  <TextField
                    id="tfModoDesconto"
                    select
                    label="Modo Desconto"
                    // sx={{ width: '25ch' }}
                    value={tipoDesconto}
                    onChange={handleChangeMdoDesconto}
                    variant="filled"
                  >
                    {modoDesconto.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <div>

                  <FormControlLabel
                    sx={{ m: 0.4 }}
                    style={{ marginTop: 12 }}
                    label="%"
                    control={<Checkbox checked={opcaoDesconto[0]} onChange={handleChangeDescontoPercentual} />}
                  />

                  <FormControlLabel
                    style={{ marginTop: 12 }}
                    label="R$"
                    control={<Checkbox checked={opcaoDesconto[1]} onChange={handleChangeDescontoValor} />}
                  />

                </div>

                {(visualizarItemNoDesconto) ? exibirItemParaDesconto() : null}

                <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                  {/* <InputLabel htmlFor="tfDesconto">Desconto</InputLabel> */}
                  <FilledInput
                    // type="number"
                    id="tfDescontoModal"
                    autoFocus={true}
                    value={fildDesconto}
                    defaultValue={fildDesconto}

                    onChange={(event) => setFildDesconto(formatarMoeda(event.target.value))}
                  />
                </FormControl>

              </Box>
            </Card>
          </Grid>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalDesconto}>Voltar
          </Button>
          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={validarDesconto}>Confirmar
          </Button>

        </Box>
      </Modal>
    )
  }

  function modalPagamento() {
    return (
      <Modal
        open={openPagamento}
        onClose={handleCloseModalPagamento}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '35%',
          bgcolor: 'background.paper',
          border: '0px solid #000',
          boxShadow: 24,
          p: 1,
        }}>
          <Grid container spacing={0}>

            <Box sx={{
              m: 1, width: '40ch',
              display: 'flex',
              flexWrap: 'wrap'
            }}>

              <Paper elevation={0} >

                <Typography style={{ m: 5 }}>PAGAMENTO</Typography>

                <FormControl fullWidth variant="filled" sx={{ minWidth: 120, m: 1 }}>
                  <InputLabel id="tfRecebimento">Descrição do pagamento</InputLabel>
                  <Select
                    labelId="tfRecebimento"
                    id="tfRecebimento"
                    value={recebimentoSelecionado}
                    onChange={handleChangeRecebimento}
                  >
                    {lsRecebimento.map((option, index) => (
                      <MenuItem key={index} value={option.codigo}>
                        {option.descricao}
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>

                <FormControl fullWidth variant="filled" sx={{ minWidth: 120, m: 1 }}>
                  <InputLabel htmlFor="tfValorPagamento">Valor</InputLabel>
                  <FilledInput
                    id="tfValorPagamento"
                    autoFocus={true}
                    variant="filled"
                    value={valorPagamento}
                    defaultValue={''}
                    onChange={(e) => setValorPagamento(e.target.value)}

                  />
                </FormControl>

                <Button fullWidth variant="contained" color="success"
                  onClick={handleDefinirPagamento} >
                  Confirmar
                </Button>

              </Paper >

              <Paper elevation={0} >

                <Paper className="container" width={'50ch'}>
                  <TableContainer>
                    <Table aria-label="custom pagination table" size={'small'}>

                      <TableBody>
                        {(rowsPerPagePagamento > 0
                          ? listaPagamentos.slice(pagePagamento * rowsPerPagePagamento, pagePagamento * rowsPerPagePagamento + rowsPerPagePagamento)
                          : listaPagamentos
                        ).map((row, index) => (
                          <TableRow key={index} >

                            <TableCell
                              align="left">
                              {row.recebimento}
                            </TableCell>

                            <TableCell
                              align="left">
                              {row.valor}
                            </TableCell>

                            <TableCell align="center">

                              <IconButton size="small" sx={{ m: 0.1 }}
                              // onClick={() => removeRegistro({ index }, row.id)}
                              >
                                <DeleteRoundedIcon fontSize="inherit" color={"error"} />
                              </IconButton>

                            </TableCell>
                          </TableRow>
                        ))}

                        {emptyRowsPagamento > 0 && (
                          <TableRow style={{ height: 33 * emptyRowsPagamento }}>
                            <TableCell colSpan={3} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Paper >
            </Box>

            <Box sx={{
              m: 1, width: '35ch',
              display: 'flex',
              flexWrap: 'wrap'
            }}>

              <Paper elevation={3} >
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfModalTotalVenda">Total venda</InputLabel>
                  <FilledInput
                    id="tfModalTotalVenda"
                    // autoFocus={true}
                    variant="filled"
                    value={formatarMoeda(fildValor_total)}
                    defaultValue={'0,00'}

                    sx={{ m: 1 }}
                  />
                </FormControl>

                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfModalAcrescimo">Acréscimo</InputLabel>
                  <FilledInput
                    id="tfModalAcrescimo"
                    autoFocus={true}
                    variant="filled"
                    //value={valorPesquisa}
                    defaultValue={'0,00'}

                    sx={{ m: 1 }}
                  />
                </FormControl>

                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfModalDesconto">Desconto</InputLabel>
                  <FilledInput
                    id="tfModalDesconto"
                    // autoFocus={true}
                    variant="filled"
                    value={formatarMoeda(fildDesconto)}
                    defaultValue={'0,00'}

                    sx={{ m: 1 }}
                  />
                </FormControl>

                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfModalValorRecebido">Total recebido</InputLabel>
                  <FilledInput
                    id="tfModalValorRecebido"
                    // autoFocus={true}
                    variant="filled"
                    value={valorrecebido}
                    defaultValue={'0,00'}

                    sx={{ m: 1 }}
                  />
                </FormControl>

                <Box sx={{ m: 1 }} >
                  <Typography variant="h5" color="red">
                    Troco :{valorTroco}
                  </Typography>
                </Box>
              </Paper>
            </Box>

          </Grid>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalPagamento}>Voltar
          </Button>

          <Button variant="contained" color="primary" style={{ margin: 5 }}
            onClick={finalizarVenda}>Finalizar
          </Button>

        </Box>
      </Modal>
    )
  }

  function modalMovimento() {
    return (
      <Modal
        open={openMovimento}
        onClose={handleCloseModalMovimento}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDesconto}>

          <Typography style={{ marginLeft: 5 }}>ABRIR MOVIMENTO</Typography>

          <Grid item xs={12} >
            <Card variant="outlined">
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1 }, }}
                noValidate
                autoComplete="off"
              >
                <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                  <InputLabel htmlFor="tfValorInicial">Valor Inicial</InputLabel>
                  <FilledInput
                    type="number"
                    id="tfValorInicial"
                    value={tipoDesconto}
                    onChange={handleChangeMdoDesconto}
                    variant="filled"
                  >
                  </FilledInput>
                </FormControl>

                <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                  <InputLabel htmlFor="tfLogin">Login</InputLabel>
                  <FilledInput
                    // type="number"
                    id="tfLogin"
                    autoFocus={true}
                    value={fildDesconto}
                    defaultValue={fildDesconto}
                    onChange={(event) => setFildDesconto(formatarMoeda(event.target.value))}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                  <InputLabel htmlFor="tfSenha">Senha</InputLabel>
                  <FilledInput
                    // type="number"
                    id="tfSenha"
                    autoFocus={true}
                    value={fildDesconto}
                    defaultValue={fildDesconto}

                    onChange={(event) => setFildDesconto(formatarMoeda(event.target.value))}
                  />
                </FormControl>

              </Box>
            </Card>
          </Grid>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalDesconto}>Voltar
          </Button>
          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={validarDesconto}>Confirmar
          </Button>

        </Box>
      </Modal>
    )
  }

  function modalUtilitarios() {

  }


  // FIM MODAL

  function awaiteSend() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={impedirMultClick}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  function botaoVoltar() {
    props.history.push('/app/PedidoVenda');
  }

  function botaoNovo() {

    // setFildId('0');
    // setFildEmissao(new Date());
    // setFildDocumento('');
    // setFildCliente('');
    // setFildFantasia('');
    // setFildRazao('');
    // setFildCnpj('');
    // setFildUf('');
    // setFildObservacao('');
    // setFildRecebimento('');
    // setFildVendedor1('');
    // setFildComissao1('0,00');
    // setFildVendedor2('');
    // setFildComissao2('0,00');
    // setFildCfop('');
    // setFildAcrescimo('0,00');
    // setFildDesconto('');
    // setFildEmpresa('');
    // setFildTipodesconto('');
    // setFildMododesconto('');
    // setFildStatus('');
    // setFildOrdemCompra('');
    // setFildFrete('9');
    // setFildFretevalor('0,00');
    // setFildDataCadastro('');
    // setFildDataAlteracao('');
    // setFildResponsavel('');
    // setFildModBc('');
    // setFildTotalPBruto('0,00');
    // setFildTotalPLiquido('0,00');

    // setFildBasecalculoicms('0,00');
    // setFildValoricms('0,00');
    // setFildBasecalculoicmsst('0,00');
    // setFildValoricmsst('0,00');
    // setFildBasecalculoipi('0,00');
    // setFildValoripi('0,00');
    // setFildTotalprodutos('0,00');
    // setFildTotal('0,00');

    // setFildTransportador('');
    // setFildRazaoTransportador('');
    // setFildFantasiaTransportador('');
    // setFildCnpjTransportador('');

    setListaItens([]);

    handleChangePedidoCancelado(false);
  }

  async function cancelRegistro() {

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiPreVenda.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'cancelar-pedido',
        senha: senhaRetorno,
        login: usuarioRetorno,
        id: fildId,
        documento: fildDocumento,

      })
    });

    let json = await response.json();

    if (json.success) {

      setTipoMensagem('success');
      setTextoMensagem('Cancelamento realizado com sucesso!');
      handleOpenMessage();

      dadosCompleto(fildId);

    }

  }

  function ImprimirPedido() {

    PrintSalesOrder(dadosCabecalho, dadosCliente, listaItens);
  }

  function menuPdv() {
    return (
      <Paper sx={{ m: 1, width: 320, maxWidth: '100%' }}>
        <MenuList>
          <MenuItem
            id="menu_novo"
            onClick={RegistroNovo}
          >
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Novo</ListItemText>
            <Typography variant="body2" color="text.secondary">
              F2
            </Typography>
          </MenuItem>

          <MenuItem
            id="menu_cancelar"
            onClick={RegistroCancelar}
          >
            <ListItemIcon>
              <CloseIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Cancelar</ListItemText>
            <Typography variant="body2" color="text.secondary">
              F3
            </Typography>
          </MenuItem>

          <MenuItem
            id="menu_salvar"
            onClick={RegistroSalvar}
          >
            <ListItemIcon>
              <CheckIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Salvar</ListItemText>
            <Typography variant="body2" color="text.secondary">
              F4
            </Typography>
          </MenuItem>

          <MenuItem
            onClick={handleOpenModalPesquisarVendas}
            id="menu_consultar" >
            <ListItemIcon>
              <SearchIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Consultar</ListItemText>
            <Typography variant="body2" color="text.secondary">

            </Typography>
          </MenuItem>

          <MenuItem id="menu_nfce">
            <ListItemIcon>
              <ReceiptLongIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>NFC-e</ListItemText>
            <Typography variant="body2" color="text.secondary">
              F8
            </Typography>
          </MenuItem>

          <MenuItem
            id="menu_pagamento"
            onClick={EfetuarPagamento}>
            <ListItemIcon>
              <PaymentsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Pagamento</ListItemText>
            <Typography variant="body2" color="text.secondary">
              F9
            </Typography>
          </MenuItem>

          <Divider />
          <MenuItem id="menu_utilitarios">
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Utilitários</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    )
  }

  function telaCaixaLivre() {
    return (

      <Box sx={{ m: 1, width: '100%', display: 'flex', flexWrap: 'wrap' }}>

        {menuPdv()}

        <Typography variant="h1" sx={{ m: 10 }}>
          CAIXA LIVRE
        </Typography>
      </Box>

    )

  }

  const [expanded, setExpanded] = React.useState('panel1');

  function telaCelular() {

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
      <div>
        {/* Apos enviar solicitação aguardar o retorno para liberar a manipulação */}
        {awaiteSend()}

        <PesquisasClientes
        openPesquisarCliente={openPesquisarCliente}
        handleOpenModalPesquisarCliente ={handleOpenModalPesquisarCliente}
        handleCloseModalPesquisarCliente={handleCloseModalPesquisarCliente}
        preencherDadosCliente={preencherDadosCliente}
        />

        {modalPesquisaProdutos()}

        <Desconto
          openDesconto={openDesconto}
          handleCloseModalDesconto={handleCloseModalDesconto}
          tipoDesconto={tipoDesconto}
          handleChangeMdoDesconto={handleChangeMdoDesconto}
          selecao_percentual={opcaoDesconto[0]}
          selecao_valor={opcaoDesconto[1]}
          visualizarItemNoDesconto={visualizarItemNoDesconto}
          fildDesconto={fildDesconto}
          handleValorDesconto={handleValorDesconto}
          validarDesconto={validarDesconto}
          exibirItemParaDesconto={exibirItemParaDesconto}
        />


        <PageTitle title={"Pedido - " + fildDocumento}
          EnableDesableButton={registroCancelado}

          buttonReturn="Voltar" eventoRetornar={botaoVoltar}

          buttonCancel="Cancelar" evendoCancelar={cancelRegistro}

          buttonNew="Novo" eventoNovo={botaoNovo}

          button="Salvar" eventoBotao={saveRegistro}

          buttonPrint="Imprimir" eventoImprimir={ImprimirPedido}

        />

        {pedidoCancelado ? <Typography style={{ marginLeft: 5, color: 'red' }}>CANCELADO</Typography> : null}

        <Snackbar open={openMessage} autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseMessage}>
          <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
            {textoMesagem}
          </Alert>
        </Snackbar>

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Pedido</Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Grid >
              {/* <IconButton
                size="large"
                onClick={handleOpenModalPesquisarCliente} >
                <SearchIcon fontSize="inherit" color="primary" />
              </IconButton> */}

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfFantasia"
                >Nome Fantasia</InputLabel>
                <Input
                  id="tfFantasia"
                  value={''}
                  defaultValue={'fildFantasia'}
                  readOnly={true}
                  //onChange={(event) => setFildFantasia(event.target.value)}

                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        // aria-label="toggle password visibility"
                        onClick={(!pedidoCancelado) ? handleOpenModalPesquisarCliente : null}

                      >
                        <SearchIcon color="primary" />
                      </IconButton>
                    </InputAdornment>}

                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfCnpj">CNPJ</InputLabel>
                <FilledInput
                  id="tfCnpj"
                  // value={fildCnpj}
                  // defaultValue={fildCnpj}
                  readOnly={true}
                // onChange={(event) => setFildCnpj(event.target.value)}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    noValidate={false}
                    id="tfDataEmissao"
                    label="Emissão"
                    inputFormat="DD/MM/YYYY"
                    value={fildEmissao}
                    onChange={handleChangeEmissao}
                    renderInput={(params) => <TextField sx={{ m: 0 }} variant="filled" {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>


              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                <InputLabel htmlFor="tfDesconto">Desconto</InputLabel>
                <FilledInput
                  // type="number"
                  id="tfDesconto"
                  value={formatarMoeda(fildDesconto)}
                  defaultValue={fildDesconto}
                  readOnly={true}
                  onChange={(event) => setFildDesconto(event.target.value)}
                  // startAdornment={<InputAdornment position="start">{indicadorDesconto}</InputAdornment>}

                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        // aria-label="toggle password visibility"
                        onClick={(!listaItens.count > 0) ? handleOpenModalDesconto : null}
                      >
                        <AddCircleRoundedIcon color="error" />
                      </IconButton>
                    </InputAdornment>}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.4 }} variant="filled">
                {exibirProgressVendedores && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}
                <TextField
                  id="tfVendedor1"
                  select
                  label="Vendedor 1"
                  // value={fildVendedor1}
                  onChange={handleChangeVendedor}
                  variant="filled"
                >
                  {lsVendedor.map((option) => (
                    <MenuItem key={option.vendedor} value={option.vendedor}>
                      {option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

            </Grid>
            <Typography style={{ marginLeft: 5 }}>Transportador e Frete</Typography>
            {/* <Card variant="outlined" > */}

            {/* TRANSPORTADOR */}
            <Grid item xs={12} >

              {/* <IconButton size="large" sx={{ m: 1 }}>
                <SearchIcon fontSize="inherit" color="primary"
                  onClick={handleOpenModalPesquisarTransportador} />
              </IconButton> */}

              <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                <InputLabel htmlFor="tfCnpj">CNPJ</InputLabel>
                <FilledInput
                  id="tfCnpj"
                  // value={fildCnpjTransportador}
                  // defaultValue={fildCnpjTransportador}
                  readOnly={true}
                // onChange={(event) => setFildCnpjTransportador(event.target.value)}
                />
              </FormControl>

              <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                <InputLabel htmlFor="tfValorFrete">Frete Valor</InputLabel>

                <FilledInput
                  id="tfValorFrete"
                  // type="number"
                  // value={fildFretevalor}
                  // defaultValue={fildFretevalor}
                  // onChange={(event) => setFildFretevalor(formatarValores(event.target.value))}
                  startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                />
              </FormControl>

              {/* TOTAIS */}

            </Grid>
            {/* </Card> */}

            <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfObservacao">Observação</InputLabel>
              <FilledInput
                id="tfObservacao"
                // value={fildObservacao}
                // defaultValue={fildObservacao}
                inputProps={{ maxLength: 255 }}

              // onChange={(event) => setFildObservacao(event.target.value)}
              />
            </FormControl>

          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Itens</Typography>
          </AccordionSummary>
          <AccordionDetails>

            {/* PRODUTO */}
            <Grid item xs={12} md={12} >

              <Card item variant="outlined" sx={{ flexGrow: 1 }}>
                <Typography sx={{ m: 1 }} >Produto(s)</Typography>

                <Grid item xs={12} md={12} sx={{ m: 1 }}>

                  {/* <IconButton size="large" sx={{ m: 1 }}
                    onClick={handleOpenModalPesquisar}
                  >
                    <SearchIcon fontSize="inherit" color="primary" />

                  </IconButton> */}

                  <FormControl sx={{ m: 0.6, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfDescricao">Descrição</InputLabel>
                    <FilledInput
                      id="tfDescricao"
                      value={fildDescricao}
                      defaultValue={fildDescricao}
                      readOnly={true}
                      onChange={(event) => setFildDescricao(event.target.value)}
                      error={validaProduto}

                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={(!pedidoCancelado) ? handleOpenModalPesquisar : null}
                          >
                            <SearchIcon color="primary" />
                          </IconButton>
                        </InputAdornment>}
                    />
                    <FormHelperText error id="component-error-text">{msgErroProduto}</FormHelperText>
                  </FormControl>

                  <FormControl sx={{ m: 0.6, width: '12ch' }} variant="filled">
                    <InputLabel htmlFor="tfQuantidade">Qtde.</InputLabel>
                    <FilledInput
                      // ref={fildQuantidadeRef}
                      id="tfQuantidade"
                      // type="number"
                      value={fildQuantidade}
                      defaultValue={fildQuantidade}
                      onChange={(event) => setFildQuantidade(event.target.value)}
                      error={validaQuantidade}
                    />
                    <FormHelperText error id="component-error-text">{msgErroQuantidade}</FormHelperText>
                  </FormControl>

                  <FormControl sx={{ m: 0.6, width: '16ch' }} variant="filled">
                    <InputLabel htmlFor="tfPreco">Valor Unitário</InputLabel>
                    <FilledInput
                      id="tfPreco"
                      // type="number"
                      value={fildPreco}
                      defaultValue={fildPreco}
                      startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      onChange={(event) => setFildPreco(formatarMoeda(event.target.value))}
                      error={validaPreco}
                    />
                    <FormHelperText error id="component-error-text">{msgErroPreco}</FormHelperText>
                  </FormControl>

                  <Button
                    color={"success"}
                    size="small"
                    className="px-2"
                    variant="contained"
                    onClick={() => mantarRegistro()}
                  >
                    <CheckIcon />Incluir
                  </Button>

                </Grid>

              </Card>

            </Grid>

            <TableContainer>
              <Table>
                {/* <Table sx={{ minWidth: 500 }} aria-label="custom pagination table"> */}
                <TableHead>
                  <TableRow>
                    {columnsMobile.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(rowsPerPage > 0
                    ? listaItens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).reverse()
                    : listaItens
                  ).map((row, index) => (
                    <TableRow key={index} >
                      <TableCell
                        align="center">
                        <TextField
                          fullWidth
                          id="tf_DescricaoProduto"
                          multiline
                          variant="standard"
                          // style={{ width: '100%' }}
                          value={row.descricao}
                          defaultValue={row.descricao}
                          readOnly={false}
                        // onChange={e => changeItemDescricao(e, index)}
                        />

                        <FormControl sx={{ m: 0.1, width: '17ch' }} variant="standard">
                          <InputLabel htmlFor={"tf_QuantidadeProduto" + index}>Qtd.</InputLabel>
                          <FilledInput
                            id={"tf_QuantidadeProduto" + index}
                            readOnly={true}
                            value={formatarMoeda(row.quantidade)}
                            defaultValue={row.quantidade}
                            onChange={e => changeItemQuantidade(e, index)}
                          />
                        </FormControl>

                        <FormControl sx={{ m: 0.1, width: '17ch' }} variant="standard">
                          <InputLabel htmlFor={"tf_AtendidoProduto" + index}>Atend.</InputLabel>
                          <FilledInput
                            id={"tf_AtendidoProduto" + index}
                            readOnly={false}
                            value={row.atendido}
                            defaultValue={row.atendido}
                            onChange={e => changeItemAtendido(e, index)}
                          />
                        </FormControl>

                        <FormControl sx={{ m: 0.1, width: '17ch' }} variant="standard">
                          <InputLabel htmlFor={"tfPreco" + index}>Preço</InputLabel>
                          <FilledInput
                            id={"tfPreco" + index}
                            readOnly={true}
                            value={formatarMoeda(row.preco)}
                            defaultValue={row.preco}
                          />
                        </FormControl>

                        <FormControl sx={{ m: 0.1, width: '17ch' }} variant="standard">
                          <InputLabel htmlFor={"tfTotal" + index}>Total</InputLabel>
                          <FilledInput
                            id={"tfTotal" + index}
                            readOnly={true}
                            value={formatarValoresPtBr(row.total)}
                            defaultValue={row.total}
                          />
                        </FormControl>

                        {/* <IconButton size="small" sx={{ marginRight: 2 }}
                          onClick={() => saveRegistro()}>
                          <CheckIcon fontSize="inherit" color={"success"} />
                        </IconButton> */}

                        <IconButton size="small" sx={{ m: 0.1 }}
                          onClick={() => removeRegistro({ index }, row.id)}>
                          <DeleteRoundedIcon fontSize="inherit" color={"error"} />
                        </IconButton>

                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>

                {/* <TableFooter> */}
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={listaItens.length}
                    rowsPerPage={rowsPerPage}
                    page={page}

                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Linhas por página',

                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
                {/* </TableFooter> */}

              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

      </div>
    );

  }

  function telaPadrao() {
    return (
      <>
        {/* Apos enviar solicitação aguardar o retorno para liberar a manipulação */}
        {awaiteSend()}

        {/* {modalPesquisaCliente()} */}

        <PesquisasClientes
        openPesquisarCliente={openPesquisarCliente}
        handleOpenModalPesquisarCliente ={handleOpenModalPesquisarCliente}
        handleCloseModalPesquisarCliente={handleCloseModalPesquisarCliente}
        preencherDadosCliente={preencherDadosCliente}
        />

        {modalPesquisaProdutos()}

        {modalPesquisaVendaPdv()}

        <Desconto
          openDesconto={openDesconto}
          handleCloseModalDesconto={handleCloseModalDesconto}
          tipoDesconto={tipoDesconto}
          handleChangeMdoDesconto={handleChangeMdoDesconto}
          opcaoDesconto={opcaoDesconto}
          visualizarItemNoDesconto={visualizarItemNoDesconto}
          fildDesconto={fildDesconto}
          handleValorDesconto={handleValorDesconto}
          validarDesconto={validarDesconto}
          exibirItemParaDesconto={exibirItemParaDesconto}
          handleChangeDescontoValor={handleChangeDescontoValor}
          handleChangeDescontoPercentual={handleChangeDescontoPercentual}
        />

        {modalPagamento()}

        {modalQuantidade()}

        {modalMovimento()}

        <PageTitle title={"PDV " + fildDocumento} />

        {pedidoCancelado ? <Typography style={{ marginLeft: 5, color: 'red' }}>CANCELADO</Typography> : null}

        <Snackbar open={openMessage} autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseMessage}>
          <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
            {textoMesagem}
          </Alert>
        </Snackbar>

        {/* TELA DE CAIXA LIVRE */}

        {(caixaLivre) ?
          (telaCaixaLivre()) : (
            <Grid container spacing={0}>

              {exibirProgress && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}

              {/* MENU PDV */}
              {menuPdv()}

              <Box sx={{
                m: 1, width: '100ch',
                display: 'flex',
                flexWrap: 'wrap'
              }}>

                <Card variant="outlined" sx={{ flexGrow: 1 }}>

                  <FormControl fullWidth sx={{ m: 0.6, width: '45ch' }} variant="filled">
                    <InputLabel htmlFor="tfFantasia">Nome</InputLabel>
                    <FilledInput
                      id="tfFantasia"
                      value={fildNome}
                      defaultValue={fildNome}
                      onChange={(event) => setFildNome(event.target.value)}
                      inputProps={{ style: { fontSize: 14, textAlign: 'center' } }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                           onClick={(!pedidoCancelado) ? handleOpenModalPesquisarCliente : null}
                          >
                            <SearchIcon color="primary" />
                          </IconButton>
                        </InputAdornment>
                      }

                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ m: 0.6, width: '20ch' }} variant="filled">
                    <InputLabel htmlFor="tfCnpj">CPF / CNPJ</InputLabel>
                    <FilledInput
                      id="tfCnpj"
                      value={fildCpfcnpj}
                      defaultValue={fildCpfcnpj}
                      inputProps={{ style: { fontSize: 14, textAlign: 'center', maxLength: 14 } }}
                      onChange={(event) => setFildCpfcnpj(event.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ m: 1, marginRight: 1 }} variant="filled">

                    <FilledInput
                      id="tfDescricao"
                      value={fildDescricao}
                      defaultValue={fildDescricao}
                      readOnly={true}
                      inputProps={{ style: { fontSize: 28, textAlign: 'center' } }}
                      onChange={(event) => setFildDescricao(event.target.value)}
                    />
                  </FormControl>

                  {/* <ThemeProvider theme={LocalesPtBr()}> */}
                  <Paper className="container">
                    <TableContainer>
                      <Table>
                        {/* <Table sx={{ minWidth: 500 }} aria-label="custom pagination table"> */}
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <StyledTableCell
                                key={index}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {(rowsPerPage > 0
                            ? listaItens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : listaItens
                          ).map((row, index) => (
                            <TableRow key={index}>

                              <TableCell
                                align="left">
                                {row.numero_item}
                              </TableCell>

                              <TableCell
                                align="left">
                                {row.gtin}
                              </TableCell>

                              <TableCell
                                align="left">
                                {row.descricao}
                              </TableCell>

                              <TableCell
                                align="left">
                                {row.unidade}
                              </TableCell>

                              <TableCell
                                align="left">
                                {converterParaFloat(row.quantidade).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                              </TableCell>

                              <TableCell
                                align="right">
                                {converterParaFloat(row.valor_unitario).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                              </TableCell>

                              <TableCell
                                align="right">
                                {converterParaFloat(row.valor_subtotal).toLocaleString('pt-br', { minimumFractionDigits: 2 })}

                              </TableCell>

                              <TableCell align="center">

                                <IconButton size="small" sx={{ m: 1 }}
                                  onClick={() => removeRegistro({ index }, row.id)}
                                >
                                  <DeleteRoundedIcon fontSize="inherit" color={"error"} />
                                </IconButton>

                              </TableCell>
                            </TableRow>
                          )).reverse()}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 33 * emptyRows }}>
                              <TableCell colSpan={4} />
                            </TableRow>
                          )}
                        </TableBody>

                        {/* <TableFooter> */}
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={listaItens.length}
                            rowsPerPage={rowsPerPage}
                            page={page}

                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',

                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                        {/* </TableFooter> */}

                      </Table>
                    </TableContainer>
                  </Paper>
                  {/* </ThemeProvider> */}
                </Card>

              </Box>

              <Box sx={{
                m: 1, width: '40ch',
                display: 'flex',
                flexWrap: 'wrap'
              }}>
                <Card item variant="outlined" sx={{ flexGrow: 1 }} >
                  <Typography sx={{ m: 1 }} >Produto(s)</Typography>

                  <Grid item xs={12} md={12} sx={{ m: 1 }}>

                    <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                      {/* <InputLabel htmlFor="tfProdutoCbarra">Produto</InputLabel> */}
                      <FilledInput
                        id="tfProdutoCbarra"
                        value={fildCBarra}
                        defaultValue={fildCBarra}
                        autoFocus={true}
                        onKeyDown={Produto_keyPress}
                        onChange={(event) => setFildCBarra(event.target.value)}
                        error={validaProduto}

                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              // aria-label="toggle password visibility"
                              onClick={(!pedidoCancelado) ? handleOpenModalPesquisar : null}
                            >
                              <SearchIcon color="primary" />
                            </IconButton>
                          </InputAdornment>}
                      />
                      <FormHelperText error id="component-error-text">{msgErroProduto}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                      <InputLabel htmlFor="tfUnidade">Unidade</InputLabel>
                      <FilledInput
                        id="tfUnidade"
                        readOnly={true}
                        value={fildUnidade}
                        defaultValue={fildUnidade}
                        inputProps={{ maxLength: 4 }}
                      // onChange={(event) => setFildUnidade(event.target.value)}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                      <InputLabel htmlFor="tfQuantidade">Qtde.</InputLabel>
                      <FilledInput
                        id="tfQuantidade"
                        readOnly={true}
                        // type="number"
                        value={fildQuantidade}
                        defaultValue={fildQuantidade}
                        onChange={(event) => setFildQuantidade(event.target.value)}
                        error={validaQuantidade}
                      />
                      <FormHelperText error id="component-error-text">{msgErroQuantidade}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                      <InputLabel htmlFor="tfPreco">Valor Unitário</InputLabel>
                      <FilledInput
                        id="tfPreco"
                        // type="number"
                        readOnly={true}
                        value={fildPreco}
                        defaultValue={fildPreco}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        onChange={(event) => setFildPreco(formatarMoeda(event.target.value))}
                        error={validaPreco}
                      />
                      <FormHelperText error id="component-error-text">{msgErroPreco}</FormHelperText>
                    </FormControl>

                    <Button fullWidth sx={{ marginLeft: 0.8, marginTop: 1 }}
                      color={"success"}
                      size="small"
                      className="px-2"
                      variant="contained"
                      onClick={() => mantarRegistro()}
                    >
                      <CheckIcon />Incluir
                    </Button>

                    {/* <Typography   style={{fontSize: 18,textAlign:'right',marginTop: 4 }}> */}
                    <FormControl fullWidth sx={{ m: 0.6, marginTop: 1 }} variant="filled">
                      <InputLabel htmlFor="tfSubtotal" >Subtotal</InputLabel>
                      <FilledInput
                        id="tfSubtotal"
                        readOnly={true}
                        value={fildSubtotal}
                        defaultValue={fildSubtotal}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        onChange={(event) => setFildPreco(formatarMoeda(event.target.value))}
                        inputProps={{ style: { fontSize: 18, textAlign: 'right' } }}
                      />
                    </FormControl>
                    {/* </Typography> */}

                    <FormControl fullWidth sx={{ m: 0.6 }} variant="filled">
                      <InputLabel htmlFor="tfDesconto" >Desconto</InputLabel>
                      <FilledInput
                        id="tfDesconto"
                        readOnly={true}
                        value={fildDesconto}
                        defaultValue={'0,00'}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        onChange={(event) => setFildDesconto(formatarMoeda(event.target.value))}
                        inputProps={{ style: { fontSize: 18, textAlign: 'right' } }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              // aria-label="toggle password visibility"
                              onClick={(listaItens.length > 0) ? handleOpenModalDesconto : null}
                            // onClick={handleOpenModalDesconto}
                            >
                              <AddCircleRoundedIcon color="primary" />
                            </IconButton>
                          </InputAdornment>}
                      />
                    </FormControl>

                    <Typography style={{ fontSize: 18, textAlign: 'right' }}>
                      TOTAL
                      <FilledInput fullWidth sx={{ m: 0.6 }}
                        id="tfTotal"
                        readOnly={true}
                        value={fildValor_total}
                        defaultValue={fildValor_total}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        //onChange={(event) => setFildValor_total(formatarMoeda(event.target.value))}
                        inputProps={{ style: { fontSize: 18, textAlign: 'right' } }}
                      />
                    </Typography>

                  </Grid>

                </Card>

              </Box>
            </Grid>
          )
        }
      </>
    );
  }

  return (

    (isMobile) ?
      (telaCelular()) : telaPadrao()

  );
}
