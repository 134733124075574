// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import {
  TextField,
  Grid,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  FormControl,
  InputLabel,
  FilledInput,
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import Config from '../../../config/Config';

// components
import PageTitle from "../../../components/PageTitle";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";
import { Margin } from "@mui/icons-material";
import AguardarRetorno from "../../../components/BackDrop/AguardarRetorno";


export default function CostCenterRegister(props) {

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const [recarregar,SetRecarregar] = useState(true);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);


  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.centroc.text);

  const [fildChave,setFildChave ]= useState(dadosRegistro.chave );
  const [fildCentroCusto,setFildCentroCusto ]= useState(dadosRegistro.centrocusto );
  //Fim dos campos do formulario

  const [validaDescricao, setValidaDescricao] = useState(false);
  const [msgErroDescricao, setMsgErroDescricao] = useState('');

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };


  async function salvarDados() {

    if (fildCentroCusto.length === 0) {

      setValidaDescricao(true);
      setMsgErroDescricao('Informe a descrição do Plano de Contas!');

      return;
    }

    //Limpa a validação do campo
    setValidaDescricao(false);
    setMsgErroDescricao('');

    //Chama a API

    const registro = [{
      chave:fildChave,
      centrocusto:fildCentroCusto,
    }];

    setimpedirMultClick(true);
    const response = await fetch(`${Config.urlRoot}/centercost`, {
      method: 'POST',
      headers: { Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        registro: registro
      })
    });

    let json = await response.json();

    if(response.status!==200 && response.status!==201){

      setTipoMensagem('error');
      setTextoMensagem('Falha ao salvar o registro!');
    }

      setTipoMensagem('success');
      setTextoMensagem('Registro salvo com sucesso!');

      //id restornado
      setFildChave(json.chave );
      setFildCentroCusto(json.centrocusto );

      //Encerra o progress
      setimpedirMultClick(false);
      handleOpenMessage();

  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);


  function botaoVoltar() {
    props.history.push('/app/CostCenter');
  }

  function botaoNovo() {

    setFildChave("0");
    setFildCentroCusto("");
  }

  return (
    <>
      <PageTitle title="Centro de Custo "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

      <AguardarRetorno show={impedirMultClick} />

      <Grid container spacing={2}
        justifyContent="space-evenly"
        alignItems="center"
        >
        <Grid item xs md={6} >
          <Card variant="outlined">
            {/* <Box > */}

                <FormControl  size="small" sx={{ m: 1, width: '45ch' }} variant="filled">
                  <InputLabel htmlFor="tfDecricao">Descrição</InputLabel>
                  <FilledInput
                    id="tfDecricao"
                    value={fildCentroCusto||''}
                    size="small"
                    inputProps={{ maxLength: 25 }}
                    onChange={(event) => setFildCentroCusto(event.target.value)}
                  />
                </FormControl>

            {/* </Box> */}
          </Card>
        </Grid>

      </Grid>
    </>
  );
}
