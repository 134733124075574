// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import {
  TextField,
  Grid,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  FormControl,
  InputLabel,
  FilledInput,
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import Config from '../../../config/Config';

// components
import PageTitle from "../../../components/PageTitle";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";
import { Margin } from "@mui/icons-material";
import AguardarRetorno from "../../../components/BackDrop/AguardarRetorno";


export default function PlanAccountRegister(props) {

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const [recarregar,SetRecarregar] = useState(true);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);


  const [lsCentroCusto, setLsCentroCusto] = useState(props.location.lscentrocusto.text );

  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.plan.text);

  const [fildCodigoResumido,setFildCodigoResumido ]= useState(dadosRegistro.codigoresumido );
  const [fildCodigoContabil,setFildCodigoContabil ]= useState(dadosRegistro.codigocontabil );
  const [fildDescricao,setFildDescricao ]= useState(dadosRegistro.descricao );
  const [fildCodigoCentroCusto,setFildCodigoCentroCusto ]= useState(dadosRegistro.codigocentrocusto );
  const [fildMovimento,setFildMovimento ]= useState(dadosRegistro.movimento );
  const [fildDespesa,setFildDespesa ]= useState(dadosRegistro.despesa );
  const [fildStatus,setFildStatus ]= useState(dadosRegistro.status );
  //Fim dos campos do formulario

  const [validaCodigoContabil, setValidaCodigoContabil] = useState(false);
  const [validaDescricao, setValidaDescricao] = useState(false);
  const [msgErroCodigoContabil, setMsgErroCodigoContabil] = useState('');
  const [msgErroDescricao, setMsgErroDescricao] = useState('');

  const valoresSim_Nao = [
    {
      value: 'true',
      label: 'Sim',
    },
    {
      value: 'false',
      label: 'Não',
    }
  ];

  const valoresAtivo_Iantivo = [
    {
      value: 'true',
      label: 'Ativo',
    },
    {
      value: 'false',
      label: 'Inativo',
    }
  ];


  const handleChangeStatus = (event) => {
    setFildStatus(event.target.value);
  };

  const handleChangeCentroCusto = (event) => {
    setFildCodigoCentroCusto(event.target.value);
  };

  const handleChangeMovimento = (event) => {
    setFildMovimento(event.target.value);
  };

  const handleChangeDespesa = (event) => {
    setFildDespesa(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };


  async function salvarDados() {

    if (fildCodigoContabil.length === 0) {

      setValidaCodigoContabil(true);
      setMsgErroCodigoContabil('Informe o Código Contábil!');

      return;
    }

    if (fildDescricao.length === 0) {

      setValidaDescricao(true);
      setMsgErroDescricao('Informe a descrição do Plano de Contas!');

      return;
    }

    //Limpa a validação do campo
    setValidaCodigoContabil(false);
    setMsgErroCodigoContabil('');
    setValidaDescricao(false);
    setMsgErroDescricao('');

    //Chama a API

    const registro = [{
      codigoresumido:fildCodigoResumido,
      codigocontabil:fildCodigoContabil,
      descricao:fildDescricao,
      codigocentrocusto:fildCodigoCentroCusto,
      movimento:fildMovimento,
      despesa:fildDespesa,
      status:fildStatus,
    }];

    setimpedirMultClick(true);
    const response = await fetch(`${Config.urlRoot}/planaccount`, {
      method: 'POST',
      headers: { Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        registro: registro
      })
    });

    let json = await response.json();

    if(response.status!==200 && response.status!==201){

      setTipoMensagem('error');
      setTextoMensagem('Falha ao salvar o registro!');
    }

      setTipoMensagem('success');
      setTextoMensagem('Registro salvo com sucesso!');

      //id restornado
      setFildCodigoResumido(json.codigoresumido );
      setFildCodigoContabil(json.codigocontabil );
      setFildDescricao(json.descricao );
      setFildCodigoCentroCusto(json.codigocentrocusto );
      setFildMovimento(json.movimento );
      setFildDespesa(json.despesa );
      setFildStatus(json.status );

      //Encerra o progress
      setimpedirMultClick(false);
      handleOpenMessage();

  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);


  function botaoVoltar() {
    props.history.push('/app/PlanAccount');
  }

  function botaoNovo() {

    setFildCodigoResumido("0");
    setFildCodigoContabil("");
    setFildDescricao("");
    setFildCodigoCentroCusto("" );
    setFildMovimento("");
    setFildDespesa("");
    setFildStatus("ativo");

  }

  return (
    <>
      <PageTitle title="Plano de Contas "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

      <AguardarRetorno show={impedirMultClick} />

      <Grid container spacing={2}
        justifyContent="space-evenly"
        alignItems="center"
        >
        <Grid item xs md={6} >
          <Card variant="outlined">
            {/* <Box > */}
              <FormControl fullWidth size="small" sx={{ m: 1 }} variant="filled">
                  <InputLabel htmlFor="tfCodigoContabil">Código Contábil</InputLabel>
                  <FilledInput
                    id="tfCodigoContabil"
                    value={fildCodigoContabil||''}
                    size="small"
                    inputProps={{ maxLength: 5 }}
                    onChange={(event) => setFildCodigoContabil(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 1 }} variant="filled">
                  <InputLabel htmlFor="tfDecricao">Descrição</InputLabel>
                  <FilledInput
                    id="tfDecricao"
                    value={fildDescricao||''}
                    size="small"
                    inputProps={{ maxLength: 25 }}
                    onChange={(event) => setFildDescricao(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.5}} variant="filled">
                  <TextField
                    id="tfDespesa"
                    select
                    label="Despesa"
                    size="small"
                    value={fildDespesa ||''}
                    onChange={handleChangeDespesa}
                    variant="filled"
                  >
                    <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>

                    {valoresSim_Nao.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.5}} variant="filled">
                  <TextField
                    id="tfMovimento"
                    select
                    label="Movimento"
                    size="small"
                    value={fildMovimento ||''}
                    onChange={handleChangeMovimento}
                    variant="filled"
                  >
                    <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>

                    {valoresSim_Nao.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.5 }} variant="filled">
                  <TextField
                    id="tfStatus"
                    select
                    label="Status"
                    size="small"
                    value={fildStatus ||''}
                    onChange={handleChangeStatus}
                    variant="filled"
                  >
                    <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>

                    {valoresAtivo_Iantivo.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.5 }} variant="filled">
                  <TextField
                    id="tfCentroCusto"
                    select
                    label="Centro de Custo"
                    size="small"
                    value={fildCodigoCentroCusto ||''}
                    onChange={handleChangeCentroCusto}
                    variant="filled"
                  >
                    <MenuItem key={-1} value={null}>
                        {}
                      </MenuItem>

                    {lsCentroCusto.map((option, index) => (
                      <MenuItem key={index} value={option.chave}>
                        {option.centrocusto}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>


            {/* </Box> */}
          </Card>
        </Grid>

      </Grid>
    </>
  );
}
