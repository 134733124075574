// import React from "react";
import * as React  from 'react';
import { useTheme, createTheme } from '@mui/material/styles';

import * as locales from '@mui/material/locale';


export default function LocalesPtBr() {
    const [locale, setLocale] = React.useState('ptBR');
  
    const theme = useTheme();
  
    const themeWithLocale = React.useMemo(
      () => createTheme(theme, locales[locale]),
      [locale, theme],
    );
  
    return (
        themeWithLocale
    );
  }