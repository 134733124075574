import React from "react";
import {useState} from "react";

import Config from '../config/Config';

import md5 from 'md5';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

 var usuarioRetorno= 'Não Identificado';
 var senhaRetorno= '0';
 var configuracaoBd='';

// var [senhaRetorno,setSenhaRetorno]    = useState('');

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut,usuarioRetorno,senhaRetorno,configuracaoBd };

// ###########################################################

const sendUser = async (login,senha) => {

  try {

    var retorno=false;

        //o endereco tem que estar completo
    const response = await fetch('https://www.trinityst.com.br/apiAntares/apiLogin.php',{
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            requisicao:'login',
            senha:senha,
            login:login
        })
      });

      let json=await response.json();
        console.log(json);

      if(!json.success){

        // setUsuarioRetorno('');
        // setSenhaRetorno('');

      }else{

        // setUsuarioRetorno(json.usuario);
        // setSenhaRetorno(json.senha);

        retorno=false;
      }

      return retorno;

  } catch (error) {
    console.error(error);

  } finally {
    // setLoading(false);
  }
}

async function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

const senhaMd5=md5(password);


  //IMPLEMENTAR AQUI A VALIDACAO DO LOGIN

  //var retorno =sendUser(login,password);

  //
  const response = await fetch(`${Config.urlRootMaster}/`,{
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization : `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjcyODY0NzMzLCJleHAiOjE4MzA1NDQ3MzN9.SrFlaJ27_96EjHg9cB7muh15IYgGF7kmmVxwSP-A4_s`,
        },
        body: JSON.stringify({
            senha:senhaMd5,
            login:login,
            nome:login
        })
      });

      if(response.status!==200 && response.status!==201){

        usuarioRetorno= 'Não Identificado';
        senhaRetorno= '0';

        dispatch({ type: "SIGN_OUT_SUCCESS" });
        setError(true);
        setIsLoading(false);

        history.push("/");
        return;
      }

      let json=await response.json();



      // const Sh1 = json.nameBd.substring(0, 1).toUpperCase();
      //   const Sh2 = json.nameBd
      //       .substring(1, json.nameBd.length)
      //       .toLowerCase();

      // let pws = `@@1${Sh1}${Sh2}##`;
      // pws=pws.replace(/(a|e|i|o|u)/gi, "");

      // let uName = json.nameBd;
      // uName = uName.replace(/(a|e|i|o|u)/gi, "");
      // uName = `wwtrin_${uName}`;

      // const bd = `wwtrin_${json.nameBd}`;

      // const conn={
      //   dialect: "mysql",
      //   host: "162.214.189.17",
      //   username: uName,
      //   password: pws,
      //   database: bd,
      //   define: {
      //       timestamp: true, // cria duas colunas: createdAt e updatedAt
      //       underscored: true,
      //       underscoredAll: true,
      //   },
      // };

      configuracaoBd = json.nameBd;

      usuarioRetorno = login;
      senhaRetorno   = senhaMd5;

      setTimeout(() => {
        localStorage.setItem("id_token", "1");
        dispatch({ type: "LOGIN_SUCCESS" });
        setError(null);
        setIsLoading(false);

        history.push("/app/dashboard");
      }, 2000);


}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });

  usuarioRetorno= 'Não Identificado';
  senhaRetorno= '0';
  configuracaoBd={};

  history.push("/login");
}
