import * as React from 'react';
import { useEffect, useState, useRef, useCallback } from 'react';
import Card from '@mui/material/Card';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Button, Modal, Box, Grid, FormControl, FilledInput, InputLabel, Table, TableHead, TableRow,
  LinearProgress, TableBody, TableFooter, TablePagination
} from '@mui/material';
import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

import LocalesPtBr from "../../components/LocalesPtBr";

import Config from '../../config/Config';

import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd } from "../../context/UserContext";

const stylePesquisaProduto = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
}

const columns = [
  { id: 'razao', label: 'Razão Social' },
  { id: 'fantasia', label: 'Nome Fantasia' },
  { id: 'cnpj', label: 'CNPJ' },
  { id: '', label: '' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function PesquisarFornecedores(props) {

  const [exibirProgress, setExibirProgress] = useState(false);

  const [listaFornecedores, setListaFornecedores] = useState([]);
  const [pageFornecedor, setPageFornecedor] = React.useState(0);
  const [rowsPerPageFornecedor, setRowsPerPageFornecedor] = React.useState(5);
  const emptyRowsFornecedor = pageFornecedor > 0 ? Math.max(0, (1 + pageFornecedor) * rowsPerPageFornecedor - listaFornecedores.length) : 0;

  const handleChangePageFornecedor = (event, newPage) => {
    setPageFornecedor(newPage);
  };

  const handleChangeRowsPerPageFornecedor = (event) => {
    setPageFornecedor(parseInt(event.target.value, 10));
    setPageFornecedor(0);
  };

  async function pesquisarFornecedor(valor) {

    setExibirProgress(true);

    const response = await fetch(`${Config.urlRoot}/provider/search`, {
      method: 'POST',
      headers: { Accept: 'application/json','Content-Type': 'application/json' },
      body: JSON.stringify({
        dataBase:configuracaoBd,
        pesquisar:valor
      })
    });

    let json = await response.json();

    if(response.status!==200 && response.status!==201){
      setListaFornecedores([]);
      return;
    }

    setListaFornecedores(json);

    setExibirProgress(false);

  }

  return (
    <Modal
      open={props.openPesquisarFornecedor}
      onClose={props.handleCloseModalPesquisarFornecedor}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={stylePesquisaProduto}>

        <Grid item xs={12} md={2} >
          <Card variant="outlined">
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 0 }, }}
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth size="small" variant="filled">
                <InputLabel htmlFor="tfModalPesquisaFornecedor">Pesquisar </InputLabel>
                <FilledInput
                  id="tfModalPesquisadescricao"
                  variant="filled"
                  //value={valorPesquisa}
                  defaultValue={''}
                  onChange={(e) => pesquisarFornecedor(e.target.value)}
                  sx={{ m: 1 }}
                />
              </FormControl>

              {exibirProgress && (
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              )}

              <ThemeProvider theme={LocalesPtBr()}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(rowsPerPageFornecedor > 0
                      ? listaFornecedores.slice(pageFornecedor * rowsPerPageFornecedor, pageFornecedor * rowsPerPageFornecedor + rowsPerPageFornecedor)
                      : listaFornecedores
                    ).map((row) => (
                      <TableRow key={row.id}>

                        <TableCell
                          align="left">
                          {row.razao}
                        </TableCell>

                        <TableCell
                          // style={{ width: "25%" }}
                          align="left">
                          {row.fantasia}
                        </TableCell>

                        <TableCell
                          // style={{ width: "25%" }}
                          align="left">
                          {row.cnpj}
                        </TableCell>

                        <TableCell align="center">
                          <Button
                            size="small"
                            color={"success"}
                            onClick={() => props.preencherDadosFornecedor(row.codigo, row.razao, row.fantasia, row.cnpj,'')}
                          >
                            <CheckIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}

                    {emptyRowsFornecedor > 0 && (
                      <TableRow style={{ height: 53 * emptyRowsFornecedor }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={listaFornecedores.length}
                        rowsPerPage={rowsPerPageFornecedor}
                        page={pageFornecedor}

                        onPageChange={handleChangePageFornecedor}
                        onRowsPerPageChange={handleChangeRowsPerPageFornecedor}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </ThemeProvider>
            </Box>
          </Card>
        </Grid>

        <Button variant="contained" color="error" style={{ margin: 5 }}
          onClick={props.handleCloseModalPesquisarFornecedor}>Voltar
        </Button>

      </Box>
    </Modal>
  );
}