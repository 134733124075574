// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import {
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar, InputAdornment
} from '@mui/material';

import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import { Button } from "../../../components/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import Config from '../../../config/Config';



export default function ReceiptRegister(props) {

  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.receb.text);
  const [fildId, setFildId] = useState(props.location.idregistro.text);
  const [fildCodigo, setFildCodigo] = useState(dadosRegistro.codigo);
  const [fildDescricao, setFildDescricao] = useState(dadosRegistro.descricao);
  const [fildSigla, setFildSigla] = useState(dadosRegistro.sigla);
  const [fildBaixaAutomatica, setFildBaixaAutomatica] = useState(dadosRegistro.baixaautomatica);
  const [fildAtivo, setFildAtivo] = useState(dadosRegistro.ativo);
  const [fildForma, setFildForma] = useState(dadosRegistro.forma);
  const [fildVisualizar, setFildVisualizar] = useState(dadosRegistro.visualizar);
  const [fildPrazo1, setFildPrazo1] = useState(dadosRegistro.prazo1);
  const [fildPrazo2, setFildPrazo2] = useState(dadosRegistro.prazo2);
  const [fildPrazo3, setFildPrazo3] = useState(dadosRegistro.prazo3);
  const [fildPrazo4, setFildPrazo4] = useState(dadosRegistro.prazo4);
  const [fildPrazo5, setFildPrazo5] = useState(dadosRegistro.prazo5);
  const [fildPrazo6, setFildPrazo6] = useState(dadosRegistro.prazo6);
  const [fildPrazo7, setFildPrazo7] = useState(dadosRegistro.prazo7);
  const [fildPrazo8, setFildPrazo8] = useState(dadosRegistro.prazo8);
  const [fildPrazo9, setFildPrazo9] = useState(dadosRegistro.prazo9);
  const [fildPrazo10, setFildPrazo10] = useState(dadosRegistro.prazo10);
  const [fildPrazo11, setFildPrazo11] = useState(dadosRegistro.prazo11);
  const [fildPrazo12, setFildPrazo12] = useState(dadosRegistro.prazo12);
  const [fildDataCadastro, setFildDataCadastro] = useState('');
  const [fildDataAlteracao, setFildDataAlteracao] = useState('');
  const [fildResponsavel, setFildResponsavel] = useState(dadosRegistro.resonsavel);
  //Fim dos campos do formulario

  const [opcaoVisualizar, setOpcaoVisualizar] = useState([true, true, true]);

  const [currency, setCurrency] = useState(fildId);

  const [validaDescricao, setValidaDescricao] = useState(false);
  const [msgErroDescricao, setMsgErroDescricao] = useState('');

  const valoresSim_Nao = [
    {
      value: 'true',
      label: 'Sim',
    },
    {
      value: 'false',
      label: 'Não',
    }
  ];

  const valores_N = [
    {
      value: 'S',
      label: 'Sim',
    },
    {
      value: 'N',
      label: 'Não',
    }
  ];

  const listaForma = [
    { codigo: '01', valor: 'Dinheiro' },
    { codigo: '02', valor: 'Cheque' },
    { codigo: '03', valor: 'Cartão de Crédito' },
    { codigo: '04', valor: 'Cartão de Débito' },
    { codigo: '05', valor: 'Crédito Loja' },
    { codigo: '10', valor: 'Vale Alimentação' },
    { codigo: '11', valor: 'Vale Refeição' },
    { codigo: '12', valor: 'Vale Presente' },
    { codigo: '13', valor: 'Vale Combustível' },
    { codigo: '15', valor: 'Boleto Bancário' },
    { codigo: '16', valor: 'Depósito Bancário' },
    { codigo: '17', valor: 'Pagamento Instantâneo (PIX)' },
    { codigo: '18', valor: 'Transferência bancária, Carteira Digital' },
    { codigo: '19', valor: 'Programa de fidelidade,Cashback,Créd. Virtual' },
    { codigo: '90', valor: 'Sem pagamento' },
    { codigo: '99', valor: 'Outros' },
  ];

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleChangeForma = (event) => {
    setFildForma(event.target.value);
  }

  const handleChangeAtivo = (event) => {
    setFildAtivo(event.target.value);
  }

  const handleChangeBaixaAutomatica = (event) => {
    setFildBaixaAutomatica(event.target.value);
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  async function salvarDados() {

    if (fildDescricao.length === 0) {

      setValidaDescricao(true);
      setMsgErroDescricao('Informe a Descrição do recebimento!');
    }
    else {
      //Chama o progress
      setExibirProgress(true);

      //Limpa a validação do campo
      setValidaDescricao(false);
      setMsgErroDescricao('');

      //Chama a API

      const registro = [{
        id: fildId,
        codigo:fildCodigo,
        descricao: fildDescricao,
        sigla: fildSigla,
        baixaautomatica: fildBaixaAutomatica,
        ativo: fildAtivo,
        forma: fildForma,
        prazo1: fildPrazo1,
        prazo2: fildPrazo2,
        prazo3: fildPrazo3,
        prazo4: fildPrazo4,
        prazo5: fildPrazo5,
        prazo6: fildPrazo6,
        prazo7: fildPrazo7,
        prazo8: fildPrazo8,
        prazo9: fildPrazo9,
        prazo10: fildPrazo10,
        prazo11: fildPrazo11,
        prazo12: fildPrazo12,
        visualizar: fildVisualizar,
      }];

      const response = await fetch(`${Config.urlRoot}/payment`, {
        method: 'POST',
        headers: { Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
        dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //id restornado
        setFildId(json.id);

        setFildDescricao(json.descricao);
        setFildSigla(json.sigla);
        setFildBaixaAutomatica(json.baixaautomatica);
        setFildAtivo(json.ativo);
        setFildForma(json.forma);
        setFildPrazo1(json.prazo1);
        setFildPrazo2(json.prazo2);
        setFildPrazo3(json.prazo3);
        setFildPrazo4(json.prazo4);
        setFildPrazo5(json.prazo5);
        setFildPrazo6(json.prazo6);
        setFildPrazo7(json.prazo7);
        setFildPrazo8(json.prazo8);
        setFildPrazo9(json.prazo9);
        setFildPrazo10(json.prazo10);
        setFildPrazo11(json.prazo11);
        setFildPrazo12(json.prazo12);

        setFildDataCadastro(json.datacadastro);
        setFildDataAlteracao(json.dataalteracao);
        setFildResponsavel(json.responsavel);

        deserealizarVisualizar(json.visualizar);
      }

      setExibirProgress(false);

      handleOpenMessage();
    }
  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {
    serealizarVisualizar();
  }, [serealizarVisualizar])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function serealizarVisualizar() {
    //Aqui se verifica quem esta tru e converte nas siglas guardadas no banco de dados.
    var valores = "";

    if (opcaoVisualizar[0]) { valores = valores + "C"; }
    if (opcaoVisualizar[1]) { valores = valores + "S"; }
    if (opcaoVisualizar[2]) { valores = valores + "V"; }

    setFildVisualizar(valores);

  }

  const deserealizarVisualizar = (valor) => {
    //Aqui pega as letras do banco de dados e converte em true/false
    var opCompra = false;
    var opPdv = false;
    var opVenda = false;
    var valores = "";

    if (valor !== null && valor !== '') {

      if (valor.indexOf("C") !== -1) {
        opCompra = true;
        valores = valores + "C";
      }

      if (valor.indexOf("S") !== -1) {
        opPdv = true;
        valores = valores + "S";
      }

      if (valor.indexOf("S") !== -1) {
        opVenda = true;
        valores = valores + "V";
      }

    } else { opCompra = true; opPdv = true; opVenda = true; }

    setFildVisualizar(valores);
    setOpcaoVisualizar([opCompra, opPdv, opVenda]);

  }

  const handleChangeOpcao1 = (event) => {
    setOpcaoVisualizar([event.target.checked, opcaoVisualizar[1], opcaoVisualizar[2]]);

  }

  const handleChangeOpcao2 = (event) => {
    setOpcaoVisualizar([opcaoVisualizar[0], event.target.checked, opcaoVisualizar[2]]);

  }

  const handleChangeOpcao3 = (event) => {
    setOpcaoVisualizar([opcaoVisualizar[0], opcaoVisualizar[1], event.target.checked]);

  }

  function botaoVoltar() {
    props.history.push('/app/Receipt');
  }

  function botaoNovo() {

    setFildDescricao('');
    setFildCodigo("");
    setFildSigla('');
    setFildBaixaAutomatica('N');
    setFildAtivo('true');
    setFildForma('');
    setFildVisualizar('');
    setFildPrazo1('0');
    setFildPrazo2('');
    setFildPrazo3('');
    setFildPrazo4('');
    setFildPrazo5('');
    setFildPrazo6('');
    setFildPrazo7('');
    setFildPrazo8('');
    setFildPrazo9('');
    setFildPrazo10('');
    setFildPrazo11('');
    setFildPrazo12('');
    setFildDataCadastro('');
    setFildDataAlteracao('');
    setFildResponsavel('');
  }

  return (
    <>
      <PageTitle title="Forma de Pagamento "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} >
          <Card variant="outlined">
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 0, width: '65ch' }, }}
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth sx={{ m: 1, width: '65ch' }} variant="filled">
                <InputLabel htmlFor="tfDescricao">Descrição</InputLabel>
                <FilledInput
                  id="tfDescricao"
                  value={fildDescricao}
                  // defaultValue={fildDescricao}
                  variant="filled"
                  inputProps={{ maxLength: 50 }}
                  onChange={(event) => setFildDescricao(event.target.value)}
                  error={validaDescricao}
                />
              </FormControl>

              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '65ch' }, }}
                noValidate
                autoComplete="off"
              >

                <TextField
                  id="tfForma"
                  select
                  label="Forma"
                  value={fildForma}
                  variant="filled"
                  onChange={handleChangeForma}
                  sx={{ m: 1, width: '11.5ch' }}
                >
                  {listaForma.map((option) => (
                    <MenuItem key={option.codigo} value={option.codigo}>
                      {option.valor}
                    </MenuItem>
                  ))}

                </TextField>

              </Box>

              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '65ch' }, }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="tfBaixaAutomatica"
                  select
                  label="Baixa Automatica"
                  value={fildBaixaAutomatica}
                  variant="filled"
                  onChange={handleChangeBaixaAutomatica}
                >
                  {valores_N.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

              </Box>

              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '65ch' }, }}
                noValidate
                autoComplete="off"
              >
                <FormControl sx={{ m: 1, width: '15ch' }} variant="filled">
                  <InputLabel htmlFor="tfSigla">Sigla</InputLabel>
                  <FilledInput
                    id="tfSigla"
                    value={fildSigla}
                    // defaultValue={fildSigla}
                    inputProps={{ maxLength: 4 }}
                    onChange={(event) => setFildSigla(event.target.value)}
                  />
                </FormControl>

                <FormControlLabel
                  style={{ marginTop: 12 }}
                  label="Compra"
                  control={<Checkbox checked={opcaoVisualizar[0]} onChange={handleChangeOpcao1} />}
                />
                <FormControlLabel
                  style={{ marginTop: 12 }}
                  label="PDV"
                  control={<Checkbox checked={opcaoVisualizar[1]} onChange={handleChangeOpcao2} />}
                />
                <FormControlLabel
                  style={{ marginTop: 12 }}
                  label="Venda"
                  control={<Checkbox checked={opcaoVisualizar[2]} onChange={handleChangeOpcao3} />}
                />

              </Box>
            </Box>

            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 0, width: '65ch' }, }}
              noValidate
              autoComplete="off"
            >

              <div>
                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo1">1° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo1"
                    type={'number'}
                    value={fildPrazo1}
                    // defaultValue={fildPrazo1}
                    onChange={(event) => setFildPrazo1(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo2">2° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo2"
                    type={'number'}
                    value={fildPrazo2}
                    // defaultValue={fildPrazo2}
                    onChange={(event) => setFildPrazo2(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo3">3° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo3"
                    type={'number'}
                    value={fildPrazo3}
                    // defaultValue={fildPrazo3}
                    onChange={(event) => setFildPrazo3(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo4">4° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo4"
                    type={'number'}
                    value={fildPrazo4}
                    // defaultValue={fildPrazo4}
                    onChange={(event) => setFildPrazo4(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo5">5° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo5"
                    type={'number'}
                    value={fildPrazo5}
                    // defaultValue={fildPrazo5}
                    onChange={(event) => setFildPrazo5(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo6">6° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo6"
                    type={'number'}
                    value={fildPrazo6}
                    // defaultValue={fildPrazo6}
                    onChange={(event) => setFildPrazo6(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo7">7° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo7"
                    type={'number'}
                    value={fildPrazo7}
                    // defaultValue={fildPrazo7}
                    onChange={(event) => setFildPrazo7(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo8">8° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo8"
                    type={'number'}
                    value={fildPrazo8}
                    // defaultValue={fildPrazo8}
                    onChange={(event) => setFildPrazo8(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo9">9° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo9"
                    type={'number'}
                    value={fildPrazo9}
                    // defaultValue={fildPrazo9}
                    onChange={(event) => setFildPrazo9(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo10">10° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo10"
                    type={'number'}
                    value={fildPrazo10}
                    // defaultValue={fildPrazo10}
                    onChange={(event) => setFildPrazo10(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo11">11° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo11"
                    type={'number'}
                    value={fildPrazo11}
                    // defaultValue={fildPrazo11}
                    onChange={(event) => setFildPrazo11(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '31.5ch' }} variant="filled">
                  <InputLabel htmlFor="tfPrazo12">12° Prazo</InputLabel>
                  <FilledInput
                    id="tfPrazo12"
                    type={'number'}
                    value={fildPrazo12}
                    // defaultValue={fildPrazo12}
                    onChange={(event) => setFildPrazo12(event.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, width: '21.5ch' }} variant="filled">
                  <TextField
                    id="tfAtivo"
                    select
                    label="Ativo"
                    variant="filled"
                    value={fildAtivo}
                    onChange={handleChangeAtivo}
                  >
                    {valoresSim_Nao.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

              </div>

            </Box>

            <Card >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                  <InputLabel htmlFor="tfDataCadastro">Data do Cadastro</InputLabel>
                  <FilledInput
                    readOnly
                    id="tfDataCadastro"
                    value={fildDataCadastro}
                    // defaultValue={fildDataCadastro}
                    onChange={(event) => setFildDataCadastro(event.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                  <InputLabel htmlFor="tfDataAlteracao">Data da Alteração</InputLabel>
                  <FilledInput
                    readOnly
                    id="tfDataAlteracao"
                    value={fildDataAlteracao}
                    // defaultValue={fildDataAlteracao}
                    onChange={(event) => setFildDataAlteracao(event.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ m: 1, width: '45ch' }} variant="filled">
                  <InputLabel htmlFor="tfResponsavel">Responsável</InputLabel>
                  <FilledInput
                    readOnly
                    id="tfResponsavel"
                    value={fildResponsavel}
                    // defaultValue={fildResponsavel}
                    onChange={(event) => setFildResponsavel(event.target.value)}
                  />
                </FormControl>
              </Box>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
