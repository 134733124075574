import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Login from "../../pages/login";
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import ListClientes from "../../pages/clientList";
import ClientRegister from "../../pages/clientList/register";
import Records from "../../pages/records";
import OperationFinance from "../../pages/operationFinance";
import OperationFinanceRegister from "../../pages/operationFinance/register";
import ListCfop from "../../pages/cfop";
import CfopRegister from "../../pages/cfop/register";
import ListProviders from "../../pages/providersList";
import ProviderRegister from "../../pages/providersList/register";

import ListEmployee from "../../pages/employeeList";
import EmployeeRegister from "../../pages/employeeList/register";

import ListProduct from "../../pages/productsList";
import ProductRegister from "../../pages/productsList/register";

import ListReceipt from "../../pages/receiptList";
import ReceiptRegister from "../../pages/receiptList/register";

import ListService from "../../pages/servicesList";
import ServiceRegister from "../../pages/servicesList/register";

import ListTransport from "../../pages/transportList";
import TransportRegister from "../../pages/transportList/register";

import ListSeller from "../../pages/sellersList";
import SellerRegister from "../../pages/sellersList/register";

import AdjustmentRegister from '../../pages/stock/adjustment';

import ListPurchases from "../../pages/purchasesList/ListPurchases";
import PurchaseRegister from "../../pages/purchasesList/register/PurchaseRegister";

import ListSalesOrder from "../../pages/salesOrderList/ListSalesOrder";
import SalesOrderRegister from "../../pages/salesOrderList/register/SalesOrderRegister";

import ListPurchaseOrder from "../../pages/purchasesOrderList/ListPurchaseOrder";
import SalesPurchaseRegister from "../../pages/purchasesOrderList/register/PurchaseOrderRegister";

import ListSalesPDV from "../../pages/salesPDV/ListSalesPDV";
import SalesPDV from "../../pages/salesPDV/register/SalesPDV";

import ListPlanAccount from "../../pages/planAccountList";
import PlanAccountRegister from "../../pages/planAccountList/register/PlanAccountRegister";

import ListCostCenter from "../../pages/costCenter";
import CostCenterRegister from "../../pages/costCenter/register/CostCenterRegister";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/Login" component={Login} />

              <Route path="/app/dashboard" component={Dashboard} />

              <Route path="/app/records" component={Records} />

              <Route path="/app/typography" component={Typography} />

              <Route path="/app/Client" component={ListClientes} />
              <Route path="/app/ClientRegister" component={ClientRegister} />

              <Route path="/app/Provider" component={ListProviders} />
              <Route path="/app/ProviderRegister" component={ProviderRegister} />

              <Route path="/app/Employee" component={ListEmployee} />
              <Route path="/app/EmployeeRegister" component={EmployeeRegister} />

              <Route path="/app/Product" component={ListProduct} />
              <Route path="/app/ProductRegister" component={ProductRegister} />

              <Route path="/app/Receipt" component={ListReceipt} />
              <Route path="/app/ReceiptRegister" component={ReceiptRegister} />

              <Route path="/app/Service" component={ListService} />
              <Route path="/app/ServiceRegister" component={ServiceRegister} />

              <Route path="/app/Transport" component={ListTransport} />
              <Route path="/app/TransportRegister" component={TransportRegister} />

              <Route path="/app/Seller" component={ListSeller} />
              <Route path="/app/SellerRegister" component={SellerRegister} />

              <Route path="/app/operationFinance" component={OperationFinance} />
              <Route path="/app/OperationFinanceRegister" component={OperationFinanceRegister} />

              <Route path="/app/Cfop" component={ListCfop} />
              <Route path="/app/CfopRegister" component={CfopRegister} />

              <Route path="/app/AdjustmentRegister" component={AdjustmentRegister} />

              <Route path="/app/Compra" component={ListPurchases} />
              <Route path="/app/CompraRegister" component={PurchaseRegister} />

              <Route path="/app/PedidoVenda" component={ListSalesOrder} />
              <Route path="/app/PedidoVendaRegister" component={SalesOrderRegister} />

              <Route path="/app/PedidoCompra" component={ListPurchaseOrder} />
              <Route path="/app/PedidoCompraRegister" component={SalesPurchaseRegister} />

              <Route path="/app/ListaVendaPDV" component={ListSalesPDV} />
              <Route path="/app/VendPDV" component={SalesPDV} />

              <Route path="/app/PlanAccount" component={ListPlanAccount} />
              <Route path="/app/PlanAccountRegister" component={PlanAccountRegister} />

              <Route path="/app/CostCenter" component={ListCostCenter} />
              <Route path="/app/CostCenterRegister" component={CostCenterRegister} />

              <Route path="/app/tables" component={Tables} />
              <Route path="/app/notifications" component={Notifications} />

              <Route path="/app/tables" component={Tables} />

              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
