// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import {
  TextField,
  Grid,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle";
import AguardarRetorno from "../../../components/BackDrop/AguardarRetorno";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";

import Config from '../../../config/Config';

export default function OperationFinanceRegister(props) {

  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  const lista_Bancos = [
    {
      value: '100',
      label: 'Caixa Contábil',
    },
    {
      value: '1',
      label: 'Banco do Brasil',
    },
    {
      value: '237',
      label: 'Bradesco',
    },
    {
      value: '104',
      label: 'Caixa Econômica Federal',
    },
    {
      value: '341',
      label: 'Itaú',
    },
    {
      value: '422',
      label: 'Safra',
    },
  ];

  const valoresSim_Nao = [
    {
      value: 'true',
      label: 'Sim',
    },
    {
      value: 'false',
      label: 'Não',
    }
  ];

  const modelo_Cnab = [
    {
      value: '',
      label: '',
    },
    {
      value: '240',
      label: '240',
    }
    ,
    {
      value: '400',
      label: '400',
    }
  ];

  const lista_Especie = [
    {
      value: '',
      label: '',
    },
    {
      value: 'DM',
      label: 'Duplicata Mercantil',
    }

  ];

  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.banc.text);

  const [fildId, setFildId] = useState(props.location.idregistro.text);
  const [fildCodigo,setFildCodigo] = useState(dadosRegistro.codigo);
  const [fildBanco, setFildBanco] = useState(dadosRegistro.banco);
  const [fildDescricao, setFildDescricao] = useState(dadosRegistro.descricao );
  const [fildAgencia, setFildAgencia] = useState(dadosRegistro.agencia );
  const [fildDigito_ag, setFildDigito_ag] = useState(dadosRegistro.digito_ag );
  const [fildConta, setFildConta] = useState(dadosRegistro.conta );
  const [fildDigito_conta, setFildDigito_conta] = useState(dadosRegistro.digito_conta );
  const [fildDataCadastro, setFildDataCadastro] = useState(dadosRegistro.datacadastro );
  const [fildDataAlteracao, setFildDataAlteracao] = useState(dadosRegistro.dataalteracao );
  const [fildResponsavel, setFildResponsavel] = useState(dadosRegistro.responsavel );
  const [fildMensagem1, setFildMensagem1] = useState(dadosRegistro.mensagem1 );
  const [fildMensagem2, setFildMensagem2] = useState(dadosRegistro.mensagem2 );
  const [fildDiasProtesto, setFildDiasProtesto] = useState(dadosRegistro.diasprotesto);
  const [fildNossoNumero, setFildNossoNumero] = useState(dadosRegistro.nossonumero );
  const [fildCarteira, setFildCarteira] = useState(dadosRegistro.carteira );
  const [fildConvenio, setFildConvenio] = useState(dadosRegistro.convenio );
  const [fildProtestar, setFildProtestar] = useState(dadosRegistro.protestar);
  const [fildCnab, setFildCnab] = useState(dadosRegistro.cnab);
  const [fildJurosDeMora, setFildJurosDeMora] = useState(dadosRegistro.jurosmora);
  const [fildMulta, setFildMulta] = useState(dadosRegistro.multa);
  const [fildEspecie, setFildEspecie] = useState(dadosRegistro.especie );
  const [fildCodigoEmpresa, setFildCodigoEmpresa] = useState(dadosRegistro.codigoempresa );
  const [fildQtdRemessa, setFildQtdRemessa] = useState(dadosRegistro.qtdremessa);
  const [fildDesativar, setFildDesativar] = useState(dadosRegistro.desativar);
  const [fildApiBoleto, setFildApiBoleto] = useState(dadosRegistro.apiboleto);
  //Fim dos campos do formulario

  const [currency, setCurrency] = useState(fildId);

  const [validaBanco, setValidaBanco] = useState(false);
  const [msgErroBanco, setMsgErroBanco] = useState('');

  const handleChange = (event) => {
    setFildBanco(event.target.value);
  };

  const handleChangeApiItegracao = (event) => {
    setFildApiBoleto(event.target.value);
  };

  const handleChangeModeloCnab = (event) => {
    setFildCnab(event.target.value);
  };

  const handleChangeEspecie = (event) => {
    setFildEspecie(event.target.value);
  };

  const handleChangeProtestar = (event) => {
    setFildProtestar(event.target.value);
  };

  const handleChangeDesativar = (event) => {
    setFildDesativar(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  async function salvarDados() {

    if (fildBanco.length === 0) {

      setValidaBanco(true);
      setMsgErroBanco('Selecione um banco!');
    }
    else {

      //Limpa a validação do campo
      setValidaBanco(false);
      setMsgErroBanco('');

      //Chama a API

      const _codigo=parseInt(fildCodigo, 10);

      const registro = [{
        id: parseInt(fildId, 10),
        codigo: _codigo,
        banco: fildBanco,
        descricao: fildDescricao,
        agencia: fildAgencia,
        digito_ag: fildDigito_ag,
        conta: fildConta,
        digito_conta: fildDigito_conta,
        responsavel: usuarioRetorno,
        mensagem1: fildMensagem1,
        mensagem2: fildMensagem2,
        diasprotesto: fildDiasProtesto,
        nossonumero: fildNossoNumero,
        carteira: fildCarteira,
        convenio: fildConvenio,
        protestar: fildProtestar,
        cnab: fildCnab,
        jurosdemora: fildJurosDeMora,
        multa: fildMulta,
        especie: fildEspecie,
        codigoempresa: fildCodigoEmpresa,
        qtdremessa: fildQtdRemessa,
        desativar: fildDesativar,
        apiboleto: fildApiBoleto,
      }];

      setimpedirMultClick(true);

      const response = await fetch(`${Config.urlRoot}/operationfinance`, {
        method: "POST",
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      }
      else{

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //id restornado
        setFildId(json[0].id);
        setFildCodigo(json[0].codigo);

        // setFildBanco(json.banco);
        // setCurrency(fildBanco);
        // setFildDescricao(json.descricao);
        // setFildAgencia(json.agencia);
        // setFildDigito_ag(json.digito_ag);
        // setFildConta(json.conta);
        // setFildDigito_conta(json.digito_conta);
        // setFildMensagem1(json.mensagem1);
        // setFildMensagem2(json.mensagem2);
        // setFildDiasProtesto(json.diasprotesto);
        // setFildNossoNumero(json.nossonumero);
        // setFildCarteira(json.carteira);
        // setFildConvenio(json.convenio);
        // setFildProtestar(json.protestar);
        // setFildCnab(json.cnab);
        // setFildJurosDeMora(json.jurosdemora);
        // setFildMulta(json.multa);
        // setFildEspecie(json.especie);
        // setFildCodigoEmpresa(json.codigoempresa);
        // setFildQtdRemessa(json.qtdremessa);
        // setFildDesativar(json.desativar);
        // setFildDataCadastro(json.createdAt);
        // setFildResponsavel(json.responsavel);
        // setFildDataAlteracao(json.updatedAt);
        // setFildApiBoleto(json.apiboleto);

      }

        setimpedirMultClick(false);

        handleOpenMessage();
    }
  }

  function botaoVoltar() {
    props.history.push('/app/operationFinance');
  }

  function botaoNovo() {

    setFildId('0');
    setFildCodigo('');
    setFildBanco('');
    setFildDescricao('');
    setFildAgencia('');
    setFildDigito_ag('');
    setFildConta('');
    setFildDigito_conta('');
    setFildDataCadastro('');
    setFildDataAlteracao('');
    setFildResponsavel('');
    setFildMensagem1('');
    setFildMensagem2('');
    setFildDiasProtesto(0);
    setFildNossoNumero('');
    setFildCarteira('');
    setFildConvenio('');
    setFildProtestar('false');
    setFildCnab('');
    setFildJurosDeMora(0);
    setFildMulta(0);
    setFildEspecie('');
    setFildCodigoEmpresa('');
    setFildQtdRemessa(0);
    setFildDesativar('false');
    setFildApiBoleto('false');
  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  return (
    <>
      <PageTitle title="Operações Financeiras "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}
        buttonNew="Novo" eventoNovo={botaoNovo}
        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && (<Box sx={{ width: '100%' }}> <LinearProgress /></Box>)}

      {/* Serve para impedir multiplos clicks , aguarda ate o retorno do evento */}
      <AguardarRetorno show={impedirMultClick} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} >
          <Card variant="outlined">
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '50ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="tfBanco"
                  select
                  size="small"
                  label="Banco"
                  value={fildBanco}
                  // defaultValue={fildBanco}
                  onChange={handleChange}
                  error={validaBanco}
                  helperText={msgErroBanco}
                  variant="filled"
                >
                  {lista_Bancos.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div>
                <TextField
                  id="tfDecricao"
                  label="Descrição"
                  variant="filled"
                  size="small"
                  value={fildDescricao}
                  // defaultValue={fildDescricao}
                  inputProps={{ maxLength: 25 }}
                  onChange={(event) => setFildDescricao(event.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="tfAgencia"
                  label="Agência"
                  variant="filled"
                  size="small"
                  value={fildAgencia}
                  // defaultValue={fildAgencia}
                  inputProps={{ maxLength: 15 }}
                  onChange={(event) => setFildAgencia(event.target.value)}
                />
                <TextField
                  id="tfDigitoAgencia"
                  label="Dígito"
                  variant="filled"
                  size="small"
                  value={fildDigito_ag}
                  // defaultValue={fildDigito_ag}
                  inputProps={{ maxLength: 2 }}
                  onChange={(event) => setFildDigito_ag(event.target.value)}
                  style={{ width: 90 }}
                />
              </div>

              <div>
                <TextField
                  id="tfConta"
                  label="Conta Corrente"
                  variant="filled"
                  size="small"
                  value={fildConta}
                  // defaultValue={fildConta}
                  inputProps={{ maxLength: 15 }}
                  onChange={(event) => setFildConta(event.target.value)}

                />
                <TextField
                  id="tfDigitoConta"
                  label="Dígito"
                  size="small"
                  style={{ width: 90 }}
                  variant="filled"
                  value={fildDigito_conta}
                  // defaultValue={fildDigito_conta}
                  inputProps={{ maxLength: 2 }}
                  onChange={(event) => setFildDigito_conta(event.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="tfCodigoEmpresa"
                  label="Código Empresa"
                  size="small"
                  variant="filled"
                  value={fildCodigoEmpresa}
                  // defaultValue={fildCodigoEmpresa}
                  inputProps={{ maxLength: 20 }}
                  onChange={(event) => setFildCodigoEmpresa(event.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="tfEspecie"
                  select
                  label="Espécie"
                  size="small"
                  value={fildEspecie}
                  onChange={handleChangeEspecie}
                  variant="filled"
                >
                  {lista_Especie.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div>
                <TextField
                  id="tbModeloCnab"
                  select
                  label="Modelo CNAB"
                  size="small"
                  value={fildCnab}
                  onChange={handleChangeModeloCnab}
                  variant="filled"
                >
                  {modelo_Cnab.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} >
          <Card variant="outlined">
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '50ch' },
              }}
              noValidate
              autoComplete="off"
            >

              <div>
                <TextField
                  id="tfApi"
                  select
                  label="API Integração"
                  size="small"
                  value={fildApiBoleto}
                  onChange={handleChangeApiItegracao}
                  variant="filled"
                >
                  {valoresSim_Nao.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div>
                <TextField
                  id="tfNossoNumero"
                  label="Nosso número"
                  variant="filled"
                  size="small"
                  type="number"
                  value={fildNossoNumero}
                  // defaultValue={fildNossoNumero}
                  onChange={(event) => setFildNossoNumero(event.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="tfConvenio"
                  label="Convênio"
                  variant="filled"
                  size="small"
                  value={fildConvenio}
                  // defaultValue={fildConvenio}
                  inputProps={{ maxLength: 25 }}
                  onChange={(event) => setFildConvenio(event.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="tfJuros"
                  label="Juros"
                  variant="filled"
                  size="small"
                  type="number"
                  value={fildJurosDeMora}
                  // defaultValue={fildJurosDeMora}
                  onChange={(event) => setFildJurosDeMora(event.target.value)}
                />

                <TextField
                  id="tfMulta"
                  label="Multa"
                  variant="filled"
                  size="small"
                  type="number"
                  value={fildMulta}
                  // defaultValue={fildMulta}
                  onChange={(event) => setFildMulta(event.target.value)}

                />
              </div>


              <div>
                <TextField
                  id="tfProtesto"
                  select
                  label="Protestar"
                  size="small"
                  value={fildProtestar}
                  onChange={handleChangeProtestar}
                  // helperText="Please select your currency"
                  variant="filled"
                  style={{ width: 186 }}
                >
                  {valoresSim_Nao.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="tfDiasProtesto"
                  label="Dias para protesto"
                  variant="filled"
                  size="small"
                  type="number"
                  value={fildDiasProtesto}
                  // defaultValue={fildDiasProtesto}
                  onChange={(event) => setFildDiasProtesto(event.target.value)}
                  style={{ width: 186 }}
                />

              </div>

              <div>
                <TextField
                  id="tfQuantidadeRemessa"
                  label="Quantidade remessa"
                  size="small"
                  value={fildQtdRemessa}
                  // defaultValue={fildQtdRemessa}
                  variant="filled"
                  type="number"
                  style={{ width: 186 }}
                  onChange={(event) => setFildQtdRemessa(event.target.value)}
                />

              </div>

            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} >
          <Card variant="outlined">

            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '50ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="tfMessage1"
                  label="Mensagem 1"
                  multiline
                  rows={4}
                  variant="filled"
                  value={fildMensagem1}
                  // defaultValue={fildMensagem1}
                  inputProps={{ maxLength: 20 }}
                  onChange={(event) => setFildMensagem1(event.target.value)}
                />

                <TextField
                  id="tfMessage2"
                  label="Mensagem 2"
                  multiline
                  rows={4}
                  variant="filled"
                  value={fildMensagem2}
                  // defaultValue={fildMensagem2}
                  inputProps={{ maxLength: 20 }}
                  onChange={(event) => setFildMensagem2(event.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="tfDesativar"
                  select
                  size="small"
                  label="Desativar"
                  value={fildDesativar}
                  // defaultValue={fildDesativar}
                  onChange={handleChangeDesativar}
                  variant="filled"
                >
                  {valoresSim_Nao.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div>

                <TextField
                  id="tfDataCadastro"
                  label="Cadastro"
                  size="small"
                  variant="filled"
                  style={{ width: 186 }}

                  value={fildDataCadastro}
                  // defaultValue={fildDataCadastro}

                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  id="tfDataAlteracao"
                  label="Alterado"
                  size="small"
                  variant="filled"
                  style={{ width: 186 }}

                  value={fildDataAlteracao}
                  // defaultValue={fildDataAlteracao}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>

              <div>
                <TextField
                  id="tfResponsavel"
                  label="Responsável"
                  size="small"
                  variant="filled"
                  value={fildResponsavel}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
