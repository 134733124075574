import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


export default function PrintSalesOrder(pedido,cliente, itens) {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    function reportHeader() {

        return {

            columns: [
                {
                    margin: [20, 20, 0, 0],// left,top,rigth,botton
                    width: '50%',
                    table: {
                        widths: ['100%'],
                        body: [
                            [{ text: 'Trinity Serviços e Tecnologia', style: 'tableHeader', fontSize: 12 },
                            ],
                            [{ text: 'Av. Engenheiro Domingos Ferreira, 4060, sala 706 ', style: 'tableHeader', fontSize: 8 },
                            ],
                            [{ text: 'CNPJ: 13.817.833/000191 Email: contato@trinityst.com.br', style: 'tableHeader', fontSize: 8 },
                            ],
                            [{ text: 'Fone: (81) 3339-6662 ', style: 'tableHeader', fontSize: 8 },
                            ]
                        ]
                    },

                    layout: 'noBorders'
                }, {
                    text: ['PEDIDO DE VENDA '+pedido.documento],
                    bold: true,
                    alignment: 'right',
                    fontSize: 15,
                    margin: [0, 80, 15, 0]
                },
            ]

        };
    }
    const reportHeaderEmpresaa = [
        {
            text: 'Dados da empresa',
            alignment: 'left',
            fontSize: 15,
            bold: true,
            margin: [10, 15, 0, 0],// left,top,rigth,botton

        }
    ];

    const reportHeadera = [
        {
            text: 'PEDIDO DE VENDA',
            alignment: 'right',
            fontSize: 15,
            bold: true,
            margin: [0, 90, 15, 0],// left,top,rigth,botton

        }
    ];

    const registros = itens.map((item) => {
        return [
            { text: item.descricao, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: item.unidade, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.icms), fontSize: 9, margin: [0, 2, 0, 2] },
            { text: Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.ipi), fontSize: 9, margin: [0, 2, 0, 2] },
            { text: Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.atendido), fontSize: 9, margin: [0, 2, 0, 2] },
            { text: Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.preco), fontSize: 9, margin: [0, 2, 0, 2] },
            { text: Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.total), fontSize: 9, margin: [0, 2, 0, 2] },
        ]
    })

    function dadosCliente() {
        return [{
            alignment: 'left',
            columns: [
                {
                    width: 50,
                    fontSize: 8,
                    text: 'Cliente'
                },
                {
                    width: '*',
                    fontSize: 8,
                    bold: true,
                    text: cliente.fantasia
                }

            ],
        },
        {
            alignment: 'left',
            columns: [
                {
                    width: 50,
                    fontSize: 8,
                    text: 'CPF / CNPJ'
                },
                {
                    width: '*',
                    fontSize: 8,
                    bold: true,
                    text: cliente.cgc
                }

            ],
        },
        {
            alignment: 'left',
            columns: [
                {
                    width: 50,
                    fontSize: 8,
                    text: 'Endereço'
                },
                {
                    width: '*',
                    fontSize: 8,
                    bold: true,
                    text: cliente.endereco
                }

            ],
        },
        {
            alignment: 'left',
            columns: [
                {
                    width: 50,
                    fontSize: 8,
                    text: 'Email'
                },
                {
                    width: '*',
                    fontSize: 8,
                    bold: true,
                    text: cliente.email
                }

            ],
        },
        {
            alignment: 'left',
            columns: [
                {
                    width: 50,
                    fontSize: 8,
                    text: 'Ponto de Ref.'
                },
                {
                    width: '*',
                    fontSize: 8,
                    bold: true,
                    text: cliente.pontoreferencia
                }

            ],
        }
        ,
        {
            alignment: 'left',
            columns: [
                {
                    margin: [0, 0, 0, 25],// left,top,rigth,botton
                    width: 50,
                    fontSize: 8,
                    text: 'Observação'
                },
                {
                    margin: [0, 0, 0, 25],// left,top,rigth,botton
                    width: '*',
                    fontSize: 8,
                    bold: true,
                    text: pedido.observacao
                }

            ],
        }, {
            alignment: 'left',
            columns: [
                {
                    margin: [0, 0, 0, 25],// left,top,rigth,botton
                    width: 50,
                    fontSize: 8,
                    text: 'FORMA DE PAGAMENTO '
                },
                {
                    margin: [0, 0, 0, 25],// left,top,rigth,botton
                    width: '*',
                    fontSize: 8,
                    bold: true,
                    text: pedido.descricaorecebimento
                }

            ],
        }
        ]
    }

    const details = [

        dadosCliente()
        ,
        {
            table: {
                headerRows: 1,
                margin: [0, 100, 15, 45],// left,top,rigth,botton
                widths: ['40%', '*', '*', '*', '*', '*', '*'],//redimenciona automaticamente as larguras
                body: [
                    [
                        { text: 'Descrição', style: 'tableHeader', fontSize: 8 },
                        { text: 'UND.', style: 'tableHeader', fontSize: 8 },
                        { text: 'ICMS', style: 'tableHeader', fontSize: 8 },
                        { text: 'IPI', style: 'tableHeader', fontSize: 8 },
                        { text: 'Qtd.', style: 'tableHeader', fontSize: 8 },
                        { text: 'Vl.Unit.', style: 'tableHeader', fontSize: 8 },
                        { text: 'Total', style: 'tableHeader', fontSize: 8 }
                    ],
                    ...registros
                ]
            },
            layout: 'headerLineOnly'
        },
        {
            //style: 'tableExample',
            margin: [390, 25, 0, 0],// left,top,rigth,botton
            alignment: 'right',
            table: {
                body: [
                    [{
                        border: [false, true, false, false], // left,top,rigth,botton
                        fillColor: '#eeeeee',
                        text: 'TOTAL PRODUTOS',
                        fontSize: 10,
                        alignment: 'right'
                    },
                    {
                        border: [false, true, false, false], // left,top,rigth,botton
                        fillColor: '#eeeeee',
                        text: Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(pedido.totalproduto),
                        fontSize: 10,
                        alignment: 'right'
                    }],

                    [{
                        border: [false, false, false, false], // left,top,rigth,botton
                        fillColor: '#eeeeee',
                        text: 'DESCONTO',
                        fontSize: 10,
                        alignment: 'right'
                    },
                    {
                        border: [false, false, false, false], // left,top,rigth,botton
                        fillColor: '#eeeeee',
                        text: Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(pedido.desconto),
                        fontSize: 10,
                        alignment: 'right'
                    }],
                    [{
                        border: [false, false, false, false], // left,top,rigth,botton
                        fillColor: '#eeeeee',
                        text: 'TOTAL',
                        fontSize: 10,
                        alignment: 'right'
                    },
                    {
                        border: [false, false, false, false], // left,top,rigth,botton
                        fillColor: '#eeeeee',
                        text: Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(pedido.total),
                        fontSize: 10,
                        alignment: 'right'
                    }]

                ]
            }
        },


    ];

    function footerInf(currentPage, pageCount) {
        return {
            columns: [
                {
                    text: 'Trinity Tecnologia - Antares Web',
                    bold: true,
                    italics: true,
                    alignment: 'left',
                    color: 'gray',
                    fontSize: 7,
                    margin: [10, 5, 0, 0],// left,top,rigth,botton
                    border: [false, false, false, true],
                },
                {
                    text: ["página " + currentPage + ' / ' + pageCount],
                    bold: true,
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 5, 20, 0],// left,top,rigth,botton
                    border: [false, true, false, false],
                },
            ]

        }

    };

    const docConfigurations = {
        pageSize: 'A4',
        pageMargins: [15, 120, 15, 40], // left,top,rigth,botton
        header: reportHeader,           //[reportHeader,reportHeaderEmpresa],
        content: [details],
        footer: footerInf
    }

    pdfMake.createPdf(docConfigurations).download();

}