// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';


import {
  Typography,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Tab, Tabs, Modal, InputAdornment
} from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd } from "../../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { blue } from '@material-ui/core/colors';

import Config from '../../../config/Config';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function converterParaFloat(valor) {
  if (valor !== null && valor !== '') {
    return parseFloat(valor);
  } else {
    return 0;
  }
}

export default function SellerRegister(props) {

  const [recarregar,SetRecarregar] = useState(true);
  const [recarregarCidade,SetRecarregarCidade] = useState(false);

  const [valueTab, setValueTab] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.vend.text);

  const [fildId, setFildId] = useState(props.location.idregistro.text);
  const [fildVendedor, setFildVendedor] = useState(dadosRegistro.vendedor );
  const [fildNome, setFildNome] = useState(dadosRegistro.nome );
  const [fildEndereco, setFildEndereco] = useState(dadosRegistro.endereco );
  const [fildBairro, setFildBairro] = useState(dadosRegistro.bairro);
  const [fildCidade, setFildCidade] = useState(dadosRegistro.cidade );
  const [fildUf, setFildUf] = useState(dadosRegistro.uf );
  const [codigoUf,setCodigoUf]=useState("");
  const [fildCep, setFildCep] = useState(dadosRegistro.cep );
  const [fildRg, setFildRg] = useState(dadosRegistro.rg );
  const [fildCpf, setFildCpf] = useState(dadosRegistro.cpf );
  const [fildFone, setFildFone] = useState(dadosRegistro.fone );
  const [fildComissao, setFildComissao] = useState(dadosRegistro.comissao);
  const [fildMeta, setFildMeta] = useState(dadosRegistro.meta);
  const [fildInativo, setFildInativo] = useState(dadosRegistro.inativo);
  const [fildEmail, setFildEmail] = useState(dadosRegistro.email );
  const [fildCelular, setFildCelular] = useState(dadosRegistro.celular );
  const [fildTabelaVendedor, setFildTabelaVendedor] = useState(dadosRegistro.tabelavendedor );
  const [fildDataCadastro, setFildDataCadastro] = useState("" );
  const [fildDataAlteracao, setFildDataAlteracao] = useState("" );
  const [fildResponsavel, setFildResponsavel] = useState(dadosRegistro.responsavel );

  //Fim dos campos do formulario

  const [exibirProgressMunicipios, setExibirProgressMunicipios] = useState(false);

  const [cepJaConsultado, setCepJaConsultado] = useState(false);
  const [cnpjJaConsultado, setCnpjJaConsultado] = useState(false);

  const [validaNome, setValidaNome] = useState(false);
  const [msgErroNome, setMsgErroNome] = useState('');

  //Inverte pois o cmapo é de INATIVO entao quando estiver INATIVO=TRUE
  const valoresSim_Nao = [
    {
      value: 'false',
      label: 'Sim',
    },
    {
      value: 'true',
      label: 'Não',
    }
  ];

  const [dadosMunicipio, setDadosMunicipios] = useState([]);

  const dadosUf = [
    {
      "cuf": "11",
      "uf": "RO"
    },
    {
      "cuf": "12",
      "uf": "AC"
    },
    {
      "cuf": "13",
      "uf": "AM"
    },
    {
      "cuf": "14",
      "uf": "RR"
    },
    {
      "cuf": "15",
      "uf": "PA"
    },
    {
      "cuf": "16",
      "uf": "AP"
    },
    {
      "cuf": "17",
      "uf": "TO"
    },
    {
      "cuf": "21",
      "uf": "MA"
    },
    {
      "cuf": "22",
      "uf": "PI"
    },
    {
      "cuf": "23",
      "uf": "CE"
    },
    {
      "cuf": "24",
      "uf": "RN"
    },
    {
      "cuf": "25",
      "uf": "PB"
    },
    {
      "cuf": "26",
      "uf": "PE"
    },
    {
      "cuf": "27",
      "uf": "AL"
    },
    {
      "cuf": "28",
      "uf": "SE"
    },
    {
      "cuf": "29",
      "uf": "BA"
    },
    {
      "cuf": "31",
      "uf": "MG"
    },
    {
      "cuf": "32",
      "uf": "ES"
    },
    {
      "cuf": "33",
      "uf": "RJ"
    },
    {
      "cuf": "35",
      "uf": "SP"
    },
    {
      "cuf": "41",
      "uf": "PR"
    },
    {
      "cuf": "42",
      "uf": "SC"
    },
    {
      "cuf": "43",
      "uf": "RS"
    },
    {
      "cuf": "50",
      "uf": "MS"
    },
    {
      "cuf": "51",
      "uf": "MT"
    },
    {
      "cuf": "52",
      "uf": "GO"
    },
    {
      "cuf": "53",
      "uf": "DF"
    },
    {
      "cuf": "00",
      "uf": "EX"
    }
  ];


  const handleChangeAtivo = (event) => {
    setFildInativo(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeInativo = (event) => {
    setFildInativo(event.target.value);
  }

  const handleChangeCep = (e) => {
    setFildCep(e);

    if (e.length !== 8) {
      setCepJaConsultado(false);
    }

    ConsultarCep();
  }

  const handleChangeCidade = (event) => {
    setFildCidade(event.target.value);
  }

  const handleChangeUf = (event) => {
    setFildUf(event.target.value);
    SetRecarregarCidade(true);

  }

  async function ConsultarCep() {

    if (fildCep.length === 8 && cepJaConsultado === false) {

      //Chama a API

      const response = await fetch('https://viacep.com.br/ws/' + fildCep + '/json/');

      let json = await response.json();

      if (json.erro === 'true') {

      }
      else {
        setCepJaConsultado(true);

        const c_uf = dadosUf.find(element => element.uf === json.uf).cuf;

        setFildUf(c_uf);

        setFildEndereco(json.logradouro);
        setFildBairro(json.bairro);
        setFildCidade(json.ibge);
      }

    }

  }

  //Litas

  // eslint-disable-next-line no-unused-vars, react-hooks/exhaustive-deps
  async function listarMunicipios(_uf) {

    //Chama o progress
    setExibirProgressMunicipios(true);

    //Chama a API

    const response = await fetch(`${Config.urlRootMaster}/coutryibge/list`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        cuf:codigoUf,
        cidade:"",
        order:""
      })
    });

    let json = await response.json();

    if(response.status!==200){
      setDadosMunicipios([]);
      setExibirProgressMunicipios(false);

      return;
    }

    setDadosMunicipios(json);
    setExibirProgressMunicipios(false);

  }
  //Fim Listas

  async function salvarDados() {

    if (fildNome.length === 0) {

      setValidaNome(true);
      setMsgErroNome('Informe o Nome do vendedor!');
    }
    else {
      //Chama o progress
      setExibirProgress(true);

      //Limpa a validação do campo
      setValidaNome(false);
      setMsgErroNome('');

      //Chama a API

      const registro = [{
        chave:parseInt(fildId, 10),
        vendedor: fildVendedor,
        nome: fildNome,
        endereco: fildEndereco,
        bairro: fildBairro,
        cidade: fildCidade,
        uf: fildUf,
        cep: fildCep,
        rg: fildRg,
        cpf: fildCpf,
        fone: fildFone,
        comissao: converterParaFloat(fildComissao),
        meta: converterParaFloat(fildMeta),
        inativo: fildInativo,
        email: fildEmail,
        celular: fildCelular,
        tabelaVendedor: fildTabelaVendedor
      }];

      const response = await fetch(`${Config.urlRoot}/salesman`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //id restornado
        setFildId(json[0].chave);
        // setFildVendedor(json.vendedor);
        // setFildNome(json.nome);
        // setFildEndereco(json.endereco);
        // setFildBairro(json.bairro);
        // setFildCidade(json.cidade);
        // setFildUf(json.uf);
        // setFildCep(json.cep);
        // setFildRg(json.rg);
        // setFildCpf(json.cpf);
        // setFildFone(json.fone);
        // setFildComissao(json.comissao);
        // setFildMeta(json.meta);
        // setFildInativo(json.inativo);
        // setFildEmail(json.email);
        // setFildCelular(json.celular);
        // setFildTabelaVendedor(json.tabelavendedor);
        setFildDataCadastro(json[0].created_at);
        setFildDataAlteracao(json[0].updated_at);
        // setFildResponsavel(json.responsavel);
      }

      //Encerra o progress
      setExibirProgress(false);

      handleOpenMessage();
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCodigoUF= (valor) => dadosUf.map(item => {
    if (item.uf === valor){

     setCodigoUf(item.cuf);

    }
  });

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {

    if (recarregarCidade) {
      if (fildUf.length > 0) {
        SetRecarregarCidade(false);
        getCodigoUF(dadosRegistro.uf);
        listarMunicipios(codigoUf);
      }
    }

  }, [codigoUf, dadosRegistro.uf, fildUf.length, getCodigoUF, listarMunicipios, recarregarCidade]);

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function botaoVoltar() {
    props.history.push('/app/Seller');
  }

  function botaoNovo() {

    setFildId('0');
    setFildVendedor('');
    setFildNome('');
    setFildEndereco('');
    setFildBairro('');
    setFildCidade('');
    setFildUf('');
    setFildCep('');
    setFildRg('');
    setFildCpf('');
    setFildFone('');
    setFildComissao(0.00);
    setFildMeta(0.00);
    setFildInativo('false');
    setFildEmail('');
    setFildCelular('');
    setFildTabelaVendedor('');
    setFildDataCadastro('');
    setFildDataAlteracao('');
    setFildResponsavel('');
  }


  return (
    <>

      <PageTitle title="Vendedor "

        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)}


      <Grid container spacing={2}>

        <Grid item xs={16} md={4} >
          <Card variant="outlined">

            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '65ch' }, }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="tfNome"
                  label="Nome"
                  size="small"
                  value={fildNome}
                  // // defaultValue={fildNome}
                  variant="filled"
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildNome(event.target.value)}
                  error={validaNome}
                  fullWidth
                />
              </div>

              <div>
                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 1, width: '31.5ch' }, }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="tfCpfCnpj"
                    label="CPF / CNPJ"
                    variant="filled"
                    size="small"
                    value={fildCpf}
                    // // defaultValue={fildCpf}
                    inputProps={{ maxLength: 14 }}
                    onChange={(event) => setFildCpf(event.target.value)}
                  />

                  <TextField
                    id="tfIe"
                    label="RG/Inscrição"
                    variant="filled"
                    value={fildRg}
                    size="small"
                    // // defaultValue={fildRg}
                    inputProps={{ maxLength: 18 }}
                    onChange={(event) => setFildRg(event.target.value)}
                  />

                </Box>
              </div>

              <div>
                <div>

                  <Box
                    component="form"
                    sx={{ '& .MuiTextField-root': { m: 1, width: '31.5ch' }, }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="tfFone"
                      label="Fone"
                      variant="filled"
                      value={fildFone}
                      // // defaultValue={fildFone}
                      inputProps={{ maxLength: 11 }}
                      onChange={(event) => setFildFone(event.target.value)}
                      fullWidth
                    // style={{width:186}}
                    />

                    <TextField
                      id="tfCelular"
                      label="Celular"
                      size="small"
                      variant="filled"
                      value={fildCelular}
                      // // defaultValue={fildCelular}
                      inputProps={{ maxLength: 11 }}
                      onChange={(event) => setFildCelular(event.target.value)}
                    // style={{width:186}}
                    />
                  </Box>
                </div>
              </div>

              <div>
                <TextField
                  id="tfEmail"
                  label="Email"
                  size="small"
                  variant="filled"
                  value={fildEmail}
                  // defaultValue={fildEmail}
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildEmail(event.target.value)}
                  fullWidth
                />
              </div>

              <div>

                <TextField
                  id="tfStatus"
                  select
                  label="Ativo"
                  size="small"
                  value={fildInativo}
                  onChange={handleChangeAtivo}
                  variant="filled"
                >
                  {valoresSim_Nao.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} >
          <Card variant="outlined">

            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '65ch' },
              }}
              noValidate
              autoComplete="off"
            >

              <div>

                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 1, width: '51.5ch' }, }}
                  noValidate
                  autoComplete="off"
                >

                  <div>
                    <FormControl fullWidth  size="small" sx={{ m: 1, width: '31.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfComissao">Comissão</InputLabel>
                      <FilledInput
                        id="tfComissao"
                        type={'number'}
                        value={fildComissao}
                        // defaultValue={fildComissao}
                        onChange={(event) => setFildComissao(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth  size="small"  sx={{ m: 1, width: '31.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfMeta">Meta</InputLabel>
                      <FilledInput
                        id="tfMeta"
                        type={'number'}
                        value={fildMeta}
                        // defaultValue={fildMeta}
                        onChange={(event) => setFildMeta(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>
                  </div>

                  <TextField
                    id="tfCep"
                    label="Cep"
                    variant="filled"
                    value={fildCep}
                    size="small"
                    // defaultValue={fildCep}
                    inputProps={{ maxLength: 8 }}
                    onChange={(event) => handleChangeCep(event.target.value)}
                  // onBlur={ConsultarCep()}
                  // style={{ width: 400 }}
                  />

                  <TextField
                    id="tfUf"
                    select
                    label="UF"
                    size="small"
                    value={fildUf}
                    onChange={handleChangeUf}
                    variant="filled"
                    style={{ width: 90 }}
                  >
                    {dadosUf.map((option) => (
                      <MenuItem key={option.cuf} value={option.cuf}>
                        {option.uf}
                      </MenuItem>
                    ))}
                  </TextField>

                </Box>

                <TextField
                  id="tfEndereco"
                  label="Endereço"
                  variant="filled"
                  value={fildEndereco}
                  size="small"
                  // defaultValue={fildEndereco}
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => setFildEndereco(event.target.value)}
                  fullWidth
                />
              </div>

              <div>
                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 1, width: '65ch' }, }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="tfBairro"
                    label="Bairro"
                    size="small"
                    variant="filled"
                    value={fildBairro}
                    // defaultValue={fildBairro}
                    inputProps={{ maxLength: 50 }}
                    onChange={(event) => setFildBairro(event.target.value)}
                    fullWidth
                  />

                </Box>
              </div>

              <div>
                {exibirProgressMunicipios && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

                <TextField
                  id="tfCidade"
                  select
                  size="small"
                  label="Cidade"
                  value={fildCidade}
                  onChange={handleChangeCidade}
                  variant="filled"
                // style={{width:400}}

                >
                  {dadosMunicipio.map((option) => (
                    <MenuItem key={option.cmunicipio} value={option.cmunicipio}>
                      {option.municipio}
                    </MenuItem>
                  ))}
                </TextField>

              </div>

            </Box>
          </Card>
        </Grid>


        <footer>

          <Grid container spacing={0}>
            <Grid item xs={12} md={12} style={{ marginLeft: 15, marginRight: 0 }}>
              <Card variant="outlined">
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '139ch' }}>

                  <FormControl  size="small"  sx={{ m: 1, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfDataCadastro">Data do Cadastro</InputLabel>
                    <FilledInput
                      id="tfDataCadastro"
                      value={fildDataCadastro}
                      // defaultValue={fildDataCadastro}
                      onChange={(event) => setFildDataCadastro(event.target.value)}
                    />
                  </FormControl>

                  <FormControl  size="small"  sx={{ m: 1, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfDataAlteracao">Data da Alteração</InputLabel>
                    <FilledInput
                      id="tfDataAlteracao"
                      value={fildDataAlteracao}
                      // defaultValue={fildDataAlteracao}
                      onChange={(event) => setFildDataAlteracao(event.target.value)}
                    />
                  </FormControl>

                  <FormControl  size="small"  sx={{ m: 1, width: '35ch' }} variant="filled">
                    <InputLabel htmlFor="tfResponsavel">Responsável</InputLabel>
                    <FilledInput
                      id="tfResponsavel"
                      value={fildResponsavel}
                      readOnly={true}
                      // defaultValue={fildResponsavel}
                      // onChange={(event) => setFildResponsavel(event.target.value)}
                    />
                  </FormControl>
                </Box>
              </Card>
            </Grid>
          </Grid>

        </footer>

      </Grid>


    </>
  );
}
