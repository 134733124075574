// import React from "react";
import * as React  from 'react';

export default function FormatarValor(menorDigito,maiorDigito,valor) {
    
    const options = { 
        currency: 'BRL', 
        minimumFractionDigits: menorDigito, 
        maximumFractionDigits:  maiorDigito,
      };
      
      const formatNumber = new Intl.NumberFormat('pt-BR', options);
  
      let number = valor;
  
    return (
        formatNumber.format(number)
    );
  }