import * as React from 'react';
import { useEffect, useState, useRef, useCallback } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions,Modal,Box,Grid,FormControl,TextField
,MenuItem,FormControlLabel,FilledInput,Checkbox } from '@mui/material';

import { blue } from '@material-ui/core/colors';

const styleDesconto = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,

}

export default function Desconto(props) {

  const modoDesconto = [
    {
      value: 'P',
      label: 'Total',
    },
    {
      value: 'I',
      label: 'Produto',
    }
  ];

  function formatarMoeda(v) {

    var valor = v;

    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if (valor === 'NaN') valor = '';

    if (valor.substr(0, 1) === ',') { valor = "0" + valor; }

    return valor;
  }

  return (
    <Modal
      open={props.openDesconto}
      onClose={props.handleCloseModalDesconto}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleDesconto}>

        <Typography style={{ marginLeft: 5 }}>DESCONTO</Typography>

        <Grid item xs={12} >
          <Card variant="outlined">
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1 }, }}
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth size="small" sx={{ m: 0.4 }} variant="filled">
                <TextField
                  id="tfModoDesconto"
                  select
                  label="Modo Desconto"
                  value={props.fildMododesconto}
                  defaultValue={'P'}
                  onChange={props.handleChangeMdoDesconto}
                  variant="filled"
                >
                  {modoDesconto.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <div>
                <FormControlLabel
                  sx={{ m: 0.4 }}
                  style={{ marginTop: 12 }}
                  label="%"
                  control={<Checkbox checked={props.opcaoDesconto[0]} onChange={props.handleChangeDescontoPercentual} />}
                />

                <FormControlLabel
                  style={{ marginTop: 12 }}
                  label="R$"
                  control={<Checkbox checked={props.opcaoDesconto[1]} onChange={props.handleChangeDescontoValor} />}
                />
              </div>

              {(props.visualizarItemNoDesconto) ? props.exibirItemParaDesconto() : null}

              <FormControl fullWidth size="small" sx={{ m: 0.4 }} variant="filled">
                <FilledInput
                  // type="number"
                  id="tfDescontoModal"
                  autoFocus={true}
                  value={props.fildDesconto}
                  // defaultValue={props.fildDesconto}

                  onChange={(event) => props.handleValorDesconto(formatarMoeda(event.target.value))}
                />
              </FormControl>

            </Box>
          </Card>
        </Grid>

        <Button variant="contained" color="error" style={{ margin: 5 }}
          onClick={props.handleCloseModalDesconto}>Voltar
        </Button>
        <Button variant="contained" color="success" style={{ margin: 5 }}
          onClick={props.validarDesconto}>Confirmar
        </Button>

      </Box>
    </Modal>
  );
}