// import React from "react";
import { Grid, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr from "../../components/LocalesPtBr";

import { LinearProgress,FormControl,InputLabel,InputAdornment,FilledInput, } from '@mui/material';

import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Button } from "../../components/Wrappers/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../context/UserContext";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import Config from '../../config/Config';
import AguardarRetorno from "../../components/BackDrop/AguardarRetorno";
moment.locale('pt-br');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: '', label: '' , minWidth: '5%' },
  { id: 'produto', label: 'Código' , minWidth: '20%' },
  { id: 'descricao', label: 'Descrição' , minWidth: '60%' },
  { id: 'dataatualizacao', label: 'Atualização' , minWidth: '15%' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ListProduct(props) {

  var userDispatch = useUserDispatch();

  const [recarregar,SetRecarregar] = useState(true);
  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [listaDados, setListaDados] = useState([]);
  const [idselecionado, setIdSelecionado] = useState(0);

  const [valorPesquisa, setValorPesquisa] = useState('');

  const [exibirProgress, setExibirProgress] = useState(true);

  const [listaCfop, setListaCfop] = useState([]);
  const [listaGrupo, setListaGrupo] = useState([]);
  const [listaPlanoContas, setListaPlanoContas] = useState([]);
  const [listaUnidade, setListaUnidade] = useState([]);
  const [listaMarca, setListaMarca] = useState([]);
  const [listaFornecedor, setListaFornecedor] = useState([]);
  const [listaSt, setListaSt] = useState([]);
  const [listaStPisCofins, setListaStPisCofins] = useState([]);


  const [carregarListaAuxiliar,SetCarregarListaAuxiliar] = useState(false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaDados.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function listarRegistros(valor) {

    if(valor===undefined){
      //caso nao tenha valor informado pega do campo
      valor=valorPesquisa;
    }

    const response = await fetch(`${Config.urlRoot}/product/search`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dataBase:configuracaoBd,
        pesquisar:valor,
        servico:'false',
        sort:""
      })
    });

    let json = await response.json();

    setExibirProgress(false);

    if(response.status!==200 && response.status!==201){
    setListaDados([]);
      return;
    }

    setListaDados(json);
  }

  async function listasAuxiliares() {

    const response = await fetch(`${Config.urlRoot}/product/listAux`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dataBase:configuracaoBd,
      })
    });

    let json = await response.json();

    setExibirProgress(false);

    if(response.status!==200 && response.status!==201){
      return;
    }

    setListaCfop(json.cfopList);
    setListaGrupo(json.grupoList);
    setListaPlanoContas(json.planocontasList);
    setListaUnidade(json.unidadeList);
    setListaMarca(json.marcaList);
    setListaFornecedor(json.fornecedorList);

    //As situacoes tributarias

    const response2 = await fetch(`${Config.urlRootMaster}/listsituacaotributaria/list`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dataBase:configuracaoBd,
      })
    });

    let json2 = await response2.json();

      if(response2.status!==200 && response2.status!==201){

        setListaSt([]);
        setListaStPisCofins([]);

        return;

      }
        setListaSt(json2.situacaoTributariaList);
        setListaStPisCofins(json2.situacaoTributariaListPisCofins);

  }

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {

    if(!carregarListaAuxiliar){
      SetCarregarListaAuxiliar(false);

      listasAuxiliares();
    }
  },[carregarListaAuxiliar]);

  useEffect(() => {

    if(recarregar){
      setExibirProgress(true);
      SetRecarregar(false);

      listarRegistros("");
    }

  }, [listarRegistros, recarregar]);

  const registroSelecionado = (id,index) => {

    if(listaSt.length===0){return;}
    if(listaStPisCofins.length===0){return;}


    setIdSelecionado(id);
    const prod = listaDados[index];

    props.history.push({
      pathname: '/app/ProductRegister',
      idregistro: { text: id },
      prod: { text: prod },
      cfops:{ text:listaCfop},
      grupos:{ text:listaGrupo},
      planocontas:{ text:listaPlanoContas},
      unidades:{ text:listaUnidade},
      marcas:{ text:listaMarca},
      fornecedores:{ text:listaFornecedor},
      lsSt:{ text:listaSt},
      lsStPisCofins:{ text:listaStPisCofins},


    });
  }

  const novoRegistro = () => {

    const prod={
      id:"0",
      produto: "",
      descricao: "",
      codigogrupo:"",
      custo: "",
      precovenda: 0,
      icms: 1,
      icmssub: 1,
      aliquota: 0,
      valorsugerido: 0,
      margem: 0,
      referencia: "",
      cbarra: "",
      unidade: "",
      st: "",
      cf: "",
      modelo: "",
      venda: "true",
      ipi: "",
      cstpiscofins: "",
      cstpis: "",
      cstcofins: "",
      aliquotapis: "",
      aliquotacofins: "",
      cest: "",
      aplicacao: "",
      mensagem: "",
      embalagem: "",
      pliquido: 1,
      pbruto: 1,
      codigofornecedor: "",
      qtdminima: "",
      planocontas: "",
      inativo: "true",
      descontomaximo: 0,
      datacadastro: "",
      dataatualizacao: "",
      fichatecnica: "",
      observacao: "",
      movimentaestoque: "true",
      cfopnfce: "",
      st_nfce: "",
      icms_nfce: 1,
      icmssub_nfce: 1,
      cstcofins_nfce: "",
      cstpis_nfce: "",
      aliquotapis_nfce: 0,
      aliquotacofins_nfce:0,
      cstipi_nfce: "",
      ipi_nfce: 0,
      qtdvolume: 1,
      similar: "",
      codigoanvisa: "",
      informacoesadicionais:"",
      marca: "",
      balanca: "false",
      comissao: 0,
      qtdvolumecompra: 1,
      pfcpufdest: 0,
      picmsinter: 0,
      picmsinterpart: 0,
      almoxarifado: "false",
      cstipi: "",
      pathimagem: "",
      diasvalidade: "",
      vendedores: "",
      modbcicmsst: "",
      modbcicms: "",
      servico:'false'

    }

    props.history.push({
      pathname: '/app/ProductRegister',
      idregistro: { text: '0' },
      prod:{text:prod},
      cfops:{ text:listaCfop},
      grupos:{ text:listaGrupo},
      planocontas:{ text:listaPlanoContas},
      unidades:{ text:listaUnidade},
      marcas:{ text:listaMarca},
      fornecedores:{ text:listaFornecedor},
      lsSt:{ text:listaSt},
      lsStPisCofins:{ text:listaStPisCofins},

    });
  }

  const pesquisarRegistros = (valor) => {
    setValorPesquisa(valor);
    listarRegistros(valor);

  }

  return (
    <>
      <PageTitle title="Produtos (Lista)" button="Novo" eventoBotao={novoRegistro}/>

      {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

      <AguardarRetorno show={impedirMultClick} />

      <TableContainer component={Paper}>

        <Box>
          <FormControl fullWidth sx={{ m: 0.6, width: '45ch' }} variant="filled">
            <InputLabel htmlFor="tfPesquisar">Pesquisar ...</InputLabel>
            <FilledInput
              id="tfPesquisar"
              size="small"
              // defaultValue={fildRazao||''}
              onChange={(e) => pesquisarRegistros(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => listarRegistros()}
                  >
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>}
            />
          </FormControl>
        </Box>

        <ThemeProvider theme={LocalesPtBr()}>
          <Table  size="small" >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? listaDados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : listaDados
              ).map((row,index) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    <IconButton size="small"
                        onClick={() => registroSelecionado(row.id,index)} >
                      <EditIcon fontSize="inherit" color={"success"} />
                    </IconButton>
                  </TableCell>

                  <TableCell
                    align="left">
                    {row.produto}
                  </TableCell>
                  <TableCell
                    align="left">
                    {row.descricao}
                  </TableCell>

                  <TableCell
                    align="left">
                    {(row.updatedAt !==null && row.updatedAt!=="")?
                    moment(row.updatedAt,"YYYY-MM-DD").format('DD/MM/YYYY'):""
                    }

                  </TableCell>

                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={listaDados.length}
                  rowsPerPage={rowsPerPage}
                  page={page}

                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Linhas por página',

                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </ThemeProvider>

      </TableContainer>
    </>
  );
}
