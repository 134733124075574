// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


import { useTheme, ThemeProvider } from '@mui/material/styles';
import LocalesPtBr from "../../../components/LocalesPtBr";



import {
  Typography,
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  Input,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Tab, Tabs, Modal
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import { Button } from "../../../components/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno } from "../../../context/UserContext";

import FilledInput from '@mui/material/FilledInput';
import AddIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';

import SearchIcon from '@mui/icons-material/Search';
import { blue } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';

import DadosCfop from "../../../constant/DadosCfop";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#536DFE",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: 'produto', label: 'Código' },
  // { id: 'referencia', label: 'Referência' },
  { id: 'descricao', label: 'Descrição' },
  { id: 'lote', label: 'Lote' },
  { id: 'fabricacao', label: 'Fabricação' },
  { id: 'validade', label: 'Validade' },
  { id: 'localizacao', label: 'Localização' },
  { id: 'quantidade', label: 'Quantidade' },
  { id: '', label: '' },
];

const columnsSearch = [
  { id: 'produto', label: 'Código' },
  { id: 'descricao', label: 'Descrição' },
  { id: '', label: '' },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Página anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima página"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function AdjustmentRegister(props) {

  const refQuantidade = useRef(null);

  const [listaDados, setListaDados] = useState([]);

  const [listaItens, setListaItens] = useState([]);

  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaItens.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Controle de paginacao da pesquisa de produtos
  const [pageProdutos, setPageProdutos] = React.useState(0);
  const [rowsPerPageProdutos, setRowsPerPageProdutos] = React.useState(5);
  const emptyRowsProdutos = pageProdutos > 0 ? Math.max(0, (1 + pageProdutos) * rowsPerPage - listaDados.length) : 0;

  const handleChangePageProdutos = (event, newPage) => {
    setPageProdutos(newPage);
  };

  const handleChangeRowsPerPageProdutos = (event) => {
    setRowsPerPageProdutos(parseInt(event.target.value, 10));
    setPageProdutos(0);
  };

  //Campos do formulario
  const [fildId, setFildId] = useState('');
  const [fildProduto, setFildProduto] = useState('');
  const [fildDescricao, setFildDescricao] = useState('');

  const [fildFabricacao, setFildFabricacao] = useState('');
  const [fildValidade, setFildValidade] = useState('');
  const [fildLote, setFildLote] = useState('');
  const [fildLocalizacao, setFildLocalizacao] = useState('');
  const [fildQuantidade, setFildQuantidade] = useState(0);
  const [fildDocumento, setFildDocumento] = useState('');
  const [fildLancamento, setFildLancamento] = useState(new Date());
  const [fildObservacao, setFildObservacao] = useState('');

  const [validaQuantidade, setValidaQuantidade] = useState(false);
  const [msgErroQuantidade, setMsgErroQuantidade] = useState('');

  //Fim dos campos do formulario
  const [valorPesquisa, setValorPesquisa] = useState('');
  //

  const [openPesquisar, setOpenPesquisar] = useState(false);
  const handleOpenModalPesquisar = () => setOpenPesquisar(true);
  const handleCloseModalPesquisar = () => setOpenPesquisar(false);

  //Progreess de envio
  const [openProgressEnvio, setOpenProgressEnvio] = React.useState(false);
  const handleCloseProgressEnvio = () => {
    setOpenProgressEnvio(false);
  };
  const handleToggleProgressEnvio = () => {
    setOpenProgressEnvio(!openProgressEnvio);
  };

  //------------------------------

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeFabricacao = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildFabricacao(newValue.toJSON());
      }
    }
  };

  const handleChangeValidade = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildValidade(newValue.toJSON());
      }
    }
  };

  const handleChangeLancamento = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setFildLancamento(newValue.toJSON());
      }
    }
  };

  const handleChangePesquisarProdutos = (valor) => {

    listarRegistros(valor);

  }

  function addRegistro() {

    if (fildId === "") { return; }

    //Validar os registro

    if (fildQuantidade === 0) {
      setValidaQuantidade(true);
      setMsgErroQuantidade('Não é permitido quantidade = 0 (ZERO)!');

      return;
    }

    //------------------------------------

    setValidaQuantidade(false);
    setMsgErroQuantidade('');


    setListaItens((prevItems) => {
      const newItem = {
        id: fildId,
        produto: fildProduto,
        descricao: fildDescricao,
        lote: fildLote,
        localizacao: fildLocalizacao,
        fabricacao: fildFabricacao,
        validade: fildValidade,
        quantidade: fildQuantidade,
      };

      return [...prevItems, newItem];
    });

    //Limpa os campos
    setFildId('');
    setFildProduto('');
    setFildDescricao('');
    setFildLote('');
    setFildLocalizacao('');
    setFildFabricacao('');
    setFildValidade('');
    setFildQuantidade(0);

  }

  function removeRegistro(id) {

    setListaItens((prevPosts) =>
      prevPosts.filter((_, index) => index !== id));

  }

  //Listas
  async function listarRegistros(valor) {

    setExibirProgress(true);

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiProdutos.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'listarResumido',
        senha: senhaRetorno,
        login: usuarioRetorno,
        condicao: valor
      })
    });

    let json = await response.json();

    setExibirProgress(false);

    if (json.success) {
      if (json.result === null) {
        setListaDados([]);

      } else {
        setListaDados(json.result);

      }
    }
    else { setListaDados([]); }

  }

  //Fim Listas

  async function salvarDados() {

    //Chama a API

    handleToggleProgressEnvio();

    const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiEstoque.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requisicao: 'save-item-ajuste',
        senha: senhaRetorno,
        login: usuarioRetorno,
        documento: fildDocumento,
        data: fildLancamento,
        observacao: fildObservacao,
        itens: listaItens
      })
    });

    let json = await response.json();

    if (!json.success) {

      setTipoMensagem('error');
      setTextoMensagem('Falha ao salvar o registro!');

    } else {

      setTipoMensagem('success');
      setTextoMensagem('Registro salvo com sucesso!');

      //id restornado
      setFildId(json.id);
    }

    //Encerra o progress
    handleCloseProgressEnvio();

    handleOpenMessage();

    //Limpar campos
    setListaItens([]);
    setFildId('');
    setFildProduto('');
    setFildDescricao('');
    setFildFabricacao(null);
    setFildValidade(null);
    setFildLote('');
    setFildLocalizacao('');
    setFildQuantidade(0);
    setFildDocumento('');
    setFildLancamento(null);
    setFildObservacao('');

  }

  // useEffect(() => {

  //   //É um novo Registro
  //   if (props.location.idregistro.text === undefined) {
  //     props.history.push('/app/ProviderRegister');

  //   }
  //   else if (props.location.idregistro.text === "0") {

  //     setExibirProgress(false);
  //     setNovoRegistro(true);

  //     listarPlanoContas();
  //     listarGrupos();
  //     listarRecebimentos();
  //     listarCfop();

  //   } else {

  //     setExibirProgress(true);

  //     listarPlanoContas();
  //     listarGrupos();
  //     listarRecebimentos();
  //     listarCfop();
  //     dadosCompleto();

  //   }

  // }, []);

  function preencherDados(id, produto, descricao) {
    setFildId(id);
    setFildProduto(produto);
    setFildDescricao(descricao);

    handleCloseModalPesquisar();

    refQuantidade.current.focus();

    setListaDados([]);

  }

  const [value, setValue] = React.useState(0);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const styleContato = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  }


  function modalPesquisaProdutos() {
    return (
      <Modal
        open={openPesquisar}
        onClose={handleCloseModalPesquisar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleContato}>


          <Grid item xs={12} md={2} >
            <Card variant="outlined">
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 0 }, }}
                noValidate
                autoComplete="off"
              >
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfModalPesquisadescricao">Pesquisar </InputLabel>
                  <FilledInput
                    id="tfModalPesquisadescricao"
                    variant="filled"
                    //value={valorPesquisa}
                    // defaultValue={valorPesquisa}
                    onChange={(e) => handleChangePesquisarProdutos(e.target.value)}
                    sx={{ m: 1 }}
                  />
                </FormControl>

                {exibirProgress && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                )}

                <ThemeProvider theme={LocalesPtBr()}>
                  <Table aria-label="custom pagination table">
                    <TableHead>
                      <TableRow>
                        {columnsSearch.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {(rowsPerPage > 0
                        ? listaDados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : listaDados
                      ).map((row) => (
                        <TableRow key={row.id}>

                          <TableCell
                            align="left">
                            {row.produto}
                          </TableCell>

                          <TableCell
                            // style={{ width: "25%" }}
                            align="left">
                            {row.descricao}
                          </TableCell>

                          <TableCell align="center">
                            <Button
                              size="small"
                              color={"success"}
                              onClick={() => preencherDados(row.id, row.produto, row.descricao)}
                            >
                              <CheckIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}

                      {emptyRowsProdutos > 0 && (
                        <TableRow style={{ height: 53 * emptyRowsProdutos }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={listaDados.length}
                          rowsPerPage={rowsPerPageProdutos}
                          page={pageProdutos}


                          onPageChange={handleChangePageProdutos}
                          onRowsPerPageChange={handleChangeRowsPerPageProdutos}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </ThemeProvider>

              </Box>
            </Card>
          </Grid>


          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalPesquisar}>Voltar
          </Button>

          {/* <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarContato}>Salvar
          </Button> */}

        </Box>
      </Modal>
    )
  }

  function progressEnvio() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openProgressEnvio}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <>

      {modalPesquisaProdutos()}
      {progressEnvio()}

      <PageTitle title="Ajuste de Estoque "

        buttonPrint="Imprimir"

        button="Salvar"
        eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      <Grid container spacing={2}>

        <Grid item xs={12} md={4} >
          <Card variant="outlined">

            <div>
              <FormControl variant="filled">
                <InputLabel htmlFor="tfDocumento">Documento</InputLabel>
                <FilledInput
                  id="tfDocumento"
                  variant="filled"
                  defaultValue=""
                  inputProps={{ maxLength: 20 }}
                  sx={{ m: 1 }}
                  onChange={(event) => setFildDocumento(event.target.value)}
                />
              </FormControl>
            </div>

            <FormControl variant="filled" >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  noValidate={false}
                  id="tfLancamento"
                  label="Lançamento"
                  inputFormat="DD/MM/YYYY"
                  value={fildLancamento}
                  onChange={handleChangeLancamento}
                  renderInput={(params) => <TextField sx={{ m: 1, width: '31.8ch' }} variant="filled" {...params} />}
                  sx={{ m: 1 }}
                />
              </LocalizationProvider>
            </FormControl>


            <FormControl variant="filled">
              <InputLabel htmlFor="tfResponsavel">Responsável</InputLabel>
              <FilledInput
                id="tfResponsavel"
                variant="filled"
                value={usuarioRetorno}
                defaultValue={usuarioRetorno}
                inputProps={{ maxLength: 255 }}
                sx={{ m: 1 }}
                readOnly={true}
              />
            </FormControl>

            {/* </Box> */}
          </Card>
        </Grid>

        <Grid item xs={12} md={8} >
          <Card variant="outlined">

            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="tfObservacao">Observação</InputLabel>
              <FilledInput
                id="tfObservacao"
                variant="filled"
                inputProps={{ maxLength: 255 }}
                multiline
                rows={4}
                sx={{ m: 1 }}

                value={fildObservacao}
                defaultValue={fildObservacao}

                onChange={(event) => setFildObservacao(event.target.value)}
              />
            </FormControl>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} >
          <Card variant="outlined">
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, }, }}
              noValidate
              autoComplete="off"
            >

              <Box sx={{ m: 1 }}>
                <Button fullWidth variant="contained" color="success" startIcon={<SearchIcon />}
                  size="large"
                  onClick={handleOpenModalPesquisar}
                >
                  Pesquisar
                </Button>
              </Box>

              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 0 }, }}
                noValidate
                autoComplete="off"
              >

                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfDescricaoProduto">Produto</InputLabel>
                  <FilledInput
                    id="tfDescricaoProduto"
                    variant="filled"
                    value={fildDescricao}
                    defaultValue={fildDescricao}
                    readOnly={true}
                    onChange={(event) => setFildDescricao(event.target.value)}
                    sx={{ m: 1 }}

                  />
                </FormControl>

                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfQuantidade">Quantidade</InputLabel>
                  <FilledInput
                    ref={refQuantidade}
                    id="tfQuantidade"
                    type="number"
                    variant="filled"
                    value={fildQuantidade}
                    defaultValue={fildQuantidade}
                    inputProps={{ maxLength: 10 }}
                    onChange={(event) => setFildQuantidade(event.target.value)}
                    sx={{ m: 1 }}
                    error={validaQuantidade}
                  />
                </FormControl>

                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfLote">Lote</InputLabel>
                  <FilledInput
                    id="tfLote"
                    variant="filled"
                    value={fildLote}
                    defaultValue={fildLote}
                    inputProps={{ maxLength: 30 }}
                    onChange={(event) => setFildLote(event.target.value)}
                    sx={{ m: 1 }}
                  />
                </FormControl>

                <FormControl variant="filled" sx={{ m: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      noValidate={false}
                      id="tfFabricacao"
                      label="Fabricação"
                      inputFormat="DD/MM/YYYY"
                      value={fildFabricacao}
                      onChange={handleChangeFabricacao}
                      renderInput={(params) => <TextField sx={{ m: 1, width: '31.6ch' }} variant="filled" {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl variant="filled" sx={{ m: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      noValidate={false}
                      id="tfValidade"
                      label="Validade"
                      inputFormat="DD/MM/YYYY"
                      value={fildValidade}
                      onChange={handleChangeValidade}
                      renderInput={(params) => <TextField sx={{ m: 1, width: '31.6ch' }} variant="filled" {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="tfLocalizacao">Localização</InputLabel>
                  <FilledInput
                    id="tfLocalizacao"
                    variant="filled"
                    value={fildLocalizacao}
                    defaultValue={fildLocalizacao}
                    inputProps={{ maxLength: 50 }}
                    onChange={(event) => setFildLocalizacao(event.target.value)}
                    sx={{ m: 1 }}
                  />
                </FormControl>

              </Box>

              <Box sx={{ m: 1 }}>
                <Button fullWidth variant="contained" color="primary" startIcon={<CheckIcon />}
                  size="large"
                  onClick={addRegistro}
                >
                  Confirmar
                </Button>
              </Box>

            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8} >
          <Card variant="outlined">
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, }, }}
              noValidate
              autoComplete="off"
            >

              <Typography id="modal-modal-title" variant="h6" component="h3"
                sx={{ m: 1 }}>
                Itens
              </Typography>

              <TableContainer component={Paper}>
                <ThemeProvider theme={LocalesPtBr()}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {(rowsPerPage > 0
                        ? listaItens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : listaItens
                      ).map((row, postIndex) => (
                        <TableRow key={row.id}>
                          {/* <TableCell component="th" scope="row">
                {row.id}
              </TableCell> */}

                          <TableCell
                            align="left">
                            {row.produto}
                          </TableCell>

                          {/* <TableCell
                          // style={{ width: "40%" }}
                          align="left">
                          {row.referencia}
                        </TableCell> */}

                          <TableCell
                            // style={{ width: "25%" }}
                            align="left">
                            {row.descricao}
                          </TableCell>

                          <TableCell
                            align="left">
                            {row.lote}
                          </TableCell>

                          <TableCell
                            align="left">
                            {row.fabricacao}
                          </TableCell>

                          <TableCell
                            align="left">
                            {row.validade}
                          </TableCell>

                          <TableCell
                            align="left">
                            {row.localizacao}
                          </TableCell>

                          <TableCell
                            align="left">
                            {row.quantidade}
                          </TableCell>

                          <TableCell align="center">

                            <Button size="small"
                              onClick={() => removeRegistro(postIndex)}
                            >
                              <DeleteIcon color={"error"} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}

                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={listaItens.length}
                          rowsPerPage={rowsPerPage}
                          page={page}

                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </ThemeProvider>
              </TableContainer>

            </Box>
          </Card>
        </Grid>

      </Grid>
    </>
  );
}
