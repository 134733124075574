import React,{useState } from 'react';
import { Grid,Box,IconButton } from "@material-ui/core";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

import {Home,} from "@material-ui/icons";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import CardRecords from "./components/CardRecords";
// import { Typography } from "../../components/Wrappers";


export default function Records( props) {

  const [openPage, setOpenPage] = useState(0);

  function eventOperacoesFinanceira(){props.history.push('/app/OperationFinance');}
  function eventCfop(){props.history.push('/app/Cfop');}
  function eventClient(){props.history.push('/app/Client');}
  function eventProvider(){props.history.push('/app/Provider');}
  function eventEmployee(){props.history.push('/app/Employee');}
  function eventProduct(){props.history.push('/app/Product');}
  function eventReceipt(){props.history.push('/app/Receipt');}
  function eventService(){props.history.push('/app/Service');}
  function eventTransport(){props.history.push('/app/Transport');}
  function eventSeller(){props.history.push('/app/Seller');}
  function eventPlanAccount(){props.history.push('/app/PlanAccount');}
  function eventCostCenter(){props.history.push('/app/CostCenter');}


  return (
    <>
      <PageTitle title="Opções" />
      <Grid container spacing={2}>

        <Grid item xs={6} md={3} >

          <CardRecords
            titulo={"Operações Financeiras"}
            opcao={1}
            descricao={"Contas bancárias ou contas de movimento."}
            optionClick={eventOperacoesFinanceira}
          />

        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Centro de Custos"}
         opcao={2}
         descricao={"Descricao do centro de custo"}
         optionClick={eventCostCenter}
         />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords
         titulo={"CFOP"}
         opcao={3}
         descricao={"Cadastro das operações de Entrada e Saída."}
         optionClick={eventCfop}
         />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Clientes"} opcao={4}
         descricao={"Participantes das operações de Saída. "}
         optionClick={eventClient}
         />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords
         titulo={"Fornecedores"} opcao={5}
         descricao={"Participantes das operações de Entrada. "}
         optionClick={eventProvider}
         />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Funcionários"} opcao={6}
         descricao={"Participante das operações de serviço (O.S.). "}
         optionClick={eventEmployee}
         />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Produtos"} opcao={7}
         descricao={"Utilizados nas operações de Entrada e Saída."}
         optionClick={eventProduct}
          />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Plano de Contas"}
         opcao={8}
         descricao={"Descricao "}
         optionClick={eventPlanAccount}
         />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords
         titulo={"Formas de Pagamento"}
         opcao={9}
         descricao={"Utilizadas nas operações que utiliza pagamentos/recebimentos."}
         optionClick={eventReceipt}
          />
        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Serviços"}
         opcao={10}
         descricao={"Itens utilizados nas operações com O.S. e Saída.  "}
         optionClick={eventService}
         />
        </Grid>

        {/* <Grid item xs={6} md={3}>
         <CardRecords titulo={"Status"} opcao={11} descricao={"Define estágio de etapas em operações de saída. "} />
        </Grid> */}

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Transportes"}
         opcao={12}
         descricao={"Transportador para operações Entrada e Saída de mercadorías. "}
         optionClick={eventTransport}
         />

        </Grid>

        <Grid item xs={6} md={3}>
         <CardRecords titulo={"Vendedores"}
         opcao={13}
         descricao={"Direcionadas nas operações de saída."}
         optionClick={eventSeller}
         />
        </Grid>

      </Grid>
    </>
  );
}
