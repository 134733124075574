// import React from "react";
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


import {
  Typography,
  FormControl,
  TextField,
  Grid,
  Paper,
  InputLabel,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Modal, InputAdornment, FormControlLabel, Checkbox,
  FormHelperText, CircularProgress,
  Backdrop, Stepper, Step, StepLabel, StepContent, Input, CardContent
} from '@mui/material';

import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle";
import { Button } from "../../../components/Wrappers";

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { blue } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";

import DadosCfop from "../../../constant/DadosCfop";

import Config from '../../../config/Config';
import AguardarRetorno from "../../../components/BackDrop/AguardarRetorno";

export default function ClientRegister(props) {

  const [recarregar,SetRecarregar] = useState(true);
  const [recarregarCidade,SetRecarregarCidade] = useState(false);

  const [openMessage, setOpenMessage] = useState(false);
  const [state, setState] = useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  const [exibirProgressMunicipios, setExibirProgressMunicipios] = useState(false);
  const [exibirProgressRecebimentos, setExibirProgressRecebimentos] = useState(false);
  const [exibirProgressCfop, setExibirProgressCfop] = useState(false);
  const [exibirProgressCategorias, setExibirProgressCategorias] = useState(false);
  const [exibirProgressTabelaPreco, setExibirProgressTabelaPreco] = useState(false);
  const [exibirProgressVendedor, setExibirProgressVendedor] = useState(false);
  const [exibirProgressPlanoContas, setExibirProgressPlanoContas] = useState(false);
  const [exibirProgressContatos, setExibirProgressContatos] = useState(false);


  const [lsCfop, setLsCfop] = useState(props.location.cfops.text);
  const [lsRecebimento, setLsRecebimento] = useState(props.location.recebimentos.text);
  const [lsCategorias, setLsCategorias] = useState(props.location.grupos.text);
  const [lsTabelaPreco, setLsTabelaPreco] = useState(props.location.tabelaprecos.text);
  const [lsPlanoContas, setLsPlanoContas] = useState(props.location.planocontas.text);
  const [lsVendedores, setLsVendedores] = useState(props.location.vendedores.text);
  const [lsContatos, setLsContatos] = useState([]);


  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.client.text);
  const [fildId, setFildId] = useState(props.location.idregistro.text);
  const [fildCodigo, setFildCodigo] = useState(dadosRegistro.codigo);
  const [fildCodigoAuxiliar, setFildCodigoAuxiliar] = useState(dadosRegistro.codigoauxiliar);
  const [fildRazao, setFildRazao] = useState(dadosRegistro.razao);
  const [fildEndereco, setFildEndereco] = useState(dadosRegistro.endereco);
  const [fildNumero, setFildNumero] = useState(dadosRegistro.numero);
  const [fildComplemento, setFildComplemento] = useState(dadosRegistro.complemento);
  const [fildBairro, setFildBairro] = useState(dadosRegistro.bairro);
  const [fildCodigoCidade, setFildCodigoCidade] = useState(dadosRegistro.codigocidade);
  const [fildCidade, setFildCidade] = useState(dadosRegistro.cidade);
  const [fildCodigoUf, setFildCodigoUf] = useState(dadosRegistro.codigouf);
  const [fildUf, setFildUf] = useState(dadosRegistro.uf);
  const [fildCep, setFildCep] = useState(dadosRegistro.cep);
  const [fildInscricao, setFildInscricao] = useState(dadosRegistro.inscricao);
  const [fildCgc, setFildCgc] = useState(dadosRegistro.cgc);
  const [fildFone, setFildFone] = useState(dadosRegistro.fone);
  const [fildFax, setFildFax] = useState(dadosRegistro.fax);
  const [fildCelular, setFildCelular] = useState(dadosRegistro.celular);
  const [fildContato, setFildContato] = useState(dadosRegistro.contato);
  const [fildCodigoVendedor, setFildCodigoVendedor] = useState(dadosRegistro.codigovendedor);
  const [fildVendedor, setFildVendedor] = useState(dadosRegistro.vendedor);
  const [fildComissao, setFildComissao] = useState(dadosRegistro.comissao);
  const [fildLimite, setFildLimite] = useState(dadosRegistro.limite);
  const [fildFantasia, setFildFantasia] = useState(dadosRegistro.fantasia);
  const [fildAtivo, setFildAtivo] = useState(dadosRegistro.ativo);
  const [fildCodigoGrupo, setFildCodigoGrupo] = useState(dadosRegistro.codigogrupo);
  const [fildDescricaoGrupo, setFildDescricaoGrupo] = useState(dadosRegistro.descricaogrupo);
  const [fildObservacoes, setFildObservacoes] = useState(dadosRegistro.observacoes);
  const [fildEmail, setFildEmail] = useState(dadosRegistro.email);
  const [fildPlanoContas, setFildPlanoContas] = useState(dadosRegistro.planocontas);
  const [fildCodigoVendedor2, setFildCodigoVendedor2] = useState(dadosRegistro.codigovendedor2 );
  const [fildVendedor2, setFildVendedor2] = useState(dadosRegistro.vendedor2 );
  const [fildComissao2, setFildComissao2] = useState(dadosRegistro.comissao2);
  const [fildPontoReferencia, setFildPontoReferencia] = useState(dadosRegistro.referencia );
  const [fildCodigoRecebimento, setFildCodigoRecebimento] = useState(dadosRegistro.codigorecebimento );
  const [fildRecebimento, setFildRecebimento] = useState(dadosRegistro.recebimento );
  const [fildTabelapreco, setFildTabelapreco] = useState(dadosRegistro.tabelapreco );
  const [fildPais, setFildPais] = useState(dadosRegistro.pais );
  const [fildInscricaoMunicipal, setFildInscricaoMunicipal] = useState(dadosRegistro.inscricaomunicipal );
  const [fildCfopPadrao, setFildCfopPadrao] = useState(dadosRegistro.cfoppadrao );
  const [fildClienteDadosAdicionais, setFildClienteDadosAdicionais] = useState("");
  const [fildDataCadastro, setFildDataCadastro] = useState(dadosRegistro.datacadastro );
  const [fildDataAlteracao, setFildDataAlteracao] = useState(dadosRegistro.dataalteracao );
  const [fildResponsavel, setFildResponsavel] = useState(dadosRegistro.responsavel );
  //Fim dos campos do formulario

  // Campos formulario Contato
  const [contatoId, setContatoId] = useState('');
  const [contatoCliente, setContatoCliente] = useState('');
  const [contatoNome, setContatoNome] = useState('');
  const [contatoCargo, setContatoCargo] = useState('');
  const [contatoFone, setContatoFone] = useState('');
  const [contatoEmail, setContatoEmail] = useState('');
  const [contatoAniversario, setContatoAniversario] = useState(null);
  const [contatoSexo, setContatoSexo] = useState('');
  const [contatoFone2, setContatoFone2] = useState('');

  //modal de contatos
  const [openContato, setOpenContato] = useState(false);
  const handleOpenModalContato = () => setOpenContato(true);
  const handleCloseModalContato = () => setOpenContato(false);
  const [idContato, setIdContato] = useState('0');

  //Fim Campos formulario Contato

  const [dadosMunicipio, setDadosMunicipios] = useState([]);
  const dadosUf = [
    {
      "cuf": "11",
      "uf": "RO",
      "nome": "Rondônia"
    },
    {
      "cuf": "12",
      "uf": "AC",
      "nome": "Acre"
    },
    {
      "cuf": "13",
      "uf": "AM",
      "nome": "Amazonas"
    },
    {
      "cuf": "14",
      "uf": "RR",
      "nome": "Roraima"
    },
    {
      "cuf": "15",
      "uf": "PA",
      "nome": "Pará"

    },
    {
      "cuf": "16",
      "uf": "AP",
      "nome": "Amapá"
    },
    {
      "cuf": "17",
      "uf": "TO",
      "nome": "Tocantins"
    },
    {
      "cuf": "21",
      "uf": "MA",
      "nome": "Maranhão"
    },
    {
      "cuf": "22",
      "uf": "PI",
      "nome": "Piauí"
    },
    {
      "cuf": "23",
      "uf": "CE",
      "nome": "Ceara"
    },
    {
      "cuf": "24",
      "uf": "RN",
      "nome": "Rio Grande do Norte"

    },
    {
      "cuf": "25",
      "uf": "PB",
      "nome": "Paraíba"

    },
    {
      "cuf": "26",
      "uf": "PE",
      "nome": "Pernambuco"
    },
    {
      "cuf": "27",
      "uf": "AL",
      "nome": "Alagoas"
    },
    {
      "cuf": "28",
      "uf": "SE",
      "nome": "Sergipe"
    },
    {
      "cuf": "29",
      "uf": "BA",
      "nome": "Bahia"
    },
    {
      "cuf": "31",
      "uf": "MG",
      "nome": "Minas Gerais"
    },
    {
      "cuf": "32",
      "uf": "ES",
      "nome": "Espírito Santo"
    },
    {
      "cuf": "33",
      "uf": "RJ",
      "nome": "Rio de janeiro"
    },
    {
      "cuf": "35",
      "uf": "SP",
      "nome": "São Paulo"
    },
    {
      "cuf": "41",
      "uf": "PR",
      "nome": "Paraná"
    },
    {
      "cuf": "42",
      "uf": "SC",
      "nome": "Santa Catarina"

    },
    {
      "cuf": "43",
      "uf": "RS",
      "nome": "Rio Grande do Sul"
    },
    {
      "cuf": "50",
      "uf": "MS",
      "nome": "Mato Grosso do Sul"
    },
    {
      "cuf": "51",
      "uf": "MT",
      "nome": "Mato Gorsso"
    },
    {
      "cuf": "52",
      "uf": "GO",
      "nome": "Goiás"
    },
    {
      "cuf": "53",
      "uf": "DF",
      "nome": "Distrito Federal"
    },
    {
      "cuf": "00",
      "uf": "EX",
      "nome": "Exterior"
    }
  ];

  const [openModalMunicipios, setOpenModalMunicipios] = useState(false);
  const [currency, setCurrency] = useState(fildId);

  const [validaRazao, setValidaRazao] = useState(false);
  const [msgErroRazao, setMsgErroRazao] = useState('');

  const [validaFantasia, setValidaFantasia] = useState(false);
  const [msgErroFantasia, setMsgErroFantasia] = useState('');

  const [validaCgc, setValidaCgc] = useState(false);
  const [msgErroCgc, setMsgErroCgc] = useState('');

  const [validaUf, setValidaUf] = useState(false);
  const [msgErroUf, setMsgErroUf] = useState('');

  const [cepJaConsultado, setCepJaConsultado] = useState(false);
  const [cnpjJaConsultado, setCnpjJaConsultado] = useState(false);

  const [descricaoNovoGrupo, setDescricaoNovoGrupo] = useState('');
  const [openGrupo, setOpenGrupo] = useState(false);
  const handleOpenModalGrupo = () => setOpenGrupo(true);
  const handleCloseModalGrupo = () => setOpenGrupo(false);
  const [idGrupo, setIdGrupo] = useState('0');

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const valoresSim_Nao = [
    {
      value: 'true',
      label: 'Sim',
    },
    {
      value: 'false',
      label: 'Não',
    }
  ];


  const handleChange = (event) => {
    // setFildBanco(event.target.value);
  };

  const handleChangeAtivo = (event) => {
    setFildAtivo(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleChangeUf = (event) => {
    setFildCodigoUf(event.target.value);
    SetRecarregarCidade(true);
  }

  const handleChangeGrupo = (event) => {
    setFildCodigoGrupo(event.target.value);
  }

  const handleChangeTabelaPreco = (event) => {
    setFildTabelapreco(event.target.value);
  }

  const handleChangePlanoContas = (event) => {
    setFildPlanoContas(event.target.value);
  }

  const handleChangeRecebimento = (event) => {
    setFildCodigoRecebimento(event.target.value);
  }

  const handleChangeVendedor = (event) => {
    setFildCodigoVendedor(event.target.value);
  }

  const handleChangeVendedor2 = (event) => {
    setFildCodigoVendedor2(event.target.value);
  }

  const handleChangeCidade = (event) => {
    setFildCodigoCidade(event.target.value);
  }

  const handleChangeCfop = (event) => {
    setFildCfopPadrao(event.target.value);
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeCep = (e) => {
    setFildCep(e);

    if (e.length !== 8) {
      setCepJaConsultado(false);
    }

    ConsultarCep();
  }

  const handleChangeCnpj = (e) => {
    setFildCgc(e);

    if (e.length !== 14) {
      setCnpjJaConsultado(false);
      //return;
    }

  }

  const handleChangeEditGrupo = (valor, chave) => {
    setIdGrupo(chave);
    setDescricaoNovoGrupo(valor);
    //chama o modal
    handleOpenModalGrupo();
  }

  const handleChangeEditContato = (index) => {

    dadosCompletoContato(index);
  }

  const handleChangeContato = (event) => {
    setFildContato(event.target.value);
  }

  const handleChangeNascimento = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setContatoAniversario(newValue.toJSON());
      }
    }
  };

  //Listas

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function listarMunicipios(_uf) {

    //Chama o progress
    setExibirProgressMunicipios(true);

    //Chama a API

    const response = await fetch(`${Config.urlRootMaster}/coutryibge/list`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        cuf:fildCodigoUf,
        cidade:"",
        order:""
      })
    });

    let json = await response.json();

    if(response.status!==200){
      setDadosMunicipios([]);
      setExibirProgressMunicipios(false);

      return;
    }

    setDadosMunicipios(json);
    setExibirProgressMunicipios(false);

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function listarContatos(codigoCliente) {

    setExibirProgressContatos(true);

    const response = await fetch(`${Config.urlRoot}/contactcostumer/list`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        pesquisar:codigoCliente,
        order:""
      })
    });

    let json = await response.json();

    if(response.status!==200){
      setLsContatos([]);
      setExibirProgressContatos(false);

      return;
    }

    setLsContatos(json);

    setExibirProgressContatos(false);
  }

  async function ConsultarCep() {
    if (fildCep.length === 8 && cepJaConsultado === false) {

      //Chama a API

      const response = await fetch('https://viacep.com.br/ws/' + fildCep + '/json/');

      let json = await response.json();

      if (json.erro === 'true') {

      }
      else {
        setCepJaConsultado(true);

        const c_uf = dadosUf.find(element => element.uf === json.uf).cuf;

        setFildCodigoUf(c_uf);

        setFildEndereco(json.logradouro);
        setFildComplemento(json.complemento);
        setFildBairro(json.bairro);
        setFildCodigoCidade(json.ibge);
      }

    }

  }

  async function ConsultarCnpj() {
    setimpedirMultClick(true);
    if (fildCgc.length === 14) {

      const response = await fetch('https://www.trinityst.com.br/apiAntares/apiUtilitarios.php', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          requisicao: 'consulta-cnpj',
          senha: senhaRetorno,
          login: usuarioRetorno,
          cnpj: fildCgc
        })
      });

      let json = await response.json();

      if (json.status === "ERROR") {

        setimpedirMultClick(false);

        setTipoMensagem('error');
        setTextoMensagem(json.message);
        handleOpenMessage();

      } else {

        //const itemExists = dadosUf.find((item) => item.uf === json.uf);

        dadosUf.map((item) => {
          if (item.uf.toLowerCase() === json.uf.toLowerCase()) {

            setFildCodigoUf(item.cuf);
          }
        });

        setTimeout(() => {

          dadosMunicipio.map((item) => {

            if (item.municipio.toLowerCase() === json.municipio.toLowerCase()) {

              setFildCodigoCidade(item.cmunicipio);
            }
          });

        }, 4000);

        setFildRazao(json.nome);
        setFildFantasia(json.fantasia);
        setFildBairro(json.bairro);
        setFildEndereco(json.logradouro);
        setFildComplemento(json.complemento);
        setFildNumero(json.numero);
        setFildCep(json.cep);

        try { setFildFone(json.telefone); } catch { }
        try { setFildEmail(json.email); } catch { }

        setimpedirMultClick(false);

      }

    }

  }

  //Fim Listas

  async function salvarGrupo() {

    if (descricaoNovoGrupo.length === 0) {
      return;
    }
    else {

      setExibirProgressCategorias(true);

      //Chama a API

      const registro = [{
        categoria: idGrupo,
        descricao: descricaoNovoGrupo
      }];

      const response = await fetch(`${Config.urlRoot}/category`, {
        method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201 ){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //atualiza na tela os dados pois tem que atualizar a data de  cadastro e atualizacao e o responsavel
        setLsCategorias(json);

      }

      //Encerra o progress
      setIdGrupo('0');
      setDescricaoNovoGrupo('');
      setExibirProgressCategorias(false);
      handleCloseModalGrupo();
    }
  }

  async function salvarContato() {

    if (fildCodigo.length === 0 || fildCodigo==="0") {
      return;
    }

      setExibirProgressContatos(true);
      //Chama a API

      const registro = [{
        codigo:contatoId,
        cliente: fildCodigo,
        nome: contatoNome,
        cargo: contatoCargo,
        fone: contatoFone,
        email: contatoEmail,
        aniversario: contatoAniversario,
        fone2: contatoFone2
      }];

      const response = await fetch(`${Config.urlRoot}/contactcostumer`, {
        method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201 ){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //atualiza na tela os dados pois tem que atualizar a data de  cadastro e atualizacao e o responsavel
        setLsContatos(json);
      }

      //Encerra o progress
      setIdContato('0');
      setContatoNome('');
      setContatoCargo('');
      setContatoFone('');
      setContatoEmail('');
      setContatoAniversario(null);
      setContatoFone2('');

      setExibirProgressContatos(false);
      handleCloseModalContato();

  }

  async function salvarDados() {

    if (fildRazao.length === 0) {
      setValidaRazao(true);
      setMsgErroRazao('Informe a Razão Social!');
    }

    if (fildFantasia.length === 0) {
      setValidaFantasia(true);
      setMsgErroFantasia('Informe o Nome Fantasia!');
    }

    if (fildCgc.length !== 11 && fildCgc.length !== 14) {
      setValidaCgc(true);
      setMsgErroCgc('CPF/CNPJ inválido!');
    }

    if (fildRazao.length === 0) {

      setValidaRazao(true);
      setMsgErroRazao('Informe a Razão Social!');
    }
    else {

      //Limpa a validação do campo
      setValidaRazao(false);
      setMsgErroRazao('');

      setValidaFantasia(false);
      setMsgErroFantasia('');

      //Chama a API

      const registro = [{
        id: parseInt(fildId, 10) ,
        codigoauxiliar: fildCodigoAuxiliar,
        razao: fildRazao,
        endereco: fildEndereco,
        numero: fildNumero,
        complemento: fildComplemento,
        bairro: fildBairro,
        codigocidade: fildCodigoCidade,
        codigouf: fildCodigoUf,
        cep: fildCep,
        inscricao: fildInscricao,
        cgc: fildCgc,
        fone: fildFone,
        fax: fildFax,
        celular: fildCelular,
        contato: fildContato,
        codigovendedor: fildCodigoVendedor,
        comissao: fildComissao,
        fantasia: fildFantasia,
        ativo: fildAtivo,
        codigogrupo: fildCodigoGrupo,
        observacoes: fildObservacoes,
        email: fildEmail,
        // dsp: fildDsp,
        planocontas: fildPlanoContas,
        codigovendedor2: fildCodigoVendedor2,
        comissao2: fildComissao2,
        pontoreferencia: fildPontoReferencia,
        codigorecebimento: fildCodigoRecebimento,
        tabelapreco: fildTabelapreco,
        pais: fildPais,
        inscricaomunicipal: fildInscricaoMunicipal,
        cfoppadrao: fildCfopPadrao,
        responsavel: usuarioRetorno
      }];

      setimpedirMultClick(true);
      //SALVA ou ATUALIZA o registro
      const response = await fetch(`${Config.urlRoot}/costumer`, {
        method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201 ){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      }

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //id restornado
        setFildId(json[0].id);
        setNovoRegistro(false);

        setFildCodigo(json[0].codigo);
        // setFildCodigoAuxiliar(json.codigoauxiliar);
        // dadosRegistro.razao=json.razao;
        // setFildRazao(json.razao);
        // setFildEndereco(json.endereco);
        // setFildNumero(json.numero);
        // setFildComplemento(json.complemento);
        // setFildBairro(json.bairro);
        // setFildCodigoCidade(json.codigocidade);
        // setFildCodigoUf(json.codigouf);
        // setFildUf(json.uf);
        // setFildCep(json.cep);
        // setFildInscricao(json.inscricao);
        // setFildCgc(json.cgc);
        // setFildFone(json.fone);
        // setFildFax(json.fax);
        // setFildCelular(json.celular);
        // setFildContato(json.contato);
        // setFildCodigoVendedor(json.codigovendedor);
        // setFildComissao(json.comissao);
        // setFildLimite(json.limite);
        // setFildFantasia(json.fantasia);
        // setFildAtivo(json.ativo);
        // setFildCodigoGrupo(json.codigogrupo);
        // setFildObservacoes(json.observacoes);
        // setFildEmail(json.email);
        // // setFildDsp(json.dsp);
        // setFildPlanoContas(json.planocontas);
        // // setFildValorMeta(json.valormeta);
        // setFildCodigoVendedor2(json.codigovendedor2);
        // setFildVendedor2(json.vendedor2);
        // setFildComissao2(json.comissao2);
        // setFildPontoReferencia(json.pontoreferencia);
        // setFildCodigoRecebimento(json.codigorecebimento);
        // setFildTabelapreco(json.tabelapreco);
        // setFildPais(json.pais);
        // setFildInscricaoMunicipal(json.inscricaomunicipal);
        // setFildCfopPadrao(json.cfoppadrao);
        // setFildClienteDadosAdicionais(json.clienteadicionaias);
        setFildDataCadastro(json[0].createdAt);
        setFildDataAlteracao(json[0].updatedAt);
        // setFildResponsavel(json.responsavel);

        SetRecarregar(false);

        //Encerra o progress
        setimpedirMultClick(false);

        handleOpenMessage();
    }
  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {

    if (recarregarCidade) {
      if (fildCodigoUf.length > 0) {
        SetRecarregarCidade(false);
        listarMunicipios(fildCodigoUf);
      }
    }

  }, [fildCodigoUf, listarMunicipios, recarregarCidade]);

  useEffect(() => {

    //É um novo Registro
    if (props.location.idregistro.text === undefined) {
      props.history.push('/app/ClientRegister');

    }
    else if (props.location.idregistro.text === "0") {

      setExibirProgress(false);
      setNovoRegistro(true);

    } else {
      if(recarregar){
        // setExibirProgress(true);
        listarContatos(props.location.client.text.codigo);

        SetRecarregar(false);

        SetRecarregarCidade(true);

      }

    }

  }, [props.history, props.location.client.text.codigo, props.location.idregistro.text, recarregar]);


  async function dadosCompletoContato(index) {

    const registro={...lsContatos[index]};

    setContatoId(registro.codigo);
    setContatoCliente(registro.cliente);
    setContatoNome(registro.nome);
    setContatoCargo(registro.cargo);
    setContatoFone(registro.fone);
    setContatoEmail(registro.email);

    if (registro.admissao !== '') {
      setContatoAniversario(dayjs(registro.aniversario));
    }

    // setContatoSexo(registro.sexo);
    setContatoFone2(registro.fone2);

    setExibirProgressCategorias(false);
    //chama o modal
    handleOpenModalContato();
  }

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModalMunicipios = () => {
    setOpenModalMunicipios(true);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const styleContato = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  function modalGrupos() {

    return (
      <Modal
        open={openGrupo}
        onClose={handleCloseModalGrupo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h3">
            Novo Grupo

            <FormControl fullWidth size="small" sx={{ m: 0 }} variant="filled">
              <InputLabel htmlFor="tfNovoGrupo">Descrição</InputLabel>
              <FilledInput
                id="tfNovoGrupo"
                variant="filled"
                value={descricaoNovoGrupo}
                //defaultValue={descricaoNovoGrupo}
                inputProps={{ maxLength: 20 }}
                onChange={(event) => setDescricaoNovoGrupo(event.target.value)}
              />
            </FormControl>

          </Typography>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalGrupo}>Cancelar
          </Button>

          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarGrupo}>Salvar
          </Button>

        </Box>
      </Modal>
    )
  }

  function modalContatos() {
    return (
      <Modal
        open={openContato}
        onClose={handleCloseModalContato}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleContato}>

          <Typography id="modal-modal-title" variant="h6" component="h3">
            Contato
            <Grid item xs={12} md={4} >
              <Card variant="outlined">
                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 0, width: '45ch' }, }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl fullWidth size="small" sx={{ m: 1, width: '36.8ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoNome">Nome</InputLabel>
                    <FilledInput
                      id="tfContatoNome"
                      variant="filled"
                      value={contatoNome}
                      //defaultValue={contatoNome}
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => setContatoNome(event.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 1, width: '36.8ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoCargo">Cargo/Departamento</InputLabel>
                    <FilledInput
                      id="tfContatoCargo"
                      variant="filled"
                      value={contatoCargo}
                      //defaultValue={contatoCargo}
                      inputProps={{ maxLength: 30 }}
                      onChange={(event) => setContatoCargo(event.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 1, width: '17.7ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoFone">Fone</InputLabel>
                    <FilledInput
                      id="tfContatoFone"
                      type={'number'}
                      value={contatoFone}
                      //defaultValue={contatoFone}
                      onChange={(event) => setContatoFone(event.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 1, width: '17.7ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoFone2">Celular</InputLabel>
                    <FilledInput
                      id="tfContatoFone2"
                      type={'number'}
                      value={contatoFone2}
                      //defaultValue={contatoFone2}
                      onChange={(event) => setContatoFone2(event.target.value)}
                    />
                  </FormControl>


                  <FormControl fullWidth size="small" sx={{ m: 1, width: '36.8ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoEmail">Email</InputLabel>
                    <FilledInput
                      id="tfContatoEmail"
                      variant="filled"
                      value={contatoEmail}
                      //defaultValue={contatoEmail}
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => setContatoEmail(event.target.value)}
                    />
                  </FormControl>

                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '36.8ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        noValidate={false}
                        id="tfContatoAniversrio"
                        label="Nascimento"
                        inputFormat="DD/MM/YYYY"
                        value={contatoAniversario}
                        onChange={handleChangeNascimento}
                        renderInput={(params) => <TextField sx={{ m: 0, width: '36.8ch' }} variant="filled" {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>

                </Box>
              </Card>
            </Grid>

          </Typography>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalContato}>Cancelar
          </Button>

          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarContato}>Salvar
          </Button>

        </Box>
      </Modal>
    )
  }

  function botaoVoltar() {
    props.history.push('/app/Client');
  }

  function botaoNovo() {

    setFildId('0');

    setFildCodigo('');
    setFildCodigoAuxiliar('');
    setFildRazao('');
    setFildEndereco('');
    setFildNumero('');
    setFildComplemento('');
    setFildBairro('');
    setFildCodigoCidade('');
    setFildCidade('');
    setFildCodigoUf('');
    setFildUf('');
    setFildCep('');
    setFildInscricao('');
    setFildCgc('');
    setFildFone('');
    setFildFax('');
    setFildCelular('');
    setFildContato('');
    setFildCodigoVendedor('');
    setFildVendedor('');
    setFildComissao(0);
    setFildLimite(0);
    setFildFantasia('');
    setFildAtivo('true');
    setFildCodigoGrupo('');
    setFildDescricaoGrupo('');
    setFildObservacoes('');
    setFildEmail('');
    // setFildDsp('');
    setFildPlanoContas('');
    // setFildValorMeta(0);
    setFildCodigoVendedor2('');
    setFildVendedor2('');
    setFildComissao2(0);
    setFildPontoReferencia('');
    setFildCodigoRecebimento('');
    setFildRecebimento('');
    setFildTabelapreco('');
    setFildPais('');
    setFildInscricaoMunicipal('');
    setFildCfopPadrao('');
    setFildClienteDadosAdicionais('');
    setFildDataCadastro('');
    setFildDataAlteracao('');
    setFildResponsavel('');

    setLsContatos([]);
  }

  function awaiteSend() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={impedirMultClick}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      {awaiteSend}
      {modalGrupos()}
      {modalContatos()}

      <PageTitle title="Cliente "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

      <AguardarRetorno show={impedirMultClick} />

      <Grid container spacing={0}>

        <Card sx={{ maxWidth: 533, m: 0.6 }}>
          <CardContent>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfRazao">Razão Social</InputLabel>
              <FilledInput
                id="tfRazao"
                value={fildRazao}
                // //defaultValue={fildRazao}
                inputProps={{ maxLength: 100 }}
                variant="filled"
                onChange={(event) => setFildRazao(event.target.value)}
                error={validaRazao}

              />
            </FormControl>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfFantasia">Nome Fantasia</InputLabel>
              <FilledInput
                id="tfFantasia"
                variant="filled"
                value={fildFantasia}
                // //defaultValue={fildFantasia}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => setFildFantasia(event.target.value)}
                error={validaFantasia}

              />
            </FormControl>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfCpfCnpj">CPF / CNPJ</InputLabel>
              <FilledInput
                id="tfCpfCnpj"
                value={fildCgc}
                // //defaultValue={fildCgc}
                inputProps={{ maxLength: 14 }}
                variant="filled"
                onChange={(event) => handleChangeCnpj(event.target.value)}
                error={validaCgc}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      // aria-label="toggle password visibility"
                      onClick={(event) => ConsultarCnpj()}

                    >
                      <SearchIcon color="primary" />
                    </IconButton>
                  </InputAdornment>}
              />
            </FormControl>

            <div>
              <FormControl fullWidth size="small" sx={{ m: 0.6, width: '48.1%' }} variant="filled">
                <InputLabel htmlFor="tfIe">Inscrição Estadual</InputLabel>
                <FilledInput
                  id="tfIe"
                  value={fildInscricao}
                  //defaultValue={fildInscricao}
                  inputProps={{ maxLength: 18 }}
                  variant="filled"
                  onChange={(event) => setFildInscricao(event.target.value)}

                />
              </FormControl>

              <FormControl fullWidth size="small" sx={{ m: 0.6, width: '48.05%' }} variant="filled">
                <InputLabel htmlFor="tfIm">Inscrição Municipal</InputLabel>
                <FilledInput
                  id="tfIm"
                  value={fildInscricaoMunicipal}
                  //defaultValue={fildInscricaoMunicipal}
                  inputProps={{ maxLength: 18 }}
                  variant="filled"
                  onChange={(event) => setFildInscricaoMunicipal(event.target.value)}

                />
              </FormControl>
            </div>

            <div>
              <FormControl fullWidth size="small" sx={{ m: 0.6, width: '48.1%' }} variant="filled">
                <InputLabel htmlFor="tfFon1">Fone</InputLabel>
                <FilledInput
                  id="tfFon1"
                  value={fildFone}
                  //defaultValue={fildFone}
                  inputProps={{ maxLength: 11 }}
                  variant="filled"
                  onChange={(event) => setFildFone(event.target.value)}

                />
              </FormControl>

                <FormControl fullWidth size="small" sx={{ m: 0.6, width: '48.05%' }} variant="filled">
                  <InputLabel htmlFor="tfFon2">Fone 2</InputLabel>
                  <FilledInput
                    id="tfFon2"
                    value={fildFax}
                    // //defaultValue={fildFax}
                    inputProps={{ maxLength: 11 }}
                    variant="filled"
                    onChange={(event) => setFildFax(event.target.value)}

                  />
              </FormControl>
            </div>


            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfCelular">Celular</InputLabel>
              <FilledInput
                id="tfCelular"
                value={fildCelular}
                //defaultValue={fildCelular}
                inputProps={{ maxLength: 11 }}
                variant="filled"
                onChange={(event) => setFildCelular(event.target.value)}

              />
            </FormControl>

            <TextField
              id="tfContato"
              select
              size="small"
              label="Contatos"
              value={fildContato}
              variant="filled"
              onChange={handleChangeContato}
              sx={{ m: 0.6 }}
              fullWidth
            >
              {!novoRegistro && (
                <IconButton size="small"
                  sx={{ color: green[500] }}
                  onClick={handleOpenModalContato}
                ><AddIcon fontSize="inherit" style={{ marginRight: 5 }} />

                </IconButton>
              )}

              {lsContatos.map((option,index) => (
                <MenuItem key={option.codigo} value={option.nome}>

                  <IconButton size="small"
                    sx={{ color: blue[500] }}
                    //  onClick={(event) => setIdUnidade([option.id,option.descricao])}
                    onClick={() => (handleChangeEditContato(index))}
                  >

                    <EditIcon fontSize="inherit" style={{ marginRight: 5 }} />

                  </IconButton>

                  {option.nome}
                </MenuItem>
              ))}

            </TextField>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfEmail">Email</InputLabel>
              <FilledInput
                id="tfEmail"
                value={fildEmail}
                //defaultValue={fildEmail}
                inputProps={{ maxLength: 100 }}
                variant="filled"
                onChange={(event) => setFildEmail(event.target.value)}

              />
            </FormControl>

            <TextField
              id="tfGrupo"
              select
              size="small"
              label="Grupo"
              value={fildCodigoGrupo}
              onChange={handleChangeGrupo}
              variant="filled"
              fullWidth
              sx={{ m: 0.6 }}
            >
              <IconButton size="small"
                sx={{ color: green[500] }}
                onClick={handleOpenModalGrupo}
              ><AddIcon fontSize="inherit" style={{ marginRight: 5 }} />

              </IconButton>

              {lsCategorias.map((option) => (
                <MenuItem key={option.categoria} value={option.categoria}>

                  <IconButton size="small"
                    sx={{ color: blue[500] }}
                    //  onClick={(event) => setIdUnidade([option.id,option.descricao])}
                    onClick={() => (handleChangeEditGrupo(option.descricao, option.categoria))}
                  >

                    <EditIcon fontSize="inherit" style={{ marginRight: 5 }} />

                  </IconButton>

                  {option.descricao}
                </MenuItem>
              ))}
            </TextField>

          </CardContent>

        </Card>

        <Card sx={{ minWidth: 533, maxWidth: 533, m: 0.6 }}>
          <CardContent>
            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfCep">CEP</InputLabel>
              <FilledInput
                id="tfCep"
                value={fildCep}
                ////defaultValue={fildCep}
                inputProps={{ maxLength: 8 }}
                variant="filled"
                onChange={(event) => handleChangeCep(event.target.value)}

                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(event) => ConsultarCep()}
                    >
                      <SearchIcon color="primary" />
                    </IconButton>
                  </InputAdornment>}
              />
            </FormControl>

            <div>
              <FormControl size="small" sx={{ m: 0.6, width: '75%' }} variant="filled">
                <InputLabel htmlFor="tfEndereco">Endereço</InputLabel>
                <FilledInput
                  id="tfEndereco"
                  value={fildEndereco}
                  ////defaultValue={fildEndereco}
                  inputProps={{ maxLength: 100 }}
                  variant="filled"
                  onChange={(event) => setFildEndereco(event.target.value)}

                />
              </FormControl>

              <FormControl size="small" sx={{ m: 0.6, width: '20%' }} variant="filled">
                <InputLabel htmlFor="tfNumero">Número</InputLabel>
                <FilledInput
                  id="tfNumero"
                  value={fildNumero}
                  ////defaultValue={fildNumero}
                  inputProps={{ maxLength: 10 }}
                  variant="filled"
                  onChange={(event) => setFildNumero(event.target.value)}

                />
              </FormControl>
            </div>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfBairro">Bairro</InputLabel>
              <FilledInput
                id="tfBairro"
                value={fildBairro}
                ////defaultValue={fildBairro}
                inputProps={{ maxLength: 50 }}
                variant="filled"
                onChange={(event) => setFildBairro(event.target.value)}

              />
            </FormControl>

            <TextField fullWidth size="small" sx={{ m: 0.6 }}
              id="tfUf"
              select
              label="UF"
              value={fildCodigoUf}
              onChange={handleChangeUf}
              variant="filled"
            >
              {dadosUf.map((option) => (
                <MenuItem key={option.cuf} value={option.cuf}>
                  {option.uf+' - '+option.nome}
                </MenuItem>
              ))}
            </TextField>
            <div>

              {exibirProgressMunicipios && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField
                id="tfCidade"
                select
                size="small"
                label="Cidade"
                value={fildCodigoCidade}
                onChange={handleChangeCidade}
                variant="filled"
                fullWidth
                sx={{ m: 0.6 }}
              >
                {dadosMunicipio.map((option) => (
                  <MenuItem key={option.cmunicipio} value={option.cmunicipio}>
                    {option.municipio}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfPontoreferencia">Ponto de Referência</InputLabel>
              <FilledInput
                id="tfPontoreferencia"
                value={fildPontoReferencia}
                ////defaultValue={fildPontoReferencia}
                inputProps={{ maxLength: 255 }}
                variant="filled"
                onChange={(event) => setFildPontoReferencia(event.target.value)}

              />
            </FormControl>

            <div>
              {exibirProgressCfop && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField fullWidth
                id="tfCfop"
                select
                label="CFOP"
                value={fildCfopPadrao}
                onChange={handleChangeCfop}
                variant="filled"
                sx={{ m: 0.6 }}
              >
                {lsCfop.map((option) => (
                  <MenuItem key={option.id} value={option.cfop}>
                    {option.cfop + " - " + option.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div>
              {exibirProgressTabelaPreco && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}
              <TextField fullWidth
                id="tfTabela"
                select
                label="Tabela de Preço"
                value={fildTabelapreco}
                onChange={handleChangeTabelaPreco}
                variant="filled"
                sx={{ m: 0.6 }}
              >
                {lsTabelaPreco.map((option) => (
                  <MenuItem key={option.chave} value={option.chave}>
                    {option.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfCodigoAuxiliar">Código Aux. / UASG</InputLabel>
              <FilledInput
                id="tfCodigoAuxiliar"
                value={fildCodigoAuxiliar}
                //defaultValue={fildCodigoAuxiliar}
                inputProps={{ maxLength: 15 }}
                variant="filled"
                onChange={(event) => setFildCodigoAuxiliar(event.target.value)}

              />
            </FormControl>

            <div>
              {exibirProgressPlanoContas && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField fullWidth
                id="tfPlanoContas"
                select
                size="small"
                label="Plano de Contas"
                value={fildPlanoContas}
                onChange={handleChangePlanoContas}
                variant="filled"
                sx={{ m: 0.6 }}
              >
                {lsPlanoContas.map((option) => (
                  <MenuItem key={option.codigoresumido} value={option.codigoresumido}>
                    {option.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </div>

          </CardContent>
        </Card>

        <Card sx={{ minWidth: 533, maxWidth: 533, m: 0.6 }}>
          <CardContent>
            <div>
              {exibirProgressRecebimentos && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField
                id="tfRecebimento"
                select
                size="small"
                label="Forma de pagamento"
                value={fildCodigoRecebimento}
                onChange={handleChangeRecebimento}
                variant="filled"
                sx={{ m: 0.6 }}
                fullWidth
              >
                {lsRecebimento.map((option) => (
                  <MenuItem key={option.codigo} value={option.codigo}>
                    {option.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div>
              {exibirProgressVendedor && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField sx={{ m: 0.6, width: '75%' }}
                id="tfVendedor1"
                select
                size="small"
                label="Vendedor 1"
                value={fildCodigoVendedor}
                onChange={handleChangeVendedor}
                variant="filled"
              >
                {lsVendedores.map((option) => (
                  <MenuItem key={option.vendedor} value={option.vendedor}>
                    {option.nome}
                  </MenuItem>
                ))}
              </TextField>

              <FormControl size="small" sx={{ m: 0.6, width: '20%' }} variant="filled">
                <InputLabel htmlFor="tfComissao1">Comissão</InputLabel>
                <FilledInput
                  id="tfComissao1"
                  value={fildComissao}
                  //defaultValue={fildComissao}
                  type="number"
                  variant="filled"
                  onChange={(event) => setFildComissao(event.target.value)}
                  startAdornment={<InputAdornment position="start">%</InputAdornment>}

                />
              </FormControl>

            </div>

            <div>
              {exibirProgressVendedor && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField sx={{ m: 0.6, width: '75%' }}
                id="tfVendedor2"
                select
                size="small"
                label="Vendedor 2"
                value={fildCodigoVendedor2}
                onChange={handleChangeVendedor2}
                variant="filled"
              >
                {lsVendedores.map((option) => (
                  <MenuItem key={option.vendedor} value={option.vendedor}>
                    {option.nome}
                  </MenuItem>
                ))}
              </TextField>

              <FormControl size="small" sx={{ m: 0.6, width: '20%' }} variant="filled">
                <InputLabel htmlFor="tfComissao2">Comissão</InputLabel>
                <FilledInput
                  id="tfComissao2"
                  value={fildComissao2}
                  //defaultValue={fildComissao2}
                  type="number"
                  variant="filled"
                  onChange={(event) => setFildComissao2(event.target.value)}
                  startAdornment={<InputAdornment position="start">%</InputAdornment>}

                />
              </FormControl>

            </div>

            <div>
              <TextField
                id="tfAtivo"
                select
                size="small"
                label="Ativo"
                value={fildAtivo}
                onChange={handleChangeAtivo}
                variant="filled"
                sx={{ m: 0.6, width: '48%' }}
              >
                {valoresSim_Nao.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>


              <FormControl size="small" sx={{ m: 0.6, width: '48%' }} variant="filled">
                <InputLabel htmlFor="tfCodigoAuxiliar">Cód. País</InputLabel>
                <FilledInput
                  id="tfCodigoAuxiliar"
                  value={fildPais}
                  //defaultValue={fildPais}
                  inputProps={{ maxLength: 10 }}
                  variant="filled"
                  onChange={(event) => setFildPais(event.target.value)}

                />
              </FormControl>
            </div>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfObservacao">Observação</InputLabel>
              <FilledInput
                id="tfObservacao"
                value={fildObservacoes}
                //defaultValue={fildObservacoes}
                inputProps={{ maxLength: 255 }}
                multiline
                rows={10}
                variant="filled"
                onChange={(event) => setFildObservacoes(event.target.value)}

              />
            </FormControl>

          </CardContent>
        </Card>

      </Grid>
    </>
  );
}
