// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import {
  Typography,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Tab, Tabs, Modal, InputAdornment, Button
} from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";

import AddIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import SearchIcon from '@mui/icons-material/Search';
import { blue } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';

import Config from '../../../config/Config';
import AguardarRetorno from "../../../components/BackDrop/AguardarRetorno";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function converterParaFloat(valor) {
  if (valor !== null && valor !== '') {
    return parseFloat(valor);
  } else {
    return 0;
  }
}

export default function ProductRegister(props) {

  var userDispatch = useUserDispatch();

  const [recarregar,SetRecarregar] = useState(true);

  const [valueTab, setValueTab] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openMessage, setOpenMessage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  const [exibirProgressGrupos, setExibirProgressGrupos] = useState(false);
  const [exibirProgressSituacaoTributaria, setExibirProgressSituacaoTributaria] = useState(false);
  const [exibirProgressFornecedores, setExibirProgressFornecedores] = useState(false);
  const [exibirProgressUnidades, setExibirProgressUnidades] = useState(false);

  const [lsPlanoContas, setLsPlanoContas] = useState(props.location.planocontas.text);
  const [lsFornecedores, setLsFornecedores] = useState(props.location.fornecedores.text);
  const [lsGrupo, setLsGrupo] = useState(props.location.grupos.text);
  const [lsUnidades, setLsUnidades] = useState(props.location.unidades.text);
  const [lsCfop, setLsCfop] = useState(props.location.cfops.text);

  const [lsIcmsSt, setLsIcmsSt] = useState([]);
  const [lsSt] = useState(props.location.lsSt.text);
  const [lsStPisCofins] = useState(props.location.lsStPisCofins.text);
  const [lsMarca, setLsMarca] = useState(props.location.marcas.text );


  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.prod.text);

  const [fildId, setFildId] = useState(props.location.idregistro.text);

  const [fildProduto ,setFildProduto]=useState(dadosRegistro.produto);
  const [fildDescricao ,setFildDescricao]=useState(dadosRegistro.descricao);
  const [fildCodigoGrupo ,setFildCodigoGrupo]=useState(dadosRegistro.codigogrupo);
  const [fildCusto ,setFildCusto]=useState(dadosRegistro.custo);
  const [fildPrecoVenda ,setFildPrecoVenda]=useState(dadosRegistro.precovenda);
  const [fildIcms ,setFildIcms]=useState(dadosRegistro.icms);
  const [fildIcmsSub ,setFildIcmsSub]=useState(dadosRegistro.icmssub);
  const [fildValorSugerido ,setFildValorSugerido]=useState(dadosRegistro.valorsugerido);
  const [fildMargem ,setFildMargem]=useState(dadosRegistro.margem);
  const [fildReferencia ,setFildReferencia]=useState(dadosRegistro.referencia);
  const [fildCbarra ,setFildCbarra]=useState(dadosRegistro.cbarra);
  const [fildUnidade ,setFildUnidade]=useState(dadosRegistro.unidade);
  const [cstNfe ,setCstNfe]=useState(dadosRegistro.st);
  const [fildCf ,setFildCf]=useState(dadosRegistro.cf);
  const [fildModelo ,setFildModelo]=useState(dadosRegistro.modelo);
  const [fildVenda ,setFildVenda]=useState(dadosRegistro.venda);
  const [fildIpi ,setFildIpi]=useState(dadosRegistro.ipi);
  const [fildCstPis ,setFildCstPis]=useState(dadosRegistro.cstpis);
  const [fildPis ,setFildPis]=useState(dadosRegistro.aliquotapis);
  const [fildCstCofins ,setFildCstCofins]=useState(dadosRegistro.cstcofins);
  const [fildCofins ,setFildCofins]=useState(dadosRegistro.aliquotacofins);
  const [fildCest ,setFildCest]=useState(dadosRegistro.cest);
  const [fildAplicacao ,setFildAplicacao]=useState(dadosRegistro.aplicacao);
  const [fildEmbalagem ,setFildEmbalagem]=useState(dadosRegistro.embalagem);
  const [fildPLiquido ,setFildPLiquido]=useState(dadosRegistro.pliquido);
  const [fildPBruto ,setFildPBruto]=useState(dadosRegistro.pbruto);
  const [fildCodigoFornecedor ,setFildCodigoFornecedor]=useState(dadosRegistro.codigofornecedor);
  const [fildQtdMinima ,setFildQtdMinima]=useState(dadosRegistro.qtdminima);
  const [fildPlanoContas ,setFildPlanoContas]=useState(dadosRegistro.planocontas);
  const [fildInativo ,setFildInativo]=useState(dadosRegistro.inativo);
  const [fildDescontoMaximo ,setFildDescontoMaximo]=useState(dadosRegistro.descontomaximo);
  const [fildMovimentaEstoque ,setFildMovimentaEstoque]=useState(dadosRegistro.movimentaestoque);
  const [fildCfopNfce ,setFildCfopNfce]=useState(dadosRegistro.cfopnfce);
  const [cstNfce ,setCstNfce]=useState(dadosRegistro.st_nfce);
  const [fildIcmsNfce ,setFildIcmsNfce]=useState(dadosRegistro.icms_nfce);
  const [fildIcmsSubNfce ,setFildIcmsSubNfce]=useState(dadosRegistro.icmssub_nfce);
  const [fildCstCofinsNfce ,setFildCstCofinsNfce]=useState(dadosRegistro.cstcofins_nfce);
  const [fildCstPisNfce ,setFildCstPisNfce]=useState(dadosRegistro.cstpis_nfce);
  const [fildPisNfce ,setFildPisNfce]=useState(dadosRegistro.aliquotapis_nfce);
  const [fildCofinsNfce ,setFildCofinsNfce]=useState(dadosRegistro.aliquotacofins_nfce);
  const [fildCstIpiNfce ,setFildCstIpiNfce]=useState(dadosRegistro.cstipi_nfce);
  const [fildIpiNfce ,setFildIpiNfce]=useState(dadosRegistro.ipi_nfce);
  const [fildQtdVolume ,setFildQtdVolume]=useState(dadosRegistro.qtdvolume);
  const [fildQtdVolumeCompra ,setFildQtdVolumeCompra]=useState(dadosRegistro.qtdvolumecompra);
  const [fildCodigoAnvisa ,setFildCodigoAnvisa]=useState(dadosRegistro.codigoanvisa);
  const [fildMarca ,setFildMarca]=useState(dadosRegistro.marca);
  const [fildComissao ,setFildComissao]=useState(dadosRegistro.comissao);
  const [fildAlmoxarifado ,setFildAlmoxarifado]=useState(dadosRegistro.almoxarifado);
  const [fildCstIpi ,setFildCstIpi]=useState(dadosRegistro.cstipi);
  const [fildModBcSt ,setFildModBcSt]=useState(dadosRegistro.modbcicmsst);
  const [fildModBc ,setFildModBc]=useState(dadosRegistro.modbcicms);
  const [fildResponsavel ,setFildResponsavel]=useState(dadosRegistro.responsavel);
  const [fildDataAtualizacao ,setFildDataAtualizacao]=useState(dadosRegistro.updated_at);
  const [fildDataCadastro ,setFildDataCadastro]=useState(dadosRegistro.created_at);
  const [fildBalanca,setFildBalanca]=useState(dadosRegistro.balanca);

  const [cstOrigemNfce,setCstOrigemNfce]=useState(
    (dadosRegistro.st_nfce !== null && dadosRegistro.st_nfce.length>0) ? dadosRegistro.st_nfce.substring(0, 1):""
    );

  const [cstOrigemNfe,setCstOrigemNfe]=useState(
    (dadosRegistro.st !== null &&  dadosRegistro.st.length>0) ? dadosRegistro.st.substring(0, 1):""
    );

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  //Fim dos campos do formulario

  const [descricaoNovaUnidade, setDescricaoNovaUnidade] = useState('');
  const [openUnidades, setOpenUnidades] = useState(false);
  const handleOpenModalUnidades = () => setOpenUnidades(true);
  const handleCloseModalUnidades = () => setOpenUnidades(false);
  const [idUnidade, setIdUnidade] = useState('0');

  const [descricaoNovoGrupo, setDescricaoNovoGrupo] = useState('');
  const [openGrupo, setOpenGrupo] = useState(false);
  const handleOpenModalGrupo = () => setOpenGrupo(true);
  const handleCloseModalGrupo = () => setOpenGrupo(false);
  const [idGrupo, setIdGrupo] = useState('0');

  const [descricaoNovaMarca, setDescricaoNovaMarca] = useState('');
  const [openMarcas, setOpenMarcas] = useState(false);
  const handleOpenModalMarcas = () => setOpenMarcas(true);
  const handleCloseModalMarcas = () => setOpenMarcas(false);
  const [idMarca, setIdMarca] = useState('0');

  const handleOpenModalEditUnidades = () => setOpenUnidades(true);

  const [validaDescricao, setValidaDescricao] = useState(false);
  const [msgErroDescricao, setMsgErroDescricao] = useState('');

  const lsIcms = [
    {
      coluna: 1,
      descricao: 'Tabela 1'
    },
    {
      coluna: 2,
      descricao: 'Tabela 2'
    },
    {
      coluna: 3,
      descricao: 'Tabela 3'
    },
    {
      coluna: 4,
      descricao: 'Tabela 4'
    },
    {
      coluna: 5,
      descricao: 'Tabela 5'
    },
    {
      coluna: 6,
      descricao: 'Tabela 6'
    },
    {
      coluna: 7,
      descricao: 'Tabela 7'
    },
    {
      coluna: 8,
      descricao: 'Tabela 8'
    },
    {
      coluna: 9,
      descricao: 'Tabela 9'
    },
    {
      coluna: 10,
      descricao: 'Tabela 10'
    },
  ];

  const lsStIpi = [
    // { codigo: '00', descricao: 'Entrada com Recuperação de Crédito' },
    // { codigo: '01', descricao: 'Entrada Tributável com Alíquota Zero' },
    // { codigo: '02', descricao: 'Entrada Isenta' },
    // { codigo: '03', descricao: 'Entrada Não-Tributada' },
    // { codigo: '04', descricao: 'Entrada Imune' },
    // { codigo: '05', descricao: 'Entrada com Suspensão' },
    // { codigo: '49', descricao: 'Outras Entradas' },
    { codigo: '50', descricao: 'Saída Tributada' },
    { codigo: '51', descricao: 'Saída Tributável com Alíquota Zero' },
    { codigo: '52', descricao: 'Saída Isenta' },
    { codigo: '53', descricao: 'Saída Não-Tributada' },
    { codigo: '54', descricao: 'Saída Imune' },
    { codigo: '55', descricao: 'Saída com Suspensão' },
    { codigo: '99', descricao: 'Outras Saídas' },
  ];

  const valoresSim_Nao = [
    {
      value: 'true',
      label: 'Sim',
    },
    {
      value: 'false',
      label: 'Não',
    }
  ];

  const lsOrigem = [

    {
      value: '0',
      label: '0',
    },

    {
      value: '1',
      label: '1',
    },

    {
      value: '2',
      label: '2',
    },

    {
      value: '3',
      label: '3',
    },

    {
      value: '4',
      label: '4',
    },

    {
      value: '5',
      label: '5',
    },

    {
      value: '6',
      label: '6',
    },

    {
      value: '7',
      label: '7',
    },

    {
      value: '8',
      label: '8',
    },

  ];

  const lsModelo = [

    {
      value: '0',
      label: 'Sincronizar para plataforma web',
    },

    {
      value: '1',
      label: 'Remover da plataforma web',
    },

    {
      value: '2',
      label: 'Não Sincronizar para plataforma web',
    },

  ];

  const lsModBcIcms = [

    { codigo: '0', value: 'Margem Valor Agregado (%)' },
    { codigo: '1', value: 'Pauta (valor)' },
    { codigo: '2', value: 'Preço Tabelado Máximo (valor)' },
    { codigo: '3', value: 'Valor da Operação' },
  ];

  const lsModBcSt = [
    { codigo: '0', value: 'Preço tabelado ou máximo  sugerido' },
    { codigo: '1', value: 'Lista Negativa (valor)' },
    { codigo: '2', value: 'Lista Positiva (valor)' },
    { codigo: '3', value: 'Lista Neutra (valor)' },
    { codigo: '4', value: 'Margem Valor Agregado (%)' },
    { codigo: '5', value: 'Pauta (valor)' },
    { codigo: '6', value: 'Valor da Operação' },
  ];

  //o campo Inativo no banco de dados verifica se é true ou false
  const valoresAtivo_Inativo = [
    {
      value: 'true',
      label: 'Não',
    },
    {
      value: 'false',
      label: 'Sim',
    }
  ];

  const handleChangeAtivo = (event) => {
    setFildInativo(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangePlanoContas = (event) => {
    setFildPlanoContas(event.target.value);
  }

  const handleChangeGrupo = (event) => {
    setFildCodigoGrupo(event.target.value);
  }

  const handleChangeFornecedor = (event) => {
    setFildCodigoFornecedor(event.target.value);
  }

  const handleChangeCfop = (event) => {
    setFildCfopNfce(event.target.value);
  }

  const handleChangeIcmsStNfce = (event) => {
    setFildIcmsSubNfce(event.target.value);
  }

  const handleChangeIcmsNfce = (event) => {
    setFildIcmsNfce(event.target.value);
  }

  const handleChangeCstCofinsNfce = (event) => {
    setFildCstCofinsNfce(event.target.value);
  }

  const handleChangeCstPisNfce = (event) => {
    setFildCstPisNfce(event.target.value);
  }

  const handleChangeCstIpiNfce = (event) => {
    setFildCstIpiNfce(event.target.value);
  }

  const handleChangeCstNfce = (event) => {
    setCstNfce(event.target.value);
  }

  const handleChangeCstOrigemNfce = (event) => {
    setCstOrigemNfce(event.target.value);
  }

  const handleChangeIcmsNfe = (event) => {
    setFildIcms(event.target.value);
  }

  const handleChangeIcmsStNfe = (event) => {
    setFildIcmsSub(event.target.value);
  }

  const handleChangeCstCofins = (event) => {
    setFildCstCofins(event.target.value);
  }

  const handleChangeCstPis = (event) => {
    setFildCstPis(event.target.value);
  }

  const handleChangeCstIpi = (event) => {
    setFildCstIpi(event.target.value);
  }

  const handleChangeCstOrigemNfe = (event) => {
    setCstOrigemNfe(event.target.value);
  }

  const handleChangeCstNfe = (event) => {
    setCstNfe(event.target.value);
  }

  const handleChangeMarca = (event) => {
    setFildMarca(event.target.value);
  }

  const handleChangeUnidade = (event) => {
    setFildUnidade(event.target.value);
  }

  const handleChangeEditUnidade = (valor, chave) => {
    setIdUnidade(chave);
    setDescricaoNovaUnidade(valor);
    //chama o modal
    handleOpenModalUnidades();
  }

  const handleChangeEditMarca = (valor, chave) => {
    setIdMarca(chave);
    setDescricaoNovaMarca(valor);
    //chama o modal
    handleOpenModalMarcas();
  }

  const handleChangeEditGrupo = (valor, chave) => {
    setIdGrupo(chave);
    setDescricaoNovoGrupo(valor);
    //chama o modal
    handleOpenModalGrupo();
  }

  const handleChangeVenda = (event) => {
    setFildVenda(event.target.value);
  }

  const handleChangeModelo = (event) => {
    setFildModelo(event.target.value);
  }

  const handleChangeMovimentaEstoque = (event) => {
    setFildMovimentaEstoque(event.target.value);
  }

  const handleChangeInativo = (event) => {
    setFildInativo(event.target.value);
  }

  const handleChangeAlmoxarifado = (event) => {
    setFildAlmoxarifado(event.target.value);
  }

  const handleChangeBalanca = (event) => {
    setFildBalanca(event.target.value);
  }

  const handleChangeModBc = (event) => {
    setFildModBc(event.target.value);
  }

  const handleChangeModBcSt = (event) => {
    setFildModBcSt(event.target.value);
  }

  const handleChangeCusto = (event) => {
    setFildCusto(event.target.value);

  }

  const handleChangeMargem = (event) => {
    setFildMargem(event.target.value);

  }

  const calcularValorSugerido = () => {

    if (fildCusto > 0 && fildMargem > 0) {

      let vCusto = parseFloat(fildCusto);
      let vMargem = parseFloat(fildMargem);
      let novoPreco = 0;

      let valor = (vCusto * vMargem) / 100;
      novoPreco = valor + vCusto;

      setFildValorSugerido(novoPreco);

    }

  }

  //Listas

  // async function popularFormulario(id) {

  //   // dadosRegistro
  //   setFildId(dadosRegistro.id);
  //   setFildProduto(dadosRegistro.produto);
  //   setFildDescricao(dadosRegistro.descricao);
  //   setFildReferencia(dadosRegistro.referencia);
  //   setFildCbarra(dadosRegistro.cbarra);
  //   setFildUnidade(dadosRegistro.unidade);
  //   setFildVenda(dadosRegistro.venda);
  //   setFildModelo(dadosRegistro.modelo);
  //   setFildMovimentaEstoque(dadosRegistro.movimentaestoque);
  //   setFildInativo(dadosRegistro.inativo);
  //   setFildAlmoxarifado(dadosRegistro.almoxarifado);
  //   setFildBalanca(dadosRegistro.balanca);
  //   setFildEmbalagem(dadosRegistro.embalagem);
  //   setFildCusto(dadosRegistro.custo);
  //   setFildValorSugerido(dadosRegistro.valorsugerido);
  //   setFildMargem(dadosRegistro.margem);
  //   setFildPrecoVenda(dadosRegistro.precovenda);
  //   setFildDescontoMaximo(dadosRegistro.descontomaximo);
  //   setFildQtdMinima(dadosRegistro.qtdminima);
  //   setFildCodigoAnvisa(dadosRegistro.codigoanvisa);
  //   setFildComissao(dadosRegistro.comissao);
  //   setFildAplicacao(dadosRegistro.aplicacao);
  //   setFildCf(dadosRegistro.cf);
  //   setFildCstIpi(dadosRegistro.cstipi);
  //   setFildIpi(dadosRegistro.ipi);
  //   setFildCstPis(dadosRegistro.cstpis);
  //   setFildPis(dadosRegistro.pis);
  //   setFildCstCofins(dadosRegistro.cstcofins);
  //   setFildCofins(dadosRegistro.cofins);
  //   setFildIcms(dadosRegistro.icms);
  //   setFildIcmsSub(dadosRegistro.icmssub);
  //   setFildCest(dadosRegistro.cest);
  //   setFildCstIpiNfce(dadosRegistro.cstipinfce);
  //   setFildIpiNfce(dadosRegistro.ipinfce);
  //   setFildCstPisNfce(dadosRegistro.cstpisnfce);
  //   setFildPisNfce(dadosRegistro.pisnfce);
  //   setFildCstCofinsNfce(dadosRegistro.cstcofinsnfce);
  //   setFildCofinsNfce(dadosRegistro.cofinsnfce);
  //   setFildIcmsNfce(dadosRegistro.icmsnfce);
  //   setFildIcmsSubNfce(dadosRegistro.icmssubnfce);
  //   setFildCfopNfce(dadosRegistro.cfopnfce);
  //   setFildCodigoFornecedor(dadosRegistro.codigofornecedor);
  //   setPlanoContas(dadosRegistro.planocontas);
  //   setFildCodigoGrupo(dadosRegistro.codigogrupo);
  //   setFildPLiquido(dadosRegistro.pliquido);
  //   setFildPBruto(dadosRegistro.pbruto);
  //   setFildMarca(dadosRegistro.marca);
  //   setFildModBc(dadosRegistro.modbc);
  //   setFildModBcSt(dadosRegistro.modbcst);
  //   setFildDataCadastro(dadosRegistro.datacadastro);
  //   setFildDataAtualizacao(dadosRegistro.dataatualizacao);
  //   setFildResponsavel(dadosRegistro.responsavel);
  //   setCstOrigemNfce(dadosRegistro.cstorigemnfce);
  //   setCstNfce(dadosRegistro.cstnfce);
  //   setCstOrigemNfe(dadosRegistro.cstorigemnfe);
  //   setCstNfe(dadosRegistro.cstnfe);
  //   // setExibirProgress(true);
  //   // setExibirProgressGrupos(true);
  //   // setExibirProgressSituacaoTributaria(true);
  //   // setExibirProgressPlanoContas(true);
  //   // setExibirProgressUnidades(true);

  //   // const response = await fetch(`${Config.urlRoot}/product/list/${id}`, {
  //   //   method: 'POST',
  //   //   headers: {
  //   //     Accept: 'application/json',
  //   //     'Content-Type': 'application/json'
  //   //   },
  //   //   body: JSON.stringify({
  //   //     dataBase:configuracaoBd,
  //   //   })
  //   // });

  //   // SetRecarregar(false);

  //   // let json = await response.json();

  //   // if(response.status!==200 && response.status!==201){
  //   //   SetRecarregar(false);

  //   //   setExibirProgress(false);
  //   //   setExibirProgressGrupos(false);
  //   //   setExibirProgressSituacaoTributaria(false);
  //   //   setExibirProgressPlanoContas(false);
  //   //   setExibirProgressUnidades(false);

  //   //   return;
  //   // }

  //   // if(json.produtoList!==null){

  //   //   setFildId(json.produtoList.id);
  //   //   setFildProduto(json.produtoList.produto);
  //   //   setFildDescricao(json.produtoList.descricao);
  //   //   setFildReferencia(json.produtoList.referencia);
  //   //   setFildCbarra(json.produtoList.cbarra);
  //   //   setFildUnidade(json.produtoList.unidade);
  //   //   setFildVenda(json.produtoList.venda);
  //   //   setFildModelo(json.produtoList.modelo);
  //   //   setFildMovimentaEstoque(json.produtoList.movimentaestoque);
  //   //   setFildInativo(json.produtoList.inativo);
  //   //   setFildAlmoxarifado(json.produtoList.almoxarifado);
  //   //   setFildBalanca(json.produtoList.balanca);
  //   //   setFildEmbalagem(json.produtoList.embalagem);
  //   //   setFildCusto(json.produtoList.custo);
  //   //   setFildValorSugerido(json.produtoList.valorsugerido);
  //   //   setFildMargem(json.produtoList.margem);
  //   //   setFildPrecoVenda(json.produtoList.precovenda);
  //   //   setFildDescontoMaximo(json.produtoList.descontomaximo);
  //   //   setFildQtdMinima(json.produtoList.qtdminima);
  //   //   setFildCodigoAnvisa(json.produtoList.codigoanvisa);
  //   //   setFildComissao(json.produtoList.comissao);
  //   //   setFildAplicacao(json.produtoList.aplicacao);
  //   //   setFildCf(json.produtoList.cf);
  //   //   setFildCstIpi(json.produtoList.cstipi);
  //   //   setFildIpi(json.produtoList.ipi);
  //   //   setFildCstPis(json.produtoList.cstpis);
  //   //   setFildPis(json.produtoList.pis);
  //   //   setFildCstCofins(json.produtoList.cstcofins);
  //   //   setFildCofins(json.produtoList.cofins);
  //   //   setFildIcms(json.produtoList.icms);
  //   //   setFildIcmsSub(json.produtoList.icmssub);
  //   //   setFildCest(json.produtoList.cest);
  //   //   setFildCstIpiNfce(json.produtoList.cstipinfce);
  //   //   setFildIpiNfce(json.produtoList.ipinfce);
  //   //   setFildCstPisNfce(json.produtoList.cstpisnfce);
  //   //   setFildPisNfce(json.produtoList.pisnfce);
  //   //   setFildCstCofinsNfce(json.produtoList.cstcofinsnfce);
  //   //   setFildCofinsNfce(json.produtoList.cofinsnfce);
  //   //   setFildIcmsNfce(json.produtoList.icmsnfce);
  //   //   setFildIcmsSubNfce(json.produtoList.icmssubnfce);
  //   //   setFildCfopNfce(json.produtoList.cfopnfce);
  //   //   setFildCodigoFornecedor(json.produtoList.codigofornecedor);
  //   //   setPlanoContas(json.produtoList.planocontas);
  //   //   setFildCodigoGrupo(json.produtoList.codigogrupo);
  //   //   setFildPLiquido(json.produtoList.pliquido);
  //   //   setFildPBruto(json.produtoList.pbruto);
  //   //   setFildMarca(json.produtoList.marca);
  //   //   setFildModBc(json.produtoList.modbc);
  //   //   setFildModBcSt(json.produtoList.modbcst);
  //   //   setFildDataCadastro(json.produtoList.datacadastro);
  //   //   setFildDataAtualizacao(json.produtoList.dataatualizacao);
  //   //   setFildResponsavel(json.produtoList.responsavel);
  //   //   setCstOrigemNfce(json.produtoList.cstorigemnfce);
  //   //   setCstNfce(json.produtoList.cstnfce);
  //   //   setCstOrigemNfe(json.produtoList.cstorigemnfe);
  //   //   setCstNfe(json.produtoList.cstnfe);

  //   //   setExibirProgress(false);
  //   //   SetRecarregar(false);
  //   // }

  //   // if(json.cfopList!==null){
  //   //   setLsCfop(json.cfopList);

  //   // }

  //   // if(json.grupoList!==null){
  //   //   setLsGrupo(json.grupoList);
  //   //   setExibirProgressGrupos(false);

  //   // }

  //   // if(json.stList!==null){
  //   //   setLsSt(json.stList);

  //   // }

  //   // if(json.stpiscofinsList!==null){
  //   //   setLsStPisCofins(json.stpiscofinsList);

  //   // }

  //   // if(json.unidadeList!==null){
  //   //   setLsUnidades(json.unidadeList);

  //   // }

  //   // if(json.marcaList!==null){
  //   //   setLsMarca(json.marcaList);
  //   // }

  //   // if(json.planocontasList!==null){
  //   //   setLsPlanoContas(json.planocontasList);
  //   //   setExibirProgressPlanoContas(false);

  //   // }

  // }

  // async function listarSituacaoTributaria() {

  //   setExibirProgressSituacaoTributaria(true);

  //   const response = await fetch('https://www.trinityst.com.br/apiAntares/apiUtilitarios.php', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       requisicao: 'listarSt',
  //       senha: senhaRetorno,
  //       login: usuarioRetorno,
  //       regime: '1'
  //     })
  //   });

  //   let json = await response.json();

  //   if (json.success) {
  //     if (json.result === null) {
  //       setLsSt([]);
  //     } else {
  //       setLsSt(json.result);
  //     }
  //   }
  //   else {
  //     setLsSt([]);
  //   }

  //   setExibirProgressSituacaoTributaria(false);

  // }

  // async function listarSituacaoTributariaPisCofins() {

  //   setExibirProgressSituacaoTributaria(true);

  //   const response = await fetch('https://www.trinityst.com.br/apiAntares/apiUtilitarios.php', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       requisicao: 'listarStPisCofins',
  //       senha: senhaRetorno,
  //       login: usuarioRetorno,
  //     })
  //   });

  //   let json = await response.json();

  //   if (json.success) {
  //     if (json.result === null) {
  //       setLsStPisCofins([]);
  //     } else {
  //       setLsStPisCofins(json.result);
  //     }
  //   }
  //   else {
  //     setLsStPisCofins([]);
  //   }

  //   setExibirProgressSituacaoTributaria(false);

  // }

  // async function listarSituacaoTributaria() {

  //   const response = await fetch(`${Config.urlRootMaster}/taxsituation/list`, {
  //     method: 'POST',
  //     headers: {Accept: 'application/json','Content-Type': 'application/json'},
  //     body: JSON.stringify({
  //       dataBase:configuracaoBd,
  //       pesquisar:"",
  //       order:""
  //     })
  //   });

  //   let json = await response.json();

  //   if(response.status!==200){
  //     setLsSt([]);
  //     return;
  //   }

  //   setLsSt(json);

  // }

  // async function listarSituacaoTributariaPisCofins() {

  //   const response = await fetch(`${Config.urlRootMaster}/taxsituationpiscofins/list`, {
  //     method: 'POST',
  //     headers: {Accept: 'application/json','Content-Type': 'application/json'},
  //     body: JSON.stringify({
  //       dataBase:configuracaoBd,
  //       pesquisar:"",
  //       order:""
  //     })
  //   });

  //   let json = await response.json();

  //   if(response.status!==200){
  //     setLsStPisCofins([]);
  //     return;
  //   }

  //   setLsStPisCofins(json);

  // }

  //Fim Listas

  async function salvarDados() {

    if (fildDescricao.length === 0) {

      setValidaDescricao(true);
      setMsgErroDescricao('Informe a Descrição do Produto!');
    }
    else {

      //Limpa a validação do campo
      setValidaDescricao(false);
      setMsgErroDescricao('');

      //Chama a API

      const registro = [{
        id:parseInt(fildId, 10),
        produto:fildProduto,
        descricao:fildDescricao,
        codigogrupo:fildCodigoGrupo,
        custo: converterParaFloat(fildCusto),
        precovenda:converterParaFloat(fildPrecoVenda),
        icms:fildIcms,
        icmssub:fildIcmsSub,
        valorsugerido:converterParaFloat(fildValorSugerido),
        margem:converterParaFloat(fildMargem),
        servico:"false",
        referencia:fildReferencia,
        cbarra:fildCbarra,
        unidade:fildUnidade,
        st:String(cstOrigemNfe)+ String(cstNfe) ,
        cf:fildCf,
        modelo:fildModelo,
        venda:fildVenda,
        ipi:converterParaFloat(fildIpi),
        cstpis:fildCstPis,
        cstcofins:fildCstCofins,
        aliquotapis:converterParaFloat(fildCofins),
        aliquotacofins:converterParaFloat(fildCofins),
        cest:fildCest,
        iss:0,
        aplicacao:fildAplicacao,
        embalagem:fildEmbalagem,
        pliquido:converterParaFloat(fildPLiquido),
        pbruto:converterParaFloat(fildPBruto),
        codigofornecedor:fildCodigoFornecedor,
        qtdminima:converterParaFloat(fildQtdMinima),
        planocontas:fildPlanoContas,
        inativo:fildInativo,
        descontomaximo: converterParaFloat(fildDescontoMaximo),
        movimentaestoque:fildMovimentaEstoque,
        cfopnfce:fildCfopNfce,
        st_nfce:String(cstOrigemNfce)+ String(cstNfce),
        icms_nfce:fildIcmsNfce,
        icmssub_nfce:fildIcmsSubNfce,
        cstcofins_nfce:fildCstCofinsNfce,
        cstpis_nfce:fildCstPisNfce,
        aliquotapis_nfce:converterParaFloat(fildPisNfce),
        aliquotacofins_nfce:converterParaFloat(fildCofinsNfce),
        cstipi_nfce:fildCstIpiNfce,
        ipi_nfce:converterParaFloat(fildIpiNfce),
        qtdvolume:fildQtdVolume,
        qtdvolumecompra:fildQtdVolumeCompra,
        codigoanvisa:fildCodigoAnvisa,
        marca:fildMarca,
        comissao:fildComissao,
        almoxarifado:fildAlmoxarifado,
        cstipi:fildCstIpi,
        modbcicmsst:fildModBcSt,
        modbcicms:fildModBc,
      }];

      setimpedirMultClick(true);
      const response = await fetch(`${Config.urlRoot}/product`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');
      }

      else{

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

      //id restornado
      setFildId(json[0].id);

      setFildProduto(json[0].produto);
      // setFildDescricao(json.descricao);
      // setFildCodigoGrupo(json.codigogrupo);
      // setFildCusto(json.custo);
      // setFildPrecoVenda(json.precovenda);
      // setFildIcms(json.icms);
      // setFildIcmsSub(json.icmssub);
      // setFildValorSugerido(json.valorsugerido);
      // setFildMargem(json.margem);
      // setFildReferencia(json.referencia);
      // setFildCbarra(json.cbarra);
      // setFildUnidade(json.unidade);
      // setCstNfe(json.st);
      // setFildCf(json.cf);
      // setFildModelo(json.modelo);
      // setFildVenda(json.venda);
      // setFildIpi(json.ipi);
      // setFildCstPis(json.cstpis);
      // setFildPis(json.aliquotapis);
      // setFildCstCofins(json.cstcofins);
      // setFildCofins(json.aliquotacofins);
      // setFildCest(json.cest);
      // setFildAplicacao(json.aplicacao);
      // setFildEmbalagem(json.embalagem);
      // setFildPLiquido(json.pliquido);
      // setFildPBruto(json.pbruto);
      // setFildCodigoFornecedor(json.codigofornecedor);
      // setFildQtdMinima(json.qtdminima);
      // setFildPlanoContas(json.planocontas);
      // setFildInativo(json.inativo);
      // setFildDescontoMaximo(json.descontomaximo);
      // setFildMovimentaEstoque(json.movimentaestoque);
      // setFildCfopNfce(json.cfopnfce);
      // setCstNfce(json.st_nfce);
      // setFildIcmsNfce(json.icms_nfce);
      // setFildIcmsSubNfce(json.icmssub_nfce);
      // setFildCstCofinsNfce(json.cstcofins_nfce);
      // setFildCstPisNfce(json.cstpis_nfce);
      // setFildPisNfce(json.aliquotapis_nfce);
      // setFildCofinsNfce(json.aliquotacofins_nfce);
      // setFildCstIpiNfce(json.cstipi_nfce);
      // setFildIpiNfce(json.ipi_nfce);
      // setFildQtdVolume(json.qtdvolume);
      // setFildQtdVolumeCompra(json.qtdvolumecompra);
      // setFildCodigoAnvisa(json.codigoanvisa);
      // setFildMarca(json.marca);
      // setFildComissao(json.comissao);
      // setFildAlmoxarifado(json.almoxarifado);
      // setFildCstIpi(json.cstipi);
      // setFildModBcSt(json.modbcicmsst);
      // setFildModBc(json.modbcicms);
      // setFildResponsavel(json.responsavel);
      // setFildDataAtualizacao(json.updated_at);
      // setFildDataCadastro(json.created_at);
      // setFildBalanca(json.balanca);
      setCstOrigemNfce( (json[0].st_nfce !== null &&  json[0].st_nfce.length>0) ? json[0].st_nfce.substring(0,1):"");
      setCstOrigemNfe((json[0].st !== null &&  json[0].st.length>0)? json[0].st.substring(0,1):"");

      }

      setimpedirMultClick(false);

      handleOpenMessage();
    }
  }

  async function salvarUnidade() {
    if (descricaoNovaUnidade.length === 0) {
      return;
    }
    else {

      setExibirProgressUnidades(true);
      //Chama a API

      const registro = [{
        chave: idUnidade,
        descricao: descricaoNovaUnidade
      }];

      const response = await fetch(`${Config.urlRoot}/unity`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){
        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');
      }
      else{
        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //atualiza na tela os dados pois tem que atualizar a data de  cadastro e atualizacao e o responsavel
        setLsUnidades(json);
      }
      //Encerra o progress
      setIdUnidade('0');
      setDescricaoNovaUnidade('');
      setExibirProgressUnidades(false);
      handleCloseModalUnidades();
    }
  }

  async function salvarMarca() {
    if (descricaoNovaMarca.length === 0) {
      return;
    }
    else {

      //Chama a API

      const registro = [{
        chave: idMarca,
        descricao: descricaoNovaMarca
      }];

      const response = await fetch(`${Config.urlRoot}/brand`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){
        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');
      }
      else{
        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //atualiza na tela os dados pois tem que atualizar a data de  cadastro e atualizacao e o responsavel
        setLsMarca(json);
      }

      //Encerra o progress
      setIdMarca('0');
      setDescricaoNovaMarca('');
      handleCloseModalMarcas();
    }
  }

  async function salvarGrupo() {

    if (descricaoNovoGrupo.length === 0) {
      return;
    }
    else {

      setExibirProgressGrupos(true);
      //Chama a API
      const registro = [{
        grupo: idGrupo,
        descricao: descricaoNovoGrupo
      }];

      const response = await fetch(`${Config.urlRoot}/groupproduct`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //atualiza na tela os dados pois tem que atualizar a data de  cadastro e atualizacao e o responsavel
        setLsGrupo(json);
      }

      //Encerra o progress
      setIdGrupo('0');
      setDescricaoNovoGrupo('');
      setExibirProgressGrupos(false);
      handleCloseModalGrupo();
    }
  }

  useEffect(() => {
  if(usuarioRetorno === "Não Identificado"){
    signOut(userDispatch, props.history);
  }
}, [props.history, userDispatch]);

  // useEffect(() => {

  //   //É um novo Registro
  //   if (props.location.idregistro.text === undefined) {
  //     props.history.push('/app/ProductRegister');

  //   }
  //   else if (props.location.idregistro.text === "0") {

  //     setExibirProgress(false);
  //     setNovoRegistro(true);

  //     if(recarregar){
  //      popularFormulario("0");
  //     }

  //   } else {

  //     // if(recarregar){

  //     //   // setExibirProgress(true);

  //     //   popularFormulario(props.location.idregistro.text);

  //     // }
  //   }

  // }, [popularFormulario, props.history, props.location.idregistro.text, recarregar]);

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function modalUnidades() {

    return (
      <Modal
        open={openUnidades}
        onClose={handleCloseModalUnidades}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h3">
            Nova Unidade
            <FormControl fullWidth size="small" sx={{ m: 0 }} variant="filled">
              <InputLabel htmlFor="tfNovaUnidade">Descrição</InputLabel>
              <FilledInput
                id="tfNovaUnidade"
                variant="filled"
                value={descricaoNovaUnidade}
                //defaultValue={descricaoNovaUnidade}
                inputProps={{ maxLength: 3 }}
                onChange={(event) => setDescricaoNovaUnidade(event.target.value)}
              />
            </FormControl>

          </Typography>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalUnidades}>Cancelar
          </Button>

          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarUnidade}>Salvar
          </Button>

        </Box>
      </Modal>
    )
  }

  function modalGrupos() {

    return (
      <Modal
        open={openGrupo}
        onClose={handleCloseModalGrupo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h3">
            Novo Grupo

            <FormControl fullWidth size="small" sx={{ m: 0 }} variant="filled">
              <InputLabel htmlFor="tfNovoGrupo">Descrição</InputLabel>
              <FilledInput
                id="tfNovoGrupo"
                variant="filled"
                value={descricaoNovoGrupo}
                //defaultValue={descricaoNovoGrupo}
                inputProps={{ maxLength: 20 }}
                onChange={(event) => setDescricaoNovoGrupo(event.target.value)}
              />
            </FormControl>

          </Typography>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalGrupo}>Cancelar
          </Button>

          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarGrupo}>Salvar
          </Button>

        </Box>
      </Modal>
    )
  }

  function botaoVoltar() {
    props.history.push('/app/Product');
  }

  function botaoNovo() {

    setFildId('0');
    setFildProduto("");
    setFildDescricao("");
    setFildCodigoGrupo("");
    setFildCusto(0);
    setFildPrecoVenda(0);
    setFildIcms(1);
    setFildIcmsSub(1);
    setFildValorSugerido(0);
    setFildMargem(0);
    setFildReferencia("");
    setFildCbarra("");
    setFildUnidade("");
    setCstNfe("");
    setFildCf("");
    setFildModelo("");
    setFildVenda("");
    setFildIpi(0);
    setFildCstPis("");
    setFildPis(0);
    setFildCstCofins("");
    setFildCofins(0);
    setFildCest("");
    setFildAplicacao("");
    setFildEmbalagem("");
    setFildPLiquido(1);
    setFildPBruto(1);
    setFildCodigoFornecedor("");
    setFildQtdMinima(0);
    setFildPlanoContas("");
    setFildInativo("true");
    setFildDescontoMaximo(0);
    setFildMovimentaEstoque("true");
    setFildCfopNfce("");
    setCstNfce("");
    setFildIcmsNfce(1);
    setFildIcmsSubNfce(1);
    setFildCstCofinsNfce("");
    setFildCstPisNfce("");
    setFildPisNfce(0);
    setFildCofinsNfce(0);
    setFildCstIpiNfce("");
    setFildIpiNfce(0);
    setFildQtdVolume(1);
    setFildQtdVolumeCompra(1);
    setFildCodigoAnvisa("");
    setFildMarca("");
    setFildComissao(0);
    setFildAlmoxarifado("false");
    setFildCstIpi("");
    setFildModBcSt("");
    setFildModBc("");
    setFildResponsavel(usuarioRetorno);
    setFildDataAtualizacao("");
    setFildDataCadastro("");
    setFildBalanca("false");
    setCstOrigemNfce("");
    setCstOrigemNfe("");
  }

  function modalMarca() {

    return (
      <Modal
        open={openMarcas}
        onClose={handleCloseModalMarcas}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h3">
            Nova Marca
            <FormControl fullWidth size="small" sx={{ m: 0 }} variant="filled">
              <InputLabel htmlFor="tfNovaMarca">Descrição</InputLabel>
              <FilledInput
                id="tfNovaMarca"
                variant="filled"
                value={descricaoNovaMarca}
                //defaultValue={descricaoNovaUnidade}
                inputProps={{ maxLength: 20 }}
                onChange={(event) => setDescricaoNovaMarca(event.target.value)}
              />
            </FormControl>

          </Typography>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalMarcas}>Cancelar
          </Button>

          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarMarca}>Salvar
          </Button>

        </Box>
      </Modal>
    )
  }

  return (
    <>

      {modalUnidades()}
      {modalGrupos()}
      {modalMarca()}

      <PageTitle title="Produto "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

      <AguardarRetorno show={impedirMultClick} />

      <Grid container spacing={2}>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Dados" {...a11yProps(0)} />
              <Tab label="Fiscal" {...a11yProps(1)} />
              <Tab label="Complemento" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Card variant="outlined">
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '65ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      <InputLabel htmlFor="tfDescricao">Descrição</InputLabel>
                      <FilledInput
                        id="tfDescricao"
                        value={fildDescricao}
                        //defaultValue={fildDescricao}
                        variant="filled"
                        inputProps={{ maxLength: 120 }}
                        onChange={(event) => setFildDescricao(event.target.value)}
                        error={validaDescricao}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfReferencia">Referência</InputLabel>
                      <FilledInput
                        id="tfReferencia"
                        value={fildReferencia}
                        //defaultValue={fildReferencia}
                        inputProps={{ maxLength: 50 }}
                        onChange={(event) => setFildReferencia(event.target.value)}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfGtin">Cód. Barras / GTIN</InputLabel>
                      <FilledInput
                        id="tfGtin"
                        value={fildCbarra}
                        //defaultValue={fildCbarra}
                        inputProps={{ maxLength: 20 }}
                        onChange={(event) => setFildCbarra(event.target.value)}
                      />
                    </FormControl>

                    <Box
                      component="form"
                      sx={{ '& .MuiTextField-root': { m: 1, width: '41.5ch' }, }}
                      noValidate
                      autoComplete="off"
                    >

                      <TextField
                        id="tfUnidade"
                        size="small"
                        select
                        label="Unidade"
                        value={fildUnidade}
                        variant="filled"
                        onChange={handleChangeUnidade}
                      >
                        <IconButton size="small"
                          sx={{ color: green[500] }}
                          onClick={handleOpenModalUnidades}
                        ><AddIcon fontSize="inherit" style={{ marginRight: 5 }} />

                        </IconButton>

                        {lsUnidades.map((option) => (
                          <MenuItem key={option.descricao} value={option.descricao}>

                            <IconButton size="small"
                              sx={{ color: blue[500] }}
                              //  onClick={(event) => setIdUnidade([option.id,option.descricao])}
                              onClick={() => (handleChangeEditUnidade(option.descricao, option.chave))}
                            >

                              <EditIcon fontSize="inherit" style={{ marginRight: 5 }} />

                            </IconButton>

                            {option.descricao}
                          </MenuItem>
                        ))}

                      </TextField>

                      <TextField
                        id="tfVenda"
                        select
                        size="small"
                        label="Venda"
                        value={fildVenda}
                        variant="filled"
                        onChange={handleChangeVenda}
                      >
                        {valoresSim_Nao.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Box>

                    <Box
                      component="form"
                      sx={{ '& .MuiTextField-root': { m: 1, width: '41.5ch' }, }}
                      noValidate
                      autoComplete="off"
                    >

                      <TextField
                        id="tfMeuMenu"
                        select
                        size="small"
                        label="Utilizar Aplicativo Web"
                        variant="filled"
                        value={fildModelo}
                        //defaultValue={fildModelo}
                        onChange={handleChangeModelo}
                      >
                        {lsModelo.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        id="tfMovimento"
                        select
                        size="small"
                        label="Movimentar Estoque"
                        variant="filled"
                        value={fildMovimentaEstoque}
                        //defaultValue={fildMovimentaEstoque}
                        onChange={handleChangeMovimentaEstoque}
                      >
                        {valoresSim_Nao.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Box>

                    <Box
                      component="form"
                      sx={{ '& .MuiTextField-root': { m: 1, width: '41.5ch' }, }}
                      noValidate
                      autoComplete="off"
                    >

                      <TextField
                        id="tfAtivo"
                        select
                        size="small"
                        label="Ativo"
                        variant="filled"
                        value={fildInativo}
                        //defaultValue={fildInativo}
                        onChange={handleChangeInativo}
                      >

                        {valoresAtivo_Inativo.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}

                      </TextField>

                      <TextField
                        id="tfTipo"
                        select
                        size="small"
                        label="Almoxarifado"
                        variant="filled"
                        value={fildAlmoxarifado}
                        //defaultValue={fildAlmoxarifado}
                        onChange={handleChangeAlmoxarifado}
                      >
                        {valoresSim_Nao.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}

                      </TextField>
                    </Box>

                    <Box
                      component="form"
                      sx={{ '& .MuiTextField-root': { m: 1, width: '41.5ch' }, }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="tfBalanca"
                        select
                        size="small"
                        label="Balança"
                        variant="filled"
                        value={fildBalanca}
                        //defaultValue={fildBalanca}
                        onChange={handleChangeBalanca}
                      >
                        {valoresSim_Nao.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>

                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfEmbalagem">Embalagem</InputLabel>
                        <FilledInput
                          id="tfEmbalagem"
                          value={fildEmbalagem}
                          //defaultValue={fildEmbalagem}
                          inputProps={{ maxLength: 14 }}
                          onChange={(event) => setFildEmbalagem(event.target.value)}
                        />
                      </FormControl>
                    </Box>

                    <Box
                      component="form"
                      sx={{ '& .MuiTextField-root': { m: 1, width: '41.5ch' }, }}
                      noValidate
                      autoComplete="off"
                    >

                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfPBruto">Peso Bruto</InputLabel>
                        <FilledInput
                          id="tfPBruto"
                          type={"number"}
                          value={fildPBruto}
                          //defaultValue={fildPBruto}
                          onChange={(event) => setFildPBruto(event.target.value)}
                        />
                      </FormControl>

                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfPLiquido">Peso Líquido</InputLabel>
                        <FilledInput
                          id="tfPLiquido"
                          type={"number"}
                          value={fildPLiquido}
                          //defaultValue={fildPLiquido}
                          onChange={(event) => setFildPLiquido(event.target.value)}
                        />
                      </FormControl>
                    </Box>

                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} >
                <Card variant="outlined">
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '65ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <div>
                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfCusto">Custo</InputLabel>
                        <FilledInput
                          id="tfCusto"
                          type={'number'}
                          value={fildCusto}
                          //defaultValue={fildCusto}
                          onChange={handleChangeCusto}
                          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        />
                      </FormControl>

                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfMargem">Margem</InputLabel>
                        <FilledInput
                          id="tfMargem"
                          type={'number'}
                          value={fildMargem}
                          //defaultValue={fildMargem}
                          onChange={handleChangeMargem}
                          startAdornment={<InputAdornment position="start">%</InputAdornment>}
                        />
                      </FormControl>
                    </div>

                    <div>
                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfValorSugerido">Preço Sugerido</InputLabel>
                        <FilledInput
                          id="tfValorSugerido"
                          // readOnly
                          value={fildValorSugerido}
                          //defaultValue={fildValorSugerido}
                          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        />
                      </FormControl>

                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tPrecoVenda">Preço Venda</InputLabel>
                        <FilledInput
                          id="tPrecoVenda"
                          type={'number'}
                          value={fildPrecoVenda}
                          //defaultValue={fildPrecoVenda}
                          onChange={(event) => setFildPrecoVenda(event.target.value)}
                          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        />
                      </FormControl>
                    </div>

                    <div>
                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfDescontoMaximo">Desconto Máximo</InputLabel>
                        <FilledInput
                          id="tfDescontoMaximo"
                          type={"number"}
                          value={fildDescontoMaximo}
                          //defaultValue={fildDescontoMaximo}
                          onChange={(event) => setFildDescontoMaximo(event.target.value)}
                          startAdornment={<InputAdornment position="start">%</InputAdornment>}
                        />
                      </FormControl>

                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfQtdMinima">Qtd. Mínima</InputLabel>
                        <FilledInput
                          id="tfQtdMinima"
                          type={'number'}
                          value={fildQtdMinima}
                          //defaultValue={fildQtdMinima}
                          onChange={(event) => setFildQtdMinima(event.target.value)}
                          startAdornment={<InputAdornment position="start">Qtd.</InputAdornment>}
                        />
                      </FormControl>
                    </div>

                    <div>
                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfAnvisa">ANVISA</InputLabel>
                        <FilledInput
                          id="tfAnvisda"
                          value={fildCodigoAnvisa}
                          //defaultValue={fildCodigoAnvisa}
                          inputProps={{ maxLength: 16 }}
                          onChange={(event) => setFildCodigoAnvisa(event.target.value)}
                        />
                      </FormControl>

                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfComissao">Comissao</InputLabel>
                        <FilledInput
                          id="tfComissao"
                          type={'number'}
                          value={fildComissao}
                          //defaultValue={fildComissao}
                          onChange={(event) => setFildComissao(event.target.value)}
                          startAdornment={<InputAdornment position="start">%</InputAdornment>}
                        />
                      </FormControl>
                    </div>

                    <div>
                      <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                        <InputLabel htmlFor="tfLocalizacao">Localização Fixa</InputLabel>
                        <FilledInput
                          id="tfLocalizacao"
                          value={fildAplicacao}
                          //defaultValue={fildAplicacao}
                          inputProps={{ maxLength: 25 }}
                          onChange={(event) => setFildAplicacao(event.target.value)}
                        />
                      </FormControl>

                      <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                        <InputLabel htmlFor="tfCf">CF / NCM</InputLabel>
                        <FilledInput
                          id="tfCf"
                          value={fildCf}
                          //defaultValue={fildCf}
                          variant="filled"
                          inputProps={{ maxLength: 8 }}
                          onChange={(event) => setFildCf(event.target.value)}
                        />
                      </FormControl>

                      <Box
                        component="form"
                        sx={{ '& .MuiTextField-root': { m: 1, width: '41.5ch' }, }}
                        noValidate
                        autoComplete="off"
                      >


                      <TextField
                        id="tfMarca"
                        size="small"
                        select
                        label="Marca"
                        value={fildMarca}
                        variant="filled"
                        onChange={handleChangeMarca}
                      >
                        <IconButton size="small"
                          sx={{ color: green[500] }}
                          onClick={handleOpenModalMarcas}
                        ><AddIcon fontSize="inherit" style={{ marginRight: 5 }} />

                        </IconButton>

                        {lsMarca.map((option) => (
                          <MenuItem key={option.descricao} value={option.descricao}>

                            <IconButton size="small"
                              sx={{ color: blue[500] }}
                              //  onClick={(event) => setIdUnidade([option.id,option.descricao])}
                              onClick={() => (handleChangeEditMarca(option.descricao, option.chave))}
                            >

                              <EditIcon fontSize="inherit" style={{ marginRight: 5 }} />

                            </IconButton>

                            {option.descricao}
                          </MenuItem>
                        ))}

                      </TextField>

                      </Box>
                    </div>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1}>

            <Grid container spacing={2} style={{ marginBottom: 5 }}>
              {/* COnfigursacoes NFE */}
              <Grid item xs={12} md={6} >
                <Card variant="outlined">
                  <Box
                    component="form"
                    sx={{ '& .MuiTextField-root': { m: 1, width: '41.5ch' }, }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField
                      id="tfModBc"
                      select
                      size="small"
                      label="Modelo Base Cálculo"
                      value={fildModBc}
                      variant="filled"
                      onChange={handleChangeModBc}
                    >
                      {lsModBcIcms.map((option) => (
                        <MenuItem key={option.codigo} value={option.codigo}>
                          {option.codigo + " - " + option.value}
                        </MenuItem>
                      ))}

                    </TextField>

                    <TextField
                      id="tfModBcSt"
                      select
                      size="small"
                      label="Modelo Base Cálculo ST"
                      value={fildModBcSt}
                      variant="filled"
                      onChange={handleChangeModBcSt}
                    >
                      {lsModBcSt.map((option) => (
                        <MenuItem key={option.codigo} value={option.codigo}>
                          {option.codigo + " - " + option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Card>
              </Grid>
            </Grid>


            <Grid container spacing={2}>
              {/* COnfigursacoes NFE */}
              <Grid item xs={12} md={6} >
                <Card variant="outlined">

                  <Typography color="text" colorBrightness="secondary" spacing={2} variant="h6"
                    sx={{ color: blue[500] }}
                    style={{ marginLeft: 10, marginTop: 10 }}
                  >
                    Configurações para NF-e
                  </Typography>

                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '85ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      {/* {exibirProgressSituacaoTributaria && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)} */}

                      <Box
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { m: 1, width: '85ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="tfNfeStOrigem"
                          select
                          size="small"
                          label="Origem"
                          variant="filled"
                          style={{ width: '31.5ch' }}
                          value={cstOrigemNfe}
                          //defaultValue={cstOrigemNfe}
                          onChange={handleChangeCstOrigemNfe}
                        >
                          {lsOrigem.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          id="tfNfeSt"
                          select
                          size="small"
                          label="ST / CSOSN"
                          variant="filled"
                          style={{ width: '51.5ch' }}
                          value={cstNfe}
                          //defaultValue={cstNfe}
                          onChange={handleChangeCstNfe}
                        >
                          {lsSt.map((option) => (
                            <MenuItem key={option.codigo} value={option.codigo}>
                              {option.codigo + ' - ' + option.descricao}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </div>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfNfeAliquotaIpi">IPI</InputLabel>
                      <FilledInput
                        id="tfNfeAliquotaIpi"
                        type={'number'}
                        value={fildIpi}
                        //defaultValue={fildIpi}
                        onChange={(event) => setFildIpi(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 0, width: '41.5ch' }} variant="filled">
                      {/* {exibirProgressSituacaoTributaria && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)} */}

                      <TextField
                        id="tfNfeStIpi"
                        select
                        size="small"
                        label="Situação Trib."
                        variant="filled"
                        style={{ width: '41.5ch' }}
                        value={fildCstIpi}
                        //defaultValue={fildCstIpi}
                        onChange={handleChangeCstIpi}
                      >
                        {lsStIpi.map((option) => (
                          <MenuItem key={option.codigo} value={option.codigo}>
                            {option.codigo + ' - ' + option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfNfeAliquotaPis">PIS</InputLabel>
                      <FilledInput
                        id="tfNfeAliquotaPis"
                        type={'number'}
                        value={fildPis}
                        //defaultValue={fildPis}
                        onChange={(event) => setFildPis(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 0, width: '41.5ch' }} variant="filled">
                      {/* {exibirProgressSituacaoTributaria && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)} */}

                      <TextField
                        id="tfNfeCstPis"
                        select
                        label="Situação Trib."
                        size="small"
                        variant="filled"
                        value={fildCstPis}
                        //defaultValue={fildCstPis}
                        onChange={handleChangeCstPis}
                        style={{ width: '41.5ch' }}
                      >
                        {lsStPisCofins.map((option) => (
                          <MenuItem key={option.codigo} value={option.codigo}>
                            {option.codigo + ' - ' + option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfNfeAliquotaCofins">COFINS</InputLabel>
                      <FilledInput
                        id="tfNfeAliquotaCofins"
                        type={'number'}
                        value={fildCofins}
                        //defaultValue={fildCofins}
                        onChange={(event) => setFildCofins(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 0, width: '41.5ch' }} variant="filled">
                      {/* {exibirProgressSituacaoTributaria && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)} */}

                      <TextField
                        id="tfNfeCstCofins"
                        select
                        label="Situação Trib."
                        size="small"
                        variant="filled"
                        style={{ width: '41.5ch' }}
                        value={fildCstCofins}
                        //defaultValue={fildCstCofins}
                        onChange={handleChangeCstCofins}
                      >
                        {lsStPisCofins.map((option) => (
                          <MenuItem key={option.codigo} value={option.codigo}>
                            {option.codigo + ' - ' + option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <div>
                      <Box
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { m: 1, width: '41.5ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        {/* {exibirProgressSituacaoTributaria && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)} */}

                        <TextField
                          id="tfNfeIcms"
                          select
                          label="ICMS"
                          size="small"
                          variant="filled"
                          style={{ width: '41.5ch' }}
                          value={fildIcms}
                          //defaultValue={fildIcms}
                          onChange={handleChangeIcmsNfe}
                        >
                          {lsIcms.map((option) => (
                            <MenuItem key={option.coluna} value={option.coluna}>
                              {option.descricao}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          id="tfNFeICmsst"
                          select
                          label="ICMS Sub."
                          size="small"
                          variant="filled"
                          style={{ width: '41.5ch' }}
                          value={fildIcmsSub}
                          //defaultValue={fildIcmsSub}
                          onChange={handleChangeIcmsStNfe}
                        >
                          {lsIcms.map((option) => (
                            <MenuItem key={option.coluna} value={option.coluna}>
                              {option.descricao}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </div>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfCest">CEST</InputLabel>
                      <FilledInput
                        id="tfNfeCest"
                        value={fildCest}
                        //defaultValue={fildCest}
                        inputProps={{ maxLength: 15 }}
                        onChange={(event) => setFildCest(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                </Card>
              </Grid>

              {/* COnfiguracoes NFCe */}
              <Grid item xs={12} md={6} >
                <Card variant="outlined">

                  <Typography color="text" colorBrightness="secondary" spacing={2} variant="h6"
                    sx={{ color: blue[500] }}
                    style={{ marginLeft: 10, marginTop: 10 }}
                  >
                    Configurações para NFC-e
                  </Typography>

                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '85ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <Box
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { m: 1, width: '41.5ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        {/* {exibirProgressSituacaoTributaria && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)} */}

                        <TextField
                          id="tfNfceStOrigem"
                          select
                          size="small"
                          label="Origem"
                          variant="filled"
                          style={{ width: '31.5ch' }}
                          value={cstOrigemNfce}
                          //defaultValue={cstOrigemNfce}
                          onChange={handleChangeCstOrigemNfce}
                        >
                          {lsOrigem.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          id="tfNfceSt"
                          select
                          size="small"
                          label="ST / CSOSN"
                          variant="filled"
                          style={{ width: '51.5ch' }}
                          value={cstNfce}
                          //defaultValue={cstNfce}
                          onChange={handleChangeCstNfce}

                        >
                          {lsSt.map((option) => (
                            <MenuItem key={option.codigo} value={option.codigo}>
                              {option.codigo + ' - ' + option.descricao}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </div>


                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfNfceIpi">IPI</InputLabel>
                      <FilledInput
                        id="tfNfceIpi"
                        type={'number'}
                        value={fildIpiNfce}
                        //defaultValue={fildIpiNfce}
                        onChange={(event) => setFildIpiNfce(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 0, width: '41.5ch' }} variant="filled">
                      {/* {exibirProgressSituacaoTributaria && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)} */}

                      <TextField
                        id="tfNfceStIpi"
                        select
                        size="small"
                        label="Situação Trib."
                        variant="filled"
                        value={fildCstIpiNfce}
                        style={{ width: '41.5ch' }}
                        onChange={handleChangeCstIpiNfce}
                      >
                        {lsStIpi.map((option) => (
                          <MenuItem key={option.codigo} value={option.codigo}>
                            {option.codigo + ' - ' + option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfNfcePis">PIS</InputLabel>
                      <FilledInput
                        id="tfNfcePis"
                        type={'number'}
                        value={fildPisNfce}
                        //defaultValue={fildPisNfce}
                        onChange={(event) => setFildPisNfce(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 0, width: '41.5ch' }} variant="filled">
                      {/* {exibirProgressSituacaoTributaria && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)} */}

                      <TextField
                        id="tfNfceCstPis"
                        select
                        size="small"
                        label="Situação Trib."
                        variant="filled"
                        value={fildCstPisNfce}
                        style={{ width: '41.5ch' }}
                        onChange={handleChangeCstPisNfce}
                      >
                        {lsStPisCofins.map((option) => (
                          <MenuItem key={option.codigo} value={option.codigo}>
                            {option.codigo + ' - ' + option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '41.5ch' }} variant="filled">
                      <InputLabel htmlFor="tfCofins">COFINS</InputLabel>
                      <FilledInput
                        id="tfNfceCofins"
                        type={'number'}
                        value={fildCofinsNfce}
                        //defaultValue={fildCofinsNfce}
                        onChange={(event) => setFildCofinsNfce(event.target.value)}
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      />

                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 0, width: '41.5ch' }} variant="filled">

                      <TextField
                        id="tfNfceStCofins"
                        select
                        label="Situação Trib."
                        size="small"
                        variant="filled"
                        value={fildCstCofinsNfce}
                        style={{ width: '41.5ch' }}
                        onChange={handleChangeCstCofinsNfce}
                      >
                        {lsStPisCofins.map((option) => (
                          <MenuItem key={option.codigo} value={option.codigo}>
                            {option.codigo + ' - ' + option.descricao}
                          </MenuItem>
                        ))}

                      </TextField>

                    </FormControl>

                    <div>
                      <Box
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { m: 1, width: '41.5ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      >

                        <TextField
                          id="tfNfceIcms"
                          select
                          size="small"
                          label="ICMS"
                          value={fildIcmsNfce}
                          variant="filled"
                          style={{ width: '41.5ch' }}
                          onChange={handleChangeIcmsNfce}
                        >
                          {lsIcms.map((option) => (
                            <MenuItem key={option.coluna} value={option.coluna}>
                              {option.descricao}
                            </MenuItem>
                          ))}

                        </TextField>

                        <TextField
                          id="tfNFeICmsst"
                          select
                          size="small"
                          label="ICMS Sub."
                          variant="filled"
                          style={{ width: '41.5ch' }}
                          value={fildIcmsSubNfce}
                          onChange={handleChangeIcmsStNfce}
                        >
                          {lsIcms.map((option) => (
                            <MenuItem key={option.coluna} value={option.coluna}>
                              {option.descricao}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </div>

                    <FormControl fullWidth size="small" sx={{ m: 0, width: '65ch' }} variant="filled">
                      <TextField
                        id="tfNfceCfop"
                        select
                        label="CFOP"
                        size="small"
                        variant="filled"
                        fullWidth
                        value={fildCfopNfce}
                        onChange={handleChangeCfop}
                      >
                        {lsCfop.map((option) => (
                          <MenuItem key={option.id} value={option.cfop}>
                            {option.cfop + " - " + option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                  </Box>
                </Card>
              </Grid>

            </Grid>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Card variant="outlined">
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '85ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      {exibirProgressFornecedores && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

                      <TextField
                        id="tfFornecedor"
                        select
                        size="small"
                        label="Fornecedor"
                        variant="filled"
                        fullWidth
                        value={fildCodigoFornecedor}
                        onChange={handleChangeFornecedor}
                      >
                        {lsFornecedores.map((option) => (
                          <MenuItem key={option.id} value={option.codigo}>
                            {option.fantasia}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      <TextField
                        id="tfPlanoContas"
                        select
                        size="small"
                        label="Plano de Contas"
                        variant="filled"
                        fullWidth
                        value={fildPlanoContas}
                        onChange={handleChangePlanoContas}
                      >
                        {lsPlanoContas.map((option) => (
                          <MenuItem key={option.codigoresumido} value={option.codigoresumido}>
                            {option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      {exibirProgressGrupos && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

                      <TextField
                        id="tfGrupo"
                        select
                        size="small"
                        label="Grupo"
                        variant="filled"
                        fullWidth
                        value={fildCodigoGrupo}
                        onChange={handleChangeGrupo}
                      >
                        <IconButton size="small"
                          sx={{ color: green[500] }}
                          onClick={handleOpenModalGrupo}
                        ><AddIcon fontSize="inherit" style={{ marginRight: 5 }} />

                        </IconButton>
                        {lsGrupo.map((option) => (
                          <MenuItem key={option.id} value={option.grupo}>

                            <IconButton size="small"
                              sx={{ color: blue[500] }}
                              //  onClick={(event) => setIdUnidade([option.id,option.descricao])}
                              onClick={() => (handleChangeEditGrupo(option.descricao, option.grupo))}
                            >
                              <EditIcon fontSize="inherit" style={{ marginRight: 5 }} />
                            </IconButton>

                            {option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl fullWidth size="small" sx={{ m: 1, width: '85ch' }} variant="filled">
                      <TextField
                        id="tfObservacao"
                        label="Observação"
                        multiline
                        rows={10}
                        variant="filled"
                        // value={fildObservacoes}
                        // //defaultValue={fildObservacoes}
                        inputProps={{ maxLength: 255 }}
                      // onChange={(event) => setFildObservacoes(event.target.value)}
                      />
                    </FormControl>

                  </Box>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>

          <footer>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12} style={{ marginLeft: 5, marginRight: 5 }}>
                <Card variant="outlined">
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                    <FormControl size="small" sx={{ m: 1, width: '35ch' }} variant="filled">
                      <InputLabel htmlFor="tfDataCadastro">Data do Cadastro</InputLabel>
                      <FilledInput
                        id="tfDataCadastro"
                        readOnly={true}
                        value={fildDataCadastro}
                      />
                    </FormControl>

                    <FormControl size="small" sx={{ m: 1, width: '35ch' }} variant="filled">
                      <InputLabel htmlFor="tfDataAlteracao">Data da Alteração</InputLabel>
                      <FilledInput
                        id="tfDataAlteracao"
                        value={fildDataAtualizacao}
                        readOnly={true}
                      />
                    </FormControl>

                    <FormControl size="small" sx={{ m: 1, width: '35ch' }} variant="filled">
                      <InputLabel htmlFor="tfResponsavel">Responsável</InputLabel>
                      <FilledInput
                        id="tfResponsavel"
                        value={fildResponsavel}
                        readOnly={true}
                      />
                    </FormControl>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </footer>
        </Box>
      </Grid>
    </>
  );
}
