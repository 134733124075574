// import React from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


import {
  Typography,
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  Input,
  MenuItem,
  Card,
  LinearProgress,
  Snackbar,
  Tab, Tabs, Modal, InputAdornment, CircularProgress, Backdrop, CardContent
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';

// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import { Button } from "../../../components/Wrappers";

// import Table from "./components/Tabela";
import { useUserDispatch, signOut, usuarioRetorno, senhaRetorno,configuracaoBd  } from "../../../context/UserContext";

import FilledInput from '@mui/material/FilledInput';
import AddIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import SearchIcon from '@mui/icons-material/Search';
import { blue } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';

import DadosCfop from "../../../constant/DadosCfop";

import Config from '../../../config/Config';
import AguardarRetorno from "../../../components/BackDrop/AguardarRetorno";


export default function ProviderRegister(props) {

  const [recarregar,SetRecarregar] = useState(true);
  const [recarregarCidade,SetRecarregarCidade] = useState(false);

  const [openMessage, setOpenMessage] = useState(false);
  const [state, setState] = useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const [textoMesagem, setTextoMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [novoRegistro, setNovoRegistro] = useState(false);
  const [exibirProgress, setExibirProgress] = useState(false);

  const [exibirProgressMunicipios, setExibirProgressMunicipios] = useState(false);
  const [exibirProgressRecebimentos, setExibirProgressRecebimentos] = useState(false);
  const [exibirProgressCfop, setExibirProgressCfop] = useState(false);
  const [exibirProgressCategorias, setExibirProgressCategorias] = useState(false);
  const [exibirProgressPlanoContas, setExibirProgressPlanoContas] = useState(false);
  const [exibirProgressContatos, setExibirProgressContatos] = useState(false);

  const [lsCfop, setLsCfop] = useState(props.location.cfops.text);
  const [lsRecebimento, setLsRecebimento] = useState(props.location.recebimentos.text);
  const [lsCategorias, setLsCategorias] = useState(props.location.grupos.text);
  const [lsPlanoContas, setLsPlanoContas] = useState(props.location.planocontas.text);
  const [lsContatos, setLsContatos] = useState([]);


  //Campos do formulario
  const [dadosRegistro,setDadosRegistro]= useState(props.location.fornec.text);

  const [fildId, setFildId] = useState(props.location.idregistro.text);
  const [fildCodigo, setFildCodigo] = useState(dadosRegistro.codigo);
  const [fildRazao, setFildRazao] = useState(dadosRegistro.razao);
  const [fildFantasia, setFildFantasia] = useState(dadosRegistro.fantasia);
  const [fildEndereco, setFildEndereco] = useState(dadosRegistro.endereco);
  const [fildBairro, setFildBairro] = useState(dadosRegistro.bairro);
  const [fildComplemento, setFildComplemento] = useState(dadosRegistro.complemento);
  const [fildCep, setFildCep] = useState(dadosRegistro.cep);
  const [fildInscricao, setFildInscricao] = useState(dadosRegistro.insceicao);
  const [fildCnpj, setFildCnpj] = useState(dadosRegistro.cnpj);
  const [fildFone, setFildFone] = useState(dadosRegistro.fone);
  const [fildFax, setFildFax] = useState(dadosRegistro.fax);
  const [fildContato, setFildContato] = useState(dadosRegistro.contato);
  const [fildStatus, setFildStatus] = useState(dadosRegistro.status);
  const [fildDataCadastro, setFildDataCadastro] = useState(dadosRegistro.created_at);
  const [fildEmail, setFildEmail] = useState(dadosRegistro.email);
  const [fildDataAtualizacao, setFildDataAtualizacao] = useState(dadosRegistro.updateted_at);
  const [fildObservacao, setFildObservacao] = useState(dadosRegistro.observacao);
  const [fildCodigoRecebimento, setFildCodigoRecebimento] = useState(dadosRegistro.codigorecebimento);
  const [fildReferencias, setFildReferencias] = useState(dadosRegistro.referencias);
  const [fildPlanoContas, setFildPlanoContas] = useState(dadosRegistro.planocontas);
  const [fildCodigoCidade, setFildCodigoCidade] = useState(dadosRegistro.codigocidade);
  const [fildCodigoUf, setFildCodigoUf] = useState(dadosRegistro.codigouf);
  const [fildPais, setFildPais] = useState(dadosRegistro.pais);
  const [fildCfop, setFildCfop] = useState(dadosRegistro.cfop);
  const [fildAuxiliar, setFildAuxiliar] = useState(dadosRegistro.auxiliar);
  const [fildNumero, setFildNumero] = useState(dadosRegistro.numero);
  const [fildCelular, setFildCelular] = useState(dadosRegistro.celular);
  const [fildPontoReferencia, setFildPontoReferencia] = useState(dadosRegistro.pontoreferencia);
  const [fildInscricaoMunicipal, setFildInscricaoMunicipal] = useState(dadosRegistro.inscricaomunicipal);
  const [fildResponsavel, setFildResponsavel] = useState(dadosRegistro.responsavel);
  const [fildGrupo, setFildGrupo] = useState(dadosRegistro.grupo);
  //Fim dos campos do formulario

  // Campos formulario Contato
  const [contatoId, setContatoId] = useState('');
  const [contatoCliente, setContatoCliente] = useState('');
  const [contatoNome, setContatoNome] = useState('');
  const [contatoCargo, setContatoCargo] = useState('');
  const [contatoFone, setContatoFone] = useState('');
  const [contatoEmail, setContatoEmail] = useState('');
  const [contatoAniversario, setContatoAniversario] = useState(null);
  const [contatoSexo, setContatoSexo] = useState('');
  const [contatoFone2, setContatoFone2] = useState('');

  //modal de contatos
  const [openContato, setOpenContato] = useState(false);
  const handleOpenModalContato = () => setOpenContato(true);
  const handleCloseModalContato = () => setOpenContato(false);
  const [idContato, setIdContato] = useState('0');

  //Fim Campos formulario Contato

  const [dadosMunicipio, setDadosMunicipios] = useState([]);
  const dadosUf = [
    {
      "cuf": "11",
      "uf": "RO",
      "nome": "Rondônia"
    },
    {
      "cuf": "12",
      "uf": "AC",
      "nome": "Acre"
    },
    {
      "cuf": "13",
      "uf": "AM",
      "nome": "Amazonas"
    },
    {
      "cuf": "14",
      "uf": "RR",
      "nome": "Roraima"
    },
    {
      "cuf": "15",
      "uf": "PA",
      "nome": "Pará"

    },
    {
      "cuf": "16",
      "uf": "AP",
      "nome": "Amapá"
    },
    {
      "cuf": "17",
      "uf": "TO",
      "nome": "Tocantins"
    },
    {
      "cuf": "21",
      "uf": "MA",
      "nome": "Maranhão"
    },
    {
      "cuf": "22",
      "uf": "PI",
      "nome": "Piauí"
    },
    {
      "cuf": "23",
      "uf": "CE",
      "nome": "Ceara"
    },
    {
      "cuf": "24",
      "uf": "RN",
      "nome": "Rio Grande do Norte"

    },
    {
      "cuf": "25",
      "uf": "PB",
      "nome": "Paraíba"

    },
    {
      "cuf": "26",
      "uf": "PE",
      "nome": "Pernambuco"
    },
    {
      "cuf": "27",
      "uf": "AL",
      "nome": "Alagoas"
    },
    {
      "cuf": "28",
      "uf": "SE",
      "nome": "Sergipe"
    },
    {
      "cuf": "29",
      "uf": "BA",
      "nome": "Bahia"
    },
    {
      "cuf": "31",
      "uf": "MG",
      "nome": "Minas Gerais"
    },
    {
      "cuf": "32",
      "uf": "ES",
      "nome": "Espírito Santo"
    },
    {
      "cuf": "33",
      "uf": "RJ",
      "nome": "Rio de janeiro"
    },
    {
      "cuf": "35",
      "uf": "SP",
      "nome": "São Paulo"
    },
    {
      "cuf": "41",
      "uf": "PR",
      "nome": "Paraná"
    },
    {
      "cuf": "42",
      "uf": "SC",
      "nome": "Santa Catarina"

    },
    {
      "cuf": "43",
      "uf": "RS",
      "nome": "Rio Grande do Sul"
    },
    {
      "cuf": "50",
      "uf": "MS",
      "nome": "Mato Grosso do Sul"
    },
    {
      "cuf": "51",
      "uf": "MT",
      "nome": "Mato Gorsso"
    },
    {
      "cuf": "52",
      "uf": "GO",
      "nome": "Goiás"
    },
    {
      "cuf": "53",
      "uf": "DF",
      "nome": "Distrito Federal"
    },
    {
      "cuf": "00",
      "uf": "EX",
      "nome": "Exterior"
    }
  ];


  const [openModalMunicipios, setOpenModalMunicipios] = useState(false);

  const [validaRazao, setValidaRazao] = useState(false);
  const [msgErroRazao, setMsgErroRazao] = useState('');

  const [validaFantasia, setValidaFantasia] = useState(false);
  const [msgErroFantasia, setMsgErroFantasia] = useState('');

  const [validaCgc, setValidaCgc] = useState(false);
  const [msgErroCgc, setMsgErroCgc] = useState('');

  const [validaUf, setValidaUf] = useState(false);
  const [msgErroUf, setMsgErroUf] = useState('');

  const [cepJaConsultado, setCepJaConsultado] = useState(false);
  const [cnpjJaConsultado, setCnpjJaConsultado] = useState(false);

  const [descricaoNovoGrupo, setDescricaoNovoGrupo] = useState('');
  const [openGrupo, setOpenGrupo] = useState(false);
  const handleOpenModalGrupo = () => setOpenGrupo(true);
  const handleCloseModalGrupo = () => setOpenGrupo(false);
  const [idGrupo, setIdGrupo] = useState('0');

  const [impedirMultClick, setimpedirMultClick] = useState(false);

  const valoresSim_Nao = [
    {
      value: 'true',
      label: 'Sim',
    },
    {
      value: 'false',
      label: 'Não',
    }
  ];


  const handleChange = (event) => {
    // setFildBanco(event.target.value);
  };

  const handleChangeAtivo = (event) => {
    setFildStatus(event.target.value);
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleChangeUf = (event) => {
    setFildCodigoUf(event.target.value);
  }

  const handleChangeGrupo = (event) => {
    setFildGrupo(event.target.value);
  }

  const handleChangePlanoContas = (event) => {
    setFildPlanoContas(event.target.value);
  }

  const handleChangeRecebimento = (event) => {
    setFildCodigoRecebimento(event.target.value);
  }

  const handleChangeCidade = (event) => {
    setFildCodigoCidade(event.target.value);
  }

  const handleChangeCfop = (event) => {
    setFildCfop(event.target.value);
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const handleChangeCep = (e) => {
    setFildCep(e);

    if (e.length !== 8) {
      setCepJaConsultado(false);
    }

    ConsultarCep();
  }

  const handleChangeCnpj = (e) => {
    setFildCnpj(e);

    if (e.length !== 14) {
      setCnpjJaConsultado(false);
      //return;
    }

  }

  const handleChangeEditGrupo = (valor, chave) => {
    setIdGrupo(chave);
    setDescricaoNovoGrupo(valor);
    //chama o modal
    handleOpenModalGrupo();
  }

  const handleChangeEditContato = (id) => {

    dadosCompletoContato(id);
  }

  const handleChangeContato = (event) => {
    setFildContato(event.target.value);
  }

  const handleChangeNascimento = (newValue) => {

    if (newValue !== '') {
      if (newValue !== null) {
        setContatoAniversario(newValue.toJSON());
      }
    }
  };

  //Listas
  async function popularFormulario(id) {

    // setExibirProgress(true);
    // setExibirProgressRecebimentos(true);
    // setExibirProgressCategorias(true);
    // setExibirProgressCfop(true);
    // setExibirProgressContatos(true);
    // setExibirProgressPlanoContas(true);

    // const response = await fetch(`${Config.urlRoot}/provider/list/${id}`, {
    //   method: 'POST',
    //   headers: {Accept: 'application/json','Content-Type': 'application/json'},
    //   body: JSON.stringify({
    //     dataBase:configuracaoBd,
    //   })
    // });

    // SetRecarregar(false);

    // let json = await response.json();

    // if(response.status!==200 && response.status!==201){
    //   SetRecarregar(false);

    //   setExibirProgress(false);
    //   setExibirProgressRecebimentos(false);
    //   setExibirProgressCategorias(false);
    //   setExibirProgressCfop(false);
    //   setExibirProgressContatos(false);
    //   setExibirProgressPlanoContas(false);

    //   return;
    // }

    // if(json.fornecedorList!==null){

    //   if(json.fornecedorList.uf!==""){
    //     SetRecarregarCidade(true);
    //   }

    //   setFildCodigo(json.fornecedorList.codigo);
    //     setFildRazao(json.fornecedorList.razao);
    //     setFildEndereco(json.fornecedorList.endereco);
    //     setFildNumero(json.fornecedorList.numero);
    //     setFildComplemento(json.fornecedorList.complemento);
    //     setFildBairro(json.fornecedorList.bairro);
    //     setFildCodigoCidade(json.fornecedorList.codigocidade);
    //     setFildCodigoUf(json.fornecedorList.codigouf);
    //     setFildCep(json.fornecedorList.cep);
    //     setFildInscricao(json.fornecedorList.inscricao);
    //     setFildCnpj(json.fornecedorList.cnpj);
    //     setFildFone(json.fornecedorList.fone);
    //     setFildFax(json.fornecedorList.fax);
    //     setFildCelular(json.fornecedorList.celular);
    //     setFildContato(json.fornecedorList.contato);
    //     setFildFantasia(json.fornecedorList.fantasia);
    //     setFildStatus(json.fornecedorList.status);
    //     setFildGrupo(json.fornecedorList.grupo);
    //     setFildObservacao(json.fornecedorList.observacao);
    //     setFildEmail(json.fornecedorList.email);
    //     setFildPlanoContas(json.fornecedorList.planocontas);
    //     setFildPontoReferencia(json.fornecedorList.pontoreferencia);
    //     setFildCodigoRecebimento(json.fornecedorList.codigorecebimento);
    //     setFildPais(json.fornecedorList.pais);
    //     setFildInscricaoMunicipal(json.fornecedorList.inscricaomunicipal);
    //     setFildCfop(json.fornecedorList.cfop);
    //     setFildDataCadastro(json.fornecedorList.datacadastro);
    //     setFildDataAtualizacao(json.fornecedorList.dataalteracao);
    //     setFildResponsavel(json.fornecedorList.responsavel);

    //   setExibirProgress(false);
    //   SetRecarregar(false);
    // }

    // if(json.cfopList!==null){
    //   setLsCfop(json.cfopList[0]);
    //   setExibirProgressCfop(false);

    // }

    // if(json.recebimentoList!==null){
    //   setLsRecebimento(json.recebimentoList);
    //   setExibirProgressRecebimentos(false);

    // }

    // if(json.categoriaList!==null){
    //   setLsCategorias(json.categoriaList);
    //   setExibirProgressCategorias(false);

    // }

    // if(json.planocontasList!==null){
    //   setLsPlanoContas(json.planocontasList);
    //   setExibirProgressPlanoContas(false);

    // }

  }


  // async function listarContatos() {

  //   setExibirProgressContatos(true);

  //   const response = await fetch('https://www.trinityst.com.br/apiAntares/administrativo/apiClientesContatos.php', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       requisicao: 'listar',
  //       senha: senhaRetorno,
  //       login: usuarioRetorno,
  //       cliente: fildCodigo
  //     })
  //   });

  //   let json = await response.json();

  //   if (json.success) {
  //     if (json.result === null) {
  //       setLsContatos([]);
  //     } else {
  //       setLsContatos(json.result);
  //     }
  //   }
  //   else {
  //     setLsContatos([]);
  //   }

  //   setExibirProgressContatos(false);

  // }

   // eslint-disable-next-line react-hooks/exhaustive-deps
  async function listarMunicipios(_uf) {

    //Chama o progress
    setExibirProgressMunicipios(true);

    //Chama a API

    const response = await fetch(`${Config.urlRootMaster}/coutryibge/list`, {
      method: 'POST',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
      body: JSON.stringify({
        dataBase:configuracaoBd,
        cuf:fildCodigoUf,
        cidade:"",
        order:""
      })
    });

    let json = await response.json();

    if(response.status!==200){
      setDadosMunicipios([]);
      setExibirProgressMunicipios(false);

      return;
    }

    setDadosMunicipios(json);
    setExibirProgressMunicipios(false);

  }

  async function ConsultarCep() {

    if (fildCep.length === 8 && cepJaConsultado === false) {

      //Chama a API

      const response = await fetch('https://viacep.com.br/ws/' + fildCep + '/json/');

      let json = await response.json();

      if (json.erro === 'true') {

      }
      else {
        setCepJaConsultado(true);

        const c_uf = dadosUf.find(element => element.uf === json.uf).cuf;

        setFildCodigoUf(c_uf);

        setFildEndereco(json.logradouro);
        setFildComplemento(json.complemento);
        setFildBairro(json.bairro);
        setFildCodigoCidade(json.ibge);
      }

    }

  }

  async function ConsultarCnpj() {
    setimpedirMultClick(true);

    if (fildCnpj.length === 14) {

      const response = await fetch('https://www.trinityst.com.br/apiAntares/apiUtilitarios.php', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          requisicao: 'consulta-cnpj',
          senha: senhaRetorno,
          login: usuarioRetorno,
          cnpj: fildCnpj
        })
      });

      let json = await response.json();

      if (json.status === "ERROR") {

        setimpedirMultClick(false);

        setTipoMensagem('error');
        setTextoMensagem(json.message);
        handleOpenMessage();

      } else {

        //const itemExists = dadosUf.find((item) => item.uf === json.uf);

        dadosUf.map((item) => {
          if (item.uf.toLowerCase() === json.uf.toLowerCase()) {

            setFildCodigoUf(item.cuf);
          }
        });

        setTimeout(() => {

          dadosMunicipio.map((item) => {

            if (item.municipio.toLowerCase() === json.municipio.toLowerCase()) {

              setFildCodigoCidade(item.cmunicipio);
            }
          });

        }, 4000);

        setFildRazao(json.nome);
        setFildFantasia(json.fantasia);
        setFildBairro(json.bairro);
        setFildEndereco(json.logradouro);
        setFildComplemento(json.complemento);
        setFildNumero(json.numero);
        setFildCep(json.cep);

        try { setFildFone(json.telefone); } catch { }
        try { setFildEmail(json.email); } catch { }

        setimpedirMultClick(false);

      }

    }

  }

  //Fim Listas

  async function salvarGrupo() {

    if (descricaoNovoGrupo.length === 0) {
      return;
    }
    else {

      setExibirProgressCategorias(true);

      //Chama a API

      const registro = [{
        categoria: idGrupo,
        descricao: descricaoNovoGrupo
      }];

      const response = await fetch(`${Config.urlRoot}/category`, {
        method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201 ){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //atualiza na tela os dados pois tem que atualizar a data de  cadastro e atualizacao e o responsavel
        setLsCategorias(json);

      }

      //Encerra o progress
      setIdGrupo('0');
      setDescricaoNovoGrupo('');
      setExibirProgressCategorias(false);
      handleCloseModalGrupo();
    }
  }

  async function salvarContato() {

    if (fildCodigo.length === 0 || fildCodigo==="0") {
      return;
    }

      setExibirProgressContatos(true);
      //Chama a API

      const registro = [{
        codigo:contatoId,
        cliente: fildCodigo,
        nome: contatoNome,
        cargo: contatoCargo,
        fone: contatoFone,
        email: contatoEmail,
        aniversario: contatoAniversario,
        fone2: contatoFone2
      }];

      const response = await fetch(`${Config.urlRoot}/contactprovider`, {
        method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
          dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201 ){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        //atualiza na tela os dados pois tem que atualizar a data de  cadastro e atualizacao e o responsavel
        setLsContatos(json);
      }

      //Encerra o progress
      setIdContato('0');
      setContatoNome('');
      setContatoCargo('');
      setContatoFone('');
      setContatoEmail('');
      setContatoAniversario(null);
      setContatoFone2('');

      setExibirProgressContatos(false);
      handleCloseModalContato();

  }

  async function salvarDados() {

    if (fildRazao.length === 0) {
      setValidaRazao(true);
      setMsgErroRazao('Informe a Razão Social!');
    }

    if (fildFantasia.length === 0) {
      setValidaFantasia(true);
      setMsgErroFantasia('Informe o Nome Fantasia!');
    }

    if (fildCnpj.length !== 11 && fildCnpj.length !== 14) {
      setValidaCgc(true);
      setMsgErroCgc('CPF/CNPJ inválido!');
    }

    if (fildRazao.length === 0) {

      setValidaRazao(true);
      setMsgErroRazao('Informe a Razão Social!');
    }
    else {

      //Limpa a validação do campo
      setValidaRazao(false);
      setMsgErroRazao('');

      setValidaFantasia(false);
      setMsgErroFantasia('');

      //Chama a API

      const registro = [{
        id: parseInt(fildId, 10),
        razao: fildRazao,
        endereco: fildEndereco,
        numero: fildNumero,
        complemento: fildComplemento,
        bairro: fildBairro,
        codigocidade: fildCodigoCidade,
        codigouf: fildCodigoUf,
        cep: fildCep,
        inscricao: fildInscricao,
        cnpj: fildCnpj,
        fone: fildFone,
        fax: fildFax,
        celular: fildCelular,
        contato: fildContato,
        fantasia: fildFantasia,
        status: fildStatus,
        grupo: fildGrupo,
        observacao: fildObservacao,
        email: fildEmail,
        planocontas: fildPlanoContas,
        pontoreferencia: fildPontoReferencia,
        codigorecebimento: fildCodigoRecebimento,
        pais: fildPais,
        inscricaomunicipal: fildInscricaoMunicipal,
        cfop: fildCfop,
        responsavel: usuarioRetorno
      }];

      setimpedirMultClick(true);

      const response = await fetch(`${Config.urlRoot}/provider`, {
        method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({
        dataBase:configuracaoBd,
          registro: registro
        })
      });

      let json = await response.json();

      if(response.status!==200 && response.status!==201 ){

        setTipoMensagem('error');
        setTextoMensagem('Falha ao salvar o registro!');

      } else {

        setTipoMensagem('success');
        setTextoMensagem('Registro salvo com sucesso!');

        setNovoRegistro(false);


        //id restornado
        setFildId(json[0].id);
        setFildCodigo(json[0].codigo);
        // setFildRazao(json[0].razao);
        // setFildEndereco(json[0].endereco);
        // setFildNumero(json[0].numero);
        // setFildComplemento(json[0].complemento);
        // setFildBairro(json[0].bairro);
        // setFildCodigoCidade(json[0].codigocidade);
        // setFildCodigoUf(json[0].codigouf);
        // setFildCep(json[0].cep);
        // setFildInscricao(json[0].inscricao);
        // setFildCnpj(json[0].cnpj);
        // setFildFone(json[0].fone);
        // setFildFax(json[0].fax);
        // setFildCelular(json[0].celular);
        // setFildContato(json[0].contato);
        // setFildFantasia(json[0].fantasia);
        // setFildStatus(json[0].status);
        // setFildGrupo(json[0].grupo);
        // setFildObservacao(json[0].observacao);
        // setFildEmail(json[0].email);
        // setFildPlanoContas(json[0].planocontas);
        // setFildPontoReferencia(json[0].pontoreferencia);
        // setFildCodigoRecebimento(json[0].codigorecebimento);
        // setFildPais(json[0].pais);
        // setFildInscricaoMunicipal(json[0].inscricaomunicipal);
        // setFildCfop(json[0].cfop);
        setFildDataCadastro(json[0].created_at);
        setFildDataAtualizacao(json[0].updateted_at);
        // setFildResponsavel(json[0].responsavel);

        SetRecarregar(false);
      }

      //Encerra o progress
      setimpedirMultClick(false);

      handleOpenMessage();
    }
  }

  var userDispatch = useUserDispatch();

  useEffect(() => {
    if(usuarioRetorno === "Não Identificado"){
      signOut(userDispatch, props.history);
    }
  }, [props.history, userDispatch]);

  useEffect(() => {

    if (recarregarCidade) {
      if (fildCodigoUf.length > 0) {
        SetRecarregarCidade(false);
        listarMunicipios(fildCodigoUf);
      }
    }

  }, [fildCodigoUf, listarMunicipios, recarregarCidade]);

  useEffect(() => {

    //É um novo Registro
    if (props.location.idregistro.text === undefined) {
      props.history.push('/app/ProviderRegister');

    }
    else if (props.location.idregistro.text === "0") {

      setExibirProgress(false);
      setNovoRegistro(true);

      if(recarregar){
        popularFormulario("0");
      }

    } else {

      if(recarregar){

        SetRecarregarCidade(true);

      }

    }

  }, [ props.history, props.location.idregistro.text, recarregar]);


  async function dadosCompletoContato(id) {

    const response = await fetch(`${Config.urlRoot}/contactprovider/list`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        senha:senhaRetorno,
        login:usuarioRetorno,
        nome:usuarioRetorno,
        pesquisar:id
      })
    });

    let json = await response.json();

    if(response.status!==200){
      setExibirProgressCategorias(false);
      return;
    }

    setFildCodigo(json.result[0].id);
    setContatoCliente(json.result[0].codigofornecedor);
    setContatoNome(json.result[0].nome);
    setContatoCargo(json.result[0].cargo);
    setContatoFone(json.result[0].fone);
    setContatoEmail(json.result[0].email);

    if (json.result[0].admissao !== '') {
      setContatoAniversario(dayjs(json.result[0].aniversario));
    }

    setContatoSexo(json.result[0].sexo);
    setContatoFone2(json.result[0].fone2);

    setExibirProgressCategorias(false);
    //chama o modal
    handleOpenModalContato();
  }

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModalMunicipios = () => {
    setOpenModalMunicipios(true);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const styleContato = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  function modalGrupos() {

    return (
      <Modal
        open={openGrupo}
        onClose={handleCloseModalGrupo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h3">
            Novo Grupo

            <FormControl fullWidth size="small" sx={{ m: 0 }} variant="filled">
              <InputLabel htmlFor="tfNovoGrupo">Descrição</InputLabel>
              <FilledInput
                id="tfNovoGrupo"
                variant="filled"
                value={descricaoNovoGrupo}
                //defaultValue={descricaoNovoGrupo}
                inputProps={{ maxLength: 20 }}
                onChange={(event) => setDescricaoNovoGrupo(event.target.value)}
              />
            </FormControl>

          </Typography>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalGrupo}>Cancelar
          </Button>

          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarGrupo}>Salvar
          </Button>

        </Box>
      </Modal>
    )
  }

  function modalContatos() {
    return (
      <Modal
        open={openContato}
        onClose={handleCloseModalContato}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleContato}>

          <Typography id="modal-modal-title" variant="h6" component="h3">
            Contato
            <Grid item xs={12} md={4} >
              <Card variant="outlined">
                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 0, width: '45ch' }, }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl fullWidth size="small" sx={{ m: 1, width: '36.8ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoNome">Nome</InputLabel>
                    <FilledInput
                      id="tfContatoNome"
                      variant="filled"
                      value={contatoNome}
                      //defaultValue={contatoNome}
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => setContatoNome(event.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 1, width: '36.8ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoCargo">Cargo/Departamento</InputLabel>
                    <FilledInput
                      id="tfContatoCargo"
                      variant="filled"
                      value={contatoCargo}
                      //defaultValue={contatoCargo}
                      inputProps={{ maxLength: 30 }}
                      onChange={(event) => setContatoCargo(event.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 1, width: '17.7ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoFone">Fone</InputLabel>
                    <FilledInput
                      id="tfContatoFone"
                      type={'number'}
                      value={contatoFone}
                      //defaultValue={contatoFone}
                      onChange={(event) => setContatoFone(event.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth size="small" sx={{ m: 1, width: '17.7ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoFone2">Celular</InputLabel>
                    <FilledInput
                      id="tfContatoFone2"
                      type={'number'}
                      value={contatoFone2}
                      //defaultValue={contatoFone2}
                      onChange={(event) => setContatoFone2(event.target.value)}
                    />
                  </FormControl>


                  <FormControl fullWidth size="small" sx={{ m: 1, width: '36.8ch' }} variant="filled">
                    <InputLabel htmlFor="tfContatoEmail">Email</InputLabel>
                    <FilledInput
                      id="tfContatoEmail"
                      variant="filled"
                      value={contatoEmail}
                      //defaultValue={contatoEmail}
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => setContatoEmail(event.target.value)}
                    />
                  </FormControl>

                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '36.8ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        noValidate={false}
                        id="tfContatoAniversrio"
                        label="Nascimento"
                        inputFormat="DD/MM/YYYY"
                        value={contatoAniversario}
                        onChange={handleChangeNascimento}
                        renderInput={(params) => <TextField sx={{ m: 0, width: '36.8ch' }} variant="filled" {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>

                </Box>
              </Card>
            </Grid>

          </Typography>

          <Button variant="contained" color="error" style={{ margin: 5 }}
            onClick={handleCloseModalContato}>Cancelar
          </Button>

          <Button variant="contained" color="success" style={{ margin: 5 }}
            onClick={salvarContato}>Salvar
          </Button>

        </Box>
      </Modal>
    )
  }

  function botaoVoltar() {
    props.history.push('/app/Provider');
  }

  function botaoNovo() {

    setFildId('0');
    setFildCodigo('');
    setFildRazao('');
    setFildFantasia('');
    setFildEndereco('');
    setFildBairro('');
    setFildComplemento('');
    setFildCep('');
    setFildInscricao('');
    setFildCnpj('');
    setFildFone('');
    setFildFax('');
    setFildContato('');
    setFildStatus('true');
    setFildDataCadastro('');
    setFildEmail('');
    setFildDataAtualizacao('');
    setFildObservacao('');
    setFildCodigoRecebimento('');
    setFildReferencias('');
    setFildPlanoContas('');
    setFildCodigoCidade('');
    setFildCodigoUf('');
    setFildPais('');
    setFildCfop('');
    setFildAuxiliar('');
    setFildNumero('');
    setFildCelular('');
    setFildPontoReferencia('');
    setFildInscricaoMunicipal('');
    setFildResponsavel('');
    setFildGrupo('');
    setContatoCliente('');
    setContatoNome('');
    setContatoCargo('');
    setContatoFone('');
    setContatoEmail('');
    setContatoAniversario(null);
    setContatoSexo('');
    setContatoFone2('');

    setLsContatos([]);
  }

  return (
    <>

      {modalGrupos()}
      {modalContatos()}

      <PageTitle title="Fornecedor "
        buttonReturn="Voltar" eventoRetornar={botaoVoltar}

        buttonNew="Novo" eventoNovo={botaoNovo}

        button="Salvar" eventoBotao={salvarDados} />

      <Snackbar open={openMessage} autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity={tipoMensagem} sx={{ width: '100%' }}>
          {textoMesagem}
        </Alert>
      </Snackbar>

      {exibirProgress && ( <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> )}

      <AguardarRetorno show={impedirMultClick} />

      <Grid container spacing={0}>

        <Card sx={{ maxWidth: 533, m: 0.6 }}>
          <CardContent>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfRazao">Razão Social</InputLabel>
              <FilledInput
                id="tfRazao"
                value={fildRazao}
                //defaultValue={fildRazao}
                inputProps={{ maxLength: 100 }}
                variant="filled"
                onChange={(event) => setFildRazao(event.target.value)}
                error={validaRazao}

              />
            </FormControl>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfFantasia">Nome Fantasia</InputLabel>
              <FilledInput
                id="tfFantasia"
                variant="filled"
                value={fildFantasia}
                //defaultValue={fildFantasia}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => setFildFantasia(event.target.value)}
                error={validaFantasia}

              />
            </FormControl>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfCpfCnpj">CPF / CNPJ</InputLabel>
              <FilledInput
                id="tfCpfCnpj"
                value={fildCnpj}
                //defaultValue={fildCnpj}
                inputProps={{ maxLength: 14 }}
                variant="filled"
                onChange={(event) => handleChangeCnpj(event.target.value)}
                error={validaCgc}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      // aria-label="toggle password visibility"
                      onClick={(event) => ConsultarCnpj()}
                    >
                      <SearchIcon color="primary" />
                    </IconButton>
                  </InputAdornment>}
              />
            </FormControl>

            <div>
              <FormControl fullWidth size="small" sx={{ m: 0.6, width: '48.1%' }} variant="filled">
                <InputLabel htmlFor="tfIe">Inscrição Estadual</InputLabel>
                <FilledInput
                  id="tfIe"
                  value={fildInscricao}
                  //defaultValue={fildInscricao}
                  inputProps={{ maxLength: 18 }}
                  variant="filled"
                  onChange={(event) => setFildInscricao(event.target.value)}

                />
              </FormControl>

              <FormControl fullWidth size="small" sx={{ m: 0.6, width: '48.05%' }} variant="filled">
                <InputLabel htmlFor="tfIm">Inscrição Municipal</InputLabel>
                <FilledInput
                  id="tfIm"
                  value={fildInscricaoMunicipal}
                  //defaultValue={fildInscricaoMunicipal}
                  inputProps={{ maxLength: 18 }}
                  variant="filled"
                  onChange={(event) => setFildInscricaoMunicipal(event.target.value)}

                />
              </FormControl>
            </div>

            <div>
              <FormControl fullWidth size="small" sx={{ m: 0.6, width: '48.1%' }} variant="filled">
                <InputLabel htmlFor="tfFon1">Fone</InputLabel>
                <FilledInput
                  id="tfFon1"
                  value={fildFone}
                  //defaultValue={fildFone}
                  inputProps={{ maxLength: 11 }}
                  variant="filled"
                  onChange={(event) => setFildFone(event.target.value)}

                />
              </FormControl>

              <FormControl fullWidth size="small" sx={{ m: 0.6, width: '48.05%' }} variant="filled">
                <InputLabel htmlFor="tfFon2">Fone 2</InputLabel>
                <FilledInput
                  id="tfFon2"
                  value={fildFax}
                  //defaultValue={fildFax}
                  inputProps={{ maxLength: 11 }}
                  variant="filled"
                  onChange={(event) => setFildFax(event.target.value)}

                />
              </FormControl>
            </div>


            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfCelular">Celular</InputLabel>
              <FilledInput
                id="tfCelular"
                value={fildCelular}
                //defaultValue={fildCelular}
                inputProps={{ maxLength: 11 }}
                variant="filled"
                onChange={(event) => setFildCelular(event.target.value)}

              />
            </FormControl>

            <TextField
              id="tfContato"
              select
              size="small"
              label="Contatos"
              value={fildContato}
              variant="filled"
              onChange={handleChangeContato}
              sx={{ m: 0.6 }}
              fullWidth
            >
              {!novoRegistro && (
                <IconButton size="small"
                  sx={{ color: green[500] }}
                  onClick={handleOpenModalContato}
                ><AddIcon fontSize="inherit" style={{ marginRight: 5 }} />

                </IconButton>
              )}

              {lsContatos.map((option) => (
                <MenuItem key={option.id} value={option.nome}>

                  <IconButton size="small"
                    sx={{ color: blue[500] }}
                    //  onClick={(event) => setIdUnidade([option.id,option.descricao])}
                    onClick={() => (handleChangeEditContato(option.id))}
                  >

                    <EditIcon fontSize="inherit" style={{ marginRight: 5 }} />

                  </IconButton>

                  {option.nome}
                </MenuItem>
              ))}

            </TextField>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfEmail">Email</InputLabel>
              <FilledInput
                id="tfEmail"
                value={fildEmail}
                //defaultValue={fildEmail}
                inputProps={{ maxLength: 100 }}
                variant="filled"
                onChange={(event) => setFildEmail(event.target.value)}

              />
            </FormControl>

          </CardContent>

        </Card>

        <Card sx={{ minWidth: 533, maxWidth: 533, m: 0.6 }}>
          <CardContent>
            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfCep">CEP</InputLabel>
              <FilledInput
                id="tfCep"
                value={fildCep}
                //defaultValue={fildCep}
                inputProps={{ maxLength: 8 }}
                variant="filled"
                onChange={(event) => handleChangeCep(event.target.value)}

                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(event) => ConsultarCep()}
                    >
                      <SearchIcon color="primary" />
                    </IconButton>
                  </InputAdornment>}
              />
            </FormControl>

            <div>
              <FormControl  size="small"  sx={{ m: 0.6, width: '75%' }} variant="filled">
                <InputLabel htmlFor="tfEndereco">Endereço</InputLabel>
                <FilledInput
                  id="tfEndereco"
                  value={fildEndereco}
                  //defaultValue={fildEndereco}
                  inputProps={{ maxLength: 100 }}
                  variant="filled"
                  onChange={(event) => setFildEndereco(event.target.value)}

                />
              </FormControl>

              <FormControl  size="small"  sx={{ m: 0.6, width: '20%' }} variant="filled">
                <InputLabel htmlFor="tfNumero">Número</InputLabel>
                <FilledInput
                  id="tfNumero"
                  value={fildNumero}
                  //defaultValue={fildNumero}
                  inputProps={{ maxLength: 10 }}
                  variant="filled"
                  onChange={(event) => setFildNumero(event.target.value)}

                />
              </FormControl>
            </div>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfBairro">Bairro</InputLabel>
              <FilledInput
                id="tfBairro"
                value={fildBairro}
                //defaultValue={fildBairro}
                inputProps={{ maxLength: 50 }}
                variant="filled"
                onChange={(event) => setFildBairro(event.target.value)}

              />
            </FormControl>

            <TextField fullWidth size="small" sx={{ m: 0.6 }}
              id="tfUf"
              select
              label="UF"
              value={fildCodigoUf}
              onChange={handleChangeUf}
              variant="filled"
            >
              {dadosUf.map((option) => (
                <MenuItem key={option.cuf} value={option.cuf}>
                  {option.uf + ' - ' + option.nome}
                </MenuItem>
              ))}
            </TextField>
            <div>

              {exibirProgressMunicipios && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField
                id="tfCidade"
                select
                size="small"
                label="Cidade"
                value={fildCodigoCidade}
                onChange={handleChangeCidade}
                variant="filled"
                fullWidth
                sx={{ m: 0.6 }}
              >
                {dadosMunicipio.map((option) => (
                  <MenuItem key={option.cmunicipio} value={option.cmunicipio}>
                    {option.municipio}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfPontoreferencia">Ponto de Referência</InputLabel>
              <FilledInput
                id="tfPontoreferencia"
                value={fildPontoReferencia}
                //defaultValue={fildPontoReferencia}
                inputProps={{ maxLength: 255 }}
                variant="filled"
                onChange={(event) => setFildPontoReferencia(event.target.value)}

              />
            </FormControl>

            <div>
              {exibirProgressCfop && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField fullWidth
                id="tfCfop"
                select
                size="small"
                label="CFOP"
                value={fildCfop}
                onChange={handleChangeCfop}
                variant="filled"
                sx={{ m: 0.6 }}
              >
                {lsCfop.map((option) => (
                  <MenuItem key={option.id} value={option.cfop}>
                    {option.cfop + " - " + option.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </div>


            <div>
              {exibirProgressPlanoContas && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField fullWidth
                id="tfPlanoContas"
                select
                size="small"
                label="Plano de Contas"
                value={fildPlanoContas}
                onChange={handleChangePlanoContas}
                variant="filled"
                sx={{ m: 0.6 }}
              >
                {lsPlanoContas.map((option) => (
                  <MenuItem key={option.codigoresumido} value={option.codigoresumido}>
                    {option.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </div>

          </CardContent>
        </Card>

        <Card sx={{ minWidth: 533, maxWidth: 533, m: 0.6 }}>
          <CardContent>
            <TextField
              id="tfGrupo"
              select
              size="small"
              label="Grupo"
              value={fildGrupo}
              onChange={handleChangeGrupo}
              variant="filled"
              fullWidth
              sx={{ m: 0.6 }}
            >
              <IconButton size="small"
                sx={{ color: green[500] }}
                onClick={handleOpenModalGrupo}
              ><AddIcon fontSize="inherit" style={{ marginRight: 5 }} />

              </IconButton>

              {lsCategorias.map((option) => (
                <MenuItem key={option.categoria} value={option.categoria}>

                  <IconButton size="small"
                    sx={{ color: blue[500] }}
                    //  onClick={(event) => setIdUnidade([option.id,option.descricao])}
                    onClick={() => (handleChangeEditGrupo(option.descricao, option.categoria))}
                  >

                    <EditIcon fontSize="inherit" style={{ marginRight: 5 }} />

                  </IconButton>

                  {option.descricao}
                </MenuItem>
              ))}
            </TextField>

            <div>
              {exibirProgressRecebimentos && (<Box sx={{ width: '100%' }}><LinearProgress /></Box>)}

              <TextField
                id="tfRecebimento"
                select
                size="small"
                label="Forma de pagamento"
                value={fildCodigoRecebimento}
                onChange={handleChangeRecebimento}
                variant="filled"
                sx={{ m: 0.6 }}
                fullWidth
              >
                {lsRecebimento.map((option) => (
                  <MenuItem key={option.codigo} value={option.codigo}>
                    {option.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </div>


            <div>
              <TextField
                id="tfAtivo"
                select
                size="small"
                label="Ativo"
                value={fildStatus}
                onChange={handleChangeAtivo}
                variant="filled"
                sx={{ m: 0.6, width: '48%' }}
              >
                {valoresSim_Nao.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

            </div>

            <FormControl fullWidth size="small" sx={{ m: 0.6 }} variant="filled">
              <InputLabel htmlFor="tfObservacao">Observação</InputLabel>
              <FilledInput
                id="tfObservacao"
                value={fildObservacao}
                //defaultValue={fildObservacao}
                inputProps={{ maxLength: 255 }}
                multiline
                rows={10}
                variant="filled"
                onChange={(event) => setFildObservacao(event.target.value)}

              />
            </FormControl>

          </CardContent>
        </Card>

      </Grid>
    </>
  );
}
